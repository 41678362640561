export const filterSections = {
  probes: 'probes',
  excursions: 'excursions',
  dods: 'dods',
} as const;

export const filterControls = {
  industry: 'industry',
  speciality: 'speciality',
  college: 'college',
  district: 'district',
} as const;

export const filterStatus = {
  applied: 'applied',
} as const;
