import { useUserFunctionality } from 'hooks';

import { ViewedFunctionCodes, ViewedFunctionKeys } from '../../../../const';
import { IUseFeaturePreview } from '../types';
import SnowThemePreviewComponent from './component';

const useSnowThemePreview = (): IUseFeaturePreview => {
  const user = useUserFunctionality();

  return {
    isLoaded: true,
    hasAccess: user.isStudent,
    functionCode: ViewedFunctionCodes[ViewedFunctionKeys.SNOW_THEME],
    component: SnowThemePreviewComponent,
  };
};

export default useSnowThemePreview;
