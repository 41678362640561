import безопасность from './безопасность.svg';
import гостеприимство from './гостеприимство.svg';
import день_открытых_дверей from './день_открытых_дверей.svg';
import здравоохранение from './здравоохранение.svg';
import ит from './ит.svg';
import креативная_индустрия from './креативная_индустрия.svg';
import образование from './образование.svg';
import промышленность from './промышленность.svg';
import проф_тестирование from './проф_тестирование.svg';
import строительство from './строительство.svg';
import торговля_и_финансы from './торговля_и_финансы.svg';
import транспорт from './транспорт.svg';
import экскурсии_к_работодателю from './экскурсии_к_работодателю.svg';

export const industryIllustrations = {
  безопасность,
  гостеприимство,
  день_открытых_дверей,
  здравоохранение,
  ит,
  креативная_индустрия,
  образование,
  промышленность,
  проф_тестирование,
  строительство,
  торговля_и_финансы,
  транспорт,
  экскурсии_к_работодателю,
};
