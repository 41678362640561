/* eslint-disable prettier/prettier */
/* eslint-disable */

import {
  all,
  fork,
  takeEvery,
  takeLatest,
  put
} from 'redux-saga/effects';

import { Action } from 'redux';
import { toast } from 'portfolio3/ui-kit';
import {
  tooltipOptions, LinkErrorCode, GENERAL_ERROR, SLURS_ERROR_CODE, ImportErrorsArray, NOT_FOUND_CODE, WRONG_OPERATOR, NO_RESULT_CODE, InterestHeadKindKeys, DUPLICATE_ENTITY_CODE, DUPLICATE_ENTITY_ERROR, ACCESS_ERROR_CODE, ACCESS_ERROR, RedirectToServiceErrorPageHttpCodes, HttpErrorCodes
} from '../const';

import {
  IQueryParams
} from '../api';

import {
  GET_USER_CONTEXT,
  GET_STUDENT_GIA_LIST,
  INITIALIZATION,
  GET_STUDENT_SHARE_LINK_LIST,
  CREATE_STUDENT_SHARE_LINK,
  UPDATE_STUDENT_SHARE_LINK_ACTIVITY,
  DELETE_STUDENT_SHARE_LINK,
  GET_LINK_SETTINGS,
  GET_STUDENT_INFO,
  GET_ATTACHMENT,
  GET_OLYMPIAD_LEVELS,
  GET_OLYMPIAD_RESULTS,
  GET_OLYMPIAD_PARALLELS,
  GET_OLYMPIAD_TYPES,
  GET_OLYMPIAD_FORMATS,
  GET_STUDENT_DIAGNOSIS,
  GET_PDF_STUDENT_DIAGNOSIS_FULL,
  GET_PDF_STUDENT_DIAGNOSIS_YEAR,
  GET_FEEDBACK_LINK,
  SAVE_VISIBILITY_SETTINGS,
  GET_USER_SETTINGS,
  SAVE_STUDENT_AVATAR,
  GET_EVENT_KINDS,
  GET_SPORT_KINDS,
  GET_TOURISM_KINDS,
  GET_CREATION_KINDS,
  GET_CULTURAL_KINDS,
  GET_REWARD_KINDS,
  getLinkSettingsActions,
  GET_PORTFOLIO_SUBCATEGORIES,
  GET_STATUS_REWARDS,
  GET_PROJECT_FORMATS,
  POST_PORTFOLIO_REWARD,
  EDIT_PORTFOLIO_REWARD,
  POST_PORTFOLIO_SPORT_REWARD,
  EDIT_PORTFOLIO_SPORT_REWARD,
  POST_PORTFOLIO_PROJECT,
  EDIT_PORTFOLIO_PROJECT,
  POST_PORTFOLIO_EVENT,
  POST_PORTFOLIO_EMPLOYMENT,
  POST_PORTFOLIO_AFFILATION,
  EDIT_PORTFOLIO_EVENT,
  EDIT_PORTFOLIO_EMPLOYMENT,
  EDIT_PORTFOLIO_AFFILATION,
  GET_PERSONS_OBJECTS,
  DELETE_PORTFOLIO_REWARD,
  DELETE_PORTFOLIO_SPORT_REWARD,
  DELETE_PORTFOLIO_PROJECT,
  DELETE_PORTFOLIO_EVENT,
  DELETE_PORTFOLIO_EMPLOYMENT,
  DELETE_PORTFOLIO_AFFILATION,
  GET_DISCIPLINES,
  GET_MUSEUMS,
  GET_CINEMAS,
  GET_THEATRES,
  GET_AFFILATION_KINDS,
  GET_SPORT_CLUBS,
  GET_SPORT_REWARD_KINDS,
  PUT_ATTACHMENT,
  GET_ORGANIZATIONS,
  GET_PARALLELS,
  GET_PERSONS_LIST,
  GET_STUDENT_EVENTS,
  GET_STUDENT_REWARDS,
  GET_STUDENT_PROJECTS,
  GET_STUDENT_EMPLOYMENTS,
  GET_STUDENT_SPORT_REWARDS,
  GET_STUDENT_AFFILATIONS,
  GET_SPORT_AGES_REF,
  GET_IMPORT_TEMPLATE,
  POST_ATTACHMENT_TEMPLATE,
  GET_HISTORY,
  GET_STUDENT_CHECKIN_HISTORY,
  DELETE_STUDENT_CHECKIN,
  GET_IMPORT_HISTORY,
  GET_IMPORT_REPORT,
  GET_IMPORT_LIST,
  GET_ATTACHMENT_TEMPLATE_INFO,
  GET_ATTACHMENT_TEMPLATE_INFO_PARTITION,
  DELETE_ATTACHMENT_TEMPLATE_INFO,
  DELETE_EMPLOYEE_EMPLOYMENT,
  DELETE_EMPLOYEE_AFFILATION,
  DELETE_EMPLOYEE_EVENT,
  DELETE_EMPLOYEE_PROJECT,
  DELETE_EMPLOYEE_REWARD,
  DELETE_EMPLOYEE_SPORT_REWARD,
  GET_EVENT_BY_ID,
  GET_PROJECT_BY_ID,
  GET_EMPLOYMENT_BY_ID,
  GET_AFFILATION_BY_ID,
  GET_REWARD_BY_ID,
  GET_SPORT_REWARD_BY_ID,
  GET_ADMIN_SETTINGS,
  GET_LEARNER_CATEGORIES,
  GET_SETTINGS_SECTIONS,
  SAVE_ADMIN_SETTINGS,
  GET_ADMIN_HISTORY,
  GET_ADMIN_LOG,
  GET_ACTION_KINDS,
  GET_STUDENT_CULTURE_VISITINGS,
  GET_ADMIN_FUNCTION_SETTINGS,
  SAVE_ADMIN_FUNCTION_SETTINGS,
  GET_ADMIN_DELETE_HISTORY,
  RESTORE_PORTFOLIO_ENTITY,
  GET_SOURCES,
  GET_STUDENT_INDEPENDENT_DIAGNOSIS,
  SAVE_INDEPENDENT_DIAGNOSIS_SETTINGS,
  GET_STUDENT_INTERESTS,
  GET_INTEREST_HEAD_KINDS,
  GET_INTEREST_ACTION_KINDS,
  GET_INTEREST_GROUP_KINDS,
  GET_INTEREST_OBJECT_KINDS,
  DELETE_STUDENT_INTEREST,
  CREATE_STUDENT_INTEREST_LIST,
  EDIT_STUDENT_INTEREST,
  SET_FUNCTION_VIEWED,
  GET_STUDENT_RECOMMENDATIONS,
  GET_STUDENT_GIA_WORLDSKILLS,
  POST_PORTFOLIO_GIA_WORLDSKILLS,
  EDIT_PORTFOLIO_GIA_WORLDSKILLS,
  DELETE_PORTFOLIO_GIA_WORLDSKILLS,
  GET_GIA_WORLDSKILLS_BY_ID,
  DELETE_EMPLOYEE_GIA_WORLDSKILLS,
  GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION,
  GET_SPORT_TRAINING_STAGE,
  GET_STUDENT_CLASS_RECOMMENDATIONS,
  GET_ADMIN_INCORRECT_DATA_FILE,
  REPORT_ERROR,
  GET_ADMIN_VIEW_MODE_SETTINGS,
  GET_ENTITY_ERROR_MESSAGES,
  GRATITUDE_TEACHER,
  GET_STUDENT_AVATAR,
  SAVE_USER_THEME_SETTINGS,
  GET_INDEPENDENT_DIAGNOSTICS,
  GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING,
  GET_META_SKILL_REF,
  GET_STUDENT_META_SKILLS,
  GET_STUDENT_SPO_DOCUMENTS,
  GET_DOCUMENT_REF,
  GET_STUDENT_JOBS,
  GET_STUDENT_SPO_EDUCATION,
  DELETE_PORTFOLIO_JOB,
  GET_STUDENT_SPO_STATUS,
  POST_STUDENT_META_SKILLS,
  POST_STUDENT_SPO_STATUS,
  GET_PROFESSIONAL_PROGRAMM_REF,
  GET_LEVEL_BUSINESS_REF,
  GET_SALARY_RANGE_REF,
  GET_EMPLOYMENT_DOC_TYPE_REF,
  POST_PORTFOLIO_JOB,
  EDIT_PORTFOLIO_JOB,
  POST_PORTFOLIO_SPO_DOCUMENT,
  EDIT_PORTFOLIO_SPO_DOCUMENT,
  DELETE_PORTFOLIO_SPO_DOCUMENT,
  GET_SPO_ORGANIZATION_REF,
  GET_SPO_GIA_MARK_REF,
  GET_PROFESSION_RANK_REF,
  GET_ARCHIVED_INTEREST_OBJECT_KINDS,
  GET_STUDENT_PERSONAL_ACCESS,
  UPDATE_STUDENT_PERSONAL_ACCESS,
  GET_STUDENT_EIS_DOP_RECOMMENDATIONS,
  GET_SECTION_REF,
  GET_INDEPENDENT_DIAGNOSTICS_RATING,
  GET_OLYMPIAD_SUBJECTS_REF,
  SAVE_AVATAR_SETTINGS,
  GET_AVATAR_TYPE_REF,
  GET_VIEWED_FUNCTIONS,
  SAVE_PERSONAL_DIAGNOSTICS_SETTINGS,
  GET_PERSONAL_DIAGNOSTICS,
} from '../actions';

import {
  getPortfolioSubcategoriesSaga,
  getStatusRewardsSaga,
  getProjectFormatsSaga,
  postPortfolioEventSaga,
  postPortfolioEmploymentSaga,
  postPortfolioAffilationSaga,
  editPortfolioEventSaga,
  editPortfolioEmploymentSaga,
  editPortfolioAffilationSaga,
  getEventKindSaga,
  getSportKindSaga,
  getTourismKindSaga,
  getCreationKindSaga,
  getCulturalKindSaga,
  getRewardKindSaga,
  postPortfolioRewardSaga,
  editPortfolioRewardSaga,
  postPortfolioSportRewardSaga,
  editPortfolioSportRewardSaga,
  postPortfolioProjectSaga,
  editPortfolioProjectSaga,
  getPersonsObjectsSaga,
  deletePortfolioRewardSaga,
  deletePortfolioSportRewardSaga,
  deletePortfolioProjectSaga,
  deletePortfolioEventSaga,
  deletePortfolioEmploymentSaga,
  deletePortfolioAffilationSaga,
  getDisciplinesSaga,
  getMuseumsSaga,
  getCinemasSaga,
  getTheatresSaga,
  getAffilationKindSaga,
  getSportClubsSaga,
  getSportRewardKindsSaga,
  getOrganizationsSaga,
  getParallelsSaga,
  getPersonsListSaga,
  getHistorySaga,
  getImportHistorySaga,
  getImportReportSaga,
  getImportTemplateSaga,
  postAttachmentTemplateSaga,
  getImportListSaga,
  getAttachmentTemplateInfoSaga,
  deleteAttachmentTemplateInfoSaga,
  getAttachmentTemplatePartitionInfoSaga,
  deleteEmployeeAffilationSaga,
  deleteEmployeeEmploymentSaga,
  deleteEmployeeEventSaga,
  deleteEmployeeProjectSaga,
  deleteEmployeeRewardSaga,
  deleteEmployeeSportRewardSaga,
  getEventByIdSaga,
  getProjectByIdSaga,
  getEmploymentByIdSaga,
  getAffilationByIdSaga,
  getRewardByIdSaga,
  getSportRewardByIdSaga,
  postPortfolioGiaWorldSkillsSaga,
  editPortfolioGiaWorldSkillsSaga,
  deletePortfolioGiaWorldSkillsSaga,
  getGiaWorldSkillsByIdSaga,
  deleteEmployeeGiaWorldSkillsSaga,
  getSportTrainingStageSaga,
  deletePortfolioJobSaga,
  postPortfolioJobSaga,
  editPortfolioJobSaga,
  postPortfolioSpoDocumentSaga,
  editPortfolioSpoDocumentSaga,
  deletePortfolioSpoDocumentSaga
} from './employees';

import {
  REQUEST
} from '../actions/utils';

import {
  deleteStudentShareLinkSaga,
  getStudentShareLinkListSaga,
  getStudentGiaListSaga,
  createStudentShareLinkSaga,
  updateStudentShareLinkActivitySaga,
  getStudentInfoSaga,
  getStudentDiagnosisSaga,
  saveVisibilitySettingsSaga,
  getUserSettingsSaga,
  saveStudentAvatarSaga,
  getStudentEventsSaga,
  getStudentRewardsSaga,
  getStudentProjectsSaga,
  getStudentEmploymentsSaga,
  getStudentSportRewardsSaga,
  getStudentAffilationsSaga,
  getStudentGiaWorldSkillsSaga,
  getPdfStudentDiagnosisFullSaga,
  getPdfStudentDiagnosisYearSaga,
  getStudentCheckInHistorySaga,
  deleteStudentCheckInSaga,
  getStudentCultureVisitingsSaga,
  getStudentIndependentDiagnosisSaga,
  saveIndependentDiagnosisSettingsSaga,
  getStudentRecommendationsSaga,
  getStudentProfessionalAdditionalEducationSaga,
  getStudentClassRecommendationsSaga,
  reportErrorSaga,
  getEntityErrorMessagesSaga,
  gratitudeTeacherSaga,
  getStudentAvatarSaga,
  saveUserThemeSettingsSaga,
  getStudentMetaSkillsSaga,
  getStudentSpoDocumentsSaga,
  getStudentJobsSaga,
  getStudentSpoStatusSaga,
  postStudentMetaSkillsSaga,
  getStudentSpoEducationSaga,
  postStudentSpoStatusSaga,
  getStudentPersonalAccessSaga,
  updateStudentPersonalAccessSaga,
  getStudentEisDopRecommendationsSaga,
  saveAvatarSettingsSaga
} from './students';

import {
  getOlympiadLevelsSaga,
  getOlympiadResultsSaga,
  getOlympiadParallelsSaga,
  getOlympiadTypesSaga,
  getOlympiadFormatsSaga,
  getSourcesSaga
} from './dictionaries';

import {
  initializationSaga,
  getUserContextSaga,
  getLinkSettingsSaga,
  getFeedbackLinkSaga
} from './auth';

import { redirectToChoicePage, getSharedLinkString, redirectToServiceErrorPage } from '../utils';

import { IResponseError } from '../api/types';
import {
  getAttachmentSaga, putAttachmentSaga
} from './attachments';
import {
  getActionKindsSaga,
  getAdminDeleteHistorySaga,
  getAdminFunctionSettingsSaga,
  getAdminHistorySaga,
  getAdminLogSaga,
  getAdminIncorrectDataFileSaga,
  getAdminSettingsSaga, getLearnerCategoriesSaga, getSettingSectionsSaga, restorePortfolioEntitySaga, saveAdminFunctionSettingsSaga, saveAdminSettingsSaga, getAdminViewModeSettingsSaga
} from './admins';

import {
  getInterestActionKindsSaga,
  getInterestHeadKindsSaga,
  getStudentInterestsSaga,
  getInterestGroupKindsSaga,
  getInterestObjectKindsSaga,
  deleteStudentInterestsSaga,
  createStudentInterestListSaga,
  editStudentInterestSaga,
  getArchivedInterestObjectKindsSaga
} from './interests';

import { getViewdFunctionsSaga, setFunctionViewedSaga } from './viewedFunctions';
import { getIndependentDiagnosticsGeneralRatingSaga, getIndependentDiagnosticsRatingSaga, getIndependentDiagnosticsSaga, getPersonalDiagnosticsSaga, savePersonalDiagnosticsSettingsSaga } from './diagnostics';
import { getAvatarTypeRefSaga, getDocumentRefSaga, getEmploymentDocTypeRefSaga, getLevelBusinessRefSaga, getMetaSkillRefSaga, getOlympiadSubjectsRefSaga, getProfessionProgramRefSaga, getProfessionRankRefSaga, getSalaryRangeRefSaga, getSectionRefSaga, getSpoGiaMarkRefSaga, getSpoOrganizationRefSaga, getSportAgesRefSaga } from './reference';
import { vuzRecommendationModel } from 'portfolio3/features/vuzRecommendation';
import { proforientationModel } from 'portfolio3/features/proforientation';
import { searchModel } from 'portfolio3/features/search';
import { performanceModel } from 'portfolio3/features/performance';
import { performanceOldModel } from 'portfolio3/features/performanceOld';

export interface GenericGetAction<P> {
  type: string;
  payload?: P;
}

export interface GenericPostAction<P> {
  type: string;
  payload?: P;
  requestHeaders?: any
}

export interface GenericCreateUpdateAction<R> {
  type: string;
  payload: {
    request: R
    queryParams?: IQueryParams;
    personId?: string;
  };
}

export interface GenericDeleteAction<P> {
  type: string;
  payload?: P;
  queryParams?: IQueryParams;
}

export interface IAction<T> extends Action<string> {
  payload: T;
}

function* watchForAll() {
  yield takeEvery('*', function* (action: IAction<IResponseError | any>) {
    if (action.type.includes('FAILURE')) {
      const { code, status, message, statusCode } = action.payload || {};
      if (window.location.pathname.includes('shared-link') && code === LinkErrorCode.noToken) {
        yield put(getLinkSettingsActions.request(getSharedLinkString(), new Date()));
        return;
      }

      if (RedirectToServiceErrorPageHttpCodes.includes(statusCode)) {
        redirectToServiceErrorPage();
      }

      // ?? предположительно тут нужно использовать statusCode, status - не понятно что и откуда
      if (status === HttpErrorCodes.FORBIDDEN) {
        redirectToChoicePage();
      } else if (code === ACCESS_ERROR_CODE) {
        toast.error(ACCESS_ERROR, tooltipOptions);
      } else if (code === SLURS_ERROR_CODE || code === NOT_FOUND_CODE || code === NO_RESULT_CODE || code === WRONG_OPERATOR || ImportErrorsArray.includes(code)) {
        toast.error(message, tooltipOptions);
      } else if (code === DUPLICATE_ENTITY_CODE) {
        toast.error(DUPLICATE_ENTITY_ERROR, tooltipOptions);
      } else if (code !== LinkErrorCode.expired && code !== LinkErrorCode.unavailable && code !== LinkErrorCode.notStarted) {
        toast.error(GENERAL_ERROR, tooltipOptions);
      }
    }
  });
}

// AUTH
function* watchInitialization() { yield takeLatest(INITIALIZATION, initializationSaga); }
function* watchGetUserContext() { yield takeLatest(GET_USER_CONTEXT[REQUEST], getUserContextSaga); }
function* watchGetLinkSettings() { yield takeLatest(GET_LINK_SETTINGS[REQUEST], getLinkSettingsSaga); }

// ATTACHMENTS
function* watchGetAttachment() { yield takeLatest(GET_ATTACHMENT[REQUEST], getAttachmentSaga); }
function* watchPutAttachment() { yield takeLatest(PUT_ATTACHMENT[REQUEST], putAttachmentSaga); }

// TEMPLATES
function* watchGetImportTemplateSaga() { yield takeLatest(GET_IMPORT_TEMPLATE[REQUEST], getImportTemplateSaga); }
function* watchGetAttachmentTemplateInfoSaga() { yield takeLatest(GET_ATTACHMENT_TEMPLATE_INFO[REQUEST], getAttachmentTemplateInfoSaga); }
function* watchGetAttachmentTemplatePartitionInfo() { yield takeLatest(GET_ATTACHMENT_TEMPLATE_INFO_PARTITION[REQUEST], getAttachmentTemplatePartitionInfoSaga); }
function* watchPostAttachmentTemplate() { yield takeLatest(POST_ATTACHMENT_TEMPLATE[REQUEST], postAttachmentTemplateSaga); }
function* watchGetImportListSaga() { yield takeLatest(GET_IMPORT_LIST[REQUEST], getImportListSaga); }
function* watchDeleteAttachmentTemplateInfoSaga() { yield takeLatest(DELETE_ATTACHMENT_TEMPLATE_INFO[REQUEST], deleteAttachmentTemplateInfoSaga); }

// STUDENTS
function* watchGetStudentInfo() { yield takeLatest(GET_STUDENT_INFO[REQUEST], getStudentInfoSaga); }

function* watchGetStudentGiaList() { yield takeLatest(GET_STUDENT_GIA_LIST[REQUEST], getStudentGiaListSaga); }

function* watchGetOlympiadLevels() { yield takeLatest(GET_OLYMPIAD_LEVELS[REQUEST], getOlympiadLevelsSaga); }
function* watchGetOlympiadParallels() { yield takeLatest(GET_OLYMPIAD_PARALLELS[REQUEST], getOlympiadParallelsSaga); }
function* watchGetOlympiadResults() { yield takeLatest(GET_OLYMPIAD_RESULTS[REQUEST], getOlympiadResultsSaga); }
function* watchGetOlympiadTypes() { yield takeLatest(GET_OLYMPIAD_TYPES[REQUEST], getOlympiadTypesSaga); }
function* watchGetOlympiadFormats() { yield takeLatest(GET_OLYMPIAD_FORMATS[REQUEST], getOlympiadFormatsSaga); }
function* watchGetSources() { yield takeLatest(GET_SOURCES[REQUEST], getSourcesSaga); }

function* watchGetStudentShareLinkList() { yield takeLatest(GET_STUDENT_SHARE_LINK_LIST[REQUEST], getStudentShareLinkListSaga); }
function* watchDeleteStudentShareLink() { yield takeLatest(DELETE_STUDENT_SHARE_LINK[REQUEST], deleteStudentShareLinkSaga); }
function* watchCreateStudentShareLink() { yield takeLatest(CREATE_STUDENT_SHARE_LINK[REQUEST], createStudentShareLinkSaga); }
function* watchUpdateStudentShareLinkActivity() { yield takeLatest(UPDATE_STUDENT_SHARE_LINK_ACTIVITY[REQUEST], updateStudentShareLinkActivitySaga); }

function* watchGetStudentDiagnosis() { yield takeLatest(GET_STUDENT_DIAGNOSIS[REQUEST], getStudentDiagnosisSaga); }
function* watchGetStudentIndependentDiagnosis() { yield takeLatest(GET_STUDENT_INDEPENDENT_DIAGNOSIS[REQUEST], getStudentIndependentDiagnosisSaga); }
function* watchSaveIndependentDiagnosisSettings() { yield takeLatest(SAVE_INDEPENDENT_DIAGNOSIS_SETTINGS[REQUEST], saveIndependentDiagnosisSettingsSaga); }
function* watchGetPdfStudentDiagnosisFull() { yield takeLatest(GET_PDF_STUDENT_DIAGNOSIS_FULL[REQUEST], getPdfStudentDiagnosisFullSaga); }
function* watchGetPdfStudentDiagnosisYear() { yield takeLatest(GET_PDF_STUDENT_DIAGNOSIS_YEAR[REQUEST], getPdfStudentDiagnosisYearSaga); }
function* watchGetFeedbackLink() { yield takeLatest(GET_FEEDBACK_LINK[REQUEST], getFeedbackLinkSaga); }

function* watchGetIndependentDiagnostics() { yield takeLatest(GET_INDEPENDENT_DIAGNOSTICS[REQUEST], getIndependentDiagnosticsSaga); }
function* watchGetIndependentDiagnosticsGeneralRating() { yield takeLatest(GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING[REQUEST], getIndependentDiagnosticsGeneralRatingSaga); }
function* watchGetIndependentDiagnosticsRating() { yield takeLatest(GET_INDEPENDENT_DIAGNOSTICS_RATING[REQUEST], getIndependentDiagnosticsRatingSaga); }
function* watchGetPersonalDiagnostics() { yield takeLatest(GET_PERSONAL_DIAGNOSTICS[REQUEST], getPersonalDiagnosticsSaga); }
function* watchSavePersonalDiagnosticsSettings() { yield takeLatest(SAVE_PERSONAL_DIAGNOSTICS_SETTINGS[REQUEST], savePersonalDiagnosticsSettingsSaga); }

function* watchGetUserSettings() { yield takeLatest(GET_USER_SETTINGS[REQUEST], getUserSettingsSaga); }
function* watchSaveVisibilitySettings() { yield takeLatest(SAVE_VISIBILITY_SETTINGS[REQUEST], saveVisibilitySettingsSaga); }
function* watchSaveThemeSettings() { yield takeLatest(SAVE_USER_THEME_SETTINGS[REQUEST], saveUserThemeSettingsSaga); }

function* watchSaveAvatarSettings() { yield takeEvery(SAVE_AVATAR_SETTINGS[REQUEST], saveAvatarSettingsSaga) }
function* watchGetStudentAvatar() { yield takeEvery(GET_STUDENT_AVATAR[REQUEST], getStudentAvatarSaga) }
function* watchSaveStudentAvatar() { yield takeLatest(SAVE_STUDENT_AVATAR[REQUEST], saveStudentAvatarSaga); }

function* watchGetStudentPersonalAccess() { yield takeLatest(GET_STUDENT_PERSONAL_ACCESS[REQUEST], getStudentPersonalAccessSaga); }
function* watchUpdateStudentPersonalAccess() { yield takeLatest(UPDATE_STUDENT_PERSONAL_ACCESS[REQUEST], updateStudentPersonalAccessSaga); }
function* watchGetStudentEvents() { yield takeLatest(GET_STUDENT_EVENTS[REQUEST], getStudentEventsSaga); }
function* watchGetStudentRewards() { yield takeLatest(GET_STUDENT_REWARDS[REQUEST], getStudentRewardsSaga); }
function* watchGetStudentSportRewards() { yield takeLatest(GET_STUDENT_SPORT_REWARDS[REQUEST], getStudentSportRewardsSaga); }
function* watchGetStudentProjects() { yield takeLatest(GET_STUDENT_PROJECTS[REQUEST], getStudentProjectsSaga); }
function* watchGetStudentEmployments() { yield takeLatest(GET_STUDENT_EMPLOYMENTS[REQUEST], getStudentEmploymentsSaga); }
function* watchGetStudentAffilations() { yield takeLatest(GET_STUDENT_AFFILATIONS[REQUEST], getStudentAffilationsSaga); }
function* watchGetStudentGiaWorldSkills() { yield takeLatest(GET_STUDENT_GIA_WORLDSKILLS[REQUEST], getStudentGiaWorldSkillsSaga); }
function* watchGetProfessionalAdditionalEducation() { yield takeLatest(GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION[REQUEST], getStudentProfessionalAdditionalEducationSaga); }
function* watchGetStudentJobs() { yield takeLatest(GET_STUDENT_JOBS[REQUEST], getStudentJobsSaga); }
function* watchGetStudentMetaSkills() { yield takeLatest(GET_STUDENT_META_SKILLS[REQUEST], getStudentMetaSkillsSaga); }
function* watchGetStudentSpoEducation() { yield takeLatest(GET_STUDENT_SPO_EDUCATION[REQUEST], getStudentSpoEducationSaga); }
function* watchGetStudentSpoStatus() { yield takeLatest(GET_STUDENT_SPO_STATUS[REQUEST], getStudentSpoStatusSaga); }
function* watchPostStudentSpoStatus() { yield takeLatest(POST_STUDENT_SPO_STATUS[REQUEST], postStudentSpoStatusSaga); }
function* watchGetStudentSpoDocuments() { yield takeLatest(GET_STUDENT_SPO_DOCUMENTS[REQUEST], getStudentSpoDocumentsSaga); }
function* watchPostStudentMetaSkills() { yield takeLatest(POST_STUDENT_META_SKILLS[REQUEST], postStudentMetaSkillsSaga); }
function* watchGetStudentRecommendations() { yield takeLatest(GET_STUDENT_RECOMMENDATIONS[REQUEST], getStudentRecommendationsSaga); }
function* watchGetStudentClassRecommendations() { yield takeLatest(GET_STUDENT_CLASS_RECOMMENDATIONS[REQUEST], getStudentClassRecommendationsSaga); }
function* watchGetStudentEisDopRecommendations() { yield takeLatest(GET_STUDENT_EIS_DOP_RECOMMENDATIONS[REQUEST], getStudentEisDopRecommendationsSaga); }
function* watchGetStudentCultureVisitings() { yield takeLatest(GET_STUDENT_CULTURE_VISITINGS[REQUEST], getStudentCultureVisitingsSaga); }
function* watchReportErrorMessage() { yield takeLatest(REPORT_ERROR[REQUEST], reportErrorSaga); }
function* watchGetEntityErrorMessages() { yield takeLatest(GET_ENTITY_ERROR_MESSAGES[REQUEST], getEntityErrorMessagesSaga); }
function* watchGratitudeTeacher() { yield takeLatest(GRATITUDE_TEACHER[REQUEST], gratitudeTeacherSaga); }

// EMPLOYEE
function* watchAffilationKinds() { yield takeLatest(GET_AFFILATION_KINDS[REQUEST], getAffilationKindSaga); }
function* watchEventKinds() { yield takeLatest(GET_EVENT_KINDS[REQUEST], getEventKindSaga); }
function* watchSportKinds() { yield takeLatest(GET_SPORT_KINDS[REQUEST], getSportKindSaga); }
function* watchGetPortfolioSubcategories() { yield takeLatest(GET_PORTFOLIO_SUBCATEGORIES[REQUEST], getPortfolioSubcategoriesSaga); }
function* watchGetStatusRewards() { yield takeLatest(GET_STATUS_REWARDS[REQUEST], getStatusRewardsSaga); }
function* watchGetProjectFormats() { yield takeLatest(GET_PROJECT_FORMATS[REQUEST], getProjectFormatsSaga); }
function* watchGetTourismKinds() { yield takeLatest(GET_TOURISM_KINDS[REQUEST], getTourismKindSaga); }
function* watchGetCreationKinds() { yield takeLatest(GET_CREATION_KINDS[REQUEST], getCreationKindSaga); }
function* watchGetCulturalKinds() { yield takeLatest(GET_CULTURAL_KINDS[REQUEST], getCulturalKindSaga); }
function* watchGetRewardKinds() { yield takeLatest(GET_REWARD_KINDS[REQUEST], getRewardKindSaga); }
function* watchGetDisciplines() { yield takeLatest(GET_DISCIPLINES[REQUEST], getDisciplinesSaga); }
function* watchGetPersonsObjects() { yield takeLatest(GET_PERSONS_OBJECTS[REQUEST], getPersonsObjectsSaga); }
function* watchGetPersonsList() { yield takeLatest(GET_PERSONS_LIST[REQUEST], getPersonsListSaga); }
function* watchGetOrganizations() { yield takeLatest(GET_ORGANIZATIONS[REQUEST], getOrganizationsSaga); }
function* watchGetParallels() { yield takeLatest(GET_PARALLELS[REQUEST], getParallelsSaga); }

function* watchGetMuseums() { yield takeLatest(GET_MUSEUMS[REQUEST], getMuseumsSaga); }
function* watchGetCinemas() { yield takeLatest(GET_CINEMAS[REQUEST], getCinemasSaga); }
function* watchGetTheatres() { yield takeLatest(GET_THEATRES[REQUEST], getTheatresSaga); }
function* watchGetSportClubs() { yield takeLatest(GET_SPORT_CLUBS[REQUEST], getSportClubsSaga); }
function* watchGetSportTrainingStage() { yield takeLatest(GET_SPORT_TRAINING_STAGE[REQUEST], getSportTrainingStageSaga); }
function* watchGetSportRewardKinds() { yield takeLatest(GET_SPORT_REWARD_KINDS[REQUEST], getSportRewardKindsSaga); }

function* watchPostPortfolioReward() { yield takeLatest(POST_PORTFOLIO_REWARD[REQUEST], postPortfolioRewardSaga); }
function* watchEditPortfolioReward() { yield takeLatest(EDIT_PORTFOLIO_REWARD[REQUEST], editPortfolioRewardSaga); }
function* watchDeletePortfolioReward() { yield takeLatest(DELETE_PORTFOLIO_REWARD[REQUEST], deletePortfolioRewardSaga); }

function* watchPostPortfolioSportReward() { yield takeLatest(POST_PORTFOLIO_SPORT_REWARD[REQUEST], postPortfolioSportRewardSaga); }
function* watchEditPortfolioSportReward() { yield takeLatest(EDIT_PORTFOLIO_SPORT_REWARD[REQUEST], editPortfolioSportRewardSaga); }
function* watchDeletePortfolioSportReward() { yield takeLatest(DELETE_PORTFOLIO_SPORT_REWARD[REQUEST], deletePortfolioSportRewardSaga); }

function* watchPostPortfolioProject() { yield takeLatest(POST_PORTFOLIO_PROJECT[REQUEST], postPortfolioProjectSaga); }
function* watchEditPortfolioProject() { yield takeLatest(EDIT_PORTFOLIO_PROJECT[REQUEST], editPortfolioProjectSaga); }
function* watchDeletePortfolioProject() { yield takeLatest(DELETE_PORTFOLIO_PROJECT[REQUEST], deletePortfolioProjectSaga); }

function* watchPostPortfolioEvent() { yield takeLatest(POST_PORTFOLIO_EVENT[REQUEST], postPortfolioEventSaga); }
function* watchEditPortfolioEvent() { yield takeLatest(EDIT_PORTFOLIO_EVENT[REQUEST], editPortfolioEventSaga); }
function* watchDeletePortfolioEvent() { yield takeLatest(DELETE_PORTFOLIO_EVENT[REQUEST], deletePortfolioEventSaga); }

function* watchPostPortfolioEmployment() { yield takeLatest(POST_PORTFOLIO_EMPLOYMENT[REQUEST], postPortfolioEmploymentSaga); }
function* watchEditPortfolioEmployment() { yield takeLatest(EDIT_PORTFOLIO_EMPLOYMENT[REQUEST], editPortfolioEmploymentSaga); }
function* watchDeletePortfolioEmployment() { yield takeLatest(DELETE_PORTFOLIO_EMPLOYMENT[REQUEST], deletePortfolioEmploymentSaga); }

function* watchPostPortfolioAffilation() { yield takeLatest(POST_PORTFOLIO_AFFILATION[REQUEST], postPortfolioAffilationSaga); }
function* watchEditPortfolioAffilation() { yield takeLatest(EDIT_PORTFOLIO_AFFILATION[REQUEST], editPortfolioAffilationSaga); }
function* watchDeletePortfolioAffilation() { yield takeLatest(DELETE_PORTFOLIO_AFFILATION[REQUEST], deletePortfolioAffilationSaga); }

function* watchPostPortfolioGiaWorldSkills() { yield takeLatest(POST_PORTFOLIO_GIA_WORLDSKILLS[REQUEST], postPortfolioGiaWorldSkillsSaga); }
function* watchEditPortfolioGiaWorldSkills() { yield takeLatest(EDIT_PORTFOLIO_GIA_WORLDSKILLS[REQUEST], editPortfolioGiaWorldSkillsSaga); }
function* watchDeletePortfolioGiaWorldSkills() { yield takeLatest(DELETE_PORTFOLIO_GIA_WORLDSKILLS[REQUEST], deletePortfolioGiaWorldSkillsSaga); }

function* watchPostPortfolioSpoDocument() { yield takeLatest(POST_PORTFOLIO_SPO_DOCUMENT[REQUEST], postPortfolioSpoDocumentSaga); }
function* watchEditPortfolioSpoDocument() { yield takeLatest(EDIT_PORTFOLIO_SPO_DOCUMENT[REQUEST], editPortfolioSpoDocumentSaga); }
function* watchDeletePortfolioSpoDocument() { yield takeLatest(DELETE_PORTFOLIO_SPO_DOCUMENT[REQUEST], deletePortfolioSpoDocumentSaga); }

function* watchPostPortfolioJob() { yield takeLatest(POST_PORTFOLIO_JOB[REQUEST], postPortfolioJobSaga); }
function* watchEditPortfolioJob() { yield takeLatest(EDIT_PORTFOLIO_JOB[REQUEST], editPortfolioJobSaga); }
function* watchDeletePortfolioJob() { yield takeLatest(DELETE_PORTFOLIO_JOB[REQUEST], deletePortfolioJobSaga); }

// удаление сущностей из истории изменений
function* watchDeleteEmployeeAffilation() { yield takeLatest(DELETE_EMPLOYEE_AFFILATION[REQUEST], deleteEmployeeAffilationSaga); }
function* watchDeleteEmployeeEmployment() { yield takeLatest(DELETE_EMPLOYEE_EMPLOYMENT[REQUEST], deleteEmployeeEmploymentSaga); }
function* watchDeleteEmployeeEvent() { yield takeLatest(DELETE_EMPLOYEE_EVENT[REQUEST], deleteEmployeeEventSaga); }
function* watchDeleteEmployeeProject() { yield takeLatest(DELETE_EMPLOYEE_PROJECT[REQUEST], deleteEmployeeProjectSaga); }
function* watchDeleteEmployeeReward() { yield takeLatest(DELETE_EMPLOYEE_REWARD[REQUEST], deleteEmployeeRewardSaga); }
function* watchDeleteEmployeeSportReward() { yield takeLatest(DELETE_EMPLOYEE_SPORT_REWARD[REQUEST], deleteEmployeeSportRewardSaga); }
function* watchDeleteEmployeeGiaWorldSkills() { yield takeLatest(DELETE_EMPLOYEE_GIA_WORLDSKILLS[REQUEST], deleteEmployeeGiaWorldSkillsSaga); }

function* watchGetHistory() { yield takeLatest(GET_HISTORY[REQUEST], getHistorySaga); }
function* watchGetImportHistory() { yield takeLatest(GET_IMPORT_HISTORY[REQUEST], getImportHistorySaga); }
function* watchGetImportReport() { yield takeLatest(GET_IMPORT_REPORT[REQUEST], getImportReportSaga); }

function* watchGetEventById() { yield takeLatest(GET_EVENT_BY_ID[REQUEST], getEventByIdSaga); }
function* watchGetProjectById() { yield takeLatest(GET_PROJECT_BY_ID[REQUEST], getProjectByIdSaga); }
function* watchGetEmploymentById() { yield takeLatest(GET_EMPLOYMENT_BY_ID[REQUEST], getEmploymentByIdSaga); }
function* watchGetAffilationById() { yield takeLatest(GET_AFFILATION_BY_ID[REQUEST], getAffilationByIdSaga); }
function* watchGetRewardById() { yield takeLatest(GET_REWARD_BY_ID[REQUEST], getRewardByIdSaga); }
function* watchGetSportRewardById() { yield takeLatest(GET_SPORT_REWARD_BY_ID[REQUEST], getSportRewardByIdSaga); }
function* watchGetGiaWorldSkillsById() { yield takeLatest(GET_GIA_WORLDSKILLS_BY_ID[REQUEST], getGiaWorldSkillsByIdSaga); }

// CheckIn
function* watchGetStudentCheckInHistory() { yield takeLatest(GET_STUDENT_CHECKIN_HISTORY[REQUEST], getStudentCheckInHistorySaga); }
function* watchDeleteStudentCheckIn() { yield takeLatest(DELETE_STUDENT_CHECKIN[REQUEST], deleteStudentCheckInSaga); }

// Admin
function* watchGetAdminSettings() { yield takeLatest(GET_ADMIN_SETTINGS[REQUEST], getAdminSettingsSaga); }
function* watchGetAdminViewModeSettings() { yield takeLatest(GET_ADMIN_VIEW_MODE_SETTINGS[REQUEST], getAdminViewModeSettingsSaga); }
function* watchSaveAdminSettings() { yield takeLatest(SAVE_ADMIN_SETTINGS[REQUEST], saveAdminSettingsSaga); }
function* watchGetLearnerCategories() { yield takeLatest(GET_LEARNER_CATEGORIES[REQUEST], getLearnerCategoriesSaga); }
function* watchGetSettingSections() { yield takeLatest(GET_SETTINGS_SECTIONS[REQUEST], getSettingSectionsSaga); }
function* watchGetAdminHistory() { yield takeLatest(GET_ADMIN_HISTORY[REQUEST], getAdminHistorySaga); }
function* watchGetAdminDeleteHistory() { yield takeLatest(GET_ADMIN_DELETE_HISTORY[REQUEST], getAdminDeleteHistorySaga); }
function* watchGetAdminLog() { yield takeLatest(GET_ADMIN_LOG[REQUEST], getAdminLogSaga); }
function* watchGetActionKinds() { yield takeLatest(GET_ACTION_KINDS[REQUEST], getActionKindsSaga); }
function* watchGetAdminFunctionSettings() { yield takeLatest(GET_ADMIN_FUNCTION_SETTINGS[REQUEST], getAdminFunctionSettingsSaga); }
function* watchSaveAdminFunctionSettings() { yield takeLatest(SAVE_ADMIN_FUNCTION_SETTINGS[REQUEST], saveAdminFunctionSettingsSaga); }
function* watchRestorePortfolioEntity() { yield takeLatest(RESTORE_PORTFOLIO_ENTITY[REQUEST], restorePortfolioEntitySaga); }
function* watchGetAdminIncorrectDataFile() { yield takeLatest(GET_ADMIN_INCORRECT_DATA_FILE[REQUEST], getAdminIncorrectDataFileSaga) }

// Interests
function* watchGetStudentInterests() { yield takeLatest(GET_STUDENT_INTERESTS[REQUEST], getStudentInterestsSaga); }
function* watchCreateStudentInterestList() { yield takeLatest(CREATE_STUDENT_INTEREST_LIST[REQUEST], createStudentInterestListSaga); }
function* watchEditStudentInterest() { yield takeLatest(EDIT_STUDENT_INTEREST[REQUEST], editStudentInterestSaga); }
function* watchDeleteStudentInterest() { yield takeLatest(DELETE_STUDENT_INTEREST[REQUEST], deleteStudentInterestsSaga) }
function* watchGetInterestHeadKinds() { yield takeLatest(GET_INTEREST_HEAD_KINDS[REQUEST], getInterestHeadKindsSaga); }
function* watchGetInterestActionKinds() { yield takeLatest(GET_INTEREST_ACTION_KINDS[REQUEST], getInterestActionKindsSaga); }
function* watchGetInterestGroupKinds() { yield takeLatest(GET_INTEREST_GROUP_KINDS[REQUEST], getInterestGroupKindsSaga); }
function* watchGetInterestObjectKinds() { yield takeLatest(GET_INTEREST_OBJECT_KINDS[REQUEST], getInterestObjectKindsSaga); }
function* watchGetArchivedInterestObjectKinds() { yield takeLatest(GET_ARCHIVED_INTEREST_OBJECT_KINDS[REQUEST], getArchivedInterestObjectKindsSaga); }

// Viewed functions
function* watchGetViewedFunctions() { yield takeLatest(GET_VIEWED_FUNCTIONS[REQUEST], getViewdFunctionsSaga); }
function* watchSetFunctionViewed() { yield takeLatest(SET_FUNCTION_VIEWED, setFunctionViewedSaga); }

// Reference
function* watchGetSectionRef() { yield takeLatest(GET_SECTION_REF[REQUEST], getSectionRefSaga); }
function* watchGetDocumentRef() { yield takeLatest(GET_DOCUMENT_REF[REQUEST], getDocumentRefSaga); }
function* watchGetMetaSkillRef() { yield takeLatest(GET_META_SKILL_REF[REQUEST], getMetaSkillRefSaga); }
function* watchGetProfessionProgramRef() { yield takeLatest(GET_PROFESSIONAL_PROGRAMM_REF[REQUEST], getProfessionProgramRefSaga); }
function* watchGetLevelBusinessRef() { yield takeLatest(GET_LEVEL_BUSINESS_REF[REQUEST], getLevelBusinessRefSaga); }
function* watchGetSalaryRangeRef() { yield takeLatest(GET_SALARY_RANGE_REF[REQUEST], getSalaryRangeRefSaga); }
function* watchGetEmploymentDocTypeRef() { yield takeLatest(GET_EMPLOYMENT_DOC_TYPE_REF[REQUEST], getEmploymentDocTypeRefSaga); }
function* watchGetSpoOrganizationRef() { yield takeLatest(GET_SPO_ORGANIZATION_REF[REQUEST], getSpoOrganizationRefSaga); }
function* watchGetSpoGiaMarkRef() { yield takeLatest(GET_SPO_GIA_MARK_REF[REQUEST], getSpoGiaMarkRefSaga); }
function* watchGetProfessionRankRef() { yield takeLatest(GET_PROFESSION_RANK_REF[REQUEST], getProfessionRankRefSaga); }
function* watchGetSportAgesRef() { yield takeLatest(GET_SPORT_AGES_REF[REQUEST], getSportAgesRefSaga); }
function* watchGetOlympiadSubjectsRef() { yield takeLatest(GET_OLYMPIAD_SUBJECTS_REF[REQUEST], getOlympiadSubjectsRefSaga); }
function* watchGetAvatarTypeRef() { yield takeLatest(GET_AVATAR_TYPE_REF[REQUEST], getAvatarTypeRefSaga); }

export default function* root() {
  yield all([
    fork(watchForAll),
    fork(watchInitialization),
    fork(watchGetLinkSettings),
    fork(watchGetUserContext),
    fork(watchGetAttachment),
    fork(watchGetAdminSettings),
    fork(watchGetAdminViewModeSettings),
    fork(watchGetActionKinds),
    fork(watchGetImportTemplateSaga),
    fork(watchPutAttachment),
    fork(watchPostAttachmentTemplate),
    fork(watchSaveAdminSettings),
    fork(watchSaveAdminFunctionSettings),
    fork(watchGetAdminFunctionSettings),
    fork(watchGetAttachmentTemplateInfoSaga),
    fork(watchGetAttachmentTemplatePartitionInfo),
    fork(watchGetStudentDiagnosis),
    fork(watchGetStudentIndependentDiagnosis),
    fork(watchSaveIndependentDiagnosisSettings),
    fork(watchGetPdfStudentDiagnosisFull),
    fork(watchGetPdfStudentDiagnosisYear),
    fork(watchGetStudentGiaList),
    fork(watchGetStudentAffilations),
    fork(watchGetStudentGiaWorldSkills),
    fork(watchGetProfessionalAdditionalEducation),
    fork(watchGetStudentJobs),
    fork(watchGetStudentMetaSkills),
    fork(watchGetStudentSpoEducation),
    fork(watchGetStudentSpoStatus),
    fork(watchPostStudentSpoStatus),
    fork(watchGetStudentSpoDocuments),
    fork(watchPostStudentMetaSkills),
    fork(watchGetStudentRecommendations),
    fork(watchGetStudentClassRecommendations),
    fork(watchGetStudentEisDopRecommendations),
    fork(watchGetOlympiadLevels),
    fork(watchGetOlympiadParallels),
    fork(watchGetOlympiadResults),
    fork(watchGetOlympiadTypes),
    fork(watchGetOlympiadFormats),
    fork(watchGetStudentInfo),
    fork(watchGetFeedbackLink),
    fork(watchGetPortfolioSubcategories),
    fork(watchGetStatusRewards),
    fork(watchGetProjectFormats),
    fork(watchGetUserSettings),
    fork(watchGetMuseums),
    fork(watchGetTheatres),
    fork(watchGetCinemas),
    fork(watchGetSportClubs),
    fork(watchGetSportTrainingStage),
    fork(watchGetSportRewardKinds),
    fork(watchGetStudentEvents),
    fork(watchGetStudentRewards),
    fork(watchGetStudentSportRewards),
    fork(watchGetStudentProjects),
    fork(watchGetStudentEmployments),
    fork(watchGetStudentCultureVisitings),
    fork(watchReportErrorMessage),
    fork(watchGetEntityErrorMessages),
    fork(watchGratitudeTeacher),
    fork(watchGetEventById),
    fork(watchGetProjectById),
    fork(watchGetEmploymentById),
    fork(watchGetAffilationById),
    fork(watchGetRewardById),
    fork(watchGetSportRewardById),
    fork(watchGetGiaWorldSkillsById),
    fork(watchGetHistory),
    fork(watchGetImportHistory),
    fork(watchGetImportReport),
    fork(watchCreateStudentShareLink),
    fork(watchEditPortfolioEvent),
    fork(watchEditPortfolioAffilation),
    fork(watchEditPortfolioEmployment),
    fork(watchPostPortfolioEvent),
    fork(watchPostPortfolioEmployment),
    fork(watchPostPortfolioAffilation),
    fork(watchUpdateStudentShareLinkActivity),
    fork(watchGetStudentShareLinkList),
    fork(watchDeleteStudentShareLink),
    fork(watchDeleteAttachmentTemplateInfoSaga),
    fork(watchDeleteEmployeeAffilation),
    fork(watchDeleteEmployeeEvent),
    fork(watchDeleteEmployeeProject),
    fork(watchDeleteEmployeeEmployment),
    fork(watchDeleteEmployeeReward),
    fork(watchDeleteEmployeeSportReward),
    fork(watchDeleteEmployeeGiaWorldSkills),
    fork(watchSaveAvatarSettings),
    fork(watchGetStudentAvatar),
    fork(watchSaveStudentAvatar),
    fork(watchSaveVisibilitySettings),
    fork(watchSaveThemeSettings),
    fork(watchAffilationKinds),
    fork(watchEventKinds),
    fork(watchSportKinds),
    fork(watchGetTourismKinds),
    fork(watchGetCreationKinds),
    fork(watchGetCulturalKinds),
    fork(watchGetRewardKinds),
    fork(watchGetPersonsObjects),
    fork(watchGetDisciplines),
    fork(watchPostPortfolioReward),
    fork(watchEditPortfolioReward),
    fork(watchPostPortfolioSportReward),
    fork(watchEditPortfolioSportReward),
    fork(watchPostPortfolioProject),
    fork(watchEditPortfolioProject),
    fork(watchGetPersonsList),
    fork(watchDeletePortfolioReward),
    fork(watchDeletePortfolioSportReward),
    fork(watchDeletePortfolioEvent),
    fork(watchDeletePortfolioEmployment),
    fork(watchDeletePortfolioAffilation),
    fork(watchDeletePortfolioProject),
    fork(watchGetOrganizations),
    fork(watchGetParallels),
    fork(watchGetStudentCheckInHistory),
    fork(watchDeleteStudentCheckIn),
    fork(watchGetImportListSaga),
    fork(watchGetLearnerCategories),
    fork(watchGetSettingSections),
    fork(watchGetAdminHistory),
    fork(watchGetAdminDeleteHistory),
    fork(watchGetAdminLog),
    fork(watchRestorePortfolioEntity),
    fork(watchGetAdminIncorrectDataFile),
    fork(watchGetSources),
    fork(watchGetStudentInterests),
    fork(watchCreateStudentInterestList),
    fork(watchEditStudentInterest),
    fork(watchDeleteStudentInterest),
    fork(watchGetInterestHeadKinds),
    fork(watchGetInterestActionKinds),
    fork(watchGetInterestGroupKinds),
    fork(watchGetInterestObjectKinds),
    fork(watchGetArchivedInterestObjectKinds),
    fork(watchGetViewedFunctions),
    fork(watchSetFunctionViewed),
    fork(watchPostPortfolioGiaWorldSkills),
    fork(watchEditPortfolioGiaWorldSkills),
    fork(watchDeletePortfolioGiaWorldSkills),
    fork(watchPostPortfolioSpoDocument),
    fork(watchEditPortfolioSpoDocument),
    fork(watchDeletePortfolioSpoDocument),
    fork(watchPostPortfolioJob),
    fork(watchEditPortfolioJob),
    fork(watchDeletePortfolioJob),
    fork(watchGetIndependentDiagnostics),
    fork(watchGetIndependentDiagnosticsGeneralRating),
    fork(watchGetIndependentDiagnosticsRating),
    fork(watchGetPersonalDiagnostics),
    fork(watchSavePersonalDiagnosticsSettings),
    fork(watchGetSectionRef),
    fork(watchGetDocumentRef),
    fork(watchGetMetaSkillRef),
    fork(watchGetProfessionProgramRef),
    fork(watchGetLevelBusinessRef),
    fork(watchGetSalaryRangeRef),
    fork(watchGetEmploymentDocTypeRef),
    fork(watchGetSpoOrganizationRef),
    fork(watchGetSpoGiaMarkRef),
    fork(watchGetProfessionRankRef),
    fork(watchGetSportAgesRef),
    fork(watchGetOlympiadSubjectsRef),
    fork(watchGetAvatarTypeRef),
    fork(watchGetStudentPersonalAccess),
    fork(watchUpdateStudentPersonalAccess),
    ...vuzRecommendationModel.sagas.collection,
    ...proforientationModel.sagas.collection,
    ...searchModel.sagas.collection,
    ...performanceModel.sagas.collection,
    ...performanceOldModel.sagas.collection,
  ]);
}
