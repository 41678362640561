import { ChangeEvent, FC } from 'react';

import { RadioGroup, Typography } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { BaseInputLabel, BaseToggleLabel, FormControl } from 'portfolio3/ui-kit';
import RadioButton from 'portfolio3/ui-kit/RadioButton';
import { CommonUiKitSize } from 'portfolio3/ui-kit/types';

import { RadioGroupController } from './types';

interface IRadioGroupControlProps {
  isMobile?: boolean;
  label: string;
  value: number | undefined;
  options: IDictionaryItem[];
  controller: RadioGroupController;
  renderLabel?: (value: IDictionaryItem) => string;
  disabled?: boolean;
}

const RadioGroupControl: FC<IRadioGroupControlProps> = ({
  isMobile,
  label,
  value,
  options,
  controller,
  renderLabel,
  disabled,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const numberValue = Number(event.target.value);
    const value = isNaN(numberValue) ? undefined : numberValue;
    controller.handleChange(value);
  };

  // поменять значения, если понадобится
  const controlLabelTextVariant = isMobile ? 'Paragraph MD/Semi Bold' : 'Paragraph LG/Semi Bold';
  const controlLabelSize: CommonUiKitSize = isMobile ? 'medium' : 'large';
  const radioButtonSize = isMobile ? 'small' : 'medium';

  return (
    <FormControl
      sx={{ paddingBottom: '3px' }}
      renderMode="fixed"
      inputSize={controlLabelSize}
      disabled={disabled}
      label={
        <BaseInputLabel>
          <Typography variant={controlLabelTextVariant}>{label}</Typography>
        </BaseInputLabel>
      }
      control={
        <RadioGroup value={value ?? null} onChange={handleChange}>
          {options.map((option, index) => (
            <BaseToggleLabel
              key={option.code}
              sx={{ marginTop: index === 0 ? 0 : '12px' }}
              size="small"
              label={renderLabel ? renderLabel(option) : option.value}
              control={<RadioButton size={radioButtonSize} value={option.code} />}
            />
          ))}
        </RadioGroup>
      }
    />
  );
};

export default RadioGroupControl;
