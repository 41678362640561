import { FC } from 'react';

import { isDefined, prettifyNumber } from 'utils';

import ResultDoughnut from '../../../common/ResultDoughnut';
import { PdfDiagnostic } from '../types';

import './index.scss';

const getDoughnutData = (result: number, max: number) => {
  const valueProgressbar = (result / max) * 100;
  return {
    incomingData: [valueProgressbar, 100 - valueProgressbar],
    incomingLabels: ['1', '2'],
  };
};

interface IDiagnosticCardProps {
  diagnostic: PdfDiagnostic;
}

const DiagnosticCard: FC<IDiagnosticCardProps> = ({ diagnostic }) => {
  const { name, subject, eventDate, resultValue, maxResult, levelType, percentResult } = diagnostic;

  const prettyScorePercent = prettifyNumber(percentResult, { precision: 1 });
  const doughnutTotal = `${prettyScorePercent}%`;
  const doughnutData = getDoughnutData(percentResult, 100);
  const doughnutColor = '#B9B9B9';

  const dateZeroPadding = (num: number) => num.toString().padStart(2, '0');
  const date = new Date(eventDate);
  const [day, month, year] = [date.getDate(), date.getMonth() + 1, date.getFullYear()];
  const dateValue = `${dateZeroPadding(day)}.${dateZeroPadding(month)}.${year}`;

  return (
    <div className="pdf-diagnostic-card">
      <div className="pdf-diagnostic-card__info info">
        <header className="info__header">
          <p className="info__title">{name}</p>
          <p className="info__subject">{subject}</p>
        </header>
        <p className="info__date">{dateValue}</p>
      </div>
      <div className="pdf-diagnostic-card__result result">
        <div className="result__description">
          <p className="result__title">Твой результат</p>
          <p className="result__data">
            <strong>{resultValue} баллов</strong>
            {isDefined(maxResult) && <> из {maxResult}</>}
          </p>
          {levelType && <p className="result__level-type">{levelType.toLowerCase()}</p>}
        </div>
        <div className="result__chart">
          <ResultDoughnut total={doughnutTotal} data={doughnutData} color={doughnutColor} />
        </div>
      </div>
    </div>
  );
};

export default DiagnosticCard;
