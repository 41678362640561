import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

const styles: SxStyles = {
  padding: '16px',

  borderTop: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
};

const SpecialityContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box className="speciality-container" sx={styles}>
      {children}
    </Box>
  );
};

export default SpecialityContainer;
