import { FC } from 'react';

import { professionNavigation } from 'const';
import { useAppSelector } from 'hooks';

import ExpandableContainer from '../../components/ExpandableContainer';
import GenericEntityList from '../../components/GenericEntityList';
import GenericEventCard from '../../components/GenericEventCard';
import { IndustrySpecialityList } from '../../components/specialities';
import { proforientationEventSelectors } from '../../model/selectors';

const EventsOpenDayWidget: FC = () => {
  const dods = useAppSelector(proforientationEventSelectors.dods);

  return (
    <GenericEntityList
      containerId={professionNavigation.proforientationEvents.id}
      initialCardCount={3}
      entities={dods}
      renderEntityCard={(event, index) => {
        const key = `${event.date}-${event.name}-${index}`;
        const specialityDisclosureTitle = event.industryList.length > 1 ? 'Отрасли и специальности' : 'Специальности';

        return (
          <GenericEventCard
            key={key}
            date={event.date}
            title={event.name}
            industry={event.industry}
            illustration={event.illustration}
            bottomAddon={
              <ExpandableContainer title={specialityDisclosureTitle}>
                <IndustrySpecialityList industries={event.industryList} />
              </ExpandableContainer>
            }
          />
        );
      }}
    />
  );
};

export default EventsOpenDayWidget;
