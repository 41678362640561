import { SET_CURRENT_NAVIGATION_SECTION, setCurrentNavigationSectionAction } from 'actions';
import { IReducers } from 'reducers';
import { connectReducers } from 'utils';

export type NavigationSectionState = {
  currentNavigationSection: string | undefined;
};

const initialState: NavigationSectionState = {
  currentNavigationSection: undefined,
};

const navigationSection: IReducers<NavigationSectionState> = {
  [SET_CURRENT_NAVIGATION_SECTION]: (state, action: ReturnType<typeof setCurrentNavigationSectionAction>) => {
    return {
      ...state,
      currentNavigationSection: action.payload,
    };
  },
};

export default connectReducers(initialState, navigationSection);
