import { FC } from 'react';

import { Box, tooltipClasses, Typography } from '@mui/material';
import { StatusApplicationCodeText } from 'const';
import { IconInfoOutline } from 'icons';
import { SxStyles } from 'types';
import { Tooltip } from 'ui-kit';
import { mergeSx } from 'utils';

import { getStatusApplicationColor, getStatusApplicationTooltip } from './utils';

const styles: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
};

const iconContainerStyles: SxStyles = {
  width: '20px',
  height: '20px',

  svg: {
    width: '20px',
    height: '20px',
  },
};

const tooltipStyles: SxStyles = {
  [`& .${tooltipClasses.tooltip}`]: {
    textAlign: 'start',
  },
};

interface IApplicationStatusProps {
  statusApplicationCode: number;
  sx?: SxStyles;
}

const ApplicationStatus: FC<IApplicationStatusProps> = ({ statusApplicationCode, sx }) => {
  const text = StatusApplicationCodeText[statusApplicationCode];
  const color = getStatusApplicationColor(statusApplicationCode) ?? undefined;
  const tooltip = getStatusApplicationTooltip(statusApplicationCode);

  return (
    <Box className="application-status" sx={mergeSx(styles, sx)}>
      <Tooltip arrow title={tooltip} placement="bottom" width="312px" sx={tooltipStyles}>
        <Box sx={mergeSx(iconContainerStyles, { color })}>
          <IconInfoOutline sx={{ color }} />
        </Box>
      </Tooltip>
      <Typography variant="Paragraph MD/Semi Bold" color={color}>
        {text}
      </Typography>
    </Box>
  );
};

export default ApplicationStatus;
