import { FC, useContext } from 'react';

import { List, ListItem, ListItemText } from '@mui/material';
import clsx from 'clsx';
import { useAppSelector } from 'hooks';
import { widgetIcons } from 'icons';

import SettingsSwitch from '../../../components/common/SettingsSwitch';
import { IPortfolioNavigation, SectionCodeVisibilitySettings } from '../../../const';
import { PortfolioSettingsContext, ScrollStatusContext } from '../../../context';
import { localVisibilitySettingsSelector } from '../../../selectors';
import { findTargetVisibilitySetting } from '../../../utils/localVisibilitySettingsService';

import './index.scss';

interface ISubsectionViisibilityProps {
  parentSectionCode: number;
  portfolioNavigation: IPortfolioNavigation[];
}

const PortfolioSubsectionVisibility: FC<ISubsectionViisibilityProps> = ({ parentSectionCode, portfolioNavigation }) => {
  const localVisibilitySettings = useAppSelector(localVisibilitySettingsSelector);

  const { isContainerScrolled } = useContext(ScrollStatusContext);
  const { setSubsectionVisibility } = useContext(PortfolioSettingsContext);

  const { sectionTypeCode } = SectionCodeVisibilitySettings[parentSectionCode];

  const mappedNavigation = portfolioNavigation.map((navigation) => {
    const sectionSetting = findTargetVisibilitySetting(localVisibilitySettings, [
      sectionTypeCode,
      navigation.sectionTypeCode,
    ]);
    return {
      ...navigation,
      isEnabled: sectionSetting?.computedVisibility ?? false,
    };
  });

  const nonStaticNavigation = mappedNavigation.filter((nav) => !nav.isStatic);

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>, subsectionTypeCode: string) => {
    setSubsectionVisibility(localVisibilitySettings, sectionTypeCode, subsectionTypeCode, event.target.checked);
  };

  return (
    <div className={clsx('subsection-visibility', { 'subsection-visibility--scrolled': isContainerScrolled })}>
      <List component="ul">
        {nonStaticNavigation.map((navigation) => {
          const { label, sectionTypeCode, isEnabled, icon: iconKey } = navigation;

          const IconComponent = widgetIcons[iconKey];
          const icon = <IconComponent sx={{ color: '#B0B3C3', width: '24px', height: '24px' }} />;

          return (
            <ListItem key={navigation.id} component="li">
              {icon}
              <ListItemText>{label}</ListItemText>
              <SettingsSwitch onChange={(e) => handleSwitch(e, sectionTypeCode)} checked={isEnabled} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default PortfolioSubsectionVisibility;
