import { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, useMediaQuery } from '@mui/material';
import {
  clearIndependentDiagnosisSettingAction,
  getIndependentDiagnosticsActions,
  getPersonalDiagnosticsActions,
  personalDiagnosticsSettingsActions,
  saveIndependentDiagnosisSettingsActions,
  savePersonalDiagnosticsSettingsActions,
  saveVisibilitySettingsActions,
} from 'actions';
import { ISaveDiagnosisCardSettingsRequest, ISaveVisibilitySettingsRequest } from 'api/types';
import { routes } from 'const';
import { PortfolioSectionVisibility } from 'containers/redesignStudent';
import { useAppSelector } from 'hooks';
import FixedWidthContainer from 'portfolio3/components/common/FixedWidthContainer';
import { SettingsHeader } from 'portfolio3/components/common/headers';
import PaddingContainer from 'portfolio3/components/common/PaddingContainer';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { IndependentDiagnosisSettingsState } from 'reducers/independentDiagnosisSettings';
import { localVisibilitySettingsSelector } from 'selectors';
import { mapViewSettingsToSaveSettingsRequest } from 'utils';

import { IHeaderContainerCommonProps } from '../types';
import { studentHeaderContainerStyles } from '../utils';

const mapCardSettingsToSaveRequest = (settings: IndependentDiagnosisSettingsState['settings'], personId: string) => {
  const settingEntries = Object.entries(settings);
  const cardSettingsRequest: ISaveDiagnosisCardSettingsRequest = {
    personId,
    changeCard: settingEntries.map((entry) => {
      const [recordId, isVisible] = entry;
      return {
        recordId,
        isVisible,
      };
    }),
  };
  return cardSettingsRequest;
};

const SettingsHeaderContainer: FC<IHeaderContainerCommonProps> = ({ isFullWidth }) => {
  const dispatch = useDispatch();
  const currentStudent = useAppSelector((state) => state.currentStudent);
  const independentDiagnosticsSettings = useAppSelector((state) => state.independentDiagnosisSettings);
  const personalDiagnosticsSettings = useAppSelector((state) => state.personalDiagnosticsSettings);
  const localVisibilitySettings = useAppSelector(localVisibilitySettingsSelector);

  const history = useHistory();
  const isFullWidthBreakpoint = useMediaQuery(commonTheme.breakpoints.down('commonXl'));

  const Container = isFullWidthBreakpoint || isFullWidth ? Fragment : FixedWidthContainer;
  const isSaveSettingsActionBlocked = localVisibilitySettings.every((setting) => !setting.computedVisibility);

  const handleSettingsSave = () => {
    if (!currentStudent.meshId) {
      return;
    }

    const mappedSettings = mapViewSettingsToSaveSettingsRequest(localVisibilitySettings);

    const saveSettingsRequest: ISaveVisibilitySettingsRequest = {
      personId: currentStudent.meshId,
      setting: {
        visibilitySettings: mappedSettings,
      },
    };
    dispatch(saveVisibilitySettingsActions.request(saveSettingsRequest, currentStudent.meshId));

    const independentCardSettingsRequest = mapCardSettingsToSaveRequest(
      independentDiagnosticsSettings.settings,
      currentStudent.meshId,
    );
    const personalCardSettingsRequest = mapCardSettingsToSaveRequest(
      personalDiagnosticsSettings.settings,
      currentStudent.meshId,
    );

    if (independentCardSettingsRequest.changeCard && independentCardSettingsRequest.changeCard.length > 0) {
      dispatch(saveIndependentDiagnosisSettingsActions.request(independentCardSettingsRequest));
    }
    if (personalCardSettingsRequest.changeCard && personalCardSettingsRequest.changeCard.length > 0) {
      dispatch(savePersonalDiagnosticsSettingsActions.request(personalCardSettingsRequest));
    }

    dispatch(clearIndependentDiagnosisSettingAction());
    dispatch(personalDiagnosticsSettingsActions.clearSettings());

    history.push(routes.PORTFOLIO_STUDENT.path);

    dispatch(getIndependentDiagnosticsActions.invalidate());
    dispatch(getPersonalDiagnosticsActions.invalidate());
  };

  return (
    <Box sx={{ zIndex: 1000, position: 'sticky', top: 0 }} className="settings-header-container">
      <Container>
        <Box sx={studentHeaderContainerStyles}>
          <PaddingContainer
            backgroundColor={NeutralColors.light_neutrals_0}
            sx={{
              borderBottom: '1px solid',
              borderColor: NeutralColors.light_neutrals_300,
            }}
          >
            <SettingsHeader isSaveButtonDisabled={isSaveSettingsActionBlocked} onSettingsSave={handleSettingsSave} />
          </PaddingContainer>
          <PaddingContainer backgroundColor={NeutralColors.light_neutrals_0}>
            <PortfolioSectionVisibility />
          </PaddingContainer>
        </Box>
      </Container>
    </Box>
  );
};

export default SettingsHeaderContainer;
