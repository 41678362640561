import { FC, ReactNode } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { Attributes } from 'portfolio3/ui-kit';

import * as styles from './styles';

interface IGenericRecommendationCardProps {
  date: string;
  industry: string;
  name: string;
  description: string;
  place: string;
  district?: string;

  bottomAddon?: ReactNode;
}

const ProfessionGenericRecommendationCard: FC<IGenericRecommendationCardProps> = ({
  date,
  industry,
  name,
  description,
  place,
  district,
  bottomAddon,
}) => {
  const attributes = [date, industry].filter(Boolean).map((value) => {
    return (
      <Typography variant="Paragraph MD/Regular" key={value}>
        {value}
      </Typography>
    );
  });

  const mainContentElement = (
    <Stack gap="8px">
      <Attributes attributes={attributes} />
      <Typography variant="Paragraph LG/Semi Bold">{name}</Typography>
      <Typography variant="Paragraph LG/Regular">{description}</Typography>
    </Stack>
  );

  const locationElement = (
    <Stack gap="4px">
      <Typography variant="Paragraph MD/Regular">{place}</Typography>
      {district && <Typography variant="Paragraph MD/Regular">{district}</Typography>}
    </Stack>
  );

  return (
    <Box className="generic-recommendation-card pdf-keep" sx={styles.root}>
      <Box className="generic-recommendation-card__main" sx={styles.rootMain}>
        <Stack gap="12px" maxWidth="638px">
          {mainContentElement}
          {locationElement}
        </Stack>
      </Box>
      {bottomAddon}
    </Box>
  );
};

export default ProfessionGenericRecommendationCard;
