import proforientation from './proforientation';
import proforientationEventApplication from './proforientationEventApplication';
import proforientationParticipationDialog from './proforientationParticipationDialog';
import proforientationRecommendationApplications from './proforientationRecommendationApplications';
import proforientationRecommendationEvents from './proforientationRecommendationEvents';
import proforientationRecommendationFilters from './proforientationRecommendationFilters';

export const collection = {
  proforientation,
  proforientationRecommendationEvents,
  proforientationRecommendationFilters,
  proforientationParticipationDialog,
  proforientationRecommendationApplications,
  proforientationEventApplication,
};
