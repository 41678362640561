import {
  IconBriefcaseFilled,
  IconBrushFilled,
  IconCubeFilled,
  IconFileFilled,
  IconFileTextFilled,
  IconMapFilled,
  IconMessageCircleFilled,
  IconPieChartFilled,
  IconSchoolFilled,
} from 'icons';

import { IconCivilClubsCommon, IconCivilContestsCommon, IconCivilRewardsCommon, IconCivilUnitsCommon } from './civil';
import {
  IconCreationCollectivesCommon,
  IconCreationContestsCommon,
  IconCreationRewardsCommon,
  IconCreationUnitsCommon,
} from './creation';
import { IconCultureOfflineEventsCommon, IconCultureOnlineEventsCommon } from './culture';
import { IconProfessionEducationCommon, IconProfessionEventsCommon, IconProfessionRewardsCommon } from './profession';
import { IconProfileGoalsCommon, IconProfileInterestsCommon } from './profile';
import {
  IconScienceContestsCommon,
  IconScienceEmploymentsCommon,
  IconScienceProjectsCommon,
  IconScienceRewardsCommon,
} from './science';
import { IconGia, IconRecommendation } from './shared';
import {
  IconSportClubsCommon,
  IconSportContestsCommon,
  IconSportHikesCommon,
  IconSportRewardsCommon,
  IconSportUnitsCommon,
} from './sport';
import { IconStudyDiagnosticsCommon, IconStudyOlympiadsCommon, IconStudyResultsCommon } from './study';

export const IconWidgetProfileInterests = IconProfileInterestsCommon;
export const IconWidgetProfileRecommendations = IconRecommendation;
export const IconWidgetProfileGoals = IconProfileGoalsCommon;

export const IconWidgetStudyEducation = IconSchoolFilled;
export const IconWidgetStudyPractice = IconCubeFilled;
export const IconWidgetStudyDocuments = IconFileFilled;
export const IconWidgetStudyResults = IconStudyResultsCommon;
export const IconWidgetStudyGia = IconGia;
export const IconWidgetStudyDiagnostics = IconStudyDiagnosticsCommon;
export const IconWidgetStudyOlympiads = IconStudyOlympiadsCommon;

export const IconWidgetScienceRewards = IconScienceRewardsCommon;
export const IconWidgetScienceProjects = IconScienceProjectsCommon;
export const IconWidgetScienceEmployments = IconScienceEmploymentsCommon;
export const IconWidgetScienceContests = IconScienceContestsCommon;

export const IconWidgetSportRewards = IconSportRewardsCommon;
export const IconWidgetSportClubs = IconSportClubsCommon;
export const IconWidgetSportUnits = IconSportUnitsCommon;
export const IconWidgetSportContests = IconSportContestsCommon;
export const IconWidgetSportHikes = IconSportHikesCommon;

export const IconWidgetCreationRewards = IconCreationRewardsCommon;
export const IconWidgetCreationCollectives = IconCreationCollectivesCommon;
export const IconWidgetCreationContests = IconCreationContestsCommon;
export const IconWidgetCreationUnits = IconCreationUnitsCommon;

export const IconWidgetCultureOnlineEvents = IconCultureOnlineEventsCommon;
export const IconWidgetCultureOfflineEvents = IconCultureOfflineEventsCommon;

export const IconWidgetCivilRewards = IconCivilRewardsCommon;
export const IconWidgetCivilClubs = IconCivilClubsCommon;
export const IconWidgetCivilContests = IconCivilContestsCommon;
export const IconWidgetCivilUnits = IconCivilUnitsCommon;

export const IconWidgetProfessionRewards = IconProfessionRewardsCommon;
export const IconWidgetProfessionJobs = IconBriefcaseFilled;
export const IconWidgetProfessionMetaskills = IconMessageCircleFilled;
export const IconWidgetProfessionEducation = IconProfessionEducationCommon;
export const IconWidgetProfessionEvents = IconProfessionEventsCommon;
export const IconWidgetProfessionGia = IconGia;
export const IconWidgetProfessionTalentMap = IconMapFilled;
export const IconWidgetProfessionProforientation = IconPieChartFilled;
export const IconWidgetProfessionProforientationApplications = IconFileTextFilled;
export const IconWidgetProfessionProforientationRecommendations = IconMessageCircleFilled;
export const IconWidgetProfessionProforientationEvents = IconBrushFilled;

export const IconWidgetRecommendationVuz = IconSchoolFilled;
