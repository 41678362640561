import { FC } from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { IconArrowDiagonalRightUp } from 'icons';
import { featureArchiveSearch } from 'images';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { commonTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';
import { SxStyles } from 'types';
import { openUrlInNewTab } from 'utils';

import * as styles from '../../SnowTheme/component/styles';
import { IFeaturePreviewComponentProps } from '../../types';

const bodySx: SxStyles = (theme) => ({
  padding: '24px',
  alignItems: 'center',
  [theme.breakpoints.down('commonSm')]: {
    padding: '16px',
    paddingBottom: '32px',
  },
});

const descriptionSx: SxStyles = {
  marginTop: '12px',
  maxWidth: '708px',
};

export const ArchiveSearchPreviewComponent: FC<IFeaturePreviewComponentProps> = ({ onView }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const titleVariant = isMobile ? 'Headings/H6' : 'Headings/H5';
  const descriptionVariant = isMobile ? 'Paragraph MD/Regular' : 'Paragraph LG/Regular';
  const buttonSize = isMobile ? 'large' : 'medium';

  const imageSx: SxStyles = {
    width: '100%',
    height: '316px',
    backgroundImage: `url(${featureArchiveSearch})`,
    backgroundSize: '901px 360px',
    backgroundRepeat: 'no-repeat',
  };

  const handleServiceRedirect = () => {
    onView?.();
    openUrlInNewTab('https://ya.ru/archive');
    emitYMEvent({ type: 'main_popUpArchiveSearch_goService_open' });
  };

  return (
    <Box className="archive-search-preview" sx={styles.root}>
      <Box sx={imageSx} />
      <Stack sx={bodySx}>
        <Typography variant={titleVariant}>Поиск по архивам</Typography>
        <Typography variant={descriptionVariant} sx={descriptionSx}>
          Появилась новая возможность поиска истоков московских семей. Находи имена, фамилии, названия населённых
          пунктов и любые другие слова в рукописных документах XVIII — начала XX века. Узнавай и передавай из поколения
          в поколение подлинную историю и традиции рода.
        </Typography>
        <Box sx={styles.buttons}>
          <Button size={buttonSize} iconRight={<IconArrowDiagonalRightUp />} onClick={handleServiceRedirect}>
            Перейти к сервису
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
