import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useAppSelector } from 'hooks';
import { ProfessionGenericRecommendationCard } from 'portfolio3/features/pdf/components/cards';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { proforientationModel } from 'portfolio3/features/proforientation';

const RecommendationExcursionsSection: FC = () => {
  const excursions = useAppSelector(proforientationModel.selectors.proforientationRecommendationSelectors.excursions);
  const hasData = excursions.length > 0;

  return (
    <Box className="recommendation-excursions pdf-break">
      <Typography variant="Headings/H5">Экскурсии к работодателю</Typography>
      <Box marginTop="16px">
        {hasData && (
          <Stack gap="16px">
            {excursions.map((event) => {
              return (
                <ProfessionGenericRecommendationCard
                  key={event.eventId}
                  date={event.formattedDate}
                  industry={event.industry}
                  name={event.name}
                  description={event.description}
                  place={event.place}
                  district={event.district}
                />
              );
            })}
          </Stack>
        )}
        {!hasData && <PdfNoDataText />}
      </Box>
    </Box>
  );
};

export default RecommendationExcursionsSection;
