import { FC } from 'react';

import { IPersonalDiagnosticLearningGroup } from 'api/types';

import PersonalDiagnosticList from './PersonalDiagnosticList';

interface IPersonalDiagnosticsComponentProps {
  personalDiagnostics: IPersonalDiagnosticLearningGroup[];
}

const PersonalDiagnosticsComponent: FC<IPersonalDiagnosticsComponentProps> = ({ personalDiagnostics }) => {
  const diagnostics = [...personalDiagnostics]
    .flatMap((group) => group.diagnostics)
    .sort((a, b) => {
      return Number(new Date(b.eventDate)) - Number(new Date(a.eventDate));
    });

  return <PersonalDiagnosticList diagnostics={diagnostics} />;
};

export default PersonalDiagnosticsComponent;
