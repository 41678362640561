import { IDictionaryItem } from 'api/types';

import { filterControls, filterSections, filterStatus } from '../const/filters';
import { IRecommendedSpeciality } from '../model/types';

export const getFilterOptions = (options: string[]) => {
  const uniqueOptions = Array.from(new Set(options));
  const filterOptions = uniqueOptions.map((option, index): IDictionaryItem => {
    return {
      code: index + 1,
      value: option,
    };
  });

  return filterOptions;
};

export const mapSpecialityOptionValue = (speciality: IRecommendedSpeciality) => {
  return `${speciality.code} ${speciality.name}`;
};

export const getFilterStoreKey = (
  section: keyof typeof filterSections,
  control: keyof typeof filterControls,
  status?: keyof typeof filterStatus,
) => {
  const keys: string[] = [filterSections[section], filterControls[control]];

  if (status) {
    keys.push(filterStatus[status]);
  }

  return keys.join('/');
};
