import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '12px',
  backgroundColor: NeutralColors.light_neutrals_0,
};

export const rootMain: SxStyles = {
  display: 'flex',
  gap: '12px',
  justifyContent: 'space-between',

  padding: '20px',
};

export const rootRight: SxStyles = {
  flexShrink: 0,
};
