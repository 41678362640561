import { FC, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Box, useMediaQuery } from '@mui/material';
import { PageTemplateProps, savePDF } from '@progress/kendo-react-pdf';
import {
  createStudentShareLinkActions,
  getStudentShareLinkListActions,
  linkSharingDrawerActions,
  updateStudentShareLinkActivityActions,
} from 'actions';
import { IChildInfo, ICreateLinkRequest, IShareLinkResponse, IUpdateLinkActivityRequest } from 'api/types';
import { LinkExpiration, LinkExpirationId } from 'const';
import { format, isBefore } from 'date-fns';
import { useAppSelector, useBrightTheme } from 'hooks';
import { IconCopyOutline, IconDownloadFile, IconQr } from 'icons';
import { meshPortfolioLogo } from 'icons/pdf';
import { cardIllustrations } from 'images';
import { StudentPortfolioPdf } from 'pages';
import UnderlinedTabs from 'portfolio3/components/common/UnderlinedTabs';
import { DrawerHeader, MobileDrawerHeader } from 'portfolio3/components/drawers';
import {
  emitYMEvent,
  getMetrikaEnabledLinkSettingsSections,
  getYMEventPayload,
} from 'portfolio3/features/yandexMetrika';
import { commonTheme } from 'portfolio3/styles/theme';
import { Switcher, SwitcherButton, toast } from 'portfolio3/ui-kit';
import { DayRangePickerController } from 'portfolio3/ui-kit/datePickers';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';
import { drawerContentPadding } from 'portfolio3/ui-kit/utils';
import { IRootState } from 'reducers';
import { AdminSettingsState } from 'reducers/admin/adminSettings';
import { ThemeSettingsState } from 'reducers/theme/settings';
import { getAuthToken, isFirefox, mergeSx } from 'utils';

import { GroupedActionButton, GroupedActionButtonsContainer } from '../../common/GroupedActionButtons';
import { ActiveLinksList, ActiveLinksPopUp } from './ActiveLinks';
import { activeShareLinksSelector } from './activeShareLinksSelector';
import { getDefaultRawDisplaySettings, getSharingFormSettings } from './consts';
import LinksOverview from './LinksOverview';
import LinksPeriod from './LinksPeriod';
import Settings from './Settings';
import * as styles from './styles';
import { DynamicDisplaySettings, LinkSharingTabs } from './types';
import { mapDynamicSettingsToFullSettings } from './utils';

import './pdfStyles.scss';

interface ILinkSharingFormProps {
  isOpen: boolean;
  onClose: () => void;

  adminVisibilitySettings: AdminSettingsState;
  currentStudent: IChildInfo;
  studentShareLink?: IShareLinkResponse;
  portfolioContentLoading?: boolean;
  createStudentShareLink: typeof createStudentShareLinkActions.request;
  updateStudentShareLinkActivity: typeof updateStudentShareLinkActivityActions.request;
  getStudentShareLinkList: typeof getStudentShareLinkListActions.request;
  themeSettings: ThemeSettingsState;
}

const LinkSharingForm: FC<ILinkSharingFormProps> = ({
  isOpen,
  onClose,

  createStudentShareLink,
  adminVisibilitySettings,
  currentStudent,
  studentShareLink,
  portfolioContentLoading,
  updateStudentShareLinkActivity,
  getStudentShareLinkList,
  themeSettings,
}: ILinkSharingFormProps) => {
  const dispatch = useDispatch();
  const activeShareLinks = useAppSelector(activeShareLinksSelector);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const weekLater = new Date(new Date().setDate(today.getDate() + 7));
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const [linkExpiration, setLinkExpiration] = useState<number>(LinkExpirationId.week);
  const [customPeriodStart, setCustomPeriodStart] = useState<Date | null>(today);
  const [customPeriodEnd, setCustomPeriodEnd] = useState<Date | null>(weekLater);
  const [isActiveLinksOpen, setActiveLinksOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(LinkSharingTabs.LINK);
  const [rawDisplaySettings, setRawDisplaySettings] = useState<DynamicDisplaySettings>(
    getDefaultRawDisplaySettings(adminVisibilitySettings.content.sections),
  );
  const [areLinksActive, setLinksActive] = useState(false);
  const [isPdfLoading, setPdfLoading] = useState(false);
  const sharingContainerRef = useRef<HTMLDivElement>(null);
  const pdfContainer = useRef(null);

  const isBrightTheme = useBrightTheme();

  const viewSettings = getSharingFormSettings(
    isMobile,
    adminVisibilitySettings.content.sections,
    rawDisplaySettings,
    setRawDisplaySettings,
  );
  const fullRawSettings = mapDynamicSettingsToFullSettings(rawDisplaySettings);

  useEffect(() => {
    setRawDisplaySettings(getDefaultRawDisplaySettings(adminVisibilitySettings.content.sections));
  }, [adminVisibilitySettings.content.sections]);

  useEffect(() => {
    if (!isOpen) {
      setActiveLinksOpen(false);
    } else {
      setCurrentTab(LinkSharingTabs.LINK);
    }
  }, [isOpen]);

  // при появлении дата пикера требуется проскроллить модалку вниз
  useEffect(() => {
    // получение контейнера дровера
    const drawerContentElement = sharingContainerRef.current?.parentElement;

    if (linkExpiration === LinkExpirationId.period && drawerContentElement) {
      drawerContentElement.scrollTop = drawerContentElement.scrollHeight;
    }
  }, [linkExpiration]);

  useEffect(() => {
    if (activeShareLinks.content.length > 0) {
      setLinksActive(true);
    }
  }, [activeShareLinks.content.length]);

  // после генерации ссылки обновляет url и qr код в сторе
  useEffect(() => {
    if (studentShareLink?.loading) return;
    const linkUrl = studentShareLink?.content?.url;
    const qrCode = studentShareLink?.content?.qrCode;

    dispatch(linkSharingDrawerActions.setCurrentLink(linkUrl, qrCode));
  }, [dispatch, studentShareLink?.content?.qrCode, studentShareLink?.content?.url, studentShareLink?.loading]);

  const handleUpdateLinkActivity = (isActive: boolean) => {
    setLinksActive(isActive);
    if (currentStudent?.meshId) {
      const linkActivityRequest: IUpdateLinkActivityRequest = { isActive };
      updateStudentShareLinkActivity(currentStudent?.meshId, linkActivityRequest);
    }
  };

  const handleChangeCurrentTab = (tab: LinkSharingTabs) => {
    setCurrentTab(tab);
  };

  const handleOpenLinkUrl = () => {
    generateLink('link');
    dispatch(linkSharingDrawerActions.setCurrentLink(undefined, undefined));
    dispatch(linkSharingDrawerActions.setLinkUrlOpen(true));
  };

  const handleOpenLinkQrCode = () => {
    generateLink('qr');
    dispatch(linkSharingDrawerActions.setCurrentLink(undefined, undefined));
    dispatch(linkSharingDrawerActions.setLinkQrCodeOpen(true));
  };

  const handleOpenActiveLinks = () => {
    setActiveLinksOpen(true);
  };

  const handleCloseActiveLinks = () => {
    setActiveLinksOpen(false);
  };

  // Если выносить компонент отдельно, то реакт будет жаловаться на потерю стора. Решения лучше пока не найдено
  // TODO попробовать вынести компонент отдельно
  const PdfPageTemplate: FC<PageTemplateProps> = ({ pageNum, totalPages }) => {
    const currentDate = new Date();
    const formattedDate = `Сохранено ${format(currentDate, 'dd.MM.yyyy')} в ${currentDate.getHours()}:${
      (currentDate.getMinutes() < 10 ? '0' : '') + currentDate.getMinutes()
    }`;

    return (
      <div className="pdf-page-template">
        {pageNum !== 1 && (
          <>
            <div className="pdf-info">
              <img src={meshPortfolioLogo} alt="mesh" />
              <div className="pdf-info__creation-date">{formattedDate}</div>
            </div>
            <div className="pdf-paging">
              <div className="pdf-paging__user-info">
                {rawDisplaySettings?.studentData && (
                  <>
                    Портфолио{' '}
                    <span className="pdf-paging__user-fio">
                      {currentStudent?.lastname} {currentStudent?.firstname}
                    </span>
                  </>
                )}
              </div>
              <div className="pdf-paging__current-page">{`стр. ${pageNum - 1} из ${totalPages - 1}`}</div>
            </div>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (currentStudent.meshId) getStudentShareLinkList(currentStudent.meshId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStudent]);

  const generateLink = (type: 'link' | 'qr') => {
    const isCustomPeriod = linkExpiration === LinkExpirationId.period;

    if (
      isCustomPeriod &&
      customPeriodStart &&
      customPeriodEnd &&
      format(customPeriodStart, 'yyyy-MM-dd') === format(customPeriodEnd, 'yyyy-MM-dd')
    ) {
      setCustomPeriodEnd(new Date(customPeriodEnd.setHours(23, 59, 59)));
    }

    if (
      currentStudent &&
      currentStudent.meshId &&
      themeSettings.code &&
      (!isCustomPeriod || (customPeriodStart && customPeriodEnd))
    ) {
      const periodStartDate = customPeriodStart ?? today;
      const periodEndDate = customPeriodEnd ?? weekLater;

      const period: Pick<ICreateLinkRequest, 'accessPeriod' | 'customPeriod'> = isCustomPeriod
        ? {
            customPeriod: {
              startDate: periodStartDate.toISOString(),
              endDate: periodEndDate.toISOString(),
            },
          }
        : {
            accessPeriod: linkExpiration,
          };

      const request: ICreateLinkRequest = {
        display: fullRawSettings,
        themeSetting: themeSettings.code,
        ...period,
      };

      const expirationIdToNameMapping = {
        [LinkExpirationId.week]: LinkExpiration.week,
        [LinkExpirationId.mounth]: LinkExpiration.mounth,
        [LinkExpirationId.year]: LinkExpiration.year,
        [LinkExpirationId.period]: LinkExpiration.period,
      };
      const ymMeta = getYMEventPayload({
        type: 'linkSettingCopy',
        payload: {
          type,
          linkAccess: areLinksActive ? 'on' : 'off',
          includedSections: getMetrikaEnabledLinkSettingsSections(viewSettings),
          linkPeriod: expirationIdToNameMapping[linkExpiration],
        },
      });

      createStudentShareLink(currentStudent?.meshId, request, ymMeta);
    }
  };

  const customPeriodController: DayRangePickerController = {
    handleChange(value) {
      setCustomPeriodStart(value.start);
      setCustomPeriodEnd(value.end);
    },
    handleClear() {
      setCustomPeriodStart(today);
      setCustomPeriodEnd(weekLater);
    },
  };

  const isDisplaySettingsSelected = viewSettings.find((res) => res.isActive === true);
  const isDownloadBtnDisabled = !isDisplaySettingsSelected || portfolioContentLoading || isPdfLoading;

  const exportPDFWithMethod = () => {
    emitYMEvent({
      type: 'downloadButtonClick',
      payload: {
        includedSections: getMetrikaEnabledLinkSettingsSections(viewSettings),
      },
    });

    toast.success('Загрузка файла началась');
    setPdfLoading(true);
    const element = pdfContainer.current || document.body;
    savePDF(
      element,
      {
        pageTemplate: PdfPageTemplate,
        paperSize: 'A4',
        fileName: 'portfolio.pdf',
        keepTogether: '.pdf-keep',
        forcePageBreak: '.pdf-break',
        scale: 0.48,
        author: 'student',
        proxyURL: `/portfolio/app/persons/${currentStudent?.meshId}/printPDF`,
        forceProxy: !isFirefox,
        proxyData: {
          bearer: `Bearer ${getAuthToken()}`,
        },
      },
      () => {
        emitYMEvent({ type: 'downloadButtonSuccessful' });

        toast.success('Загрузка файла завершена');
        setPdfLoading(false);
      },
    );
  };

  const isCustomPeriodError =
    !customPeriodStart ||
    !customPeriodEnd ||
    isBefore(customPeriodStart, today) ||
    isBefore(customPeriodEnd, today) ||
    isBefore(customPeriodEnd, customPeriodStart);

  const isLinkTab = currentTab === LinkSharingTabs.LINK;
  const isFileTab = currentTab === LinkSharingTabs.FILE;

  const isMobileActiveLinksOpen = isMobile && isActiveLinksOpen;

  const desktopTabsElement = (
    <UnderlinedTabs value={currentTab} onChange={handleChangeCurrentTab} sx={{ marginTop: '24px' }}>
      <UnderlinedTabs.Button value={LinkSharingTabs.LINK} content="Доступ по ссылке" />
      <UnderlinedTabs.Button value={LinkSharingTabs.FILE} content="Скачать файл" />
    </UnderlinedTabs>
  );

  const mobileTabsElement = (
    <Switcher
      value={currentTab}
      onChange={handleChangeCurrentTab}
      componentSize="large"
      sx={{ display: 'flex', flexDirection: 'row', width: '100%', '& button': { flexGrow: 1, flexBasis: '1px' } }}
    >
      <SwitcherButton value={LinkSharingTabs.LINK} content="По ссылке" />
      <SwitcherButton value={LinkSharingTabs.FILE} content="Скачать файл" />
    </Switcher>
  );

  const desktopHeader: IDrawerBase['header'] = (crossButton) => (
    <DrawerHeader
      title="Поделиться портфолио"
      backgroundImage={isBrightTheme ? cardIllustrations.scienceReward : null}
      accentColor="indigo"
      crossButton={crossButton}
      removeBottomPadding
    >
      {desktopTabsElement}
    </DrawerHeader>
  );

  const mobileHeader: IDrawerBase['header'] = (crossButton) => (
    <MobileDrawerHeader
      accentColor="indigo"
      crossButton={crossButton}
      title={isMobileActiveLinksOpen ? `Активные ссылки (${activeShareLinks.content.length})` : 'Поделиться портфолио'}
      onExitBack={isMobileActiveLinksOpen ? handleCloseActiveLinks : undefined}
    />
  );

  const footer = (
    <GroupedActionButtonsContainer disableAbsolutePosition>
      {isLinkTab && (
        <GroupedActionButton
          variant="primary"
          disabled={studentShareLink?.loading || !isDisplaySettingsSelected || isCustomPeriodError}
          startIcon={IconCopyOutline}
          handleClick={handleOpenLinkUrl}
        >
          {isMobile ? 'Ссылка' : 'Копировать ссылку'}
        </GroupedActionButton>
      )}
      {isLinkTab && (
        <GroupedActionButton
          disabled={studentShareLink?.loading || !isDisplaySettingsSelected}
          startIcon={IconQr}
          handleClick={handleOpenLinkQrCode}
        >
          {isMobile ? 'QR-код' : 'Показать QR-код'}
        </GroupedActionButton>
      )}
      {isFileTab && (
        <GroupedActionButton
          disabled={isDownloadBtnDisabled}
          startIcon={IconDownloadFile}
          handleClick={exportPDFWithMethod}
        >
          Скачать файл
        </GroupedActionButton>
      )}
    </GroupedActionButtonsContainer>
  );

  const drawerDefaultContent = (
    <>
      {isMobile && <Box sx={styles.mobileTabs}>{mobileTabsElement}</Box>}
      <Box sx={styles.widgetsContainer(isFileTab)}>
        {isLinkTab && (
          <LinksOverview
            isAccessEnabled={areLinksActive}
            onChangeAccess={handleUpdateLinkActivity}
            onOpenLinks={handleOpenActiveLinks}
          />
        )}

        <Settings currentTab={currentTab} settings={viewSettings} sx={styles.settingsWidget(isFileTab)} />

        {isLinkTab && (
          <LinksPeriod
            periodType={linkExpiration}
            onChangePeriodType={setLinkExpiration}
            customPeriodStart={customPeriodStart}
            customPeriodEnd={customPeriodEnd}
            customPeriodController={customPeriodController}
            isCustomPeriodError={isCustomPeriodError}
          />
        )}
      </Box>
    </>
  );

  const drawerMobileActiveLinksContent = <ActiveLinksList activeLinks={activeShareLinks.content} />;

  return (
    <>
      <Drawer
        open={isOpen}
        isFullHeight
        isMobile={isMobile}
        anchor={isMobile ? 'bottom' : 'right'}
        swipeable={isMobile}
        swipeableProps={{
          onClose,
        }}
        header={isMobile ? mobileHeader : desktopHeader}
        footer={!isMobileActiveLinksOpen && footer}
        onClose={onClose}
        sx={styles.drawerRoot}
        contentSx={styles.drawerContent}
      >
        {/* overflow: auto переносится с контента дровера на внутренний элемент */}
        <Box className="link-sharing-form-container" sx={styles.drawerInnerContent}>
          <Box
            ref={sharingContainerRef}
            className="link-sharing-form"
            sx={mergeSx(drawerContentPadding(true), styles.root)}
          >
            {isMobileActiveLinksOpen ? drawerMobileActiveLinksContent : drawerDefaultContent}

            {!isMobile && (
              <ActiveLinksPopUp
                isOpen={isActiveLinksOpen}
                onClose={handleCloseActiveLinks}
                activeLinks={activeShareLinks.content}
              />
            )}
          </Box>
        </Box>
        {isOpen && isFileTab && (
          <Box ref={pdfContainer}>
            <StudentPortfolioPdf display={fullRawSettings} />
          </Box>
        )}
      </Drawer>
    </>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent,
    studentShareLink: state.studentShareLink,
    adminVisibilitySettings: state.adminSettings,
    themeSettings: state.userThemeSettings,
    // TODO сделать нормальное получение состояния загрузки из пдф модуля
    // предположительно сделать как поиске
    portfolioContentLoading:
      state.studentEvents.loading ||
      state.studentProjects.loading ||
      state.studentAffilations.loading ||
      state.studentEmployments.loading ||
      state.studentRewards.loading ||
      state.studentSportRewards.loading ||
      state.studentGiaList.loading ||
      state.studentFinalGrades.loading ||
      state.studentAverageGrades.loading ||
      state.studentDiagnosis?.loading ||
      state.studentShareLink.loading ||
      state.personsObjects.loading ||
      state.cultureVisitings.loading ||
      state.personalDiagnostics.loading,
  }),
  {
    getStudentShareLinkList: getStudentShareLinkListActions.request,
    createStudentShareLink: createStudentShareLinkActions.request,
    updateStudentShareLinkActivity: updateStudentShareLinkActivityActions.request,
  },
)(LinkSharingForm);
