import { FC } from 'react';

import { Typography, useMediaQuery } from '@mui/material';
import { professionNavigation, StudentSectionSettingTypeCodes } from 'const';
import { useAppSelector, useBrightTheme, useDataLoad, useWidgetVisibility } from 'hooks';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { WidgetContainerTitle } from 'portfolio3/components/widgetContainerRecipes';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { commonTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';
import { SxStyles } from 'types';
import { getDataLoadRequests, mergeSx } from 'utils';

import { industryIllustrations } from '../assets';
import LinkContainer from '../components/LinkContainer';
import { proforientationResultLinkSelector, proforientationVisibilitySelectors } from '../model/selectors';

const widgetIllustrationStyles: SxStyles = (theme) => ({
  backgroundImage: `url(${industryIllustrations.проф_тестирование})`,
  backgroundRepeat: 'no-repeat',

  backgroundSize: '104px 104px',
  backgroundPosition: 'top 18px right 20px',

  [theme.breakpoints.down('commonSm')]: {
    backgroundSize: '82px 82px',
    backgroundPosition: 'top 16px right 16px',
  },
});

const sectionTypeCode = StudentSectionSettingTypeCodes.profession;
const subsectionTypeCode = StudentSectionSettingTypeCodes.professionProforientation;

const navigationData = professionNavigation.proforientation;

const ProforientationWidget: FC = () => {
  const isBrightTheme = useBrightTheme();
  const { isSettingsMode, isWidgetVisible, toggleElement, overlayElement } = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
  });
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const isMediumScreen = useMediaQuery(commonTheme.breakpoints.down('commonMd'));

  useDataLoad({ shouldLoad: isWidgetVisible, requests: getDataLoadRequests().professionProforientation });

  const resultLink = useAppSelector(proforientationResultLinkSelector);
  const isOverviewVisible = useAppSelector(proforientationVisibilitySelectors.overwiew);

  const handleLinkClick = () => {
    emitYMEvent({ type: 'careerGuidanceClick' });
  };

  const widgetTitleElement = (
    <WidgetContainerTitle title={navigationData.label} right={isSettingsMode && toggleElement} />
  );

  if (!isWidgetVisible || !isOverviewVisible) return null;

  return (
    <Widget
      containerData={navigationData}
      withBackground={isBrightTheme}
      sx={mergeSx(!isBrightTheme && widgetIllustrationStyles)}
    >
      {overlayElement}
      <Widget.Header title={widgetTitleElement} />
      <Widget.Padding>
        <Typography
          variant="Paragraph LG/Regular"
          sx={mergeSx(
            { maxWidth: '612px' },
            isMediumScreen && { maxWidth: '355px' },
            isSmallScreen && { maxWidth: '222px' },
          )}
        >
          Здесь ты&nbsp;можешь ознакомиться с&nbsp;результатами тестирования и&nbsp;скачать&nbsp;их
        </Typography>

        <LinkContainer
          link={resultLink ?? ''}
          sx={mergeSx({ marginTop: isMediumScreen ? '12px' : '20px' }, isMediumScreen && { width: '100%' })}
          onClick={handleLinkClick}
        >
          <Button sx={{ width: '100%' }}>Перейти к результатам</Button>
        </LinkContainer>
      </Widget.Padding>
    </Widget>
  );
};

export default ProforientationWidget;
