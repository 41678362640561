import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { useIsFirstRender, useResizeObserver } from '@mantine/hooks';

// https://github.com/h-kanazawa/react-detectable-overflow/blob/master/src/useOverflowDetector.ts

export interface useOverflowDetectorProps {
  onChange?: (overflow: boolean) => void;
  handleHeight?: boolean;
  handleWidth?: boolean;
}

export function useOverflowDetector(props: useOverflowDetectorProps = {}) {
  const [overflow, setOverflow] = useState(false);
  const [ref, rect] = useResizeObserver();
  const isFirstRender = useIsFirstRender();

  const updateState = useCallback(() => {
    if (ref.current == undefined) {
      return;
    }

    const { handleWidth = true, handleHeight = true } = props;

    const newState =
      (handleWidth && ref.current.offsetWidth < ref.current.scrollWidth) ||
      (handleHeight && ref.current.offsetHeight < ref.current.scrollHeight);

    if (newState === overflow) {
      return;
    }
    setOverflow(newState);
    if (props.onChange) {
      props.onChange(newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, props.handleWidth, props.handleHeight, props.onChange, setOverflow, overflow]);

  useLayoutEffect(() => {
    if (isFirstRender) {
      updateState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rect]);

  useEffect(() => {
    if (!isFirstRender) {
      updateState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rect]);

  return {
    overflow,
    ref,
  };
}
