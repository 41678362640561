import { FC } from 'react';

import { professionNavigation, StudentSectionSettingTypeCodes } from 'const';
import { useAppSelector, useBrightTheme, useWidgetVisibility } from 'hooks';
import { useActiveWidget } from 'hooks';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { WidgetContainerTitle } from 'portfolio3/components/widgetContainerRecipes';
import { Switcher, SwitcherButton } from 'portfolio3/ui-kit';

import AcceptParticipationDialog from '../components/AcceptPerticipationDialog';
import { proforientationVisibilitySelectors } from '../model/selectors';
import {
  RecommendationExcursionsWidget,
  RecommendationOpenDayWidget,
  RecommendationProbesWidget,
  RecommendationProforientationWidget,
} from './RecommendationSubWidgets';
import { WidgetNames, WidgetType } from './widgets';

const sectionTypeCode = StudentSectionSettingTypeCodes.profession;
const subsectionTypeCode = StudentSectionSettingTypeCodes.professionProforientationRecommendations;

const navigationData = professionNavigation.recommendations;

const ProforientationRecommendationsWidget: FC = () => {
  const isVisible = useAppSelector(proforientationVisibilitySelectors.recommendations);

  const widgets = [
    {
      id: WidgetType.PROFORIENTATION,
      isVisible: isVisible.proforientation,
      component: RecommendationProforientationWidget,
    },
    {
      id: WidgetType.PROBES,
      isVisible: isVisible.probes,
      component: RecommendationProbesWidget,
    },
    {
      id: WidgetType.EXCURSIONS,
      isVisible: isVisible.excursions,
      component: RecommendationExcursionsWidget,
    },
    {
      id: WidgetType.OPEN_DAY,
      isVisible: isVisible.dods,
      component: RecommendationOpenDayWidget,
    },
  ];

  const { activeWidgetId, setActiveWidgetId, activeWidgets } = useActiveWidget(widgets);

  const isBrightTheme = useBrightTheme();
  const { isSettingsMode, isWidgetVisible, toggleElement, overlayElement } = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
  });

  const widgetTitleElement = (
    <WidgetContainerTitle title={navigationData.label} right={isSettingsMode && toggleElement} />
  );

  const switcherElement = (
    <Switcher value={activeWidgetId ?? undefined} onChange={setActiveWidgetId} useHiddenList>
      {activeWidgets.map(({ id }) => {
        return <SwitcherButton key={id} value={id} content={WidgetNames[id]} />;
      })}
    </Switcher>
  );

  const AcitveWidgetComponent = activeWidgets.find((widget) => widget.id === activeWidgetId)?.component;

  if (!isWidgetVisible || activeWidgets.length === 0) return null;

  return (
    <Widget containerData={navigationData} withBackground={isBrightTheme}>
      {overlayElement}
      <Widget.Header title={widgetTitleElement} options={switcherElement} />
      <Widget.Padding>
        {AcitveWidgetComponent && <AcitveWidgetComponent />}

        <AcceptParticipationDialog />
      </Widget.Padding>
    </Widget>
  );
};

export default ProforientationRecommendationsWidget;
