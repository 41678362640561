import { getViewedFunctionsActions } from 'actions';
import { ApiResult } from 'api/impl';
import { call, put } from 'redux-saga/effects';

import { addStudentViewedFunction, getViewedFunctions } from '../api';
import { IResponsePayload, IViewedFunction, TAddUserViewedFunctionRequest } from '../api/types';
import { GenericGetAction } from '.';

function* getViewdFunctionsSaga({ payload }: ReturnType<typeof getViewedFunctionsActions.request>) {
  const { personId } = payload;
  const {
    response,
    error,
  }: ApiResult<IResponsePayload<IViewedFunction[]>, unknown> = yield call(getViewedFunctions, personId);

  if (response) {
    yield put(getViewedFunctionsActions.success(response.data));
  } else {
    yield put(getViewedFunctionsActions.failure(error));
  }
}

function* setFunctionViewedSaga({
  payload: { personId, functionCode },
}: Required<GenericGetAction<TAddUserViewedFunctionRequest>>) {
  yield call(addStudentViewedFunction, personId, functionCode);
}

export { getViewdFunctionsSaga, setFunctionViewedSaga };
