import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { useAppSelector, useDataLoad } from 'hooks';
import { widgetPdfIcons } from 'icons';
import { getDataLoadRequests } from 'utils';

import {
  getPdfStudentDiagnosisFullActions,
  getPdfStudentDiagnosisYearActions,
  getStudentIndependentDiagnosisActions,
} from '../../../actions';
import {
  IChildInfo,
  IDiagnosisResponse,
  IIndependentDiagnosticResponse,
  ISectionsVisibility,
} from '../../../api/types';
import { DiagnosticCountCode, DiagnosticCountKeys, SelfDiagnosticPeriodTypeCode } from '../../../const';
import PdfCommonDataEntityContainer from '../../../containers/pdfStudent/dataEntityContainers/common';
import { IRootState } from '../../../reducers';
import DiagnocticDoughnutStats from '../DiagnocticDoughnutStats/self';
import IndependentDiagnosticComponent from './IndependentDiagnostics';
import PersonalDiagnosticsComponent from './PersonalDiagnostics';

import './index.scss';

interface IPdfDiagnosticsProps {
  displaySettings: ISectionsVisibility | undefined;
  currentStudent: IChildInfo;
  pdfStudentDiagnosisFull: IDiagnosisResponse;
  pdfStudentDiagnosisYear: IDiagnosisResponse;
  pdfStudentIndependentDiagnosisFull: IIndependentDiagnosticResponse;
  getPdfStudentDiagnosisYear: typeof getPdfStudentDiagnosisYearActions.request;
  getPdfStudentDiagnosisFull: typeof getPdfStudentDiagnosisFullActions.request;
  getPdfStudentIndependentDiagnosis: typeof getStudentIndependentDiagnosisActions.request;
}

const PdfDiagnostics: React.FC<IPdfDiagnosticsProps> = ({
  displaySettings,
  currentStudent,
  pdfStudentDiagnosisYear,
  pdfStudentDiagnosisFull,
  pdfStudentIndependentDiagnosisFull,
  getPdfStudentDiagnosisFull,
  getPdfStudentDiagnosisYear,
  getPdfStudentIndependentDiagnosis,
}) => {
  useDataLoad({
    shouldLoad: displaySettings?.personalDiagnostic ?? false,
    requests: getDataLoadRequests().studyPersonalDiagnosticGroupedVisible,
  });

  const { content: personalDiagnostics } = useAppSelector((state) => state.personalDiagnostics);

  useEffect(() => {
    if (!currentStudent?.meshId) return;
    getPdfStudentDiagnosisYear(currentStudent.meshId, SelfDiagnosticPeriodTypeCode.year);
    getPdfStudentDiagnosisFull(currentStudent.meshId, SelfDiagnosticPeriodTypeCode.full);
    getPdfStudentIndependentDiagnosis(
      currentStudent.meshId,
      SelfDiagnosticPeriodTypeCode.full,
      DiagnosticCountCode[DiagnosticCountKeys.VISIBLE],
    );
  }, [currentStudent.meshId]);

  const isDiagnosisYearExist = pdfStudentDiagnosisYear.content?.selfDiagnosticSubjects?.length !== 0;
  const isDiagnosisFullExist = pdfStudentDiagnosisFull.content?.selfDiagnosticSubjects?.length !== 0;

  const { content: independentDiagnostics } = pdfStudentIndependentDiagnosisFull;
  const isIndependentTotalExist = !!independentDiagnostics?.totalDiagnostic;
  const isIndependentSelfExist = independentDiagnostics?.selfDiagnostic.length !== 0;

  return (
    <PdfCommonDataEntityContainer title="Диагностики" icon={widgetPdfIcons.pdfIconStudyDiagnostics}>
      {displaySettings?.independentDiagnostic && (
        <div className="pdf-diagnostic-block">
          <h5 className="pdf-diagnostic-block__title">Независимые диагностики</h5>
          {isIndependentTotalExist || isIndependentSelfExist ? (
            <IndependentDiagnosticComponent independentDiagnostics={pdfStudentIndependentDiagnosisFull} />
          ) : (
            <p>Данные отсутствуют</p>
          )}
        </div>
      )}
      {displaySettings?.personalDiagnostic && (
        <div className="pdf-diagnostic-block">
          <h5 className="pdf-diagnostic-block__title">Индивидуальные диагностики</h5>
          {isIndependentTotalExist || isIndependentSelfExist ? (
            <PersonalDiagnosticsComponent personalDiagnostics={personalDiagnostics} />
          ) : (
            <p>Данные отсутствуют</p>
          )}
        </div>
      )}
      {displaySettings?.selfDiagnostic && (
        <>
          <div className="pdf-diagnostic-block pdf-keep">
            <h5 className="pdf-diagnostic-block__title">Пройденные диагностики за год</h5>
            {isDiagnosisYearExist && pdfStudentDiagnosisYear.content ? (
              <DiagnocticDoughnutStats
                studentDiagnosis={pdfStudentDiagnosisYear.content}
                bestSubject={pdfStudentDiagnosisYear.content?.bestResult}
              />
            ) : (
              <p>Нет данных о диагностиках за год</p>
            )}
          </div>
          <div className="pdf-diagnostic-block pdf-keep">
            <h5 className="pdf-diagnostic-block__title">Пройденные диагностики за все время</h5>

            {isDiagnosisFullExist && pdfStudentDiagnosisFull.content ? (
              <DiagnocticDoughnutStats
                studentDiagnosis={pdfStudentDiagnosisFull.content}
                bestSubject={pdfStudentDiagnosisFull.content?.bestResult}
              />
            ) : (
              <p>Нет данных о диагностиках за все время</p>
            )}
          </div>
        </>
      )}
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent,
    pdfStudentDiagnosisFull: state.pdfStudentDiagnosisFull,
    pdfStudentDiagnosisYear: state.pdfStudentDiagnosisYear,
    pdfStudentIndependentDiagnosisFull: state.studentIndependentDiagnosis,
  }),
  {
    getPdfStudentDiagnosisFull: getPdfStudentDiagnosisFullActions.request,
    getPdfStudentDiagnosisYear: getPdfStudentDiagnosisYearActions.request,
    getPdfStudentIndependentDiagnosis: getStudentIndependentDiagnosisActions.request,
  },
)(PdfDiagnostics);
