import { accentColors, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const container: SxStyles = {
  padding: '16px',
  borderTop: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
};

export const button: SxStyles = {
  display: 'flex',
  gap: '8px',

  '& > *': {
    color: accentColors.indigo[100],
  },

  svg: {
    width: '20px',
    height: '20px',
  },
};
