import { SUCCESS } from 'actions/utils';
import { IViewedFunction } from 'api/types';

import {
  GET_VIEWED_FUNCTIONS,
  getViewedFunctionsActions,
  SET_FUNCTION_VIEWED,
  SET_MANUAL_FUNCTION_VIEWED,
  SET_VIEWING_FUNCTION_MODE,
  setManualFunctionViewedAction,
} from '../actions';
import { connectReducers } from '../utils';
import { IReducers } from './index';

export type ViewedFunctionsState = {
  isViewingMode: boolean;
  // null если не еще не загружены
  functions: IViewedFunction[] | null;
};

type SetFunctionViewedAction = {
  payload: {
    personId: string;
    functionCode: number;
    viewed?: boolean;
  };
};

type SetViewingFunctionModeAction = {
  payload: {
    mode: boolean;
  };
};

const initialState: ViewedFunctionsState = {
  isViewingMode: false,
  functions: null,
};

const viewedFunctions: IReducers<ViewedFunctionsState> = {
  [GET_VIEWED_FUNCTIONS[SUCCESS]]: (state, action: ReturnType<typeof getViewedFunctionsActions.success>) => {
    return {
      ...state,
      functions: action.payload,
    };
  },
  [SET_FUNCTION_VIEWED]: (state, action: SetFunctionViewedAction) => {
    const functionCode = action.payload.functionCode;

    const newFunctions = state.functions?.map((func) => {
      if (func.code === functionCode) {
        return {
          ...func,
          isViewed: true,
        };
      }

      return func;
    });

    return {
      ...state,
      functions: newFunctions ?? null,
    };
  },
  [SET_MANUAL_FUNCTION_VIEWED]: (state, action: ReturnType<typeof setManualFunctionViewedAction>) => {
    const { viewed, functionCode } = action.payload;

    const newFunctions = state.functions?.map((func) => {
      if (func.code === functionCode) {
        return {
          ...func,
          isViewed: viewed,
        };
      }

      return func;
    });

    return {
      ...state,
      functions: newFunctions ?? null,
    };
  },
  [SET_VIEWING_FUNCTION_MODE]: (state, action: SetViewingFunctionModeAction) => {
    return {
      ...state,
      isViewingMode: action.payload.mode,
    };
  },
};

export default connectReducers(initialState, viewedFunctions);
