import { FC } from 'react';

import DiagnosticCard from '../DiagnosticCard';
import { PdfDiagnostic } from '../types';

import './index.scss';

interface IDiagnosticCardProps {
  diagnostic: PdfDiagnostic;
  year?: number;
}

const DiagnosticCardWrapper: FC<IDiagnosticCardProps> = ({ diagnostic, year }) => {
  return (
    <div className="pdf-diagnostic-card-wrapper pdf-keep">
      {year && <div className="list-year">{year}</div>}
      <div className="pdf-diagnostic-card-wrapper__side">
        <div className="pdf-diagnostic-card-wrapper__line">
          <div className="pdf-diagnostic-card-wrapper__pin"></div>
        </div>
      </div>
      <DiagnosticCard diagnostic={diagnostic} />
    </div>
  );
};

export default DiagnosticCardWrapper;
