export enum StatusApplicationKeys {
  APPROVED = 'approved',
  NO_PLACES_AVAILABLE = 'noPlacesAvailable',
  PENDING = 'pending',
  REGISTERED = 'registered',
}

// status_application_ref
export const StatusApplicationCode: Record<StatusApplicationKeys, number> = {
  [StatusApplicationKeys.APPROVED]: 1,
  [StatusApplicationKeys.NO_PLACES_AVAILABLE]: 2,
  [StatusApplicationKeys.PENDING]: 3,
  [StatusApplicationKeys.REGISTERED]: 4,
};

export const StatusApplicationCodeText: Record<number, string> = {
  [StatusApplicationCode.approved]: 'Заявка одобрена',
  [StatusApplicationCode.noPlacesAvailable]: 'Мест на выбранную дату нет',
  [StatusApplicationCode.pending]: 'Заявка на рассмотрении',
  [StatusApplicationCode.registered]: 'Ты зарегистрирован(а)',
};
