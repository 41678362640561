import { FC } from 'react';

import { Box, capitalize, Stack, Typography, useMediaQuery } from '@mui/material';
import { useAppSelector } from 'hooks';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { commonTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';

import LinkContainer from '../../components/LinkContainer';
import RecommendationIndustryCardList from '../../components/RecommendationIndustryCardList';
import { proforientationRecommendationSelectors, proforientationResultLinkSelector } from '../../model/selectors';

const RecommendationProforientationWidget: FC = () => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const resultLink = useAppSelector(proforientationResultLinkSelector);
  const recommendedIndustries = useAppSelector(proforientationRecommendationSelectors.proforientation);

  const industryNamesString = recommendedIndustries.map((industry) => capitalize(industry.name)).join(', ');
  const filteredIndustries = recommendedIndustries.filter(
    (industry) => industry.specialities.length > 0 || industry.collegeNames.length > 0,
  );

  const handleLinkClick = () => {
    emitYMEvent({ type: 'recommendationsCareerGuidanceClick' });
  };

  const resultLinkElement = resultLink && (
    <LinkContainer link={resultLink} onClick={handleLinkClick}>
      <Button>Подробнее</Button>
    </LinkContainer>
  );

  const industryListElement = (
    <Typography variant="Paragraph LG/Regular">
      Тебе рекомендованы следующие отрасли:{' '}
      <Typography component="span" variant="Paragraph LG/Semi Bold">
        {industryNamesString}.
      </Typography>
    </Typography>
  );

  const descriptionElement = (
    <Typography variant="Paragraph LG/Regular">
      Ниже для&nbsp;тебя сформирован список отраслей, специальностей и&nbsp;колледжей.
    </Typography>
  );

  const desktopLayoutElement = (
    <Stack direction="row" gap="16px">
      <Stack gap="16px" flex={1}>
        {industryListElement}
        {descriptionElement}
      </Stack>
      <Box alignSelf="flex-end">{resultLinkElement}</Box>
    </Stack>
  );

  const mobileLayoutElement = (
    <Stack gap="16px">
      {industryListElement}
      <Stack direction="row" gap="16px">
        <Box flex={1}>{descriptionElement}</Box>
        <Box alignSelf="flex-end">{resultLinkElement}</Box>
      </Stack>
    </Stack>
  );

  return (
    <Box className="recommendation-proforientation-widget">
      {isSmallScreen ? mobileLayoutElement : desktopLayoutElement}

      {filteredIndustries.length > 0 && (
        <Box marginTop="16px">
          <RecommendationIndustryCardList recommendations={filteredIndustries} />
        </Box>
      )}
    </Box>
  );
};

export default RecommendationProforientationWidget;
