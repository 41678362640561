import { FC, ReactNode } from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { commonTheme } from 'portfolio3/styles/theme';
import { Attributes } from 'portfolio3/ui-kit';

import * as styles from './styles';

interface IGenericEventCardProps {
  date: string;
  industry?: string;
  title: string;
  subtitle?: string;
  illustration: string;
  bottomAddon?: ReactNode;
}

const GenericEventCard: FC<IGenericEventCardProps> = ({
  date,
  industry,
  title,
  subtitle,
  illustration,
  bottomAddon,
}) => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const attributes = [date, industry].filter(Boolean).map((value) => {
    return (
      <Typography variant="Paragraph MD/Regular" key={value}>
        {value}
      </Typography>
    );
  });

  const leftElement = (
    <Box className="generic-event-card__left" sx={styles.rootLeft}>
      <Stack className="generic-event-card__content" gap="8px">
        <Attributes attributes={attributes} />
        <Typography variant="Paragraph LG/Semi Bold">{title}</Typography>
        {subtitle && <Typography variant="Paragraph MD/Regular">{subtitle}</Typography>}
      </Stack>
    </Box>
  );

  return (
    <Box className="generic-event-card" sx={styles.root}>
      <Box sx={styles.rootLayout}>
        {leftElement}

        {!isSmallScreen && (
          <Box className="generic-event-card__right" sx={styles.rootRight}>
            <Box sx={styles.image(illustration)} />
          </Box>
        )}
      </Box>

      {bottomAddon}
    </Box>
  );
};

export default GenericEventCard;
