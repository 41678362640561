/* eslint-disable prettier/prettier */
/* eslint-disable */

import { combineReducers } from 'redux';

import { IMetaData } from '../api/types';

import auth, { IAuthState } from './auth';
import attachment, { AttachmentState } from './attachment';
import attachmentTemplate, { AttachmentTemplateState } from './employee/attachmentTemplate';
import currentStudent, { TCurrentStudentState } from './currentStudent';
import currentUser, { TUserState } from './user';
import displayedEntityCount, { DisplayedEntityCountState } from './displayedEntityCount';
import linkError, { LinkErrorState } from './linkError';
import linkMode, { LinkModeState } from './linkMode';
import olympiadLevels, { OlympiadLevelsState } from './olympiadLevels';
import olympiadResults, { OlympiadResultsState } from './olympiadResults';
import olympiadParallels, { OlympiadParallelsState } from './employee/olympiadParallels';
import olympiadTypes, { OlympiadTypesState } from './employee/olympiadTypes';
import olympiadFormats, { OlympiadFormatsState } from './olympiadFormats';
import studentGiaList, { StudentGiaListState } from './student/studentGiaList';
import studentShareLink, { StudentShareLinkState } from './student/studentShareLink';
import studentShareLinkList, { StudentShareLinkListState } from './student/studentShareLinkList';
import studentDiagnosis, { StudentDiagnosisState } from './student/studentDiagnosis';
import studentIndependentDiagnosis, { StudentIndependentDiagnosisState } from './student/studentIndependentDiagnosis';
import independentDiagnostics, { IndependentDiagnosticsState } from './diagnostic/independentDiagnostics';
import personalDiagnostics, { PersonalDiagnosticsState } from './diagnostic/personalDiagnostics';
import personalDiagnosticsSettings, { PersonalDiagnosticsSettingsState } from './diagnostic/personalDiagnosticsSettings';
import independentDiagnosticsGeneralRating, { IndependentDiagnosticsGeneralRatingState } from './diagnostic/independentDiagnosticsGeneralRating';
import independentDiagnosticsRating, { IndependentDiagnosticsRatingState } from './diagnostic/independentDiagnosticsRating';
import independentDiagnosisSettings, { IndependentDiagnosisSettingsState } from './independentDiagnosisSettings';

import pdfStudentDiagnosisFull, { PdfStudentDiagnosisFullState } from './student/pdfStudentDiagnosisFull';
import pdfStudentDiagnosisYear, { PdfStudentDiagnosisYearState } from './student/pdfStudentDiagnosisYear';
import feedbackLink, { FeedbackLinkState } from './feedBackLink';
import linkVisibilitySettings, { LinkVisibilitySettingsState } from './linkVisibilitySettings';
import userSettings, { UserSettingsState } from './userSettings';
import localVisibilitySettings, { LocalVisibilitySettingState } from './localVisibilitySettings';
import cultureVisitings, { CultureVisitingsState } from './student/cultureVisitings';

import affilationKinds, { AffilationKindState } from './employee/affilationKinds';
import eventKinds, { EventKindState } from './employee/eventKinds';
import sportKinds, { SportKindsState } from './employee/sportKinds';
import tourismKinds, { TourismKindsState } from './employee/tourismKinds';
import creationKinds, { CreationKindState } from './employee/creationKinds';
import culturalKinds, { CulturalKindState } from './employee/culturalKinds';
import rewardKinds, { RewardKindState } from './employee/rewardKinds';
import portfolioSubcategories, { PortfolioSubcategoriesState } from './employee/subcategories';
import statusRewards, { StatusRewardsState } from './employee/statusRewards';
import projectFormats, { ProjectFormatsState } from './employee/projectFormats';
import personsObjects, { PersonsObjectsState } from './employee/personObjects';
import disciplines, { DisciplinesState } from './employee/disciplines';
import museums, { MuseumsState } from './employee/museums';
import theatres, { TheatresState } from './employee/theatres';
import cinemas, { CinemasState } from './employee/cinemas';
import sportClubs, { SportClubsState } from './employee/sportClubs';
import sportTrainingStage, { SportTrainingStageState } from "./employee/sportTrainingStage";
import sportRewardKinds, { SportRewardKindsState } from './employee/sportRewardKinds';
import organizations, { OrganizationState } from './employee/organizations';
import parallels, { ParallelsState } from './employee/parallels';
import personsList, { PersonsListState } from './employee/persons';
import history, { HistoryState } from './employee/history';
import importHistory, { ImportHistoryState } from './employee/importHistory';
import importList, { ImportListState } from './employee/importList';

import studentEvents, { StudentEventsState } from './student/events';
import studentRewards, { StudentRewardsState } from './student/rewards';
import studentProjects, { StudentProjectsState } from './student/projects';
import studentEmployments, { StudentEmploymentsState } from './student/employments';
import studentSportRewards, { StudentSportRewardsState } from './student/sportRewards';
import studentAffilations, { StudentAffilationsState } from './student/affilations';
import studentGiaWorldSkills, { StudentGiaWorldSkillsState } from './student/giaWorldSkills';
import studentRecommendations, { StudentRecommendationsState } from './student/recommendations';
import studentClassRecommendations, { StudentClassRecommendationsState } from './student/classRecommendations';
import studentEisDopRecommendations, { StudentEisDopRecommendationsState } from './student/eisDopRecommendations';
import studentCheckInHistory, { StudentCheckInHistoryState } from './student/checkInHistory';
import historyFormEntity, { HistoryFormEnityState } from './employee/historyFormEntity';
import historyFormEditStatus, { HistoryFormEditStatusState } from './employee/historyFormEditStatus';
import reportErrorStatus, { ReportErrorState } from './student/reportError';
import entityErrorMessages, { EntityErrorMessagesState } from './student/entityErrorMessages';
import gratitudeTeacherStatus, { GratitudeTeacherState } from './student/gratitudeTeacher';
import avatars, { AvatarsState } from './student/avatars';
import studentJobs, { StudentJobsState } from './student/studentJobs';
import studentSpoEducation, { StudentSpoEducationState } from './student/studentSpoEducation';
import studentSpoDocuments, { StudentSpoDocumentsState } from './student/studentSpoDocuments';

import adminSettings, { AdminSettingsState } from './admin/adminSettings';
import adminViewModeSettings, { AdminViewModeSettingsState } from './admin/viewModeSettings';
import learnerCategories, { LearnerCategoriesState } from './admin/learnerCategories';
import settingSections, { SettingSectionsState } from './admin/settingSections';
import adminHistory, { AdminHistoryState } from './admin/adminHistory';
import adminDeleteHistory, { AdminDeleteHistoryState } from './admin/adminDeleteHistory';
import actionKinds, { ActionKindsState } from './admin/actionKinds';
import adminFunctionSettings, { AdminFunctionSettingsState } from './admin/adminFunctionSettings';
import adminViewMode, { AdminViewModeState } from './admin/viewMode';
import sources, { SourcesState } from './sources';

import studentInterests, { StudentInterestsState } from './interests/interests';
import interestHeadKinds, { InterestHeadKindsState } from './interests/interestHeadKinds';
import interestActionKinds, {InterestActionKindsState} from './interests/interestActionKinds';
import interestGroupKinds, { InterestGroupKindsState } from './interests/interestGroupKinds';
import interestObjectKinds, { InterestObjectKindsState } from './interests/interestObjectKinds';
import archivedInterestObjectKinds, { ArchivedInterestObjectKindsState } from './interests/archivedInterestObjectKinds';

import viewedFunctions, { ViewedFunctionsState } from './viewedFunctions';
import viewedFunctionsRequest, { ViewedFunctionsRequestState } from './viewedFunctionsRequest';
import studentProfessionalAdditionalEducation, { StudentProfessionalAdditionalEducationState } from './student/studentProfessionalAdditionalEducation';
import studentMetaSkills, { StudentMetaSkillsState } from './student/studentMetaSkills';
import studentSpoStatus, { StudentSpoStatusState } from './student/studentSpoStatus';
import studentPersonalAccess, { StudentPersonalAccessState } from './student/studentPersonalAccess';

import parallelCurricula, { ParallelCurriculaState } from './dnevnik/parallelCurricula';

import userThemeSettings, { ThemeSettingsState } from './theme/settings';
import boundaryError, {BoundaryErrorState} from './boundaryError';
import applicationStatus, { ApplicationStatusState } from './applicationStatus';

import navigationSection, { NavigationSectionState } from './navigationSection';

import {
  sectionRef, SectionRefState,
  documentRef, DocumentRefState,
  metaSkillRef, MetaSkillRefState,
  professionProgrammRef, ProfessionProgrammRefState,
  levelBusinessRef, LevelBusinessRefState,
  salaryRangeRef, SalaryRangeRefState,
  employmentDocTypeRef, EmploymentDocTypeRefState,
  spoOrganizationRef, SpoOrganizationRefState,
  spoGiaMarkRef, SpoGiaMarkRefState,
  professionRankRef, ProfessionRankRefState,
  sportAgesRef, SportAgesRefState,
  olympiadSubjectsRef, OlympiadSubjectsRefState,
  avatarTypeRef, AvatarTypeRefState
} from './reference';

import dataEntryDrawer, { DataEntryDrawerState } from './drawers/dataEntryDrawer';
import detailViewDrawer, { DetailViewDrawerState } from './drawers/detailViewDrawer';
import linkSharingDrawer, { LinkSharingDrawerState } from './drawers/linkSharingDrawer';
import reportErrorDrawer, { ReportErrorDrawerState } from './drawers/reportErrorDrawer';
import thanksTeacherDrawer, { ThanksTeacherDrawerState } from './drawers/thanksTeacherDrawer';
import eisDopRecommendationsDrawer, { EisDopRecommendationsDrawerState } from './drawers/eisDopRecommendationsDrawer';

import { vuzRecommendationModel, vuzRecommendationModelTypes } from 'portfolio3/features/vuzRecommendation';
import { proforientationModel, proforientationModelTypes } from 'portfolio3/features/proforientation';
import { searchModel, searchModelTypes } from 'portfolio3/features/search';
import { performanceModel, performanceModelTypes } from 'portfolio3/features/performance';
import { performanceOldModel, performanceOldModelTypes } from 'portfolio3/features/performanceOld';
import { yandexMetrikaModel } from 'portfolio3/features/yandexMetrika';

export type RequestState = 'request' | 'failure' | 'success';

export interface GenericResponseData<T> extends IMetaData {
  content: T;
  loading?: boolean;
  more?: boolean;
}

export interface IReducers<S = any> {
  [key: string]: (state: S, action: any) => S;
}

export interface IRootState
  extends vuzRecommendationModelTypes.RootState,
    proforientationModelTypes.RootState,
    searchModelTypes.RootState,
    performanceModelTypes.RootState,
    performanceOldModelTypes.RootState {
  attachment: AttachmentState;
  attachmentTemplate: AttachmentTemplateState;
  auth: IAuthState;
  adminSettings: AdminSettingsState;
  adminViewModeSettings: AdminViewModeSettingsState;
  adminFunctionSettings: AdminFunctionSettingsState;
  adminViewMode: AdminViewModeState;
  userThemeSettings: ThemeSettingsState;
  boundaryError: BoundaryErrorState;
  applicationStatus: ApplicationStatusState;
  currentStudent: TCurrentStudentState;
  currentUser: TUserState;
  displayedEntityCount: DisplayedEntityCountState;
  linkError: LinkErrorState;
  linkMode: LinkModeState;
  olympiadFormats: OlympiadFormatsState;
  olympiadLevels: OlympiadLevelsState;
  olympiadParallels: OlympiadParallelsState;
  olympiadResults: OlympiadResultsState;
  olympiadTypes: OlympiadTypesState;
  localVisibilitySettings: LocalVisibilitySettingState;
  studentDiagnosis: StudentDiagnosisState;
  studentIndependentDiagnosis: StudentIndependentDiagnosisState;
  independentDiagnostics: IndependentDiagnosticsState;
  personalDiagnostics: PersonalDiagnosticsState;
  personalDiagnosticsSettings: PersonalDiagnosticsSettingsState;
  independentDiagnosticsGeneralRating: IndependentDiagnosticsGeneralRatingState;
  independentDiagnosticsRating: IndependentDiagnosticsRatingState;
  independentDiagnosisSettings: IndependentDiagnosisSettingsState;
  studentGiaList: StudentGiaListState;
  studentShareLink: StudentShareLinkState;
  studentShareLinkList: StudentShareLinkListState;
  feedbackLink: FeedbackLinkState;
  linkVisibilitySettings: LinkVisibilitySettingsState;
  visibilitySettings: UserSettingsState;
  pdfStudentDiagnosisFull: PdfStudentDiagnosisFullState;
  pdfStudentDiagnosisYear: PdfStudentDiagnosisYearState;
  portfolioSubcategories: PortfolioSubcategoriesState;
  statusRewards: StatusRewardsState;
  projectFormats: ProjectFormatsState;
  affilationKinds: AffilationKindState;
  eventKinds: EventKindState;
  sportKinds: SportKindsState;
  tourismKinds: TourismKindsState;
  creationKinds: CreationKindState;
  culturalKinds: CulturalKindState;
  rewardKinds: RewardKindState;
  disciplines: DisciplinesState;
  personsObjects: PersonsObjectsState,
  organizations: OrganizationState,
  parallels: ParallelsState,
  museums: MuseumsState;
  theatres: TheatresState;
  cinemas: CinemasState;
  sportClubs: SportClubsState;
  sportTrainingStage: SportTrainingStageState;
  sportRewardKinds: SportRewardKindsState;
  personsList: PersonsListState;
  studentEvents: StudentEventsState;
  studentRewards: StudentRewardsState;
  studentProjects: StudentProjectsState;
  studentEmployments: StudentEmploymentsState;
  studentSportRewards: StudentSportRewardsState;
  studentAffilations: StudentAffilationsState;
  studentGiaWorldSkills: StudentGiaWorldSkillsState;
  studentRecommendations: StudentRecommendationsState;
  studentClassRecommendations: StudentClassRecommendationsState;
  studentEisDopRecommendations: StudentEisDopRecommendationsState;
  studentProfessionalAdditionalEducation: StudentProfessionalAdditionalEducationState;
  studentJobs: StudentJobsState;
  studentMetaSkills: StudentMetaSkillsState,
  studentSpoEducation: StudentSpoEducationState;
  studentSpoStatus: StudentSpoStatusState,
  studentPersonalAccess: StudentPersonalAccessState;
  parallelCurricula: ParallelCurriculaState;
  studentSpoDocuments: StudentSpoDocumentsState;
  history: HistoryState;
  historyFormEntity: HistoryFormEnityState;
  historyFormEditStatus: HistoryFormEditStatusState;
  reportErrorStatus: ReportErrorState;
  entityErrorMessages: EntityErrorMessagesState;
  gratitudeTeacherStatus: GratitudeTeacherState;
  avatars: AvatarsState;
  importHistory: ImportHistoryState;
  studentCheckInHistory: StudentCheckInHistoryState;
  importList: ImportListState;
  learnerCategories: LearnerCategoriesState;
  settingSections: SettingSectionsState;
  adminHistory: AdminHistoryState;
  adminDeleteHistory: AdminDeleteHistoryState;
  actionKinds: ActionKindsState;
  cultureVisitings: CultureVisitingsState;
  sources: SourcesState;
  studentInterests: StudentInterestsState;
  interestHeadKinds: InterestHeadKindsState;
  interestActionKinds: InterestActionKindsState;
  interestGroupKinds: InterestGroupKindsState;
  interestObjectKinds: InterestObjectKindsState;
  archivedInterestObjectKinds: ArchivedInterestObjectKindsState;
  viewedFunctions: ViewedFunctionsState;
  viewedFunctionsRequest: ViewedFunctionsRequestState;
  sectionRef: SectionRefState;
  documentRef: DocumentRefState;
  metaSkillRef: MetaSkillRefState;
  professionProgrammRef: ProfessionProgrammRefState;
  levelBusinessRef: LevelBusinessRefState;
  salaryRangeRef: SalaryRangeRefState;
  employmentDocTypeRef: EmploymentDocTypeRefState;
  spoOrganizationRef: SpoOrganizationRefState;
  spoGiaMarkRef: SpoGiaMarkRefState;
  professionRankRef: ProfessionRankRefState;
  sportAgesRef: SportAgesRefState;
  olympiadSubjectsRef: OlympiadSubjectsRefState;
  avatarTypeRef: AvatarTypeRefState;
  dataEntryDrawer: DataEntryDrawerState;
  detailViewDrawer: DetailViewDrawerState;
  linkSharingDrawer: LinkSharingDrawerState;
  reportErrorDrawer: ReportErrorDrawerState;
  thanksTeacherDrawer: ThanksTeacherDrawerState;
  eisDopRecommendationsDrawer: EisDopRecommendationsDrawerState;
  navigationSection: NavigationSectionState;
}

export default combineReducers({
  currentUser,
  currentStudent,
  auth,
  attachment,
  attachmentTemplate,
  adminSettings,
  adminViewModeSettings,
  adminFunctionSettings,
  adminViewMode,
  adminHistory,
  adminDeleteHistory,
  actionKinds,
  cultureVisitings,
  displayedEntityCount,
  linkError,
  linkMode,
  linkVisibilitySettings,
  localVisibilitySettings,
  olympiadFormats,
  olympiadLevels,
  olympiadParallels,
  olympiadResults,
  olympiadTypes,
  pdfStudentDiagnosisFull,
  pdfStudentDiagnosisYear,
  studentDiagnosis,
  studentIndependentDiagnosis,
  independentDiagnostics,
  personalDiagnostics,
  personalDiagnosticsSettings,
  independentDiagnosticsGeneralRating,
  independentDiagnosticsRating,
  independentDiagnosisSettings,
  studentGiaList,
  studentShareLink,
  studentShareLinkList,
  feedbackLink,
  visibilitySettings: userSettings,
  portfolioSubcategories,
  statusRewards,
  projectFormats,
  affilationKinds,
  eventKinds,
  sportKinds,
  sportRewardKinds,
  tourismKinds,
  creationKinds,
  culturalKinds,
  rewardKinds,
  disciplines,
  personsObjects,
  museums,
  theatres,
  cinemas,
  sportClubs,
  sportTrainingStage,
  organizations,
  parallels,
  personsList,
  studentEvents,
  studentRewards,
  studentProjects,
  studentEmployments,
  studentSportRewards,
  studentAffilations,
  studentGiaWorldSkills,
  studentRecommendations,
  studentClassRecommendations,
  studentEisDopRecommendations,
  studentProfessionalAdditionalEducation,
  studentJobs,
  studentMetaSkills,
  studentSpoEducation,
  studentSpoStatus,
  studentPersonalAccess,
  parallelCurricula,
  studentSpoDocuments,
  history,
  historyFormEntity,
  historyFormEditStatus,
  reportErrorStatus,
  entityErrorMessages,
  gratitudeTeacherStatus,
  avatars,
  importHistory,
  studentCheckInHistory,
  importList,
  learnerCategories,
  settingSections,
  sources,
  studentInterests,
  interestHeadKinds,
  interestActionKinds,
  interestGroupKinds,
  interestObjectKinds,
  archivedInterestObjectKinds,
  viewedFunctions,
  viewedFunctionsRequest,
  userThemeSettings,
  boundaryError,
  applicationStatus,
  sectionRef,
  documentRef,
  metaSkillRef,
  professionProgrammRef,
  levelBusinessRef,
  salaryRangeRef,
  employmentDocTypeRef,
  spoOrganizationRef,
  spoGiaMarkRef,
  professionRankRef,
  sportAgesRef,
  olympiadSubjectsRef,
  avatarTypeRef,
  dataEntryDrawer,
  detailViewDrawer,
  linkSharingDrawer,
  reportErrorDrawer,
  thanksTeacherDrawer,
  eisDopRecommendationsDrawer,
  navigationSection,
  ...vuzRecommendationModel.reducers.collection,
  ...proforientationModel.reducers.collection,
  ...searchModel.reducers.collection,
  ...performanceModel.reducers.collection,
  ...performanceOldModel.reducers.collection,
  ...yandexMetrikaModel.reducers.collection,
});
