import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

import { IRecommendedSpeciality } from '../../model/types';

export const styles: SxStyles = (theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '4px',
  width: 'fit-content',

  [theme.breakpoints.down('commonSm')]: {
    width: '100%',
  },
});

interface ISpecialityProps {
  speciality: IRecommendedSpeciality;
}

const Speciality: FC<ISpecialityProps> = ({ speciality }) => {
  return (
    <Box className="speciality" sx={styles}>
      <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600} paddingTop="2px">
        {speciality.code}
      </Typography>
      <Typography variant="Paragraph LG/Regular">{speciality.name}</Typography>
    </Box>
  );
};

export default Speciality;
