import { FC } from 'react';

import { Box } from '@mui/material';
import { professionNavigation } from 'const';
import { useAppSelector } from 'hooks';
import { isDefined } from 'utils';

import GenericApplicationCard from '../../components/GenericApplicationCard';
import GenericEntityList from '../../components/GenericEntityList';
import NoDataPlug from '../../components/NoDataPlug';
import { proforientationApplicationSelectors } from '../../model/selectors';

interface IApplicationExcursionsWidgetProps {
  applicationStatusCode: number | null;
}

const ApplicationExcursionsWidget: FC<IApplicationExcursionsWidgetProps> = ({ applicationStatusCode }) => {
  const excursions = useAppSelector(proforientationApplicationSelectors.excursions);

  const filterdExcursions = excursions.filter((event) => {
    return !isDefined(applicationStatusCode) || event.statusApplication === applicationStatusCode;
  });

  if (filterdExcursions.length === 0) {
    return (
      <Box sx={{ display: 'grid', placeItems: 'center', paddingTop: '16px' }}>
        <NoDataPlug title="Ничего не найдено" />
      </Box>
    );
  }

  return (
    <GenericEntityList
      containerId={`${professionNavigation.applications.id}/excursions`}
      entities={filterdExcursions}
      initialCardCount={3}
      renderEntityCard={(entity) => {
        return (
          <GenericApplicationCard
            key={entity.eventId}
            date={entity.formattedDate}
            industry={entity.industry}
            name={entity.name}
            place={entity.place}
            statusApplicationCode={entity.statusApplication}
          />
        );
      }}
    />
  );
};

export default ApplicationExcursionsWidget;
