import { FC } from 'react';

import { IDictionaryItem } from 'api/types';

import { filterSections } from '../../const/filters';
import { getFilterStoreKey } from '../../utils/filters';
import CommonTokenFilter from './CommonTokenFilter';

interface ISpecificFilterProps {
  section: keyof typeof filterSections;
  options: IDictionaryItem[];
}

export const IndustryFilter: FC<ISpecificFilterProps> = ({ section, options }) => {
  return (
    <CommonTokenFilter
      storeKey={getFilterStoreKey(section, 'industry')}
      options={options}
      label="Отрасль"
      placeholder="Выберите отрасль..."
    />
  );
};

export const SpecialityFilter: FC<ISpecificFilterProps> = ({ section, options }) => {
  return (
    <CommonTokenFilter
      storeKey={getFilterStoreKey(section, 'speciality')}
      options={options}
      label="Специальность"
      placeholder="Выберите специальность..."
    />
  );
};

export const CollegeFilter: FC<ISpecificFilterProps> = ({ section, options }) => {
  return (
    <CommonTokenFilter
      storeKey={getFilterStoreKey(section, 'college')}
      options={options}
      label="Колледж"
      placeholder="Выберите колледж..."
    />
  );
};

export const DistrictFilter: FC<ISpecificFilterProps> = ({ section, options }) => {
  return (
    <CommonTokenFilter
      storeKey={getFilterStoreKey(section, 'district')}
      options={options}
      label="Округ"
      placeholder="Выберите округ..."
    />
  );
};
