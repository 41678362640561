import { IReducers } from 'reducers';
import { connectReducers } from 'utils';

import { proforientationRecommendationFiltersActions, SET_PROFORIENTATION_RECOMMENDATION_FILTER } from '../actions';

export type ProforientationRecommendationFiltersState = Record<string, unknown>;

const initialState: ProforientationRecommendationFiltersState = {};

const proforientationRecommendationFilters: IReducers<ProforientationRecommendationFiltersState> = {
  [SET_PROFORIENTATION_RECOMMENDATION_FILTER]: (
    state,
    action: ReturnType<typeof proforientationRecommendationFiltersActions.setFilter>,
  ) => {
    const { key, value } = action.payload;

    return {
      ...state,
      [key]: value,
    };
  },
};

export default connectReducers(initialState, proforientationRecommendationFilters);
