import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  border: '1px solid',
  borderColor: NeutralColors.night_neutrals_700,
};

export const rootMain: SxStyles = {
  padding: '20px',
};
