import { buildConfig } from 'packages/buildConfig';

import { IPathWithPermisson } from '../types';
import { adminRoleTags, employeeRoleTags, LocalRoleTags, mainPorfolioRoleTags, operatorRoleTags } from './roles';

export const routes: Record<string, IPathWithPermisson> = {
  AUTH: {
    path: '/auth',
    permissions: [],
  },
  CHOICE: {
    path: 'choice',
    permissions: [],
  },
  LOGOUT: {
    path: 'auth/logout',
    permissions: [],
  },
  ROOT: {
    path: '/',
    permissions: [],
  },
  PORTFOLIO: {
    path: '/portfolio',
    permissions: [],
  },
  SERVICE_ERROR: {
    path: '/portfolio/service-error',
    permissions: [],
  },
  PORTFOLIO_STUDENT: {
    path: '/portfolio/student',
    permissions: mainPorfolioRoleTags,
  },
  STUDENT_INTERESTS: {
    path: '/portfolio/interests',
    permissions: [LocalRoleTags.studentOperator],
  },
  PORTFOLIO_STUDENT_SETTING: {
    path: '/portfolio/student/setting',
    permissions: mainPorfolioRoleTags,
  },
  PORTFOLIO_EMPLOYEE: {
    path: '/portfolio/employee/search',
    permissions: [...operatorRoleTags, ...employeeRoleTags],
  },
  PORTFOLIO_ADMIN: {
    path: '/portfolio/admin/search',
    permissions: adminRoleTags,
  },
  EMPLOYEE_HISTORY: {
    path: '/portfolio/employee/employee-history',
    permissions: [...operatorRoleTags, ...employeeRoleTags],
  },
  ADMIN_SETTINGS: {
    path: '/portfolio/admin/settings',
    permissions: [LocalRoleTags.adminOperator],
  },
  ADMIN_ANALYTICS: {
    path: '/portfolio/admin/analytics',
    permissions: adminRoleTags,
  },
  ADMIN_ANALYTICS_LOGS: {
    path: '/portfolio/admin/analytics/logs',
    permissions: adminRoleTags,
  },
  ADMIN_ANALYTICS_REPORTS: {
    path: '/portfolio/admin/analytics/reports',
    permissions: adminRoleTags,
  },
  ADMIN_DELETE_HISTORY: {
    path: '/portfolio/admin/admin-delete-history',
    permissions: [LocalRoleTags.adminOperator],
  },
  SHARED_LINK: {
    path: '/portfolio/shared-link',
    permissions: [],
  },
  MODAL: {
    path: '/portfolio/student/modal',
    permissions: [],
  },
  MODAL_SETTING: {
    path: '/portfolio/student/setting/modal',
    permissions: [],
  },
  MODAL_SHARED: {
    path: '/portfolio/shared-link/:id/modal',
    permissions: [],
  },
  NOT_FOUND: {
    path: '/404',
    permissions: [],
  },
  WEBVIEW: {
    path: '/webview',
    permissions: mainPorfolioRoleTags,
  },
  WEBVIEW_INTERESTS: {
    path: '/webview/interests',
    permissions: [LocalRoleTags.studentOperator],
  },
};

export const AnchorRoutes: Record<string, string> = {
  DELETE: '#modal-delete',
  SETTING: '#modal-setting',
  AVATAR: '#modal-avatar',
  VIEW: '#modal-view',
  COPY: '#modal-copy',
  CLOSE: '#modal-close',
  SUBMIT: '#modal-submit',
  SAMPLE: '#modal-sample',
  IMPORT: '#modal-import',
  FORM: '#form',
  MODAL: '#modal',
  CARD_MAIN: 'view',
  CARD_LINKED: 'linked',
};

export const sectionRoutes = {
  PROFILE: '/profile',
  STUDY: '/study',
  SCIENCE: '/science',
  SPORT: '/sport',
  CREATION: '/creation',
  CULTURE: '/culture',
  CIVIL: '/civil',
  PROFESSION: '/profession',
  RECOMMENDATION: '/recommendation',
  REWARD: '/reward',
} as const;

export const getAuthRoute = () => {
  const link = buildConfig.config?.AUTH_PAGE_URL;
  if (link && link[link.length - 1] !== '/') {
    return link.concat('/');
  }
  return link;
};
