import { buildConfig } from 'packages/buildConfig';

import BaseApi from './BaseApi';
import { ApiHeaders, ApiRequestMethod } from './types';
import { prepareHeaders, processBaseResponse } from './utils';

export class MosVuzApi extends BaseApi {
  constructor(prefix: string) {
    // осуществляется проксирование на https://www.mos.ru/aisearch/vuz_frontapi/v1/
    super({ url: '', prefix: `${prefix}/vuz-api` });

    if (buildConfig.config) {
      this.url = buildConfig.config.API_URL || '';
    } else {
      // отписка не требуется
      buildConfig.onLoad((config) => {
        this.url = config.API_URL || '';
      });
    }
  }

  protected request: ApiRequestMethod = (method: string, { path, requestBody, requestHeaders }) => {
    const baseHeaders: ApiHeaders = requestHeaders ?? { 'Content-Type': 'application/json' };
    const headers = prepareHeaders(baseHeaders);

    return fetch(`${this.url}${this.prefix}${path}`, {
      method,
      body: requestHeaders ? requestBody : JSON.stringify(requestBody),
      headers,
    })
      .then(async (response) => {
        return await processBaseResponse(response);
      })
      .then(
        (response) => ({ response }),
        (error) => ({ error }),
      );
  };
}
