import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

import { IRecommendedIndustryWithSpecialities } from '../../model/types';
import Speciality from './Speciality';
import SpecialityList from './SpecialityList';

interface IIndustrySpecialityListProps {
  industries: IRecommendedIndustryWithSpecialities[];
}

const IndustrySpecialityList: FC<IIndustrySpecialityListProps> = ({ industries }) => {
  const isSingleIndustry = industries.length === 1;

  if (isSingleIndustry) {
    const specialities = industries[0]?.specialities ?? [];
    return <SpecialityList specialities={specialities} />;
  }

  return (
    <Stack className="industry-speciality-list" gap="16px">
      {industries.map((industryObject, index) => {
        const { industry, specialities } = industryObject;

        const industrySpecialities = specialities.map((speciality) => {
          const { code, name } = speciality;
          return <Speciality key={`${name}-${code}`} speciality={speciality} />;
        });

        return (
          <Box key={`${industry}-${index}`} className="industry">
            <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
              {industry}
            </Typography>
            <Stack gap="8px" marginTop="8px">
              {industrySpecialities}
            </Stack>
          </Box>
        );
      })}
    </Stack>
  );
};

export default IndustrySpecialityList;
