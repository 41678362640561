/* eslint-disable max-len */
import { StatusApplicationCode } from 'const';
import { accentColors } from 'portfolio3/styles';

export const getStatusApplicationColor = (statusApplicationCode: number) => {
  switch (statusApplicationCode) {
    case StatusApplicationCode.approved:
    case StatusApplicationCode.registered:
      return accentColors.green[100];
    case StatusApplicationCode.noPlacesAvailable:
      return accentColors.red[100];
    case StatusApplicationCode.pending:
      return accentColors.orange[100];
  }

  return null;
};

export const getStatusApplicationTooltip = (statusApplicationCode: number) => {
  switch (statusApplicationCode) {
    case StatusApplicationCode.approved:
      return 'Мероприятие появилось в твоем календаре';
    case StatusApplicationCode.noPlacesAvailable:
      return 'Обратись к классному руководителю, он предложит тебе другие даты';
    case StatusApplicationCode.pending:
      return 'Когда школа зарегистрирует тебя на мероприятие, оно появится в расписании. Обычно это занимает 10-14 дней.';
  }

  return null;
};
