import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { setApplicationStatusAction } from 'actions';
import { IApplicationStatus } from 'types';

const useLoadApplicationStatusConfig = () => {
  const dispatch = useDispatch();
  const hasLoaded = useRef(false);

  useEffect(() => {
    const loadApplicationConfig = async () => {
      const response = await fetch('/portfolio/status.json');
      const data: IApplicationStatus = await response.json();
      hasLoaded.current = true;

      dispatch(setApplicationStatusAction(data));
    };

    if (!hasLoaded.current) {
      loadApplicationConfig();
    }
  }, [dispatch]);
};

export default useLoadApplicationStatusConfig;
