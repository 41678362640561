import { createContext } from 'react';

interface IScrollStatusContext {
  isContainerScrolled: boolean;
}

const initialState: IScrollStatusContext = {
  isContainerScrolled: false,
};

export default createContext(initialState);
