import { FC, ReactNode, Ref } from 'react';

import { usePrevious } from '@mantine/hooks';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useDisclosure, useOverflowDetector } from 'hooks';
import TrimTextMultiLine from 'portfolio3/components/common/TrimTextMultiLine';
import { indigoColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { Attributes, UnstyledButton } from 'portfolio3/ui-kit';
import { isDefined, mergeSx } from 'utils';

import * as styles from './styles';

interface IGenericRecommendationCardProps {
  date: string;
  industry: string;
  name: string;
  description: string;
  place: string;
  district?: string;
  illustration: string;

  buttonElement?: ReactNode;
  bottomAddon?: ReactNode;
}

const GenericRecommendationCard: FC<IGenericRecommendationCardProps> = ({
  date,
  industry,
  name,
  description,
  place,
  district,
  illustration,
  buttonElement,
  bottomAddon,
}) => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const { ref: descriptionRef, overflow: descriptionOverflow } = useOverflowDetector();
  const prevDescriptionOverflow = usePrevious(descriptionOverflow);
  const { isOpen: isFullDescription, onToggle: onToggleDescription } = useDisclosure();

  const attributes = [date, industry].filter(Boolean).map((value) => {
    return (
      <Typography variant="Paragraph MD/Regular" key={value}>
        {value}
      </Typography>
    );
  });

  const hasPreviousOverflowValue = isDefined(prevDescriptionOverflow) && Boolean(prevDescriptionOverflow);
  const isToggleDescriptionVisible = descriptionOverflow || hasPreviousOverflowValue;

  const mainContentElement = (
    <Stack gap="8px">
      <Attributes attributes={attributes} />
      <Typography variant="Paragraph LG/Semi Bold">{name}</Typography>
      <Typography
        variant="Paragraph LG/Regular"
        component={TrimTextMultiLine}
        line={isFullDescription ? 999 : 1}
        ref={descriptionRef as Ref<HTMLDivElement>}
      >
        {description}
      </Typography>
      {isToggleDescriptionVisible && (
        <Typography
          variant="Paragraph MD/Medium"
          component={UnstyledButton}
          color={indigoColors[100]}
          width="max-content"
          marginTop="4px"
          onClick={onToggleDescription}
        >
          {descriptionOverflow ? 'Подробнее' : 'Свернуть'}
        </Typography>
      )}
    </Stack>
  );

  const locationElement = (
    <Stack gap="4px">
      <Typography variant="Paragraph MD/Regular">{place}</Typography>
      {district && <Typography variant="Paragraph MD/Regular">{district}</Typography>}
    </Stack>
  );

  const leftElement = (
    <Stack gap="12px">
      {mainContentElement}
      {locationElement}
    </Stack>
  );

  const rightElement = (
    <Stack gap="8px" justifyContent="space-between" flexShrink={0}>
      {!isSmallScreen && <Box sx={styles.image(illustration)} marginLeft="auto"></Box>}
      {buttonElement}
    </Stack>
  );

  return (
    <Box className="generic-recommendation-card" sx={styles.root}>
      <Box
        className="generic-recommendation-card__main"
        sx={mergeSx(styles.rootMain, { flexDirection: isSmallScreen ? 'column' : 'row' })}
      >
        {leftElement}
        {rightElement}
      </Box>
      {bottomAddon}
    </Box>
  );
};

export default GenericRecommendationCard;
