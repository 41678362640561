import { useLayoutEffect, useMemo, useState } from 'react';

type Widget = {
  id: number;
  isVisible: boolean;
};

export function useActiveWidget<T extends Widget>(widgets: T[]) {
  const activeWidgets = useMemo(() => widgets.filter((widget) => widget.isVisible), [widgets]);

  const [activeWidgetId, setActiveWidgetId] = useState<number | null>(() => {
    return activeWidgets[0]?.id ?? null;
  });

  // При изменении массива виджетов сбрасывать активный
  const firstWidgetId = activeWidgets[0]?.id;
  useLayoutEffect(() => {
    setActiveWidgetId(firstWidgetId ?? null);
  }, [firstWidgetId]);

  return { activeWidgetId, setActiveWidgetId, activeWidgets };
}
