import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { DotGroup, Slide, Slider } from 'pure-react-carousel';
import { AdminSettingsState } from 'reducers/admin/adminSettings';
import { openUrlInNewTab } from 'utils';

import { IUserContext } from '../../../../api/types';
import { useQueryParams, useViewedFunctions } from '../../../../hooks';
import { IRootState } from '../../../../reducers';
import useArchiveSearchPreview from '../ArchiveSearch/useArchiveSearchPreview';
// import {
//   useIndependentDiagnosticsWidgetPreview,
//   useIndependentDiagnosticsDataUpdatePreview
// } from '../IndependentDiagnostics';
import { useSnowThemePreview } from '../SnowTheme';
import { useThanksTeacherPreview } from '../ThanksTeacher';
import { IUseFeaturePreview } from '../types';
import FeaturePreviewCarousel from './carousel';
import FeaturePreviewDesktopContainer from './desktop';
import FeaturePreviewMobileContainer from './mobile';

import './index.scss';

interface IFeaturePreviewContainerProps {
  currentUser: IUserContext;
  adminVisibilitySettings: AdminSettingsState;
}

const FeaturePreviewContainer: React.FC<IFeaturePreviewContainerProps> = ({ currentUser, adminVisibilitySettings }) => {
  const isMobile = useMediaQuery('(max-width: 600px)', { noSsr: true });
  const [currentSlide, setCurrentSlide] = useState(0);
  const history = useHistory();
  const query = useQueryParams();

  const { availableUnviewedFunctions, isFunctionViewMode, setFunctionViewed, setFunctionViewMode } =
    useViewedFunctions();
  const hasSentYMEvent = useRef(false);

  const functionQuery = query.get('function');
  const isViewingFeature = functionQuery !== null;
  const Container = isMobile ? FeaturePreviewMobileContainer : FeaturePreviewDesktopContainer;

  // const independentDiagnosticsWidgetFeature = useIndependentDiagnosticsWidgetPreview({
  //   currentUser,
  //   adminSettings: adminVisibilitySettings
  // });
  // const independentDiagnosticsDataUpdateFeature = useIndependentDiagnosticsDataUpdatePreview({
  //   currentUser,
  //   adminSettings: adminVisibilitySettings
  // });
  const snowThemeFeature = useSnowThemePreview();

  const archiveSearchFeature = useArchiveSearchPreview();

  const thanksTeacherFeature = useThanksTeacherPreview({ adminSettings: adminVisibilitySettings });

  const features: IUseFeaturePreview[] = [thanksTeacherFeature, snowThemeFeature, archiveSearchFeature];

  const isAllFunctionsLoaded = features.every((func) => func.isLoaded);
  const unviewedFeatures = isAllFunctionsLoaded
    ? features.filter((feature) => {
        const userFunction = availableUnviewedFunctions?.find((userFunc) => userFunc.code === feature.functionCode);

        // если не нашли функцию, не отображает онбординг
        if (!userFunction) return false;

        return !userFunction.isViewed && feature.isLoaded && feature.hasAccess;
      })
    : [];

  const totalSlides = unviewedFeatures.length;

  const isHideComponent =
    !isAllFunctionsLoaded || unviewedFeatures.length === 0 || isViewingFeature || isFunctionViewMode;

  useEffect(() => {
    if (isViewingFeature) {
      setFunctionViewMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewingFeature]);

  useEffect(() => {
    if (!isHideComponent && !hasSentYMEvent.current) {
      emitYMEvent({ type: 'popUpBanner' });
      hasSentYMEvent.current = true;
    }
  }, [isHideComponent]);

  const handleCloseCurrentFeature = () => {
    const personId = currentUser.data?.meshId;

    if (personId) {
      const currentFunction = unviewedFeatures[currentSlide];
      setFunctionIsViewed(currentFunction.functionCode);
    }
  };

  // просмотри через редирект
  const handleViewFeatureWithRedirect = () => {
    const personId = currentUser.data?.meshId;

    if (!personId) return;

    const currentFunction = unviewedFeatures[currentSlide];
    const functionCode = currentFunction.functionCode;

    const searchQuery = `?function=${functionCode}`;

    if (unviewedFeatures.length === 1) {
      history.replace({
        pathname: history.location.pathname,
        search: searchQuery,
      });
    } else {
      openUrlInNewTab(history.location.pathname + searchQuery);
    }

    setFunctionIsViewed(functionCode);
  };

  // обычный просмотр
  const handleViewFeature = () => {
    const personId = currentUser.data?.meshId;

    if (!personId) return;

    const currentFunction = unviewedFeatures[currentSlide];
    const functionCode = currentFunction.functionCode;
    setFunctionIsViewed(functionCode);
  };

  const setFunctionIsViewed = (functionCode: number) => {
    setFunctionViewed(functionCode);

    if (currentSlide !== 0) {
      setCurrentSlide((prevState) => prevState - 1);
    }
  };

  const emitYMEventOnSlideSwitch = () => {
    emitYMEvent({ type: 'popUpBannerSwitch' });
  };

  const handleBack = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevState) => prevState - 1);
      emitYMEventOnSlideSwitch();
    }
  };

  const handleNext = () => {
    if (currentSlide < totalSlides) {
      setCurrentSlide((prevState) => prevState + 1);
      emitYMEventOnSlideSwitch();
    }
  };

  if (isHideComponent) {
    return null;
  }

  return (
    <Container isOpen={true} onClose={handleCloseCurrentFeature}>
      <div className="feature-preview-carousel">
        {/*
          TODO сделать лучше
          в текущей реализации есть проблема: currentSlide изменяется только при нажатии кнопок,
          при драге он изменяться не будет, что вызовет следующие последствия:
          1) мы не можем узнать что изменился currentSlide при перелистывании через drag (например а адаптиве)
          2) при закрытии модалки, если показывается более 1го слайда, для просмотра функции используется currentSlide,
              и так как он не меняется, может быть отправлен запрос о просмотре не той функции
        */}
        <FeaturePreviewCarousel
          totalSlides={totalSlides}
          currentSlide={currentSlide}
          onBack={handleBack}
          onNext={handleNext}
        >
          <Slider>
            {unviewedFeatures.map((func, index) => {
              const Component = func.component;

              return (
                <Slide key={func.functionCode} index={index}>
                  <Component onView={handleViewFeature} onViewWithRedirect={handleViewFeatureWithRedirect} />
                </Slide>
              );
            })}
          </Slider>
          {totalSlides > 1 && <DotGroup />}
        </FeaturePreviewCarousel>
      </div>
    </Container>
  );
};

export default connect((state: IRootState) => ({
  currentUser: state.currentUser,
  adminVisibilitySettings: state.adminSettings,
}))(FeaturePreviewContainer);
