/* eslint-disable prettier/prettier */
/* eslint-disable */

import { call, put } from 'redux-saga/effects';

import { toast } from 'portfolio3/ui-kit';

import { tooltipOptions, defaultVisibilitySettings } from '../const';
import { isDefined, syncLocalVisibilitySettings } from '../utils';

import {
  deleteStudentShareLink,
  getStudentShareLinkList,
  getStudentGiaList,
  createStudentShareLink,
  updateStudentShareLinkActivity,
  getStudentInfo,
  getStudentDiagnosis,
  saveVisibilitySettings,
  getUserSettings,
  saveStudentAvatar,
  getUserContext,
  getStudentEvents,
  getStudentRewards,
  getStudentProjects,
  getStudentEmployments,
  getStudentSportRewards,
  getStudentAffilations,
  getStudentCheckInHistory,
  deleteStudentCheckIn,
  searchStudentEvents,
  searchStudentProjects,
  searchStudentEmployments,
  searchStudentAffilations,
  searchStudentDiagnotics,
  getStudentCultureVisitings,
  getStudentIndependentDiagnosis,
  saveIndependentDiagnosticsSettings,
  getStudentRecommendations,
  getStudentGiaWorldSkills,
  getStudentProfessionalAdditionalEducation,
  getStudentClassRecommendations,
  postErrorMessage,
  putErrorMessage,
  getErrorMessage,
  gratitudeTeacher,
  getStudentAvatar,
  saveThemeSettings,
  getStudentMetaSkills,
  getStudentSpoDocuments,
  getStudentJobs,
  getStudentSpoEducation,
  getStudentSpoStatus,
  postStudentMetaSkills,
  postStudentSpoStatus,
  getStudentPersonalAccess,
  editStudentPersonalAccess,
  getEisDopRecommendations,
  saveAvatarSettings,
} from '../api';
import {
  TGetStudentGiaListRequest,
  ICreateLinkRequest,
  IUpdateLinkActivityRequest,
  TDeleteSharedLinkRequest,
  TGetShareLinkListRequest,
  ISaveStudentAvatarRequest,
  ILocalVisibilitySettingsList,
  TStudentCheckInRequest,
  ISaveDiagnosisCardSettingsRequest,
  IEntityErrorMessage,
  IGetStudentAvatarResponse,
} from '../api/types/students';
import {
  IResponsePayload,
  ISaveThemeSettingsResponse,
  IStudentVisibilitySetting,
  IThemeSettings,
  IUserSettingsResponse,
  SpoStatusRefItem
} from '../api/types';

import {
  getStudentGiaListActions,
  getStudentShareLinkListActions,
  createStudentShareLinkActions,
  updateStudentShareLinkActivityActions,
  deleteStudentShareLinkActions,
  getStudentInfoActions,
  setCurrentStudentAction,
  getStudentDiagnosisActions,
  getPdfStudentDiagnosisFullActions,
  getPdfStudentDiagnosisYearActions,
  saveVisibilitySettingsActions,
  getUserSettingsActions,
  setLocalVisibilitySettingsAction,
  saveStudentAvatarActions,
  getStudentEventsActions,
  getStudentRewardsActions,
  getStudentProjectsActions,
  getStudentEmploymentsActions,
  getStudentSportRewardsActions,
  getStudentAffilationsActions,
  getStudentCheckInHistoryActions,
  deleteStudentCheckInActions,
  getStudentCultureVisitingsActions,
  getStudentIndependentDiagnosisActions,
  saveIndependentDiagnosisSettingsActions,
  getStudentRecommendationsActions,
  getStudentGiaWorldSkillsActions,
  getStudentProfessionalAdditionalEducationActions,
  getStudentClassRecommendationsActions,
  reportErrorActions,
  getEntityErrorMessagesActions,
  gratitudeTeacherActions,
  getStudentAvatarActions,
  setUserThemeSettingsAction,
  setUserThemeCodeAction,
  saveUserThemeSettingsActions,
  getStudentMetaSkillsActions,
  getStudentSpoDocumentsActions,
  getStudentJobsActions,
  getStudentSpoEducationActions,
  getStudentSpoStatusActions,
  postStudentMetaSkillsActions,
  postStudentSpoStatusActions,
  setStudentSpoStatusAction,
  reportErrorDrawerActions,
  thanksTeacherDrawerActions,
  getStudentPersonalAccessActions,
  updateStudentPersonalAccessActions,
  getStudentEisDopRecommendationsActions,
  saveAvatarSettingsActions,
  setAvatarTypeSettingAction
} from '../actions';

import { GenericCreateUpdateAction, GenericDeleteAction, GenericGetAction, GenericPostAction } from '.';
import { ApiResult } from 'api/impl';

function* getStudentGiaListSaga({ payload: { id } }: GenericGetAction<TGetStudentGiaListRequest>) {
  const { response, error } = yield call(getStudentGiaList, id);

  if (response) {
    yield put(getStudentGiaListActions.success(response));
  } else {
    yield put(getStudentGiaListActions.failure(error));
  }
}

function* getStudentShareLinkListSaga({ payload: { personId } }: Required<GenericGetAction<TGetShareLinkListRequest>>) {
  const { response, error } = yield call(getStudentShareLinkList, personId);

  if (response) {
    yield put(getStudentShareLinkListActions.success(response));
  } else {
    yield put(getStudentShareLinkListActions.failure(error));
  }
}

function* createStudentShareLinkSaga({ payload }: ReturnType<typeof createStudentShareLinkActions.request>) {
  const { personId, request, meta } = payload;
  const { response, error } = yield call(createStudentShareLink, personId || '', request, meta);

  if (response) {
    yield put(createStudentShareLinkActions.success(response));
    yield put(getStudentShareLinkListActions.request(personId || ''));
  } else {
    yield put(createStudentShareLinkActions.failure(error));
  }
}

function* updateStudentShareLinkActivitySaga({ payload: { personId, request } }: GenericCreateUpdateAction<IUpdateLinkActivityRequest>) {
  const { response, error } = yield call(updateStudentShareLinkActivity, personId || '', request);

  if (response) {
    yield put(updateStudentShareLinkActivityActions.success(response));
    yield put(getStudentShareLinkListActions.request(personId || ''));
  } else {
    yield put(updateStudentShareLinkActivityActions.failure(error));
  }
}

function* deleteStudentShareLinkSaga({ payload: { id, personId } }: Required<GenericDeleteAction<TDeleteSharedLinkRequest>>) {
  const { response, error } = yield call(deleteStudentShareLink, id, personId);

  if (response) {
    yield put(deleteStudentShareLinkActions.success(response));
    yield put(getStudentShareLinkListActions.request(personId));

    toast.success('Ссылка удалена', tooltipOptions);
  } else {
    yield put(deleteStudentShareLinkActions.failure(error));
  }
}

function* getStudentInfoSaga({ payload: { personId } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentInfo, personId);
  if (response) {
    yield put(setCurrentStudentAction(response.data));
    yield put(getStudentInfoActions.success(response));
  } else {
    yield put(getStudentInfoActions.failure(error));
  }
}

function* getStudentDiagnosisSaga({ payload: { personId, period, subject } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentDiagnosis, personId, period, subject);
  if (response) {
    yield put(getStudentDiagnosisActions.success(response));
  } else {
    yield put(getStudentDiagnosisActions.failure(error));
  }
}

function* getStudentIndependentDiagnosisSaga({ payload: { personId, period, count, subject } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentIndependentDiagnosis, personId, period, count, subject);
  if (response) {
    yield put(getStudentIndependentDiagnosisActions.success(response));
  } else {
    yield put(getStudentIndependentDiagnosisActions.failure(error));
  }
}

function* saveIndependentDiagnosisSettingsSaga({ payload: { request } }: GenericCreateUpdateAction<ISaveDiagnosisCardSettingsRequest>) {
  const { response, error } = yield call(saveIndependentDiagnosticsSettings, request);

  if (response) {
    yield put(saveIndependentDiagnosisSettingsActions.success(response));
  } else {
    yield put(saveIndependentDiagnosisSettingsActions.failure(error));
  }
}

function* getPdfStudentDiagnosisFullSaga({ payload: { personId, period } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentDiagnosis, personId, period);

  if (response) {
    yield put(getPdfStudentDiagnosisFullActions.success(response));
  } else {
    yield put(getPdfStudentDiagnosisFullActions.failure(error));
  }
}

function* getPdfStudentDiagnosisYearSaga({ payload: { personId, period } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentDiagnosis, personId, period);

  if (response) {
    yield put(getPdfStudentDiagnosisYearActions.success(response));
  } else {
    yield put(getPdfStudentDiagnosisYearActions.failure(error));
  }
}

function* getUserSettingsSaga({ payload: { personId } }: GenericGetAction<any>) {
  const { response, error }: ApiResult<IResponsePayload<IUserSettingsResponse>, any> = yield call(getUserSettings, personId);

  if (response) {
    const sectionsSetting: IStudentVisibilitySetting[] = response.data.setting.visibilitySettings;
    const themeSettings: IThemeSettings = response.data.themeSettings;
    const themeSettingCode: number = response.data.themeSetting;
    const sections = syncLocalVisibilitySettings(sectionsSetting, defaultVisibilitySettings);

    const localSettings: ILocalVisibilitySettingsList = {
      sections: sections.filter((section) => section.sectionTypeCode)
    };

    response.data.setting.visibilitySettings = localSettings.sections;

    yield put(getUserSettingsActions.success(response));
    yield put(setLocalVisibilitySettingsAction(localSettings));
    yield put(setUserThemeSettingsAction(themeSettings));
    yield put(setUserThemeCodeAction(themeSettingCode));
  } else {
    yield put(getUserSettingsActions.failure(error));
  }
}

function* saveVisibilitySettingsSaga({ payload: { request, personId } }: Required<GenericGetAction<any>>) {
  const { response, error } = yield call(saveVisibilitySettings, request, personId);

  if (response) {
    yield put(saveVisibilitySettingsActions.success(response));
    yield put(getUserSettingsActions.request(personId));
  } else {
    yield put(saveVisibilitySettingsActions.failure(error));
  }
}

function* saveUserThemeSettingsSaga({ payload: { request, personId } }: Required<GenericGetAction<any>>) {
  const { response, error }: ApiResult<IResponsePayload<ISaveThemeSettingsResponse>, any> = yield call(saveThemeSettings, personId, request);

  if (response) {
    yield put(saveUserThemeSettingsActions.success(response.data.themeSettings));
    yield put(setUserThemeCodeAction(response.data.themeSetting));
  } else {
    yield put(saveUserThemeSettingsActions.failure(error));
  }
}

function* saveAvatarSettingsSaga({ payload }: ReturnType<typeof saveAvatarSettingsActions.request>) {
  const { personId, avatarTypeCode } = payload;
  yield put(setAvatarTypeSettingAction(personId, avatarTypeCode));

  const { response, error } = yield call(saveAvatarSettings, personId, { avatarType: avatarTypeCode });

  if (response) {
    yield put(saveAvatarSettingsActions.success(response));
  } else {
    yield put(saveAvatarSettingsActions.failure(error));
  }
}

function* getStudentAvatarSaga({ payload }: ReturnType<typeof getStudentAvatarActions.request>) {
  const personId = payload;
  const { response, error }: ApiResult<IGetStudentAvatarResponse, any> = yield call(getStudentAvatar, personId);

  if (response) {
    yield put(getStudentAvatarActions.success(response));
  } else if (error) {
    yield put(getStudentAvatarActions.failure(error));
  }
}

function* saveStudentAvatarSaga({ payload: { request } }: GenericCreateUpdateAction<ISaveStudentAvatarRequest>) {
  const { response, error } = yield call(saveStudentAvatar, request);

  if (response) {
    yield put(saveStudentAvatarActions.success(response));
    const { response: contextResponse } = yield call(getUserContext);
    toast.success('Аватар успешно сохранен', tooltipOptions);
    if (contextResponse) {
      yield put(setCurrentStudentAction(contextResponse.data.personMeshInfo));
    }
  } else {
    yield put(saveStudentAvatarActions.failure(error));
  }
}

function* getStudentEventsSaga({ payload: { personId, queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentEvents, personId, queryParams);

  if (response) {
    yield put(getStudentEventsActions.success(response));
  } else {
    yield put(getStudentEventsActions.failure(error));
  }
}

function* getStudentPersonalAccessSaga({ payload: { personId } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentPersonalAccess, personId);

  if (response) {
    yield put(getStudentPersonalAccessActions.success(response));
  } else {
    yield put(getStudentPersonalAccessActions.failure(error));
  }
}

function* updateStudentPersonalAccessSaga({ payload: { personId, isActive } }: GenericGetAction<any>) {
  if (!isDefined(isActive)) {
    yield put(updateStudentPersonalAccessActions.failure(null));
    return;
  }

  const { response, error } = yield call(editStudentPersonalAccess, personId, isActive);

  if (response) {
    yield put(updateStudentPersonalAccessActions.success(response));;
  } else {
    yield put(updateStudentPersonalAccessActions.failure(error));
  }
}

function* getStudentRewardsSaga({ payload: { personId, queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentRewards, personId, queryParams);

  if (response) {
    yield put(getStudentRewardsActions.success(response));
  } else {
    yield put(getStudentRewardsActions.failure(error));
  }
}

function* getStudentProjectsSaga({ payload: { personId, queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentProjects, personId, queryParams);

  if (response) {
    yield put(getStudentProjectsActions.success(response));
  } else {
    yield put(getStudentProjectsActions.failure(error));
  }
}

function* getStudentEmploymentsSaga({ payload: { personId, queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentEmployments, personId, queryParams);

  if (response) {
    yield put(getStudentEmploymentsActions.success(response));
  } else {
    yield put(getStudentEmploymentsActions.failure(error));
  }
}

function* getStudentSportRewardsSaga({ payload: { personId, queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentSportRewards, personId, queryParams);

  if (response) {
    yield put(getStudentSportRewardsActions.success(response));
  } else {
    yield put(getStudentSportRewardsActions.failure(error));
  }
}

function* getStudentAffilationsSaga({ payload: { personId, queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentAffilations, personId, queryParams);

  if (response) {
    yield put(getStudentAffilationsActions.success(response));
  } else {
    yield put(getStudentAffilationsActions.failure(error));
  }
}

function* getStudentGiaWorldSkillsSaga({ payload: { personId, queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentGiaWorldSkills, personId, queryParams);

  if (response) {
    yield put(getStudentGiaWorldSkillsActions.success(response));
  } else {
    yield put(getStudentGiaWorldSkillsActions.failure(error));
  }
}

function* getStudentProfessionalAdditionalEducationSaga({ payload: { personId } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentProfessionalAdditionalEducation, personId);

  if (response) {
    yield put(getStudentProfessionalAdditionalEducationActions.success(response));
  } else {
    yield put(getStudentProfessionalAdditionalEducationActions.failure(error));
  }
}

function* getStudentMetaSkillsSaga({ payload }: GenericGetAction<Parameters<typeof getStudentMetaSkillsActions.request>>) {
  if (!payload) {
    yield put(getStudentMetaSkillsActions.failure(null));
    return;
  }

  const [personId] = payload;
  const { response, error } = yield call(getStudentMetaSkills, personId);

  if (response) {
    yield put(getStudentMetaSkillsActions.success(response));
  } else {
    yield put(getStudentMetaSkillsActions.failure(error));
  }
}

function* getStudentSpoStatusSaga({ payload }: GenericGetAction<Parameters<typeof getStudentSpoStatusActions.request>>) {
  if (!payload) {
    yield put(getStudentSpoStatusActions.failure(null))
    return;
  }

  const [personId] = payload;
  const { response, error }: ApiResult<IResponsePayload<SpoStatusRefItem | null>, any> = yield call(getStudentSpoStatus, personId);

  if (response) {
    yield put(getStudentSpoStatusActions.success(response));
    yield put(setStudentSpoStatusAction(response?.data?.code ?? null));
  } else {
    yield put(getStudentSpoStatusActions.failure(error));
  }
}

function* postStudentSpoStatusSaga({ payload }: GenericPostAction<Parameters<typeof postStudentSpoStatusActions.request>>) {
  if (!payload) {
    yield put(postStudentSpoStatusActions.failure(null));
    return;
  }

  const [personId, code] = payload;
  const { response, error } = yield call(postStudentSpoStatus, personId, code);

  if (response) {
    yield put(postStudentSpoStatusActions.success(response));
    yield put(setStudentSpoStatusAction(code));
  } else {
    yield put(postStudentSpoStatusActions.failure(error));
  }
}

function* postStudentMetaSkillsSaga({ payload }: GenericGetAction<Parameters<typeof postStudentMetaSkillsActions.request>>) {
  if (!payload) {
    yield put(postStudentMetaSkillsActions.failure(null));
    return;
  }

  const [personId, request] = payload;
  const { response, error } = yield call(postStudentMetaSkills, personId, request );

  if (response) {
    yield put(postStudentMetaSkillsActions.success(response));
    yield put(getStudentMetaSkillsActions.request(personId));
    toast.success('Обновлены данные о метанавыках', tooltipOptions);
  } else {
    yield put(postStudentMetaSkillsActions.failure(error));
  }
}

function* getStudentRecommendationsSaga({ payload: {personId, request} }: Required<GenericGetAction<any>>) {
  const { response, error } = yield call(getStudentRecommendations, personId, request);

  if (response) {
    yield put(getStudentRecommendationsActions.success(response));
  } else {
    yield put(getStudentRecommendationsActions.failure(error));
  }
}

function* getStudentClassRecommendationsSaga({ payload: {personId, request} }: Required<GenericGetAction<any>>) {
  const { response, error } = yield call(getStudentClassRecommendations, personId, request);

  if (response) {
    yield put(getStudentClassRecommendationsActions.success(response));
  } else {
    yield put(getStudentClassRecommendationsActions.failure(error));
  }
}

function* getStudentEisDopRecommendationsSaga({ payload: { personId, queryParams } }: Required<GenericGetAction<any>>) {
  const { response, error } = yield call(getEisDopRecommendations, personId, queryParams);

  if (response) {
    yield put(getStudentEisDopRecommendationsActions.success(response));
  } else {
    yield put(getStudentEisDopRecommendationsActions.failure(error));
  }
}

function* getStudentCheckInHistorySaga({ payload: { personId } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentCheckInHistory, personId);

  if (response) {
    yield put(getStudentCheckInHistoryActions.success(response));
  } else {
    yield put(getStudentCheckInHistoryActions.failure(error));
  }
}

function* deleteStudentCheckInSaga({ payload: { personId, id } }: Required<GenericDeleteAction<TStudentCheckInRequest>>) {
  const { response, error } = yield call(deleteStudentCheckIn, personId, id);

  if (response) {
    yield put(deleteStudentCheckInActions.success(response));
    yield put(getStudentCheckInHistoryActions.request(personId));

    toast.success('Чек-ин удалён', tooltipOptions);
  } else {
    yield put(deleteStudentCheckInActions.failure(error));
  }
}

function* getStudentCultureVisitingsSaga({ payload: { personId, queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentCultureVisitings, personId, queryParams);

  if (response) {
    yield put(getStudentCultureVisitingsActions.success(response));
  } else {
    yield put(getStudentCultureVisitingsActions.failure(error));
  }
}

function* reportErrorSaga({ payload }: GenericPostAction<Parameters<typeof reportErrorActions.request>>) {
  if (!payload) return;

  const [personId, request, method, meta] = payload;

  let result: ApiResult<IResponsePayload<null>, any> = {};
  if (method === 'post') {
    result = yield call(postErrorMessage, personId, request, meta);
  } else if ('errorId' in request) {
    result = yield call(putErrorMessage, personId, request, meta);
  }

  const { response, error } = result;

  if (response) {
    yield put(reportErrorActions.success(response));
  } else {
    yield put(reportErrorActions.failure(error));
  }

  yield put(reportErrorDrawerActions.setSubmitModalOpen(true));
}

function* getEntityErrorMessagesSaga({ payload }: GenericGetAction<Parameters<typeof getEntityErrorMessagesActions.request>>) {
  if (!payload) {
    yield put(getEntityErrorMessagesActions.failure());
    return;
  }

  const [personId, entityType, entityId, recordId] = payload;
  const { response }: ApiResult<IResponsePayload<IEntityErrorMessage[]>, any> = yield call(getErrorMessage, personId, entityType, entityId, recordId);

  if (response) {
    yield put(getEntityErrorMessagesActions.success(response.data));
  } else {
    yield put(getEntityErrorMessagesActions.failure());
  }
}

function* gratitudeTeacherSaga({ payload }: GenericPostAction<Parameters<typeof gratitudeTeacherActions.request>>) {
  if (!payload) {
    yield put(gratitudeTeacherActions.failure());
    return;
  }

  const [personId, request, meta] = payload;
  const { response, error }: ApiResult<IResponsePayload<null>, any> = yield call(gratitudeTeacher, personId, request, meta);

  if (response) {
    yield put(gratitudeTeacherActions.success(response));
    toast.success('Ваша благодарность успешно отправлена', tooltipOptions);
  } else {
    yield put(gratitudeTeacherActions.failure(error));
  }

  yield put(thanksTeacherDrawerActions.setSubmitModalOpen(true));
}

function* getStudentJobsSaga({ payload }: GenericGetAction<Parameters<typeof getStudentJobsActions.request>>) {
  if (!payload) {
    yield put(getStudentJobsActions.failure(null));
    return;
  }

  const [personId] = payload;
  const { response, error } = yield call(getStudentJobs, personId);

  if (response) {
    yield put(getStudentJobsActions.success(response));
  } else {
    yield put(getStudentJobsActions.failure(error));
  }
}

function* getStudentSpoEducationSaga({ payload }: GenericGetAction<Parameters<typeof getStudentSpoEducationActions.request>>) {
  if (!payload) {
    yield put(getStudentSpoEducationActions.failure(null));
    return;
  }

  const [personId] = payload;
  const { response, error } = yield call(getStudentSpoEducation, personId);

  if (response) {
    yield put(getStudentSpoEducationActions.success(response));
  } else {
    yield put(getStudentSpoEducationActions.failure(error));
  }
}

function* getStudentSpoDocumentsSaga({ payload }: GenericGetAction<Parameters<typeof getStudentSpoDocumentsActions.request>>) {
  if (!payload) {
    yield put(getStudentSpoDocumentsActions.failure(null));
    return;
  }

  const [personId] = payload;
  const { response, error } = yield call(getStudentSpoDocuments, personId);

  if (response) {
    yield put(getStudentSpoDocumentsActions.success(response));
  } else {
    yield put(getStudentSpoDocumentsActions.failure(error));
  }
}

export {
  createStudentShareLinkSaga,
  deleteStudentShareLinkSaga,
  deleteStudentCheckInSaga,
  getStudentGiaListSaga,
  getStudentInfoSaga,
  getStudentShareLinkListSaga,
  getStudentDiagnosisSaga,
  getStudentIndependentDiagnosisSaga,
  getPdfStudentDiagnosisFullSaga,
  getPdfStudentDiagnosisYearSaga,
  getStudentEventsSaga,
  getStudentRewardsSaga,
  getStudentSportRewardsSaga,
  getStudentProjectsSaga,
  getStudentEmploymentsSaga,
  getStudentAffilationsSaga,
  getStudentGiaWorldSkillsSaga,
  getStudentProfessionalAdditionalEducationSaga,
  getStudentMetaSkillsSaga,
  getStudentSpoStatusSaga,
  postStudentSpoStatusSaga,
  postStudentMetaSkillsSaga,
  getStudentRecommendationsSaga,
  getStudentClassRecommendationsSaga,
  getStudentEisDopRecommendationsSaga,
  getUserSettingsSaga,
  getStudentCheckInHistorySaga,
  getStudentCultureVisitingsSaga,
  saveAvatarSettingsSaga,
  getStudentAvatarSaga,
  saveStudentAvatarSaga,
  saveVisibilitySettingsSaga,
  saveUserThemeSettingsSaga,
  searchStudentEmployments,
  searchStudentEvents,
  searchStudentProjects,
  searchStudentAffilations,
  searchStudentDiagnotics,
  updateStudentShareLinkActivitySaga,
  saveIndependentDiagnosisSettingsSaga,
  reportErrorSaga,
  getEntityErrorMessagesSaga,
  gratitudeTeacherSaga,
  getStudentJobsSaga,
  getStudentSpoEducationSaga,
  getStudentSpoDocumentsSaga,
  getStudentPersonalAccessSaga,
  updateStudentPersonalAccessSaga
};
