import { FC, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Grid, List } from '@mui/material';
import { setCurrentNavigationSectionAction } from 'actions';
import { useAppSelector, useScrollToElement } from 'hooks';

import { IPortfolioNavigation } from '../../../const';
import { ScrollStatusContext } from '../../../context';
import { openUrlInNewTab } from '../../../utils';
import ButtonNavigation from './ButtonNavigation';

import './index.scss';

export interface IPortfolioSideNavigationProps {
  portfolioNavigation: IPortfolioNavigation[];
  onNavigationButtonClick: (button: IPortfolioNavigation) => void;
}

const PortfolioSideNavigation: FC<IPortfolioSideNavigationProps> = ({
  portfolioNavigation,
  onNavigationButtonClick,
}) => {
  const dispatch = useDispatch();
  const { currentNavigationSection } = useAppSelector((state) => state.navigationSection);

  const { isContainerScrolled } = useContext(ScrollStatusContext);

  const { scrollToElementGlobal } = useScrollToElement();

  const navigationHeight = (portfolioNavigation.length + 1) * 60;

  const handleClickNavigationButton = (navigation: IPortfolioNavigation) => {
    const { url, id } = navigation;

    return () => {
      if (url) {
        openUrlInNewTab(url);
      } else {
        scrollToElementGlobal(id, currentNavigationSection);
      }

      onNavigationButtonClick(navigation);
    };
  };

  const firstNavigationId = portfolioNavigation[0]?.id;
  useEffect(() => {
    dispatch(setCurrentNavigationSectionAction(firstNavigationId));
  }, [dispatch, firstNavigationId]);

  return (
    <>
      {portfolioNavigation.length > 0 && (
        <div
          className={
            isContainerScrolled ? 'portfolio-navigation portfolio-navigation--scrolled' : 'portfolio-navigation'
          }
          style={{ maxHeight: `${navigationHeight}px` }}
        >
          <Grid container direction="column" className="portfolio-navigation__inner">
            <List component="nav" aria-label="secondary mailbox folder" sx={{ padding: 0 }}>
              {portfolioNavigation.map((navigation: IPortfolioNavigation) => (
                <ButtonNavigation
                  key={navigation.id}
                  navigation={navigation}
                  onClickListItem={handleClickNavigationButton(navigation)}
                  currentPortfolioSection={currentNavigationSection}
                />
              ))}
            </List>
          </Grid>
        </div>
      )}
    </>
  );
};

export default PortfolioSideNavigation;
