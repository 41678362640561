import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import {
  getComputedIndustryTitleOfIndustryList,
  getFormattedEventDate,
  getMappedIndustriesWithSpeciality,
  getMappedSpecialities,
} from '../../utils/data';

const probes = createSelector(
  [(state: IRootState) => state.proforientationRecommendationApplications],
  (applications) => {
    const events = applications.content?.applicationsProfTests ?? [];

    const mappedEvents = events.map((event) => {
      const {
        eventId,
        name,
        profTestDate,
        placeProfTest,
        startTime,
        endTime,
        industryName,
        specialities,
        statusApplication,
      } = event;

      const mappedSpecialities = getMappedSpecialities(specialities);

      return {
        eventId,
        name,
        place: placeProfTest,
        industry: industryName,
        specialities: mappedSpecialities,
        formattedDate: getFormattedEventDate(profTestDate, startTime, endTime),
        statusApplication,
      };
    });

    return mappedEvents;
  },
);

const excursions = createSelector(
  [(state: IRootState) => state.proforientationRecommendationApplications],
  (applications) => {
    const events = applications.content?.applicationsExcursions ?? [];

    const mappedEvents = events.map((event) => {
      const { eventId, name, excursionDate, placeExcursion, startTime, endTime, industryName, statusApplication } =
        event;

      return {
        eventId,
        name,
        place: placeExcursion,
        industry: industryName,
        formattedDate: getFormattedEventDate(excursionDate, startTime, endTime),
        statusApplication,
      };
    });

    return mappedEvents;
  },
);

const dods = createSelector(
  [(state: IRootState) => state.proforientationRecommendationApplications],
  (applications) => {
    const events = applications.content?.applicationsDod ?? [];

    const mappedEvents = events.map((event) => {
      const { eventId, name, dodDate, addressDod, startTime, endTime, industryAndSpecs, statusApplication } = event;

      const industryList = getMappedIndustriesWithSpeciality(industryAndSpecs);
      const industryTitle = getComputedIndustryTitleOfIndustryList(industryList);

      return {
        eventId,
        name,
        place: addressDod,
        industry: industryTitle,
        industryList,
        formattedDate: getFormattedEventDate(dodDate, startTime, endTime),
        statusApplication,
      };
    });

    return mappedEvents;
  },
);

export const proforientationApplicationSelectors = {
  probes,
  excursions,
  dods,
};
