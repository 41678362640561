import { useUserFunctionality } from 'hooks';

import { ViewedFunctionCodes, ViewedFunctionKeys } from '../../../../const';
import { IUseFeaturePreview } from '../types';
import { ArchiveSearchPreviewComponent } from './component';

const useArchiveSearchPreview = (): IUseFeaturePreview => {
  const { isStudent, isParent } = useUserFunctionality();

  return {
    isLoaded: true,
    hasAccess: isStudent || isParent,
    functionCode: ViewedFunctionCodes[ViewedFunctionKeys.ARCHIVE_SEARCH],
    component: ArchiveSearchPreviewComponent,
  };
};

export default useArchiveSearchPreview;
