import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { placeholderChart3 } from 'images';
import { NeutralColors } from 'portfolio3/styles';

import * as styles from './styles';

interface INoDataPlugProps {
  title: string;
  description?: string;
}

const NoDataPlug: FC<INoDataPlugProps> = ({ title, description }) => {
  return (
    <Box className="no-data-plug" sx={styles.root}>
      <Box component="img" src={placeholderChart3} sx={styles.image} />

      <Typography variant="Headings/H6">{title}</Typography>
      {description && (
        <Typography variant="Paragraph LG/Regular" color={NeutralColors.light_neutrals_600}>
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default NoDataPlug;
