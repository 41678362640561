import { FC } from 'react';

import { Box } from '@mui/material';
import { professionNavigation } from 'const';
import { useAppSelector } from 'hooks';
import { isDefined } from 'utils';

import ExpandableContainer from '../../components/ExpandableContainer';
import GenericApplicationCard from '../../components/GenericApplicationCard';
import GenericEntityList from '../../components/GenericEntityList';
import NoDataPlug from '../../components/NoDataPlug';
import { SpecialityList } from '../../components/specialities';
import { proforientationApplicationSelectors } from '../../model/selectors';

interface IApplicationProbesWidgetProps {
  applicationStatusCode: number | null;
}

const ApplicationProbesWidget: FC<IApplicationProbesWidgetProps> = ({ applicationStatusCode }) => {
  const probes = useAppSelector(proforientationApplicationSelectors.probes);

  const filterdProbes = probes.filter((event) => {
    return !isDefined(applicationStatusCode) || event.statusApplication === applicationStatusCode;
  });

  if (filterdProbes.length === 0) {
    return (
      <Box sx={{ display: 'grid', placeItems: 'center', paddingTop: '16px' }}>
        <NoDataPlug title="Ничего не найдено" />
      </Box>
    );
  }

  return (
    <GenericEntityList
      containerId={`${professionNavigation.applications.id}/probes`}
      entities={filterdProbes}
      initialCardCount={3}
      renderEntityCard={(entity) => {
        return (
          <GenericApplicationCard
            key={entity.eventId}
            date={entity.formattedDate}
            industry={entity.industry}
            name={entity.name}
            place={entity.place}
            statusApplicationCode={entity.statusApplication}
            bottomAddon={
              <ExpandableContainer title="Специальности">
                <SpecialityList specialities={entity.specialities} />
              </ExpandableContainer>
            }
          />
        );
      }}
    />
  );
};

export default ApplicationProbesWidget;
