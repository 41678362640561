import { IViewVisibilitySetting } from '../types';
import { SectionCodes, SectionKeys } from './sections';

enum LocalSectionSettingKeys {
  PROFESSION_APPLICATIONS = 'professionApplications',
}

export const LocalSectionSettingTypeCodes: Record<LocalSectionSettingKeys, string> = {
  [LocalSectionSettingKeys.PROFESSION_APPLICATIONS]: 'professionApplications',
};

export enum StudentSectionSettingKeys {
  // portfolio sections
  PROFILE = 'profile',
  STUDY = 'study',
  SCIENCE = 'science',
  SPORT = 'sport',
  CREATION = 'creation',
  CULTURE = 'culture',
  CIVIL = 'civil',
  PROFESSION = 'profession',
  RECOMMENDATION = 'recommendation',

  // profile sub sections
  PROFILE_INTEREST = 'profileInterest',
  PROFILE_RECOMMENDATIONS = 'profileRecommendations',
  // PROFILE_GOALS = 'profileGoals',

  // study sub sections
  STUDY_PERFORMANCE = 'studyPerformance',
  STUDY_TRAINING = 'studyTraining',
  STUDY_PRACTICE = 'studyPractice',
  STUDY_DOCUMENTS = 'studyDocuments',
  STUDY_GIA = 'studyGia',
  STUDY_DIAGNOSTIC = 'studyDiagnostic',
  STUDY_OLYMPIAD = 'studyOlympiad',
  STUDY_TRIAL_EXAM = 'studyTrialExam',
  // study gia categories
  GIA_OGE = 'giaOge',
  GIA_EGE = 'giaEge',
  GIA_GVE9 = 'giaGve9',
  GIA_GVE11 = 'giaGve11',
  GIA_OTHER = 'giaOther',
  // study diagnosis categories
  DIAGNOSIS_INDEPENDENT = 'diagnosticIndependent',
  DIAGNOSIS_PERSONAL = 'diagnosticPersonal',
  DIAGNOSIS_SELF = 'diagnosticSelf',
  DIAGNOSIS_INDEPENDENT_RATING = 'diagnosticIndependentRating',
  DIAGNOSIS_INDEPENDENT_LIST = 'diagnosticIndependentList',

  // science sub sections
  SCIENCE_REWARD = 'scienceReward',
  SCIENCE_PROJECT = 'scienceProject',
  SCIENCE_EMPLOYMENT = 'scienceEmployment',
  SCIENCE_CONTEST = 'scienceContest',

  // sport sub sections
  SPORT_REWARD = 'sportReward',
  SPORT_CLUB = 'sportClub',
  SPORT_UNIT = 'sportUnit',
  SPORT_GAME = 'sportGame',
  SPORT_HIKE = 'sportHike',

  // creation sub sections
  CREATION_REWARD = 'creationReward',
  CREATION_CLUB = 'creationClub',
  CREATION_CONTEST = 'creationContest',
  CREATION_UNIT = 'creationUnit',

  // culture sub sections
  CULTURE_OFFLINE_VISITING = 'cultureOfflineVisiting',
  CULTURE_ONLINE_VISITING = 'cultureOnlineVisiting',

  // civil sub sections
  CIVIL_REWARD = 'civilReward',
  CIVIL_CLUB = 'civilClub',
  CIVIL_CONTEST = 'civilContest',
  CIVIL_UNIT = 'civilUnit',

  // profession sub sections
  PROFESSION_REWARD = 'professionReward',
  PROFESSION_JOB = 'professionJob',
  PROFESSION_METASKILLS = 'professionMetaskills',
  PROFESSION_EDUCATION = 'professionEducation',
  PROFESSION_EVENT = 'professionEvent',
  PROFESSION_GIA_WORLDSKILLS = 'professionGiaWorldskills',
  PROFESSION_TALENT_MAP = 'professionTalentMap',
  PROFESSION_PROFORIENTATION = 'professionProforientation',
  PROFESSION_PROFORIENTATION_RECOMMENDATIONS = 'professionProforientationRecommendations',
  PROFESSION_PROFORIENTATION_EVENTS = 'professionProforientationEvents',

  // recommendation sub section
  RECOMMENDATION_VUZ = 'recommendationVuz',
}

/**
 * В этом объекты записываются строковые значения настроек видимости, которые сохраняются на сервере
 * Настройки имеют иерархичную структуру, значения не могут дублироваться в рамках одной ветви на одном уровне
 * то есть на первом уровне не может быть двух studies, внутри studies не может быть двух study_documents
 *
 * Настройки админа (const/adminSettings) мапятся на эти же значения
 */
export const StudentSectionSettingTypeCodes: Record<StudentSectionSettingKeys, string> = {
  [StudentSectionSettingKeys.PROFILE]: 'profile',
  [StudentSectionSettingKeys.STUDY]: 'studies',
  [StudentSectionSettingKeys.SCIENCE]: 'science',
  [StudentSectionSettingKeys.SPORT]: 'sport',
  [StudentSectionSettingKeys.CREATION]: 'creation',
  [StudentSectionSettingKeys.CULTURE]: 'culture',
  [StudentSectionSettingKeys.CIVIL]: 'civil',
  [StudentSectionSettingKeys.PROFESSION]: 'profession',
  recommendation: 'recommendation',
  [StudentSectionSettingKeys.PROFILE_INTEREST]: 'interest',
  [StudentSectionSettingKeys.PROFILE_RECOMMENDATIONS]: 'recommendation',
  // [StudentSectionSettingKeys.PROFILE_GOALS]: 'goals',
  [StudentSectionSettingKeys.STUDY_PERFORMANCE]: 'performance',
  [StudentSectionSettingKeys.STUDY_TRAINING]: 'study_training',
  [StudentSectionSettingKeys.STUDY_PRACTICE]: 'study_practice',
  [StudentSectionSettingKeys.STUDY_DOCUMENTS]: 'study_documents',
  [StudentSectionSettingKeys.STUDY_GIA]: 'gia',
  [StudentSectionSettingKeys.STUDY_DIAGNOSTIC]: 'diagnostics',
  [StudentSectionSettingKeys.STUDY_OLYMPIAD]: 'olympiads',
  [StudentSectionSettingKeys.STUDY_TRIAL_EXAM]: 'trial_exam',
  [StudentSectionSettingKeys.GIA_OGE]: 'oge',
  [StudentSectionSettingKeys.GIA_EGE]: 'ege',
  [StudentSectionSettingKeys.GIA_GVE9]: 'gve9',
  [StudentSectionSettingKeys.GIA_GVE11]: 'gve11',
  [StudentSectionSettingKeys.GIA_OTHER]: 'other',
  [StudentSectionSettingKeys.DIAGNOSIS_INDEPENDENT]: 'independentDiagnostic',
  [StudentSectionSettingKeys.DIAGNOSIS_PERSONAL]: 'personalDiagnostic',
  [StudentSectionSettingKeys.DIAGNOSIS_SELF]: 'selfDiagnostic',
  diagnosticIndependentRating: 'diagnostic_independent_rating',
  diagnosticIndependentList: 'diagnostic_independent_list',
  [StudentSectionSettingKeys.SCIENCE_REWARD]: 'rewards',
  [StudentSectionSettingKeys.SCIENCE_PROJECT]: 'projects',
  [StudentSectionSettingKeys.SCIENCE_EMPLOYMENT]: 'employments',
  [StudentSectionSettingKeys.SCIENCE_CONTEST]: 'contests',
  [StudentSectionSettingKeys.SPORT_REWARD]: 'rewards',
  [StudentSectionSettingKeys.SPORT_CLUB]: 'clubs',
  [StudentSectionSettingKeys.SPORT_UNIT]: 'units',
  [StudentSectionSettingKeys.SPORT_GAME]: 'sport_games',
  [StudentSectionSettingKeys.SPORT_HIKE]: 'hike',
  [StudentSectionSettingKeys.CREATION_REWARD]: 'rewards',
  [StudentSectionSettingKeys.CREATION_CLUB]: 'creation_club',
  [StudentSectionSettingKeys.CREATION_CONTEST]: 'creation_contest',
  [StudentSectionSettingKeys.CREATION_UNIT]: 'creation_unit',
  [StudentSectionSettingKeys.CULTURE_OFFLINE_VISITING]: 'offline_visit',
  [StudentSectionSettingKeys.CULTURE_ONLINE_VISITING]: 'online_visit',
  [StudentSectionSettingKeys.CIVIL_REWARD]: 'rewards',
  [StudentSectionSettingKeys.CIVIL_CLUB]: 'civil_club',
  [StudentSectionSettingKeys.CIVIL_CONTEST]: 'civil_contest',
  [StudentSectionSettingKeys.CIVIL_UNIT]: 'civil_unit',
  [StudentSectionSettingKeys.PROFESSION_REWARD]: 'profession_rewards',
  [StudentSectionSettingKeys.PROFESSION_JOB]: 'profession_job',
  [StudentSectionSettingKeys.PROFESSION_METASKILLS]: 'profession_metaskills',
  [StudentSectionSettingKeys.PROFESSION_EDUCATION]: 'profession_education',
  [StudentSectionSettingKeys.PROFESSION_EVENT]: 'profession_events',
  [StudentSectionSettingKeys.PROFESSION_GIA_WORLDSKILLS]: 'profession_worldskills',
  professionTalentMap: 'profession_talent_map',
  [StudentSectionSettingKeys.PROFESSION_PROFORIENTATION]: 'career_guidance',
  [StudentSectionSettingKeys.PROFESSION_PROFORIENTATION_RECOMMENDATIONS]: 'recommendations',
  [StudentSectionSettingKeys.PROFESSION_PROFORIENTATION_EVENTS]: 'prof_events',
  recommendationVuz: 'recommendation_vuz',
};

const profileKey = SectionKeys.PROFILE;
const profileCode = SectionCodes[profileKey];
const studyKey = SectionKeys.STUDY;
const studyCode = SectionCodes[studyKey];
const scienceKey = SectionKeys.SCIENCE;
const scienceCode = SectionCodes[scienceKey];
const sportKey = SectionKeys.SPORT;
const sportCode = SectionCodes[sportKey];
const creationKey = SectionKeys.CREATION;
const creationCode = SectionCodes[creationKey];
const cultureKey = SectionKeys.CULTURE;
const cultureCode = SectionCodes[cultureKey];
const civilKey = SectionKeys.CIVIL;
const civilCode = SectionCodes[civilKey];
const professionKey = SectionKeys.PROFESSION;
const professionCode = SectionCodes[professionKey];
const recommendationKey = SectionKeys.RECOMMENDATION;
const recommendationCode = SectionCodes[recommendationKey];

export const SectionTypeCodes: Record<number, string> = {
  [profileCode]: StudentSectionSettingTypeCodes.profile,
  [studyCode]: StudentSectionSettingTypeCodes.study,
  [scienceCode]: StudentSectionSettingTypeCodes.science,
  [sportCode]: StudentSectionSettingTypeCodes.sport,
  [creationCode]: StudentSectionSettingTypeCodes.creation,
  [cultureCode]: StudentSectionSettingTypeCodes.culture,
  [civilCode]: StudentSectionSettingTypeCodes.civil,
  [professionCode]: StudentSectionSettingTypeCodes.profession,
  [recommendationCode]: StudentSectionSettingTypeCodes.recommendation,
};

type SectionInfo = Omit<IViewVisibilitySetting, 'isVisible' | 'subSections'>;

type ProfileSubsectionsKeys = 'interest' | 'recommendation';
type StudySubsectionsKeys =
  | 'performance'
  | 'training'
  | 'practice'
  | 'documents'
  | 'gia'
  | 'diagnostics'
  | 'olympiads'
  | 'trialExam';
type ScienceSubsectionsKeys = 'rewards' | 'projects' | 'employments' | 'contests';
type SportSubsectionsKeys = 'rewards' | 'clubs' | 'units' | 'games' | 'hike';
type CreationSubsectionsKeys = 'rewards' | 'clubs' | 'contests' | 'units';
type CultureSubsectionsKeys = 'offlineVisit' | 'onlineVisit';
type CivilSubsectionsKeys = 'rewards' | 'clubs' | 'contests' | 'units';
type ProfessionSubsectionsKeys =
  | 'rewards'
  | 'job'
  | 'metaskills'
  | 'education'
  | 'events'
  | 'worldskills'
  | 'proforientation'
  | 'recommendations'
  | 'proforientationEvents';
type RecommendationSubsectionsKeys = 'vuz';

type ProfileSubsectionsInfoSet = { [key in ProfileSubsectionsKeys]: SectionInfo };
type StudySubsectionsInfoSet = { [key in StudySubsectionsKeys]: SectionInfo };
type ScienceSubsectionsInfoSet = { [key in ScienceSubsectionsKeys]: SectionInfo };
type SportSubsectionsInfoSet = { [key in SportSubsectionsKeys]: SectionInfo };
type CreationSubsectionsInfoSet = { [key in CreationSubsectionsKeys]: SectionInfo };
type CultureSubsectionsInfoSet = { [key in CultureSubsectionsKeys]: SectionInfo };
type CivilSubsectionsInfoSet = { [key in CivilSubsectionsKeys]: SectionInfo };
type ProfessionSubsectionsInfoSet = { [key in ProfessionSubsectionsKeys]: SectionInfo };
type RecommendationSubsectionsInfoSet = { [key in RecommendationSubsectionsKeys]: SectionInfo };

export const ProfileSettings: IViewVisibilitySetting = {
  name: 'Мой профиль',
  sectionTypeCode: StudentSectionSettingTypeCodes.profile,
  isVisible: true,
  linkSettingCode: 'profile',
  subSections: [
    {
      name: 'Анкета интересов',
      sectionTypeCode: StudentSectionSettingTypeCodes.profileInterest,
      isVisible: true,
      linkSettingCode: 'interests',
    },
    {
      name: 'Рекомендации на основе интересов',
      sectionTypeCode: StudentSectionSettingTypeCodes.profileRecommendations,
      isVisible: true,
    },
    // {
    //   name: 'Цели',
    //   sectionTypeCode: StudentSectionSettingTypeCodes[StudentSectionSettingKeys.PROFILE_GOALS],
    //   isVisible: true
    // }
  ],
};

export const StudySettings: IViewVisibilitySetting = {
  name: 'Учёба',
  sectionTypeCode: StudentSectionSettingTypeCodes.study,
  isVisible: true,
  linkSettingCode: 'studies',
  subSections: [
    {
      name: 'Успеваемость',
      sectionTypeCode: StudentSectionSettingTypeCodes.studyPerformance,
      isVisible: true,
      linkSettingCode: 'performance',
    },
    {
      name: 'Сведения об обучении',
      sectionTypeCode: StudentSectionSettingTypeCodes.studyTraining,
      isVisible: true,
      linkSettingCode: 'trainingInfo',
    },
    {
      name: 'Практика',
      sectionTypeCode: StudentSectionSettingTypeCodes.studyPractice,
      isVisible: true,
      linkSettingCode: 'practice',
    },
    {
      name: 'Документы',
      sectionTypeCode: StudentSectionSettingTypeCodes.studyDocuments,
      isVisible: true,
      linkSettingCode: 'documents',
    },
    {
      name: 'ГИА',
      sectionTypeCode: StudentSectionSettingTypeCodes.studyGia,
      isVisible: true,
      linkSettingCode: 'gia',
      subSections: [
        {
          name: 'Основной государственный экзамен',
          sectionTypeCode: StudentSectionSettingTypeCodes.giaOge,
          isVisible: true,
          linkSettingCode: 'oge',
        },
        {
          name: 'Единый Государственный экзамен',
          sectionTypeCode: StudentSectionSettingTypeCodes.giaEge,
          isVisible: true,
          linkSettingCode: 'gia',
        },
        {
          name: 'Государственный выпускной экзамен-9',
          sectionTypeCode: StudentSectionSettingTypeCodes.giaGve9,
          isVisible: true,
          linkSettingCode: 'gve9',
        },
        {
          name: 'Государственный выпускной экзамен-11',
          sectionTypeCode: StudentSectionSettingTypeCodes.giaGve11,
          isVisible: true,
          linkSettingCode: 'gve11',
        },
        {
          name: 'Другое',
          sectionTypeCode: StudentSectionSettingTypeCodes.giaOther,
          isVisible: true,
          linkSettingCode: 'other',
        },
      ],
    },
    {
      name: 'Диагностики',
      sectionTypeCode: StudentSectionSettingTypeCodes.studyDiagnostic,
      isVisible: true,
      linkSettingCode: 'diagnostic',
      subSections: [
        {
          name: 'Независимые',
          sectionTypeCode: StudentSectionSettingTypeCodes.diagnosticIndependent,
          isVisible: true,
          linkSettingCode: 'independentDiagnostic',
        },
        {
          name: 'Индивидуальные',
          sectionTypeCode: StudentSectionSettingTypeCodes.diagnosticPersonal,
          isVisible: true,
          linkSettingCode: 'personalDiagnostic',
        },
        {
          name: 'Самодиагностики',
          sectionTypeCode: StudentSectionSettingTypeCodes.diagnosticSelf,
          isVisible: true,
          linkSettingCode: 'selfDiagnostic',
        },
        {
          name: 'Независимые диагностики / Рейтинг',
          sectionTypeCode: StudentSectionSettingTypeCodes.diagnosticIndependentRating,
          isVisible: true,
          linkSettingCode: 'independentDiagnostic',
        },
        {
          name: 'Независимые диагностики / Список',
          sectionTypeCode: StudentSectionSettingTypeCodes.diagnosticIndependentList,
          isVisible: true,
          linkSettingCode: 'independentDiagnostic',
        },
      ],
    },
    {
      name: 'Олимпиады',
      sectionTypeCode: StudentSectionSettingTypeCodes.studyOlympiad,
      isVisible: true,
      linkSettingCode: 'olympiads',
    },
    {
      name: 'Пробный ЕГЭ',
      sectionTypeCode: StudentSectionSettingTypeCodes.studyTrialExam,
      isVisible: true,
      linkSettingCode: 'trialExam',
    },
  ],
};

export const ScienceSettings: IViewVisibilitySetting = {
  name: 'Наука',
  sectionTypeCode: StudentSectionSettingTypeCodes.science,
  isVisible: true,
  linkSettingCode: 'science',
  subSections: [
    {
      name: 'Награды',
      sectionTypeCode: StudentSectionSettingTypeCodes.scienceReward,
      isVisible: true,
      linkSettingCode: 'scienceReward',
    },
    {
      name: 'Проекты',
      sectionTypeCode: StudentSectionSettingTypeCodes.scienceProject,
      isVisible: true,
      linkSettingCode: 'projects',
    },
    {
      name: 'Занятия',
      sectionTypeCode: StudentSectionSettingTypeCodes.scienceEmployment,
      isVisible: true,
      linkSettingCode: 'scienceEmployments',
    },
    {
      name: 'Конкурсы, конференции',
      sectionTypeCode: StudentSectionSettingTypeCodes.scienceContest,
      isVisible: true,
      linkSettingCode: 'scienceContest',
    },
  ],
};

export const SportSettings: IViewVisibilitySetting = {
  name: 'Спорт',
  sectionTypeCode: StudentSectionSettingTypeCodes.sport,
  isVisible: true,
  linkSettingCode: 'sport',
  subSections: [
    {
      name: 'Награды и разряды',
      sectionTypeCode: StudentSectionSettingTypeCodes.sportReward,
      isVisible: true,
      linkSettingCode: 'sportReward',
    },
    {
      name: 'Клубы и команды',
      sectionTypeCode: StudentSectionSettingTypeCodes.sportClub,
      isVisible: true,
      linkSettingCode: 'sportClub',
    },
    {
      name: 'Кружки и секции',
      sectionTypeCode: StudentSectionSettingTypeCodes.sportUnit,
      isVisible: true,
      linkSettingCode: 'sportUnit',
    },
    {
      name: 'Соревнования',
      sectionTypeCode: StudentSectionSettingTypeCodes.sportGame,
      isVisible: true,
      linkSettingCode: 'sportGames',
    },
    {
      name: 'Походы и экспедиции',
      sectionTypeCode: StudentSectionSettingTypeCodes.sportHike,
      isVisible: true,
      linkSettingCode: 'hike',
    },
  ],
};

export const CreationSettings: IViewVisibilitySetting = {
  name: 'Творчество',
  sectionTypeCode: StudentSectionSettingTypeCodes.creation,
  isVisible: true,
  linkSettingCode: 'creation',
  subSections: [
    {
      name: 'Награды и достижения',
      sectionTypeCode: StudentSectionSettingTypeCodes.creationReward,
      isVisible: true,
      linkSettingCode: 'creationReward',
    },
    {
      name: 'Творческие коллективы',
      sectionTypeCode: StudentSectionSettingTypeCodes.creationClub,
      isVisible: true,
      linkSettingCode: 'creationClub',
    },
    {
      name: 'Конкурсы',
      sectionTypeCode: StudentSectionSettingTypeCodes.creationContest,
      isVisible: true,
      linkSettingCode: 'creationContest',
    },
    {
      name: 'Кружки',
      sectionTypeCode: StudentSectionSettingTypeCodes.creationUnit,
      isVisible: true,
      linkSettingCode: 'creationUnit',
    },
  ],
};

export const CultureSettings: IViewVisibilitySetting = {
  name: 'Культура',
  sectionTypeCode: StudentSectionSettingTypeCodes.culture,
  isVisible: true,
  linkSettingCode: 'culture',
  subSections: [
    {
      name: 'Посещение учреждений',
      sectionTypeCode: StudentSectionSettingTypeCodes.cultureOfflineVisiting,
      isVisible: true,
      linkSettingCode: 'offlineVisit',
    },
    {
      name: 'Онлайн-мероприятия',
      sectionTypeCode: StudentSectionSettingTypeCodes.cultureOnlineVisiting,
      isVisible: true,
      linkSettingCode: 'onlineVisit',
    },
  ],
};

export const CivilSettings: IViewVisibilitySetting = {
  name: 'Гражданская активность',
  sectionTypeCode: StudentSectionSettingTypeCodes.civil,
  isVisible: true,
  linkSettingCode: 'civil',
  subSections: [
    {
      name: 'Награды и статусы',
      sectionTypeCode: StudentSectionSettingTypeCodes.civilReward,
      isVisible: true,
      linkSettingCode: 'civilReward',
    },
    {
      name: 'Клубы',
      sectionTypeCode: StudentSectionSettingTypeCodes.civilClub,
      isVisible: true,
      linkSettingCode: 'civilClub',
    },
    {
      name: 'Конкурсы, соревнования',
      sectionTypeCode: StudentSectionSettingTypeCodes.civilContest,
      isVisible: true,
      linkSettingCode: 'civilContest',
    },
    {
      name: 'Кружки',
      sectionTypeCode: StudentSectionSettingTypeCodes.civilUnit,
      isVisible: true,
      linkSettingCode: 'civilUnit',
    },
  ],
};

export const ProfessionSettings: IViewVisibilitySetting = {
  name: 'Моя профессия',
  sectionTypeCode: StudentSectionSettingTypeCodes.profession,
  isVisible: true,
  linkSettingCode: 'profession',
  subSections: [
    {
      name: 'Награды',
      sectionTypeCode: StudentSectionSettingTypeCodes.professionReward,
      isVisible: true,
      linkSettingCode: 'professionRewards',
    },
    {
      name: 'Трудоустройство',
      sectionTypeCode: StudentSectionSettingTypeCodes.professionJob,
      isVisible: true,
      linkSettingCode: 'job',
    },
    {
      name: 'Метанавыки',
      sectionTypeCode: StudentSectionSettingTypeCodes.professionMetaskills,
      isVisible: true,
      linkSettingCode: 'metaskills',
    },
    {
      name: 'Профессиональное обучение',
      sectionTypeCode: StudentSectionSettingTypeCodes.professionEducation,
      isVisible: true,
      linkSettingCode: 'professionEducation',
    },
    {
      name: 'Мероприятия',
      sectionTypeCode: StudentSectionSettingTypeCodes.professionEvent,
      isVisible: true,
      linkSettingCode: 'professionEvents',
    },
    {
      name: 'Демонстрационные экзамены',
      sectionTypeCode: StudentSectionSettingTypeCodes.professionGiaWorldskills,
      isVisible: true,
      linkSettingCode: 'professionWorldskills',
    },
    {
      name: 'Карта талантов',
      sectionTypeCode: StudentSectionSettingTypeCodes.professionTalentMap,
      isVisible: true,
    },
    {
      name: 'Профориентация',
      sectionTypeCode: StudentSectionSettingTypeCodes.professionProforientation,
      isVisible: true,
      linkSettingCode: 'professionCareerGuidance',
    },
    {
      name: 'Рекомендации',
      sectionTypeCode: StudentSectionSettingTypeCodes.professionProforientationRecommendations,
      isVisible: true,
      linkSettingCode: 'professionRecommendations',
    },
    {
      name: 'Профориентационные мероприятия',
      sectionTypeCode: StudentSectionSettingTypeCodes.professionProforientationEvents,
      isVisible: true,
      linkSettingCode: 'professionProfEvents',
    },
  ],
};

export const RecommendationSettings: IViewVisibilitySetting = {
  name: 'Рекомендации',
  sectionTypeCode: StudentSectionSettingTypeCodes.recommendation,
  isVisible: true,
  subSections: [
    {
      name: 'Рекомендации вузов',
      sectionTypeCode: StudentSectionSettingTypeCodes.recommendationVuz,
      isVisible: true,
    },
  ],
};

export const ProfileSubsections: ProfileSubsectionsInfoSet = {
  interest: {
    name: 'Анкета интересов',
    sectionTypeCode: StudentSectionSettingTypeCodes.profileInterest,
  },
  recommendation: {
    name: 'Рекомендации на основе интересов',
    sectionTypeCode: StudentSectionSettingTypeCodes.profileRecommendations,
  },
  // goals: {
  //   name: 'Цели',
  //   sectionTypeCode: StudentSectionSettingTypeCodes[StudentSectionSettingKeys.PROFILE_GOALS]
  // }
};

export const StudySubsections: StudySubsectionsInfoSet = {
  performance: {
    name: 'Предметные результаты',
    sectionTypeCode: StudentSectionSettingTypeCodes.studyPerformance,
  },
  training: {
    name: 'Сведения об обучении',
    sectionTypeCode: StudentSectionSettingTypeCodes.studyTraining,
  },
  practice: {
    name: 'Практика',
    sectionTypeCode: StudentSectionSettingTypeCodes.studyPractice,
  },
  documents: {
    name: 'Документы',
    sectionTypeCode: StudentSectionSettingTypeCodes.studyDocuments,
  },
  gia: {
    name: 'ГИА',
    sectionTypeCode: StudentSectionSettingTypeCodes.studyGia,
  },
  diagnostics: {
    name: 'Диагностики',
    sectionTypeCode: StudentSectionSettingTypeCodes.studyDiagnostic,
  },
  olympiads: {
    name: 'Олимпиады',
    sectionTypeCode: StudentSectionSettingTypeCodes.studyOlympiad,
  },
  trialExam: {
    name: 'Пробный ЕГЭ',
    sectionTypeCode: StudentSectionSettingTypeCodes.studyTrialExam,
  },
};

export const ScienceSubsections: ScienceSubsectionsInfoSet = {
  rewards: {
    name: 'Награды',
    sectionTypeCode: StudentSectionSettingTypeCodes.scienceReward,
  },
  projects: {
    name: 'Проекты',
    sectionTypeCode: StudentSectionSettingTypeCodes.scienceProject,
  },
  employments: {
    name: 'Занятия',
    sectionTypeCode: StudentSectionSettingTypeCodes.scienceEmployment,
  },
  contests: {
    name: 'Конкурсы, конференции',
    sectionTypeCode: StudentSectionSettingTypeCodes.scienceContest,
  },
};

export const SportSubsections: SportSubsectionsInfoSet = {
  rewards: {
    name: 'Награды и разряды',
    sectionTypeCode: StudentSectionSettingTypeCodes.sportReward,
  },
  clubs: {
    name: 'Клубы и команды',
    sectionTypeCode: StudentSectionSettingTypeCodes.sportClub,
  },
  units: {
    name: 'Кружки и секции',
    sectionTypeCode: StudentSectionSettingTypeCodes.sportUnit,
  },
  games: {
    name: 'Соревнования',
    sectionTypeCode: StudentSectionSettingTypeCodes.sportGame,
  },
  hike: {
    name: 'Походы и экспедиции',
    sectionTypeCode: StudentSectionSettingTypeCodes.sportHike,
  },
};

export const CreationSubsections: CreationSubsectionsInfoSet = {
  rewards: {
    name: 'Награды и достижения',
    sectionTypeCode: StudentSectionSettingTypeCodes.creationReward,
  },
  clubs: {
    name: 'Творческие коллективы',
    sectionTypeCode: StudentSectionSettingTypeCodes.creationClub,
  },
  contests: {
    name: 'Конкурсы',
    sectionTypeCode: StudentSectionSettingTypeCodes.creationContest,
  },
  units: {
    name: 'Кружки',
    sectionTypeCode: StudentSectionSettingTypeCodes.creationUnit,
  },
};

export const CultureSubsections: CultureSubsectionsInfoSet = {
  offlineVisit: {
    name: 'Посещение учреждений',
    sectionTypeCode: StudentSectionSettingTypeCodes.cultureOfflineVisiting,
  },
  onlineVisit: {
    name: 'Онлайн-мероприятия',
    sectionTypeCode: StudentSectionSettingTypeCodes.cultureOnlineVisiting,
  },
};

export const CivilSubsections: CivilSubsectionsInfoSet = {
  rewards: {
    name: 'Награды и статусы',
    sectionTypeCode: StudentSectionSettingTypeCodes.civilReward,
  },
  clubs: {
    name: 'Клубы',
    sectionTypeCode: StudentSectionSettingTypeCodes.civilClub,
  },
  contests: {
    name: 'Конкурсы, соревнования',
    sectionTypeCode: StudentSectionSettingTypeCodes.civilContest,
  },
  units: {
    name: 'Кружки',
    sectionTypeCode: StudentSectionSettingTypeCodes.civilUnit,
  },
};

export const ProfessionSubsections: ProfessionSubsectionsInfoSet = {
  rewards: {
    name: 'Награды',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionReward,
  },
  job: {
    name: 'Трудоустройство',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionJob,
  },
  metaskills: {
    name: 'Метанавыки',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionMetaskills,
  },
  education: {
    name: 'Профессиональное обучение',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionEducation,
  },
  events: {
    name: 'Мероприятия',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionEvent,
  },
  worldskills: {
    name: 'Демонстрационные экзамены',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionGiaWorldskills,
  },
  proforientation: {
    name: 'Профориентация',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionProforientation,
  },
  recommendations: {
    name: 'Рекомендации',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionProforientationRecommendations,
  },
  proforientationEvents: {
    name: 'Профориентационные пробы',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionProforientationEvents,
  },
};

export const RecommendationSubsections: RecommendationSubsectionsInfoSet = {
  vuz: {
    name: 'Рекомендации вузов',
    sectionTypeCode: StudentSectionSettingTypeCodes.recommendationVuz,
  },
};

export const SectionCodeVisibilitySettings: Record<number, IViewVisibilitySetting> = {
  [SectionCodes[profileKey]]: ProfileSettings,
  [SectionCodes[studyKey]]: StudySettings,
  [SectionCodes[scienceKey]]: ScienceSettings,
  [SectionCodes[sportKey]]: SportSettings,
  [SectionCodes[creationKey]]: CreationSettings,
  [SectionCodes[cultureKey]]: CultureSettings,
  [SectionCodes[civilKey]]: CivilSettings,
  [SectionCodes[professionKey]]: ProfessionSettings,
  [SectionCodes[recommendationKey]]: RecommendationSettings,
};

export const SectionTypeCodeVisibilitySettings: Record<string, IViewVisibilitySetting> = {
  [SectionTypeCodes[profileCode]]: ProfileSettings,
  [SectionTypeCodes[studyCode]]: StudySettings,
  [SectionTypeCodes[scienceCode]]: ScienceSettings,
  [SectionTypeCodes[sportCode]]: SportSettings,
  [SectionTypeCodes[creationCode]]: CreationSettings,
  [SectionTypeCodes[cultureCode]]: CultureSettings,
  [SectionTypeCodes[civilCode]]: CivilSettings,
  [SectionTypeCodes[professionCode]]: ProfessionSettings,
  [SectionTypeCodes[recommendationCode]]: RecommendationSettings,
};

export const defaultVisibilitySettings: IViewVisibilitySetting[] = [
  ProfileSettings,
  StudySettings,
  ScienceSettings,
  SportSettings,
  CreationSettings,
  CultureSettings,
  CivilSettings,
  ProfessionSettings,
  RecommendationSettings,
];
