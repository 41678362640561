import { ReactNode, useId } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Stack } from '@mui/material';
import { setDisplayedEntityCountAction } from 'actions';
import { ExpandableEntityList } from 'components/redesignStudent';
import { useAppSelector, useScrollToElement } from 'hooks';

interface IGenericEntityListProps<T> {
  containerId: string;
  initialCardCount: number;
  entities: T[];
  renderEntityCard: (entity: T, index: number) => ReactNode;
  onExpand?: VoidFunction;
}

function GenericEntityList<T>({
  containerId,
  initialCardCount,
  entities,
  renderEntityCard,
  onExpand,
}: IGenericEntityListProps<T>) {
  const dispatch = useDispatch();
  const entityCount = useAppSelector((state) => state.displayedEntityCount[containerId] ?? initialCardCount);

  const listId = useId();
  const { scrollToElementGlobal } = useScrollToElement();

  const handleShowInitialEntityCount = () => {
    dispatch(setDisplayedEntityCountAction(containerId, initialCardCount));
    scrollToElementGlobal(listId);
  };

  const handleShowAllEntities = () => {
    dispatch(setDisplayedEntityCountAction(containerId, entities.length));

    onExpand?.();
  };

  return (
    <Box className="generic-entity-list" id={listId}>
      <ExpandableEntityList
        totalEntitites={entities.length}
        shownEntities={entityCount}
        hasHiddenEntities={entities.length > initialCardCount}
        onSetAllEntities={handleShowAllEntities}
        onSetInitialEntities={handleShowInitialEntityCount}
      >
        <Stack gap="16px">
          {entities.slice(0, entityCount).map((entity, index) => {
            return renderEntityCard(entity, index);
          })}
        </Stack>
      </ExpandableEntityList>
    </Box>
  );
}

export default GenericEntityList;
