import { IAdminSettingSection } from 'api/types';
import { AdminSectionSettingCodes as codes } from 'const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { isDefined } from 'utils';

import { proforientationApplicationSelectors } from './applications';
import { proforientationEventSelectors } from './events';
import proforientationResultLinkSelector from './proforientationResultLinkSelector';
import { proforientationRecommendationSelectors } from './recommendations';

const adminVisible = (settings: IAdminSettingSection[], sectionId: number) => {
  const section = settings.find((section) => section.sectionId === sectionId);
  return section?.isVisible ?? true;
};

const overwiew = createSelector([proforientationResultLinkSelector], (resultLink) => {
  return isDefined(resultLink);
});

const applications = createSelector(
  [
    proforientationApplicationSelectors.probes,
    proforientationApplicationSelectors.excursions,
    proforientationApplicationSelectors.dods,
  ],
  (probes, excursions, dods) => {
    return {
      probes: probes.length > 0,
      excursions: excursions.length > 0,
      dods: dods.length > 0,
    };
  },
);

const recommendations = createSelector(
  [
    proforientationRecommendationSelectors.proforientation,
    proforientationRecommendationSelectors.probes,
    proforientationRecommendationSelectors.excursions,
    proforientationRecommendationSelectors.dods,
    (state: IRootState) => state.adminSettings,
  ],
  (proforientation, probes, excursions, dods, adminSettings) => {
    const proforientationHasData = proforientation.length > 0;
    const probesHasData = probes.length > 0;
    const excursionsHasData = excursions.length > 0;
    const dodsHasData = dods.length > 0;

    // Если хотя бы одна вкладка с данными, все вкладки появляются, с заглушками или с данными
    const isSomeHasData = [proforientationHasData, probesHasData, excursionsHasData, dodsHasData].some(Boolean);

    const s = adminSettings.content.sections;
    const proforientationVisible = adminVisible(s, codes.professionProforientationRecommentationsProforientation);
    const probesVisible = adminVisible(s, codes.professionProforientationRecommentationsProbes);
    const excursionsVisible = adminVisible(s, codes.professionProforientationRecommentationsExcursions);
    const dodsVisible = adminVisible(s, codes.professionProforientationRecommentationsDods);

    return {
      proforientation: proforientationHasData && proforientationVisible,
      probes: isSomeHasData && probesVisible,
      excursions: isSomeHasData && excursionsVisible,
      dods: isSomeHasData && dodsVisible,
    };
  },
);

const events = createSelector(
  [
    proforientationEventSelectors.probes,
    proforientationEventSelectors.excursions,
    proforientationEventSelectors.dods,
    (state: IRootState) => state.adminSettings,
  ],
  (probes, excursions, dods, adminSettings) => {
    const s = adminSettings.content.sections;
    const probesVisible = adminVisible(s, codes.professionProforientationEventsProbes);
    const excursionsVisible = adminVisible(s, codes.professionProforientationEventsExcursions);
    const dodsVisible = adminVisible(s, codes.professionProforientationEventsDods);

    return {
      probes: probes.length > 0 && probesVisible,
      excursions: excursions.length > 0 && excursionsVisible,
      dods: dods.length > 0 && dodsVisible,
    };
  },
);

export const proforientationVisibilitySelectors = {
  overwiew,
  applications,
  recommendations,
  events,
};
