import { FunctionComponent } from 'react';

import {
  IconSectionCivil,
  IconSectionCreation,
  IconSectionCulture,
  IconSectionProfession,
  IconSectionProfile,
  IconSectionRecommendation,
  IconSectionScience,
  IconSectionSport,
  IconSectionStudy,
  widgetIcons,
} from '../icons';
import { SectionCodes } from './sections';
import { StudentSectionSettingTypeCodes } from './visibility';

export enum AdminSectionSettingKeys {
  PROFILE = 'profile',
  INTEREST = 'interest',
  GOALS = 'goals',
  RECOMMENDATIONS = 'recommendations',
  STUDY = 'study',
  SCIENCE = 'science',
  SPORT = 'sport',
  CULTURE = 'culture',
  CREATION = 'creation',
  CIVIL = 'civil',
  PERFORMANCE = 'performance',
  STUDY_TRAINING = 'studyTraining',
  STUDY_PRACTICE = 'studyPractice',
  STUDY_DOCUMENTS = 'studyDocuments',
  GIA = 'gia',
  DIAGNOSIS = 'diagnosis',
  OLYMPIAD = 'olympiad',
  REWARD = 'reward',
  PROJECT = 'project',
  EMPLOYMENT = 'employment',
  CONTEST_CONFERENCE = 'contestConference',
  SPORT_REWARD = 'sportReward',
  CLUB_TEAM = 'clubTeam',
  CIRCLE_SECTION = 'circleSection',
  COMPETITION = 'competition',
  EXPEDITION = 'expedition',
  REWARD_ACHIEVEMENT = 'rewardAchievement',
  CREATION_COLLECTIVE = 'creationCollective',
  CONTEST = 'contest',
  CIRCLE = 'circle',
  CULTURE_VISITING = 'cultureVisiting',
  ONLINE_VISITING = 'onlineVisiting',
  REWARD_STATUS = 'rewardStatus',
  CLUB = 'club',
  CONTEST_COMPETITION = 'contestCompetition',
  CIVIL_CIRCLE = 'civilCircle',
  OGE = 'oge',
  EGE = 'ege',
  GVE9 = 'gve9',
  GVE11 = 'gve11',
  TRIAL_EXAM = 'trialExam',
  PROFESSION = 'profession',
  PROFESSION_REWARD = 'professionReward',
  PROFESSION_JOB = 'professionJob',
  PROFESSION_METASKILLS = 'professionMetaskills',
  PROFESSION_EDUCATION = 'professionEducation',
  PROFESSION_EVENT = 'professionEvents',
  PROFESSION_GIA_WORLDSKILLS = 'professionExamGia',
  PROFESSION_TALENT_MAP = 'professionTalentMap',
  DIAGNOSIS_INDEPENDENT = 'diagnosisIndependent',
  DIAGNOSIS_PERSONAL = 'diagnosisPersonal',
  DIAGNOSIS_SELF = 'diagnosisSelf',
  RECOMMENDATION = 'recommendation',
  RECOMMENDATION_VUZ = 'recommendationVuz',
  DIAGNOSIS_INDEPENDENT_RATING = 'diagnosisIndependentRating',
  DIAGNOSIS_INDEPENDENT_LIST = 'diagnosisIndependentList',
  PROFESSION_PROFORIENTATION = 'professionProforientation',
  PROFESSION_PROFORIENTATION_RECOMMENDATIONS = 'professionProforientationRecommendations',
  // eslint-disable-next-line max-len
  PROFESSION_PROFORIENTATION_RECOMMENDATIONS_PROFORIENTATION = 'professionProforientationRecommentationsProforientation',
  PROFESSION_PROFORIENTATION_RECOMMENDATIONS_PROBES = 'professionProforientationRecommentationsProbes',
  PROFESSION_PROFORIENTATION_RECOMMENDATIONS_EXCURSIONS = 'professionProforientationRecommentationsExcursions',
  PROFESSION_PROFORIENTATION_RECOMMENDATIONS_DODS = 'professionProforientationRecommentationsDods',
  PROFESSION_PROFORIENTATION_EVENTS = 'professionProforientationEvents',
  PROFESSION_PROFORIENTATION_EVENTS_PROBES = 'professionProforientationEventsProbes',
  PROFESSION_PROFORIENTATION_EVENTS_EXCURSIONS = 'professionProforientationEventsExcursions',
  PROFESSION_PROFORIENTATION_EVENTS_DODS = 'professionProforientationEventsDods',
  OTHER = 'other',
}

// section_settings_ref
export const AdminSectionSettingCodes: Record<AdminSectionSettingKeys, number> = {
  [AdminSectionSettingKeys.STUDY]: 1,
  [AdminSectionSettingKeys.SCIENCE]: 2,
  [AdminSectionSettingKeys.SPORT]: 3,
  [AdminSectionSettingKeys.CREATION]: 4,
  [AdminSectionSettingKeys.CULTURE]: 5,
  [AdminSectionSettingKeys.CIVIL]: 6,
  [AdminSectionSettingKeys.PERFORMANCE]: 7,
  [AdminSectionSettingKeys.GIA]: 8,
  [AdminSectionSettingKeys.DIAGNOSIS]: 9,
  [AdminSectionSettingKeys.OLYMPIAD]: 10,
  [AdminSectionSettingKeys.REWARD]: 11,
  [AdminSectionSettingKeys.PROJECT]: 12,
  [AdminSectionSettingKeys.EMPLOYMENT]: 13,
  [AdminSectionSettingKeys.CONTEST_CONFERENCE]: 14,
  [AdminSectionSettingKeys.SPORT_REWARD]: 15,
  [AdminSectionSettingKeys.CLUB_TEAM]: 16,
  [AdminSectionSettingKeys.CIRCLE_SECTION]: 17,
  [AdminSectionSettingKeys.COMPETITION]: 18,
  [AdminSectionSettingKeys.EXPEDITION]: 19,
  [AdminSectionSettingKeys.REWARD_ACHIEVEMENT]: 20,
  [AdminSectionSettingKeys.CREATION_COLLECTIVE]: 21,
  [AdminSectionSettingKeys.CONTEST]: 22,
  [AdminSectionSettingKeys.CIRCLE]: 23,
  [AdminSectionSettingKeys.CULTURE_VISITING]: 24,
  [AdminSectionSettingKeys.ONLINE_VISITING]: 25,
  [AdminSectionSettingKeys.REWARD_STATUS]: 26,
  [AdminSectionSettingKeys.CLUB]: 27,
  [AdminSectionSettingKeys.CONTEST_COMPETITION]: 28,
  [AdminSectionSettingKeys.CIVIL_CIRCLE]: 29,
  [AdminSectionSettingKeys.OGE]: 30,
  [AdminSectionSettingKeys.EGE]: 31,
  [AdminSectionSettingKeys.GVE9]: 32,
  [AdminSectionSettingKeys.GVE11]: 33,
  [AdminSectionSettingKeys.OTHER]: 34,
  [AdminSectionSettingKeys.PROFILE]: 35,
  [AdminSectionSettingKeys.INTEREST]: 36,
  [AdminSectionSettingKeys.RECOMMENDATIONS]: 37,
  // TODO поменять на реальные значения
  [AdminSectionSettingKeys.GOALS]: 1236,

  [AdminSectionSettingKeys.PROFESSION]: 38,
  [AdminSectionSettingKeys.PROFESSION_REWARD]: 39,
  [AdminSectionSettingKeys.PROFESSION_EDUCATION]: 40,
  [AdminSectionSettingKeys.PROFESSION_EVENT]: 41,
  [AdminSectionSettingKeys.PROFESSION_GIA_WORLDSKILLS]: 42,

  [AdminSectionSettingKeys.DIAGNOSIS_INDEPENDENT]: 43,
  [AdminSectionSettingKeys.DIAGNOSIS_SELF]: 44,
  [AdminSectionSettingKeys.STUDY_TRAINING]: 45,
  [AdminSectionSettingKeys.STUDY_PRACTICE]: 46,
  [AdminSectionSettingKeys.STUDY_DOCUMENTS]: 47,
  [AdminSectionSettingKeys.PROFESSION_JOB]: 48,
  [AdminSectionSettingKeys.PROFESSION_METASKILLS]: 49,
  [AdminSectionSettingKeys.TRIAL_EXAM]: 50,
  recommendation: 51,
  recommendationVuz: 52,
  professionTalentMap: 53,
  diagnosisIndependentRating: 54,
  diagnosisIndependentList: 55,
  [AdminSectionSettingKeys.PROFESSION_PROFORIENTATION]: 56,
  [AdminSectionSettingKeys.PROFESSION_PROFORIENTATION_RECOMMENDATIONS]: 57,
  [AdminSectionSettingKeys.PROFESSION_PROFORIENTATION_EVENTS]: 58,
  [AdminSectionSettingKeys.DIAGNOSIS_PERSONAL]: 59,
  // Рекомендации/Профориентация
  [AdminSectionSettingKeys.PROFESSION_PROFORIENTATION_RECOMMENDATIONS_PROFORIENTATION]: 60,
  // Рекомендации/Пробы
  [AdminSectionSettingKeys.PROFESSION_PROFORIENTATION_RECOMMENDATIONS_PROBES]: 61,
  // Рекомендации/Экскурсии к работодателю
  [AdminSectionSettingKeys.PROFESSION_PROFORIENTATION_RECOMMENDATIONS_EXCURSIONS]: 62,
  // Рекомендации/Дни открытых дверей
  [AdminSectionSettingKeys.PROFESSION_PROFORIENTATION_RECOMMENDATIONS_DODS]: 63,
  // Профориентационные мероприятия/Пробы
  [AdminSectionSettingKeys.PROFESSION_PROFORIENTATION_EVENTS_PROBES]: 64,
  // Профориентационные мероприятия/Экскурсии к работодателю
  [AdminSectionSettingKeys.PROFESSION_PROFORIENTATION_EVENTS_EXCURSIONS]: 65,
  // Профориентационные мероприятия/Дни открытых дверей
  [AdminSectionSettingKeys.PROFESSION_PROFORIENTATION_EVENTS_DODS]: 66,
};

export const AdminParentSectionSettingCodeToSectionRef: Record<number, number> = {
  [AdminSectionSettingCodes.profile]: SectionCodes.profile,
  [AdminSectionSettingCodes.study]: SectionCodes.study,
  [AdminSectionSettingCodes.science]: SectionCodes.science,
  [AdminSectionSettingCodes.sport]: SectionCodes.sport,
  [AdminSectionSettingCodes.creation]: SectionCodes.creation,
  [AdminSectionSettingCodes.culture]: SectionCodes.culture,
  [AdminSectionSettingCodes.civil]: SectionCodes.civil,
  [AdminSectionSettingCodes.profession]: SectionCodes.profession,
  [AdminSectionSettingCodes.recommendation]: SectionCodes.recommendation,
};

export const AdminSectionSettingIcons: Record<number, FunctionComponent> = {
  [AdminSectionSettingCodes.profile]: IconSectionProfile,
  [AdminSectionSettingCodes.interest]: widgetIcons.IconWidgetProfileInterests,
  [AdminSectionSettingCodes.recommendations]: widgetIcons.IconWidgetProfileRecommendations,
  [AdminSectionSettingCodes.goals]: widgetIcons.IconWidgetProfileGoals,

  [AdminSectionSettingCodes.study]: IconSectionStudy,
  [AdminSectionSettingCodes.performance]: widgetIcons.IconWidgetStudyResults,
  [AdminSectionSettingCodes.studyTraining]: widgetIcons.IconWidgetStudyEducation,
  [AdminSectionSettingCodes.studyPractice]: widgetIcons.IconWidgetStudyPractice,
  [AdminSectionSettingCodes.studyDocuments]: widgetIcons.IconWidgetStudyDocuments,
  [AdminSectionSettingCodes.gia]: widgetIcons.IconWidgetStudyGia,
  [AdminSectionSettingCodes.diagnosis]: widgetIcons.IconWidgetStudyDiagnostics,
  [AdminSectionSettingCodes.olympiad]: widgetIcons.IconWidgetStudyOlympiads,
  [AdminSectionSettingCodes.trialExam]: widgetIcons.IconWidgetStudyGia,

  [AdminSectionSettingCodes.science]: IconSectionScience,
  [AdminSectionSettingCodes.reward]: widgetIcons.IconWidgetScienceRewards,
  [AdminSectionSettingCodes.project]: widgetIcons.IconWidgetScienceProjects,
  [AdminSectionSettingCodes.employment]: widgetIcons.IconWidgetScienceEmployments,
  [AdminSectionSettingCodes.contestConference]: widgetIcons.IconWidgetScienceContests,

  [AdminSectionSettingCodes.sport]: IconSectionSport,
  [AdminSectionSettingCodes.sportReward]: widgetIcons.IconWidgetSportRewards,
  [AdminSectionSettingCodes.clubTeam]: widgetIcons.IconWidgetSportClubs,
  [AdminSectionSettingCodes.circleSection]: widgetIcons.IconWidgetSportUnits,
  [AdminSectionSettingCodes.competition]: widgetIcons.IconWidgetSportContests,
  [AdminSectionSettingCodes.expedition]: widgetIcons.IconWidgetSportHikes,

  [AdminSectionSettingCodes.creation]: IconSectionCreation,
  [AdminSectionSettingCodes.rewardAchievement]: widgetIcons.IconWidgetCreationRewards,
  [AdminSectionSettingCodes.creationCollective]: widgetIcons.IconWidgetCreationCollectives,
  [AdminSectionSettingCodes.contest]: widgetIcons.IconWidgetCreationContests,
  [AdminSectionSettingCodes.circle]: widgetIcons.IconWidgetCreationUnits,

  [AdminSectionSettingCodes.culture]: IconSectionCulture,
  [AdminSectionSettingCodes.cultureVisiting]: widgetIcons.IconWidgetCultureOfflineEvents,
  [AdminSectionSettingCodes.onlineVisiting]: widgetIcons.IconWidgetCultureOnlineEvents,

  [AdminSectionSettingCodes.civil]: IconSectionCivil,
  [AdminSectionSettingCodes.rewardStatus]: widgetIcons.IconWidgetCivilRewards,
  [AdminSectionSettingCodes.club]: widgetIcons.IconWidgetCivilClubs,
  [AdminSectionSettingCodes.contestCompetition]: widgetIcons.IconWidgetCivilContests,
  [AdminSectionSettingCodes.civilCircle]: widgetIcons.IconWidgetCivilUnits,

  [AdminSectionSettingCodes.profession]: IconSectionProfession,
  [AdminSectionSettingCodes.professionReward]: widgetIcons.IconWidgetProfessionRewards,
  [AdminSectionSettingCodes.professionJob]: widgetIcons.IconWidgetProfessionJobs,
  [AdminSectionSettingCodes.professionMetaskills]: widgetIcons.IconWidgetProfessionMetaskills,
  [AdminSectionSettingCodes.professionEducation]: widgetIcons.IconWidgetProfessionEducation,
  [AdminSectionSettingCodes.professionEvents]: widgetIcons.IconWidgetProfessionEvents,
  [AdminSectionSettingCodes.professionExamGia]: widgetIcons.IconWidgetProfessionGia,
  [AdminSectionSettingCodes.professionTalentMap]: widgetIcons.IconWidgetProfessionTalentMap,
  [AdminSectionSettingCodes.professionProforientation]: widgetIcons.IconWidgetProfessionProforientation,
  [AdminSectionSettingCodes.professionProforientationRecommendations]:
    widgetIcons.IconWidgetProfessionProforientationRecommendations,
  [AdminSectionSettingCodes.professionProforientationEvents]: widgetIcons.IconWidgetProfessionProforientationEvents,

  [AdminSectionSettingCodes.recommendation]: IconSectionRecommendation,
  [AdminSectionSettingCodes.recommendationVuz]: widgetIcons.IconWidgetRecommendationVuz,
};

type SectionSettingNameSet = {
  [key: number]: string;
};

export const AdminSectionSettingNames: Record<number, string> = {
  [AdminSectionSettingCodes.profile]: StudentSectionSettingTypeCodes.profile,
  [AdminSectionSettingCodes.study]: StudentSectionSettingTypeCodes.study,
  [AdminSectionSettingCodes.sport]: StudentSectionSettingTypeCodes.sport,
  [AdminSectionSettingCodes.science]: StudentSectionSettingTypeCodes.science,
  [AdminSectionSettingCodes.creation]: StudentSectionSettingTypeCodes.creation,
  [AdminSectionSettingCodes.culture]: StudentSectionSettingTypeCodes.culture,
  [AdminSectionSettingCodes.civil]: StudentSectionSettingTypeCodes.civil,
  [AdminSectionSettingCodes.profession]: StudentSectionSettingTypeCodes.profession,
  [AdminSectionSettingCodes.recommendation]: StudentSectionSettingTypeCodes.recommendation,
  [AdminSectionSettingCodes.interest]: StudentSectionSettingTypeCodes.profileInterest,
  [AdminSectionSettingCodes.recommendations]: StudentSectionSettingTypeCodes.profileRecommendations,
  [AdminSectionSettingCodes.goals]: 'goals',
  [AdminSectionSettingCodes.performance]: StudentSectionSettingTypeCodes.studyPerformance,
  [AdminSectionSettingCodes.studyTraining]: StudentSectionSettingTypeCodes.studyTraining,
  [AdminSectionSettingCodes.studyPractice]: StudentSectionSettingTypeCodes.studyPractice,
  [AdminSectionSettingCodes.studyDocuments]: StudentSectionSettingTypeCodes.studyDocuments,
  [AdminSectionSettingCodes.gia]: StudentSectionSettingTypeCodes.studyGia,
  [AdminSectionSettingCodes.diagnosis]: StudentSectionSettingTypeCodes.studyDiagnostic,
  [AdminSectionSettingCodes.diagnosisSelf]: StudentSectionSettingTypeCodes.diagnosticSelf,
  [AdminSectionSettingCodes.diagnosisPersonal]: StudentSectionSettingTypeCodes.diagnosticPersonal,
  [AdminSectionSettingCodes.diagnosisIndependent]: StudentSectionSettingTypeCodes.diagnosticIndependent,
  [AdminSectionSettingCodes.diagnosisIndependentRating]: StudentSectionSettingTypeCodes.diagnosticIndependentRating,
  [AdminSectionSettingCodes.diagnosisIndependentList]: StudentSectionSettingTypeCodes.diagnosticIndependentList,
  [AdminSectionSettingCodes.olympiad]: StudentSectionSettingTypeCodes.studyOlympiad,
  [AdminSectionSettingCodes.reward]: StudentSectionSettingTypeCodes.scienceReward,
  [AdminSectionSettingCodes.project]: StudentSectionSettingTypeCodes.scienceProject,
  [AdminSectionSettingCodes.employment]: StudentSectionSettingTypeCodes.scienceEmployment,
  [AdminSectionSettingCodes.contestConference]: StudentSectionSettingTypeCodes.scienceContest,
  [AdminSectionSettingCodes.sportReward]: StudentSectionSettingTypeCodes.sportReward,
  [AdminSectionSettingCodes.clubTeam]: StudentSectionSettingTypeCodes.sportClub,
  [AdminSectionSettingCodes.circleSection]: StudentSectionSettingTypeCodes.sportUnit,
  [AdminSectionSettingCodes.competition]: StudentSectionSettingTypeCodes.sportGame,
  [AdminSectionSettingCodes.expedition]: StudentSectionSettingTypeCodes.sportHike,
  [AdminSectionSettingCodes.rewardAchievement]: StudentSectionSettingTypeCodes.creationReward,
  [AdminSectionSettingCodes.creationCollective]: StudentSectionSettingTypeCodes.creationClub,
  [AdminSectionSettingCodes.contest]: StudentSectionSettingTypeCodes.creationContest,
  [AdminSectionSettingCodes.circle]: StudentSectionSettingTypeCodes.creationUnit,
  [AdminSectionSettingCodes.cultureVisiting]: StudentSectionSettingTypeCodes.cultureOfflineVisiting,
  [AdminSectionSettingCodes.onlineVisiting]: StudentSectionSettingTypeCodes.cultureOnlineVisiting,
  [AdminSectionSettingCodes.rewardStatus]: StudentSectionSettingTypeCodes.civilReward,
  [AdminSectionSettingCodes.club]: StudentSectionSettingTypeCodes.civilClub,
  [AdminSectionSettingCodes.contestCompetition]: StudentSectionSettingTypeCodes.civilContest,
  [AdminSectionSettingCodes.civilCircle]: StudentSectionSettingTypeCodes.civilUnit,
  [AdminSectionSettingCodes.professionReward]: StudentSectionSettingTypeCodes.professionReward,
  [AdminSectionSettingCodes.professionJob]: StudentSectionSettingTypeCodes.professionJob,
  [AdminSectionSettingCodes.professionMetaskills]: StudentSectionSettingTypeCodes.professionMetaskills,
  [AdminSectionSettingCodes.professionEducation]: StudentSectionSettingTypeCodes.professionEducation,
  [AdminSectionSettingCodes.professionEvents]: StudentSectionSettingTypeCodes.professionEvent,
  [AdminSectionSettingCodes.professionExamGia]: StudentSectionSettingTypeCodes.professionGiaWorldskills,
  [AdminSectionSettingCodes.professionTalentMap]: StudentSectionSettingTypeCodes.professionTalentMap,
  [AdminSectionSettingCodes.professionProforientation]: StudentSectionSettingTypeCodes.professionProforientation,
  [AdminSectionSettingCodes.professionProforientationRecommendations]:
    StudentSectionSettingTypeCodes.professionProforientationRecommendations,
  [AdminSectionSettingCodes.professionProforientationEvents]:
    StudentSectionSettingTypeCodes.professionProforientationEvents,
  [AdminSectionSettingCodes.oge]: 'oge',
  [AdminSectionSettingCodes.ege]: 'ege',
  [AdminSectionSettingCodes.gve9]: 'gve9',
  [AdminSectionSettingCodes.gve11]: 'gve11',
  [AdminSectionSettingCodes.other]: 'other',
  [AdminSectionSettingCodes.trialExam]: StudentSectionSettingTypeCodes.studyTrialExam,
  [AdminSectionSettingCodes.recommendationVuz]: StudentSectionSettingTypeCodes.recommendationVuz,
};

export const AdminSectionSettingRewardNames: SectionSettingNameSet = {
  [AdminSectionSettingCodes.reward]: 'scienceReward',
  [AdminSectionSettingCodes.rewardAchievement]: 'creationReward',
  [AdminSectionSettingCodes.rewardStatus]: 'civilReward',
  [AdminSectionSettingCodes.sportReward]: 'sportReward',
};
