/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useState, useMemo, useContext, useEffect } from 'react';
import { connect } from 'react-redux';

import { IconButton, useMediaQuery } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import {
  InterestsChecker,
  InterestsDirections,
  InterestsEntry,
  InterestsBubbles,
  InterestsSummary,
  InterestEditForm
} from '../../../components/redesignStudent';
import ConfirmCloseDialog from '../../../components/common/ConfirmCloseDialog';

import {
  IDragElements, IDragItem, IInterest, IInterestSection
} from '../../../components/redesignStudent/StudentsInterests/types';
import {
  calculateNewStep,
  mapDragElementIntoInterestSection,
  mapSelectedInterestIntoInterestEntity,
  InterestChapters,
  InterestSectionThemes
} from './utils';
import { IInterestEntity, IInterestEditFormAttributes, IInterestEditFormData, InterestActionKind, IViewVisibilitySetting } from '../../../types';
import { iconArrowLeft, iconCircularBackArrow } from '../../../icons';
import useComponentDidUpdate from '../../../hooks/useComponentDidUpdate';
import { ScrollStatusContext, InterestsEditFormContext } from '../../../context';
import { Drawer } from '../../../ui-kit';
import { IRootState } from '../../../reducers';
import { InterestHeadKindsState } from '../../../reducers/interests/interestHeadKinds';
import PaddingContainer from "../../../portfolio3/components/common/PaddingContainer";
import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';
import { visibilitySettingsSelector } from 'selectors';
import { routes, sectionRoutes } from 'const';

import './index.scss';

interface IStudentInterestsContainerProps {
  isWebView?: boolean;
  interestHeadKinds: InterestHeadKindsState;
  visibilitySettings: IViewVisibilitySetting[];
}

const StudentInterestsContainer: React.FC<IStudentInterestsContainerProps> = ({
  isWebView,
  interestHeadKinds,
  visibilitySettings,
}) => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().interestRefs });

  const isSmallDevice = useMediaQuery('(max-width: 638px)', { noSsr: true });
  const isTablet = useMediaQuery('(max-width: 1023px)', { noSsr: true });
  const [currentStep, setCurrentStep] = useState(InterestChapters.FIRST);
  const [dragElements, setDragElements] = useState<IDragElements>({
    initial: [],
    insipid: [],
    unknown: [],
    sipid: []
  });
  const [selectedInterests, setSelectedInterests] = useState<IInterest[]>([]);
  const [currentSection, setCurrentSection] = useState<IInterestSection>();
  const [lastVisitedSectionIndex, setLastVisitedSectionIndex] = useState(0);
  const [finalInterests, setFinalInterests] = useState<IInterestEntity[]>([]);
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);

  const [isEditFormDrawerOpen, setEditFormDrawerOpen] = useState(false);
  const [editFormAttributes, setEditFormAttributes] = useState<IInterestEditFormAttributes>();
  const [editFormData, setEditFormData] = useState<IInterestEditFormData>();
  const location = useLocation();
  const history = useHistory();

  const { isContainerScrolled } = useContext(ScrollStatusContext);

  const interestSections: IInterestSection[] = useMemo(() => dragElements.sipid.map(mapDragElementIntoInterestSection), [dragElements.sipid]);

  useEffect(() => {
    setFinalInterests(selectedInterests.map(mapSelectedInterestIntoInterestEntity).filter((interest) => interest.actionKinds.length > 0));
  }, [selectedInterests]);

  useEffect(() => {
    const currentSectionIndex = interestSections.findIndex((section) => section.id === currentSection?.id);
    if (currentSectionIndex > lastVisitedSectionIndex) setLastVisitedSectionIndex(currentSectionIndex);
  }, [interestSections, currentSection, lastVisitedSectionIndex]);

  useEffect(() => {
    const dragItems: IDragItem[] = interestHeadKinds.content.map((headKind) => ({
      code: headKind.code,
      value: headKind.value,
      id: headKind.code.toString(),
      theme: InterestSectionThemes[headKind.code]
    }));

    setDragElements({
      initial: dragItems,
      insipid: [],
      unknown: [],
      sipid: []
    });
  }, [interestHeadKinds]);

  useComponentDidUpdate(() => {
    const sipidIds = dragElements.sipid.map((element) => element.id);
    if (selectedInterests.length > 0) {
      setSelectedInterests((prevstate) => {
        const newstate = [...prevstate].filter((interest) => sipidIds.includes(String(interest.headId)));
        return newstate;
      });
    }
  }, [dragElements.sipid]);

  const redirectToMainPortfolio = () => {
    history?.replace(`${routes.PORTFOLIO_STUDENT.path}${sectionRoutes.PROFILE}`);
  };

  const handleExitPortfolioInterests = () => {
    if (currentStep >= InterestChapters.SECOND) setIsExitDialogOpen(true);
    else redirectToMainPortfolio();
  };

  const handleChangeStepForward = () => setCurrentStep((prevstate) => calculateNewStep(prevstate, 1));
  const handleChangeStepBack = () => setCurrentStep((prevstate) => calculateNewStep(prevstate, -1));
  const redirectToCheckerPage = () => setCurrentStep(InterestChapters.THIRD);

  const getPageTitleText = () => {
    if (isSmallDevice) {
      if (currentStep === InterestChapters.FIRST || currentStep === InterestChapters.SECOND) return 'Что тебе интересно?';
      if (currentStep === InterestChapters.THIRD) return 'Мои интересы';
      if (currentStep === InterestChapters.FOURTH) return currentSection?.name;
      if (currentStep === InterestChapters.FIFTH) return 'Мои интересы';
    }
    return 'Анкета интересов';
  };

  const getBackArrow = () => {
    if (isTablet) {
      return <img src={iconArrowLeft} alt="назад" className="interests-page__icon" />;
    }
    return <img src={iconCircularBackArrow} alt="назад" className="interests-page__icon" />;
  };

  const handleCloseEditFormDrawer = () => {
    setEditFormDrawerOpen(false);
  };

  const handleSaveEditFormChanges = (interestId: number, formData: IInterestEditFormData) => {
    setEditFormDrawerOpen(false);

    const newInterests: IInterestEntity[] = finalInterests.map((interest): IInterestEntity => {
      if (interest.id === interestId) {
        return {
          ...interest,
          actionKinds: formData.actions
            ?.filter((action) => action.isActive)
            .map((action): InterestActionKind => ({
              code: action.code,
              value: action.name
            })) || [],
          subinterests: formData.categories
            ?.filter((category) => category.isActive)
            .map((category) => ({
              code: category.code,
              value: category.name
            })),
          editFormData: formData
        };
      }

      return interest;
    });

    setFinalInterests(newInterests);
  };

  const controlBackgroundColor = isContainerScrolled && isSmallDevice && currentStep === InterestChapters.FOURTH
    ? currentSection?.theme.header.backgroundColor || ''
    : '';

  return (
    <InterestsEditFormContext.Provider
      value={{
        onOpen: () => setEditFormDrawerOpen(true),
        setEditFormAttributes,
        setEditFormData
      }}
    >
      <div
        className={clsx(
          'interests-page',
          { 'interests-page--scrollable': currentStep === InterestChapters.FOURTH }
        )}
      >
        <header className="interests-page__header" style={{ backgroundColor: controlBackgroundColor }}>
          <PaddingContainer>
            <div className="interests-page__header-content">
              <IconButton
                className="interests-page__button"
                onClick={handleExitPortfolioInterests}
                role="button"
                aria-label="Назад в портфолио"
                size="large">
                {getBackArrow()}
              </IconButton>
                <h4 className="interests-page__header-title">{getPageTitleText()}</h4>
            </div>
          </PaddingContainer>
        </header>
        {currentStep === InterestChapters.FIRST && (
          <InterestsEntry
            isWebView={isWebView}
            onChangeStepForward={handleChangeStepForward}
          />
        )}
        {currentStep === InterestChapters.SECOND && (
          <InterestsDirections
            isWebView={isWebView}
            dragElements={dragElements}
            setDragElements={setDragElements}
            onChangeStepForward={handleChangeStepForward}
          />
        )}
        {currentStep === InterestChapters.THIRD && (
          <InterestsChecker
            isWebView={isWebView}
            dragElements={dragElements}
            selectedInterests={selectedInterests}
            setDragElements={setDragElements}
            onChangeStepForward={handleChangeStepForward}
            onChangeStepBack={handleChangeStepBack}
          />
        )}
        {currentStep === InterestChapters.FOURTH && (
          <InterestsBubbles
            selectedInterests={selectedInterests}
            lastVisitedSectionIndex={lastVisitedSectionIndex}
            setSelectedInterests={setSelectedInterests}
            interestSections={interestSections}
            onChangeStepForward={handleChangeStepForward}
            onChangeStepBack={handleChangeStepBack}
            setCurrentStepSection={setCurrentSection}
          />
        )}
        {currentStep === InterestChapters.FIFTH && (
          <InterestsSummary
            isWebView={isWebView}
            interests={finalInterests}
            setSelectedInterests={setSelectedInterests}
            onChangeStepBack={handleChangeStepBack}
            redirectToCheckerPage={redirectToCheckerPage}
            redirectToMainPage={redirectToMainPortfolio}
          />
        )}
        <ConfirmCloseDialog
          isOpen={isExitDialogOpen}
          onCancel={() => setIsExitDialogOpen(false)}
          title="Ты точно хочешь выйти?"
          submitButtonTitle="Выйти"
          description="Если прервать заполнение анкеты, то выбранные интересы не будут сохранены."
          onConfirmClose={redirectToMainPortfolio}
        />
        {editFormAttributes && editFormData && (
          <Drawer
            isContainerScrollable={false}
            isFullHeight
            anchor="right"
            transitionDuration={{ appear: 300, enter: 300, exit: 250 }}
            open={isEditFormDrawerOpen}
            onClose={handleCloseEditFormDrawer}
          >
            <InterestEditForm
              formAttributes={editFormAttributes}
              formData={editFormData}
              onClose={handleCloseEditFormDrawer}
              onSave={handleSaveEditFormChanges}
            />
          </Drawer>
        )}
      </div>
    </InterestsEditFormContext.Provider>
  );
};

export default connect(
  (state: IRootState) => ({
    interestHeadKinds: state.interestHeadKinds,
    visibilitySettings: visibilitySettingsSelector(state),
  })
)(StudentInterestsContainer);

