import { useCallback } from 'react';

import { isDefined } from 'utils';

import useViewedFunctions from './useViewedFunctions';

const useViewedFunctionCode = (code: number) => {
  const { availableFunctions, setFunctionViewed } = useViewedFunctions();

  const isViewed = availableFunctions.find((func) => func.code === code)?.isViewed;

  const viewFunction = useCallback(() => {
    setFunctionViewed(code);
  }, [code, setFunctionViewed]);

  return { isReady: isDefined(isViewed) && isViewed === false, viewFunction };
};

export default useViewedFunctionCode;
