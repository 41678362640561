import { FC } from 'react';

import { professionNavigation } from 'const';
import { useAppSelector } from 'hooks';

import GenericEntityList from '../../components/GenericEntityList';
import GenericEventCard from '../../components/GenericEventCard';
import { proforientationEventSelectors } from '../../model/selectors';

const EventsExcursionsWidget: FC = () => {
  const excursions = useAppSelector(proforientationEventSelectors.excursions);

  return (
    <GenericEntityList
      containerId={professionNavigation.proforientationEvents.id}
      initialCardCount={3}
      entities={excursions}
      renderEntityCard={(event, index) => {
        const key = `${event.date}-${event.name}-${index}`;
        return (
          <GenericEventCard
            key={key}
            date={event.date}
            title={event.name}
            subtitle={event.organizer}
            industry={event.industry}
            illustration={event.illustration}
          />
        );
      }}
    />
  );
};

export default EventsExcursionsWidget;
