import { FC } from 'react';

import { professionNavigation } from 'const';
import { useAppSelector } from 'hooks';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';

import GenericEntityList from '../../components/GenericEntityList';
import GenericEventCard from '../../components/GenericEventCard';
import { proforientationEventSelectors } from '../../model/selectors';

const EventsProbesWidget: FC = () => {
  const probes = useAppSelector(proforientationEventSelectors.probes);

  const handleExpand = () => {
    emitYMEvent({ type: 'careerGuidanceOpen' });
  };

  return (
    <GenericEntityList
      containerId={professionNavigation.proforientationEvents.id}
      initialCardCount={3}
      entities={probes}
      renderEntityCard={(event, index) => {
        const key = `${event.date}-${event.name}-${index}`;
        return (
          <GenericEventCard
            key={key}
            date={event.date}
            title={event.name}
            subtitle={event.organizer}
            industry={event.industry}
            illustration={event.illustration}
          />
        );
      }}
      onExpand={handleExpand}
    />
  );
};

export default EventsProbesWidget;
