import { apiPaths } from 'const';
import { serializeQueryParams } from 'utils';

import { mainApi, proforientationApi } from './impl';
import {
  IGetProforientationRequestParams,
  IPostProforientationEventApplication,
  IProfessionProforientationData,
  IProfessionProforientationResponse,
  IProforientaitonRecommendationEvents,
  IProforientationRecommendatonApplications,
  IResponsePayload,
} from './types';

export const getProforientation = (guid: string) =>
  proforientationApi.request<
    IGetProforientationRequestParams,
    IProfessionProforientationResponse<IProfessionProforientationData>
  >('Integration/ExportResultInteractions', { guid });

export const getProforientationToken = () =>
  mainApi.get<IResponsePayload<string>>(`/external/proforientation/authentication`);

export const getProforientationRecommendationEvents = (personId: string, classLevel: number) =>
  mainApi.get<IResponsePayload<IProforientaitonRecommendationEvents>>(
    `${apiPaths.STUDENT}/${personId}/proforientation/recommendation/event${serializeQueryParams({ classLevel })}`,
  );

export const getProforientationRecommendationApplications = (personId: string) =>
  mainApi.get<IResponsePayload<IProforientationRecommendatonApplications>>(
    `${apiPaths.STUDENT}/${personId}/proforientation/recommendation/status`,
  );

export const postProforientationEventApplication = (personId: string, eventId: string) =>
  mainApi.post<IPostProforientationEventApplication, void>(
    `${apiPaths.STUDENT}/proforientation/recommendation/${personId}`,
    { eventId },
  );
