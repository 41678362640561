import { SxStyles } from 'types';

export const root: SxStyles = {
  textAlign: 'center',
};

export const image: SxStyles = (theme) => ({
  width: '100%',
  maxHeight: '360px',
  objectFit: 'cover',

  [theme.breakpoints.down('commonSm')]: {
    height: '96px',
  },
});

export const body: SxStyles = (theme) => ({
  padding: '24px',

  [theme.breakpoints.down('commonSm')]: {
    padding: '16px',
    paddingBottom: '32px',
  },
});

export const description: SxStyles = {
  marginTop: '12px',
};

export const buttons: SxStyles = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '12px',

  marginTop: '12px',

  [theme.breakpoints.down('commonSm')]: {
    '& > *': {
      width: '100%',
    },
  },

  [theme.breakpoints.down(375)]: {
    flexDirection: 'column',
  },
});
