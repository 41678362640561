import { StudentSectionSettingTypeCodes } from 'const';
import useWidgetVisibility from 'hooks/useWidgetVisibility';

const professionUseWidgetVisibilityCreator = (subsectionTypeCode: string) => {
  const useProfessionWidgetVisibility = () => {
    return useWidgetVisibility({
      sectionTypeCode: StudentSectionSettingTypeCodes.profession,
      subsectionTypeCode,
    });
  };
  return useProfessionWidgetVisibility;
};

const useProforientation = professionUseWidgetVisibilityCreator(
  StudentSectionSettingTypeCodes.professionProforientation,
);

const useProforientationRecommendations = professionUseWidgetVisibilityCreator(
  StudentSectionSettingTypeCodes.professionProforientationRecommendations,
);

const useProforientationEvents = professionUseWidgetVisibilityCreator(
  StudentSectionSettingTypeCodes.professionProforientationEvents,
);

const useReward = professionUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.professionReward);

const useJobs = professionUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.professionJob);

const useMetaSkills = professionUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.professionMetaskills);

const useAdditionalEducation = professionUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.professionEducation);

const useEvents = professionUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.professionEvent);

const useGiaWorldSkills = professionUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.professionGiaWorldskills);

export const professionWidgetVisibilityHooks = {
  useProforientation,
  useProforientationRecommendations,
  useProforientationEvents,
  useReward,
  useJobs,
  useMetaSkills,
  useAdditionalEducation,
  useEvents,
  useGiaWorldSkills,
};
