import { FC } from 'react';

import { Box } from '@mui/material';
import { useDataLoad } from 'hooks';
import { NeutralColors } from 'portfolio3/styles';
import { getDataLoadRequests } from 'utils';

import { widgetPdfIcons } from '../../../../icons';
import PdfCommonDataEntityContainer from '../common';
import EventExcursionsSection from './proforientation/EventExcursionsSection';
import EventOpenDaySection from './proforientation/EventOpenDaySection';
import EventProbesSection from './proforientation/EventProbesSection';

import '../index.scss';

const PdfProforientationEvents: FC = () => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().professionProforientation });

  const divider = (
    <Box
      sx={{
        height: '2px',
        width: 'calc(100% + 48px * 2)',
        transform: 'translateX(-48px)',
        marginBlock: '24px',
        backgroundColor: NeutralColors.night_neutrals_700,
      }}
    />
  );

  return (
    <PdfCommonDataEntityContainer
      title="Профориентационные мероприятия"
      icon={widgetPdfIcons.pdfIconProfessionProforientationEvents}
    >
      <EventProbesSection />
      {divider}
      <EventExcursionsSection />
      {divider}
      <EventOpenDaySection />
    </PdfCommonDataEntityContainer>
  );
};

export default PdfProforientationEvents;
