import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useAppSelector } from 'hooks';
import { ProfessionGenericRecommendationCard } from 'portfolio3/features/pdf/components/cards';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { proforientationModel, SpecialityList } from 'portfolio3/features/proforientation';

import SpecialityContainer from './SpecialityContainer';

const RecommendationProbesSection: FC = () => {
  const probes = useAppSelector(proforientationModel.selectors.proforientationRecommendationSelectors.probes);
  const hasData = probes.length > 0;

  return (
    <Box className="recommendation-probes pdf-break">
      <Typography variant="Headings/H5">Пробы</Typography>
      <Box marginTop="16px">
        {hasData && (
          <Stack gap="16px">
            {probes.map((event) => {
              return (
                <ProfessionGenericRecommendationCard
                  key={event.eventId}
                  date={event.formattedDate}
                  industry={event.industry}
                  name={event.name}
                  description={event.description}
                  place={event.place}
                  district={event.district}
                  bottomAddon={
                    <SpecialityContainer>
                      <SpecialityList specialities={event.specialities} />
                    </SpecialityContainer>
                  }
                />
              );
            })}
          </Stack>
        )}
        {!hasData && <PdfNoDataText />}
      </Box>
    </Box>
  );
};

export default RecommendationProbesSection;
