import { IViewedFunction } from '../api/types';
import { action, createAction, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './utils';

export const GET_VIEWED_FUNCTIONS = createRequestTypes('GET_VIEWED_FUNCTIONS');
export const getViewedFunctionsActions = {
  request: (personId: string) => createAction(GET_VIEWED_FUNCTIONS[REQUEST], { personId }),
  success: (response: IViewedFunction[]) => createAction(GET_VIEWED_FUNCTIONS[SUCCESS], response),
  failure: (error: unknown) => createAction(GET_VIEWED_FUNCTIONS[FAILURE], error),
};

export const SET_FUNCTION_VIEWED = 'SET_FUNCTION_VIEWED';
export const setFunctionViewedAction = (personId: string, functionCode: number) =>
  action(SET_FUNCTION_VIEWED, { payload: { personId, functionCode } });

export const SET_VIEWING_FUNCTION_MODE = 'SET_VIEWING_FUNCTION_MODE';
export const setViewingFunctionModeAction = (mode: boolean) => action(SET_VIEWING_FUNCTION_MODE, { payload: { mode } });

export const SET_MANUAL_FUNCTION_VIEWED = 'SET_MANUAL_FUNCTION_VIEWED';
export const setManualFunctionViewedAction = (functionCode: number, viewed: boolean) =>
  createAction(SET_MANUAL_FUNCTION_VIEWED, { functionCode, viewed });
