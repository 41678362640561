import { ICommonResponse } from 'api/types';

import { GET_PERSONAL_DIAGNOSTICS } from '../../actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from '../../actions/utils';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import genericReducers from '../genericReducer';
import { IPersonalDiagnosticLearningGroup } from './../../api/types/diagnostic';

export type PersonalDiagnosticsState = ICommonResponse<IPersonalDiagnosticLearningGroup>;

const initialState: PersonalDiagnosticsState = {
  content: [],
  loading: false,
  requestCache: 'notCached',
};

const personalDiagnostics: IReducers<PersonalDiagnosticsState> = {
  [GET_PERSONAL_DIAGNOSTICS[REQUEST]]: genericReducers().get.request,
  [GET_PERSONAL_DIAGNOSTICS[SUCCESS]]: genericReducers().get.success,
  [GET_PERSONAL_DIAGNOSTICS[FAILURE]]: genericReducers().get.failure,
  [GET_PERSONAL_DIAGNOSTICS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, personalDiagnostics);
