import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { isDefined } from 'utils';

import { industryIllustrations } from '../../assets';
import {
  getComputedIndustryTitleOfIndustryList,
  getFormattedEventDate,
  getIndustryIllustration,
  getMappedIndustriesWithSpeciality,
  getMappedSpecialities,
} from '../../utils/data';
import { IRecommendedIndustry } from '../types';

// Вкладка профориентация
const proforientation = createSelector(
  [(state: IRootState) => state.proforientation],
  (proforientation): IRecommendedIndustry[] => {
    const industruies = proforientation.content?.industry?.data
      ?.map((industryPart): IRecommendedIndustry | null => {
        const { industry, specs, colleges, industryID } = industryPart;

        if (!isDefined(industry)) return null;

        const specialities = specs?.spec?.map((spec) => {
          return {
            code: spec.code ?? '',
            name: spec.name ?? '',
          };
        });

        const collegeNames = colleges?.college?.map((college) => college.name ?? '');

        return {
          name: industry,
          specialities: specialities ?? [],
          collegeNames: collegeNames ?? [],
          illustration: getIndustryIllustration(Number(industryID)) ?? industryIllustrations.проф_тестирование,
        };
      })
      .filter(isDefined);

    return industruies ?? [];
  },
);

// Вкладка пробы
const probes = createSelector([(state: IRootState) => state.proforientationRecommendationEvents], (events) => {
  const probes = events.content?.recommendationsProfTests ?? [];

  const mappedProbes = probes.map((probe) => {
    const {
      eventId,
      name,
      description,
      profTestDate,
      placeProfTest,
      startTime,
      endTime,
      district,
      industryName,
      industryCode,
      specialities,
    } = probe;

    const mappedSpecialities = getMappedSpecialities(specialities);

    return {
      eventId,
      name,
      description,
      industry: industryName,
      place: placeProfTest,
      district,
      specialities: mappedSpecialities,
      illustration: getIndustryIllustration(industryCode) ?? industryIllustrations.проф_тестирование,
      formattedDate: getFormattedEventDate(profTestDate, startTime, endTime),
    };
  });

  return mappedProbes;
});

// Вкладка экскурсии к работодателю
const excursions = createSelector([(state: IRootState) => state.proforientationRecommendationEvents], (events) => {
  const excursions = events.content?.recommendationsExcursions ?? [];

  const mappedExcursions = excursions.map((excursion) => {
    const {
      eventId,
      name,
      description,
      excursionDate,
      placeExcursion,
      startTime,
      endTime,
      district,
      industryName,
      industryCode,
    } = excursion;

    return {
      eventId,
      name,
      description,
      industry: industryName,
      place: placeExcursion,
      district,
      illustration: getIndustryIllustration(industryCode) ?? industryIllustrations.экскурсии_к_работодателю,
      formattedDate: getFormattedEventDate(excursionDate, startTime, endTime),
    };
  });

  return mappedExcursions;
});

// Вкладка день открытых дверей
const dods = createSelector([(state: IRootState) => state.proforientationRecommendationEvents], (events) => {
  const dods = events.content?.recommendationsDod ?? [];

  const mappedDods = dods.map((dod) => {
    const { eventId, name, description, dodDate, addressDod, startTime, endTime, url, industryAndSpecs } = dod;

    const industryList = getMappedIndustriesWithSpeciality(industryAndSpecs);
    const industryTitle = getComputedIndustryTitleOfIndustryList(industryList);

    return {
      eventId,
      url,
      name,
      description,
      industry: industryTitle,
      place: addressDod,
      illustration: industryIllustrations.день_открытых_дверей,
      formattedDate: getFormattedEventDate(dodDate, startTime, endTime),
      industryList,
    };
  });

  return mappedDods;
});

export const proforientationRecommendationSelectors = {
  proforientation,
  probes,
  excursions,
  dods,
};
