import { FC } from 'react';

import { Box, Stack } from '@mui/material';
import { IDictionaryItem } from 'api/types';

import FiltersContainer from './FiltersContainer';
import { CollegeFilter, DistrictFilter, IndustryFilter, SpecialityFilter } from './specificFilters';

interface IRecommendationProbesFiltersProps {
  industryOptions: IDictionaryItem[];
  specialityOptions: IDictionaryItem[];
  collegeOptions: IDictionaryItem[];
  districtOptions: IDictionaryItem[];
}

const RecommendationProbesFilters: FC<IRecommendationProbesFiltersProps> = ({
  industryOptions,
  specialityOptions,
  collegeOptions,
  districtOptions,
}) => {
  const industryControl = <IndustryFilter section="probes" options={industryOptions} />;
  const specialityControl = <SpecialityFilter section="probes" options={specialityOptions} />;
  const collegeControl = <CollegeFilter section="probes" options={collegeOptions} />;
  const districtControl = <DistrictFilter section="probes" options={districtOptions} />;

  return (
    <FiltersContainer sectionKey="probes" controlKeys={['industry', 'speciality', 'college', 'district']}>
      <Stack gap="12px">
        <Box className="row" sx={{ display: 'flex', gap: '12px' }}>
          {industryControl}
          {specialityControl}
        </Box>
        <Box className="row" sx={{ display: 'flex', gap: '12px' }}>
          {collegeControl}
          {districtControl}
        </Box>
      </Stack>
    </FiltersContainer>
  );
};

export default RecommendationProbesFilters;
