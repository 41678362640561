import { FC } from 'react';

import { Box, DrawerProps, Stack } from '@mui/material';
import { MobileDrawerHeader } from 'portfolio3/components/drawers';
import { NeutralColors } from 'portfolio3/styles';
import { Button } from 'portfolio3/ui-kit/button';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';

interface IMobileActionsDrawerProps {
  title: string;
  cancelText: string;
  acceptText: string;
  onCancel: VoidFunction;
  onAccept: VoidFunction;
}

const MobileAcceptDrawer: FC<IMobileActionsDrawerProps & IDrawerBase & DrawerProps> = ({
  title,
  cancelText,
  acceptText,
  onCancel,
  onAccept,
  children,
  ...drawerProps
}) => {
  const header: IDrawerBase['header'] = (crossButton) => (
    <MobileDrawerHeader
      title={title}
      overrideTitleColor={NeutralColors.light_neutrals_900}
      overrideBackgroundColor={NeutralColors.light_neutrals_0}
      crossButton={crossButton}
    />
  );

  const footer = (
    <Stack direction="row" gap="16px" sx={{ paddingBlock: '16px 20px', paddingInline: '12px' }}>
      <Button sx={{ flex: 1 }} variant="secondary" onClick={onCancel}>
        {cancelText}
      </Button>
      <Button sx={{ flex: 1 }} variant="primary" onClick={onAccept}>
        {acceptText}
      </Button>
    </Stack>
  );

  return (
    <Drawer
      {...drawerProps}
      swipeable
      swipeableProps={{
        onClose: (event) => drawerProps.onClose?.(event, 'escapeKeyDown'),
      }}
      anchor="bottom"
      isMobile
      header={header}
      footer={footer}
    >
      <Box sx={{ paddingInline: '12px' }}>{children}</Box>
    </Drawer>
  );
};

export default MobileAcceptDrawer;
