import { FC } from 'react';

import { professionNavigation } from 'const';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';

import { IRecommendedIndustry } from '../../model/types';
import GenericEntityList from '../GenericEntityList';
import RecommendationIndustryCard from '../RecommendationIndustryCard';

interface IRecommendationCardListProps {
  recommendations: IRecommendedIndustry[];
}

const RecommendationIndustryCardList: FC<IRecommendationCardListProps> = ({ recommendations }) => {
  const handleExpand = () => {
    emitYMEvent({ type: 'recommendationsAllCareerGuidance' });
  };

  return (
    <GenericEntityList
      containerId={professionNavigation.recommendations.id}
      initialCardCount={1}
      entities={recommendations}
      renderEntityCard={(recommendation) => (
        <RecommendationIndustryCard key={recommendation.name} recommendation={recommendation} />
      )}
      onExpand={handleExpand}
    />
  );
};

export default RecommendationIndustryCardList;
