import { createAction, createRequestTypes, FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';

export const GET_PROFORIENTATION = createRequestTypes('GET_PROFORIENTATION');
export const getProforientationActions = {
  request: (guid: string) => createAction(GET_PROFORIENTATION[REQUEST], { guid }),
  success: (response: unknown) => createAction(GET_PROFORIENTATION[SUCCESS], response),
  failure: (error: unknown) => createAction(GET_PROFORIENTATION[FAILURE], error),
  invalidate: () => createAction(GET_PROFORIENTATION[INVALIDATE], null),
};

export const GET_PROFORIENTATION_RECOMMENDATION_EVENTS = createRequestTypes(
  'GET_PROFORIENTATION_RECOMMENDATION_EVENTS',
);
export const getProforientationRecommendationEventsActions = {
  request: (personId: string, classLevel: number) =>
    createAction(GET_PROFORIENTATION_RECOMMENDATION_EVENTS[REQUEST], { personId, classLevel }),
  success: (response: unknown) => createAction(GET_PROFORIENTATION_RECOMMENDATION_EVENTS[SUCCESS], response),
  failure: (error: unknown) => createAction(GET_PROFORIENTATION_RECOMMENDATION_EVENTS[FAILURE], error),
  invalidate: () => createAction(GET_PROFORIENTATION_RECOMMENDATION_EVENTS[INVALIDATE], null),
};

export const GET_PROFORIENTATION_RECOMMENDATION_APPLICATIONS = createRequestTypes(
  'GET_PROFORIENTATION_RECOMMENDATION_APPLICATIONS',
);
export const getProforientationRecommendationApplicationsActions = {
  request: (personId: string) => createAction(GET_PROFORIENTATION_RECOMMENDATION_APPLICATIONS[REQUEST], { personId }),
  success: (response: unknown) => createAction(GET_PROFORIENTATION_RECOMMENDATION_APPLICATIONS[SUCCESS], response),
  failure: (error: unknown) => createAction(GET_PROFORIENTATION_RECOMMENDATION_APPLICATIONS[FAILURE], error),
  invalidate: () => createAction(GET_PROFORIENTATION_RECOMMENDATION_APPLICATIONS[INVALIDATE], null),
};

export const SET_PROFORIENTATION_RECOMMENDATION_FILTER = 'SET_PROFORIENTATION_RECOMMENDATION_FILTER';
export const proforientationRecommendationFiltersActions = {
  setFilter: (key: string, value: unknown) => createAction(SET_PROFORIENTATION_RECOMMENDATION_FILTER, { key, value }),
};

export const SET_PROFORIENTATION_PARTICIPATION_EVENT_ID = 'SET_PROFORIENTATION_PARTICIPATION_EVENT_ID';
export const proforientationParticipationDialogActions = {
  setParticipationEventId: (eventId: string | null) =>
    createAction(SET_PROFORIENTATION_PARTICIPATION_EVENT_ID, { eventId }),
};

export const POST_PROFORIENTATION_EVENT_APPLICATION = createRequestTypes('POST_PROFORIENTATION_EVENT_APPLICATION');
export const postProforientationEventApplicationActions = {
  request: (personId: string, eventId: string) =>
    createAction(POST_PROFORIENTATION_EVENT_APPLICATION[REQUEST], { personId, eventId }),
  success: (response: unknown) => createAction(POST_PROFORIENTATION_EVENT_APPLICATION[SUCCESS], response),
  failure: (error: unknown) => createAction(POST_PROFORIENTATION_EVENT_APPLICATION[FAILURE], error),
};
