import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { professionNavigation } from 'const';
import { useAppSelector, useUserFunctionality } from 'hooks';
import { Button } from 'portfolio3/ui-kit/button';

import ExpandableContainer from '../../components/ExpandableContainer';
import { RecommendationOpenDayFilters } from '../../components/filters';
import GenericEntityList from '../../components/GenericEntityList';
import GenericRecommendationCard from '../../components/GenericRecommendationCard';
import NoDataPlug from '../../components/NoDataPlug';
import { IndustrySpecialityList } from '../../components/specialities';
import { proforientationRecommendationSelectors } from '../../model/selectors';
import { getFilterOptions, getFilterStoreKey, mapSpecialityOptionValue } from '../../utils/filters';

const RecommendationOpenDayWidget: FC = () => {
  const dods = useAppSelector(proforientationRecommendationSelectors.dods);
  const filters = useAppSelector((state) => state.proforientationRecommendationFilters);

  const { isStudent } = useUserFunctionality();

  const industries = dods.flatMap((event) => {
    return event.industryList.map((industryObject) => industryObject.industry);
  });
  const industryOptions = getFilterOptions(industries);

  const specialities = dods.flatMap((event) => {
    const specialities = event.industryList.flatMap((industry) => industry.specialities.map(mapSpecialityOptionValue));
    return specialities;
  });
  const specialityOptions = getFilterOptions(specialities);

  const colleges = dods.map((event) => event.name);
  const collegeOptions = getFilterOptions(colleges);

  const filteredDods = dods.filter((event) => {
    const { industryList, place } = event;
    const industries = industryList.flatMap((industry) => industry.industry);
    const mappedSpecialities = industryList.flatMap((industry) => industry.specialities).map(mapSpecialityOptionValue);

    const filterIndustries = (filters[getFilterStoreKey('dods', 'industry', 'applied')] as string | undefined) ?? null;
    const filterSpecialities =
      (filters[getFilterStoreKey('dods', 'speciality', 'applied')] as string | undefined) ?? null;
    const filterColleges = (filters[getFilterStoreKey('dods', 'college', 'applied')] as string | undefined) ?? null;

    const filterIndustryCodes = filterIndustries?.split(',').map(Number) ?? [];
    const filterSpecialityCodes = filterSpecialities?.split(',').map(Number) ?? [];
    const filterCollegeCodes = filterColleges?.split(',').map(Number) ?? [];

    const isContainIndustry = filterIndustryCodes.some((code) => {
      const option = industryOptions.find((option) => option.code === code);
      return industries.some((industry) => option?.value === industry);
    });
    const isContainSpeciality = filterSpecialityCodes.some((code) => {
      const option = specialityOptions.find((option) => option.code === code);
      return mappedSpecialities.some((speciality) => option?.value === speciality);
    });
    const isContainCollege = filterCollegeCodes.some((code) => {
      const option = collegeOptions.find((option) => option.code === code);
      return option?.value === place;
    });

    return (
      (isContainIndustry || filterIndustryCodes.length === 0) &&
      (isContainSpeciality || filterSpecialityCodes.length === 0) &&
      (isContainCollege || filterCollegeCodes.length === 0)
    );
  });

  const filtersElement = (
    <RecommendationOpenDayFilters
      industryOptions={industryOptions}
      specialityOptions={specialityOptions}
      collegeOptions={collegeOptions}
    />
  );

  const listElement = (
    <GenericEntityList
      containerId={`${professionNavigation.recommendations.id}/dods`}
      entities={filteredDods}
      initialCardCount={3}
      renderEntityCard={(entity) => {
        const linkElement = entity.url && (
          <Box
            component={Link}
            sx={{ textDecoration: 'none' }}
            target="_blank"
            to={{
              pathname: entity.url,
            }}
          >
            <Button>Зарегистрироваться</Button>
          </Box>
        );

        const specialityDisclosureTitle = entity.industryList.length > 1 ? 'Отрасли и специальности' : 'Специальности';

        return (
          <GenericRecommendationCard
            key={entity.eventId}
            date={entity.formattedDate}
            industry={entity.industry}
            name={entity.name}
            description={entity.description}
            place={entity.place}
            illustration={entity.illustration}
            buttonElement={isStudent && linkElement}
            bottomAddon={
              <ExpandableContainer title={specialityDisclosureTitle}>
                <IndustrySpecialityList industries={entity.industryList} />
              </ExpandableContainer>
            }
          />
        );
      }}
    />
  );

  const emptyFilterResultsElement = (
    <Box sx={{ display: 'grid', placeItems: 'center', paddingBlock: '26px' }}>
      <NoDataPlug title="Ничего не найдено" description="Попробуйте изменить поисковый запрос" />
    </Box>
  );

  if (dods.length === 0) {
    return (
      <Box sx={{ display: 'grid', placeItems: 'center' }}>
        <NoDataPlug
          title="Пройдены все доступные мероприятия"
          description="Пройденные мероприятия можно посмотреть ниже в блоке «Профориентационные мероприятия»"
        />
      </Box>
    );
  }

  return (
    <>
      <Typography variant="Paragraph LG/Regular">Рекомендации обновляются ежедневно</Typography>

      <Box marginTop="20px">{filtersElement}</Box>

      {filteredDods.length > 0 ? <Box marginTop="20px">{listElement}</Box> : emptyFilterResultsElement}
    </>
  );
};

export default RecommendationOpenDayWidget;
