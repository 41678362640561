/* eslint-disable @typescript-eslint/no-explicit-any */

import { RequestCacheStatus } from 'api/types';

import { Action } from '../actions/utils';
import { RequestState } from '.';

const combineReducers = (reducers: any) => (state: any, action: any) =>
  reducers.reduce((acc: any, fn: any) => fn(acc, action), state);

const setGetRequestState = (requestState: RequestState) => (state: any) => ({
  ...state,
  more: false,
  loading: requestState === 'request',
  cleared: false,
});

const setCacheState = (requestCache: RequestCacheStatus, setState?: unknown, setLoading?: boolean) => (state: any) => ({
  ...(setState !== undefined ? setState : state),
  loading: setLoading !== undefined ? setLoading : state.loading,
  requestCache,
});

const setCreateUpdateRequestState = (createUpdateRequestState: RequestState) => (state: any) => ({
  ...state,
  createUpdateRequestState,
});

const setDeleteRequestState = (deleteRequestState: RequestState) => (state: any) => ({
  ...state,
  loading: deleteRequestState === 'request',
});

const putPayload = (state: any, action: Action<any>) => {
  const commonState = {
    loading: false,
    requestState: 'success',
  };

  if (action.payload?.data?.content) {
    return {
      ...state,
      ...action.payload.data,
      content: action.payload.data.content,
      more: action.payload.data.totalPages - 1 > action.payload.data.number,
      ...commonState,
    };
  }

  if (typeof action.payload?.data !== 'undefined') {
    return {
      ...state,
      content: action.payload.data,
      ...commonState,
    };
  }

  return {
    ...state,
    content: action.payload,
    ...commonState,
  };
};

const putLoading = (value: boolean) => {
  return (state: any) => {
    return {
      ...state,
      loading: value,
    };
  };
};

const clearError = (state: any) => {
  return {
    ...state,
    error: undefined,
  };
};

const putError = (state: any, action: Action<any>) => {
  return {
    ...state,
    error: action.payload,
  };
};

const genericReducers = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  incomeReducers: Function | Function[] = [],
) => {
  const reducers = Array.isArray(incomeReducers) ? incomeReducers : [incomeReducers];

  return {
    get: {
      request: combineReducers([setGetRequestState('request'), clearError, setCacheState('requested'), ...reducers]),
      success: combineReducers([
        setGetRequestState('success'),
        clearError,
        putPayload,
        setCacheState('cached'),
        ...reducers,
      ]),
      failure: combineReducers([setGetRequestState('failure'), putError, setCacheState('failed'), ...reducers]),
      invalidate: (setState: unknown) =>
        combineReducers([setCacheState('invalidated', setState, false), clearError, ...reducers]),
    },

    createUpdate: {
      request: combineReducers([setCreateUpdateRequestState('request'), clearError, putLoading(true), ...reducers]),
      success: combineReducers([
        setCreateUpdateRequestState('success'),
        clearError,
        putPayload,
        putLoading(false),
        ...reducers,
      ]),
      failure: combineReducers([setCreateUpdateRequestState('failure'), putError, putLoading(false), ...reducers]),
    },

    delete: {
      request: combineReducers([setDeleteRequestState('request'), clearError, ...reducers]),
      success: combineReducers([setDeleteRequestState('success'), clearError, putPayload, ...reducers]),
      failure: combineReducers([setDeleteRequestState('failure'), putError, ...reducers]),
    },
  };
};

export default genericReducers;
