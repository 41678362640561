import { IAdminSettingSection, ISectionsVisibility, IStudentVisibilitySetting } from '../../api/types';
import { AdminSectionSettingNames } from '../../const';
import { IViewVisibilitySetting } from '../../types';
import { findTargetVisibilitySetting } from '../../utils/localVisibilitySettingsService';

/**
 * Преобразует список настроек доступа по ссылке в унифицированный массив настроек
 * в соответствии с настройками администратора
 * @param defaultSettings полный список настроек портфолио
 * @param linkDisplaySettings настройки доступа по ссылке
 * @param adminSettings настройки администратора
 * @returns измененные настройки видимости
 */
export const getMappedLinkViewSettings = (
  defaultSettings: IViewVisibilitySetting[],
  linkDisplaySettings: ISectionsVisibility,
  adminSettings: IAdminSettingSection[],
): IViewVisibilitySetting[] => {
  return defaultSettings.map((defaultSetting): IViewVisibilitySetting => {
    const adminVisibilitySetting = adminSettings.find((adminSetting) => {
      return AdminSectionSettingNames[adminSetting.sectionId] === defaultSetting.sectionTypeCode;
    });

    const isLinkVisible = defaultSetting.linkSettingCode ? linkDisplaySettings[defaultSetting.linkSettingCode] : false;
    const isAdminVisible = adminVisibilitySetting?.isVisible ?? true;
    const computedVisibility = isAdminVisible && isLinkVisible;

    return {
      ...defaultSetting,
      isVisible: isLinkVisible,
      isAdminVisible,
      computedVisibility,
      subSections:
        defaultSetting.subSections && defaultSetting.subSections?.length > 0
          ? getMappedLinkViewSettings(defaultSetting.subSections, linkDisplaySettings, adminSettings)
          : undefined,
    };
  });
};

/**
 * Преобразует настройки видимости пользователя в унифицированный массив настроек
 * в соответствии с настройками администратора
 * @param defaultSettings полный список настроек портфолио
 * @param settings настройки видимости пользователя
 * @param adminSettings настройки администратора
 * @param adminParentSectionId ид родительского раздела в настройках администратора
 * @returns измененные настройки видимости
 */
export const getMappedViewSettings = (
  defaultSettings: IStudentVisibilitySetting[],
  settings: IStudentVisibilitySetting[],
  adminSettings: IAdminSettingSection[],
  adminParentSectionId: number | null = null,
): IViewVisibilitySetting[] => {
  return defaultSettings.map((defaultSetting): IViewVisibilitySetting => {
    const personalVisibilitySetting = findTargetVisibilitySetting(settings, [defaultSetting.sectionTypeCode]);

    const adminVisibilitySetting = adminSettings
      // найти все админские настройки у которых такой же parent
      .filter((adminSetting) => {
        const currentSettingParentSectionId = adminSetting.parentSectionId ?? null;
        const parentMatch = currentSettingParentSectionId === adminParentSectionId;
        return parentMatch;
      })
      // найти настройку по имени
      .find((adminSetting) => {
        return AdminSectionSettingNames[adminSetting.sectionId] === defaultSetting.sectionTypeCode;
      });

    // ! при отсутствии записи в админских настройках разрешить отображение
    const isAdminVisible = adminVisibilitySetting?.isVisible ?? true;
    // ! персональные настройки должны приходить в полном виде, при отсутствии записи не отображать раздел
    const isPersonalVisible = personalVisibilitySetting?.isVisible ?? false;
    const computedVisibility = personalVisibilitySetting?.computedVisibility ?? (isAdminVisible && isPersonalVisible);

    return {
      ...defaultSetting,
      isVisible: isPersonalVisible,
      isAdminVisible,
      computedVisibility,
      subSections:
        defaultSetting.subSections && defaultSetting.subSections.length > 0
          ? getMappedViewSettings(
              defaultSetting.subSections,
              personalVisibilitySetting?.subSections || [],
              adminSettings,
              adminVisibilitySetting?.sectionId,
            )
          : undefined,
    };
  });
};
