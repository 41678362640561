import { FC } from 'react';

import { Doughnut } from '@reactchartjs/react-chart.js';

interface IPercentDoughnutProps {
  percent: number;
  color: string;
  thickness?: number;
}

const PercentDoughnut: FC<IPercentDoughnutProps> = ({ percent, color, thickness }) => {
  const singleData = () => ({
    datasets: [
      {
        label: '',
        data: [percent, 100 - percent],
        backgroundColor: [color, '#F4F3F8'],
        hoverBackgroundColor: [color, '#F4F3F8'],
        borderWidth: 0,
        weight: 0.3,
      },
    ],
  });

  const options = {
    responsice: true,
    maintainAspectRatio: false,
    cutoutPercentage: thickness ?? 90,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      datalabels: {
        display: false,
        color: '#7B819B',
      },
    },
  };

  return <Doughnut data={singleData} options={options} type="doughnut" />;
};

export default PercentDoughnut;
