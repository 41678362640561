import { IRecommendationIndustryWithSpecialities } from 'api/types';
import { format } from 'date-fns';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { isDefined } from 'utils';

import { industryIllustrations } from '../../assets';
import { IndustryCodeIllustration } from '../../const/industryIllustrationMapping';
import { getComputedIndustryTitleOfIndustryList, getMappedIndustriesWithSpeciality } from '../../utils/data';

const probes = createSelector([(state: IRootState) => state.proforientation], (proforientation) => {
  const probes = proforientation.content?.events?.history?.data?.masterstvo ?? [];

  const mappedProbes = probes
    .filter((event) => event.visited)
    .map((event) => {
      const { date_event, name_event, organizer_event, industry, industryID } = event;

      if (!date_event) return null;

      const illustrationId = industryID ?? -1;

      return {
        date: format(new Date(date_event), 'dd.MM.yy'),
        name: name_event ?? '',
        organizer: organizer_event ?? undefined,
        industry: industry ?? undefined,
        illustration: IndustryCodeIllustration[illustrationId] ?? industryIllustrations.проф_тестирование,
      };
    });

  return mappedProbes.filter(isDefined);
});

const excursions = createSelector([(state: IRootState) => state.proforientation], (proforientation) => {
  const excursions = proforientation.content?.events?.history?.data?.partners ?? [];

  const mappedExcursions = excursions
    .filter((event) => event.visited)
    .map((event) => {
      const { date_event, name_event, organizer_event, industry, industryID } = event;

      if (!date_event) return null;

      const illustrationId = industryID ?? -1;

      return {
        date: format(new Date(date_event), 'dd.MM.yy'),
        name: name_event ?? '',
        organizer: organizer_event ?? undefined,
        industry: industry ?? undefined,
        illustration: IndustryCodeIllustration[illustrationId] ?? industryIllustrations.экскурсии_к_работодателю,
      };
    });

  return mappedExcursions.filter(isDefined);
});

const dods = createSelector([(state: IRootState) => state.proforientation], (proforientation) => {
  const dods = proforientation.content?.events?.history?.data?.dod ?? [];

  const mappedDods = dods
    .filter((event) => event.visited)
    .map((event) => {
      const { date_event, organizer_event, spec_list = {} } = event;

      if (!date_event) return null;

      const industryEntries = Object.entries(spec_list);
      const mappedIndustries = industryEntries
        // Достаем название отрасли из объекта специальности
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(([industryNameKey, specialities]): IRecommendationIndustryWithSpecialities | null => {
          const industryName = specialities?.[0]?.industry;
          const industryCode = specialities?.[0]?.industryID;

          if (!industryName || !industryCode) {
            return null;
          }

          return {
            industryCode: String(industryCode),
            industryName,
            specialities: specialities
              .map((speciality) => {
                if (!speciality.code || !speciality.name) {
                  return null;
                }

                return {
                  specCode: speciality.code,
                  specName: speciality.name,
                };
              })
              .filter(isDefined),
          };
        })
        .filter(isDefined);

      const industryList = getMappedIndustriesWithSpeciality(mappedIndustries);
      const industryTitle = getComputedIndustryTitleOfIndustryList(industryList);

      const illustrationId = mappedIndustries.length > 1 ? -1 : Number(mappedIndustries?.[0].industryCode);

      return {
        date: format(new Date(date_event), 'dd.MM.yy'),
        name: organizer_event ?? '',
        industry: industryTitle,
        illustration: IndustryCodeIllustration[illustrationId] ?? industryIllustrations.день_открытых_дверей,
        industryList,
      };
    });

  return mappedDods.filter(isDefined);
});

export const proforientationEventSelectors = {
  probes,
  excursions,
  dods,
};
