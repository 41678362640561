import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponseObject, IProforientationRecommendatonApplications } from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

import { GET_PROFORIENTATION_RECOMMENDATION_APPLICATIONS } from '../actions';

export type ProforientationRecommendationApplicationsState =
  ICommonResponseObject<IProforientationRecommendatonApplications | null>;

const initialState: ProforientationRecommendationApplicationsState = {
  content: null,
  loading: false,
};

const proforientationRecommendationApplications: IReducers<ProforientationRecommendationApplicationsState> = {
  [GET_PROFORIENTATION_RECOMMENDATION_APPLICATIONS[REQUEST]]: genericReducers().get.request,
  [GET_PROFORIENTATION_RECOMMENDATION_APPLICATIONS[SUCCESS]]: genericReducers().get.success,
  [GET_PROFORIENTATION_RECOMMENDATION_APPLICATIONS[FAILURE]]: genericReducers().get.failure,
  [GET_PROFORIENTATION_RECOMMENDATION_APPLICATIONS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, proforientationRecommendationApplications);
