import { FC, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import { Attributes } from 'portfolio3/ui-kit';

import * as styles from './styles';

interface IProfessionProforientationGenericEventCardProps {
  date: string;
  industry?: string;
  title: string;
  subtitle?: string;
  bottomAddon?: ReactNode;
}

const ProfessionProforientationGenericEventCard: FC<IProfessionProforientationGenericEventCardProps> = ({
  date,
  industry,
  title,
  subtitle,
  bottomAddon,
}) => {
  const attributes = [date, industry].filter(Boolean).map((value) => {
    return (
      <Typography variant="Paragraph MD/Regular" key={value}>
        {value}
      </Typography>
    );
  });

  return (
    <Box className="proforientation-test-card pdf-keep" sx={styles.root}>
      <Box sx={styles.rootMain}>
        <Attributes attributes={attributes} />
        <Typography variant="Paragraph LG/Semi Bold">{title}</Typography>
        {subtitle && <Typography variant="Paragraph MD/Regular">{subtitle}</Typography>}
      </Box>
      {bottomAddon}
    </Box>
  );
};

export default ProfessionProforientationGenericEventCard;
