import { FC } from 'react';

import { IPersonalDiagnostic } from '../../../../../api/types';
import DiagnosticCardWrapper from '../../DiagnosticCardWrapper';

import './index.scss';

interface IPersonalDiagnosticListProps {
  diagnostics: IPersonalDiagnostic[];
}

const PersonalDiagnosticList: FC<IPersonalDiagnosticListProps> = ({ diagnostics }) => {
  const year = (diagnostics && new Date(diagnostics[0].eventDate).getFullYear()) || null;

  return (
    <div className="pdf-personal-diagnostic-list">
      <header className="pdf-personal-diagnostic-list__header">
        <h3 className="pdf-personal-diagnostic-list__title">Пройденные индивидуальные диагностики</h3>
      </header>
      <div className="pdf-personal-diagnostic-list__items">
        {diagnostics.map((diagnostic, index) => (
          /* показывает год только для первой сущности из списка */
          <DiagnosticCardWrapper
            key={diagnostic.recordId}
            diagnostic={diagnostic}
            year={index === 0 ? year || undefined : undefined}
          />
        ))}
      </div>
    </div>
  );
};

export default PersonalDiagnosticList;
