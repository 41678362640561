import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Typography, useMediaQuery } from '@mui/material';
import { useAppSelector } from 'hooks';
import { commonTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';
import PopUp from 'portfolio3/ui-kit/modals/PopUp';
import { isDefined } from 'utils';

import {
  postProforientationEventApplicationActions,
  proforientationParticipationDialogActions,
} from '../../model/actions';
import MobileAcceptDrawer from './MobileAcceptDrawer';

const dialogTitle = 'Подтверждение участия';
const dialogText =
  // eslint-disable-next-line max-len
  'После нажатия на кнопку «Хочу пойти» заявка на участие будет отправлена в школу. Когда школа зарегистрирует тебя на мероприятие, оно появится в расписании. Обычно это занимает 10-14 дней.';

const cancelText = 'Отмена';
const acceptText = 'Хочу пойти';

const AcceptParticipationDialog: FC = () => {
  const dispatch = useDispatch();
  const { meshId } = useAppSelector((state) => state.currentStudent);
  const { eventId } = useAppSelector((state) => state.proforientationParticipationDialog);

  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const isOpen = isDefined(eventId);

  const handleClose = () => {
    dispatch(proforientationParticipationDialogActions.setParticipationEventId(null));
  };

  const handleAccept = () => {
    if (isDefined(eventId)) {
      dispatch(postProforientationEventApplicationActions.request(meshId, eventId));
    }

    handleClose();
  };

  const cancelButtonElement = (
    <Button variant="secondary" onClick={handleClose}>
      {cancelText}
    </Button>
  );

  const acceptButtonElement = <Button onClick={handleAccept}>{acceptText}</Button>;

  if (isSmallScreen) {
    return (
      <MobileAcceptDrawer
        open={isOpen}
        onClose={handleClose}
        title={dialogTitle}
        cancelText={cancelText}
        acceptText={acceptText}
        onCancel={handleClose}
        onAccept={handleAccept}
      >
        <Typography textAlign="center">{dialogText}</Typography>
      </MobileAcceptDrawer>
    );
  }

  return (
    <PopUp
      open={isOpen}
      onClose={handleClose}
      title={dialogTitle}
      body={<Typography variant="Paragraph LG/Regular">{dialogText}</Typography>}
      rightAdditionalButton={cancelButtonElement}
      rightMainButton={acceptButtonElement}
    />
  );
};

export default AcceptParticipationDialog;
