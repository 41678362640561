import { EventBus } from 'packages/eventBus';

import { BuildConfigEventMap, IBuildConfig } from './types';

/**
 * Класс для работы с конфигурационным файлом сборки
 */
class BuildConfig {
  private _isLoading = false;
  private _config: IBuildConfig | null = null;
  private _bus = new EventBus<BuildConfigEventMap>();

  get config() {
    return this._config;
  }

  /**
   * Загружает конфигурационный файл
   * @returns объект с конфигурационными данными или null
   */
  async loadConfig(): Promise<IBuildConfig | null> {
    if (this._isLoading) return null;

    this._isLoading = true;

    try {
      const response = await fetch('/portfolio/config.json');
      const data: IBuildConfig = await response.json();

      this._config = data;
      this._bus.broadcast('onLoaded', this._config);

      return this._config;
    } catch {
      console.error('Не удалось получить конфигурационный файл');

      this._config = null;
      return this._config;
    } finally {
      this._isLoading = false;
    }
  }

  /**
   * Подписывается на событие загрузки конфига
   * @param cb
   * @returns функция отписки
   */
  onLoad(cb: BuildConfigEventMap['onLoaded']) {
    return this._bus.subscribe('onLoaded', cb, true);
  }
}

export const buildConfig = new BuildConfig();
