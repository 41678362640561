import { FC, PropsWithChildren } from 'react';

import { Box, Collapse, Typography } from '@mui/material';
import { useDisclosure } from 'hooks';
import { IconChevronSmallDown, IconChevronSmallUp } from 'icons';
import { UnstyledButton } from 'portfolio3/ui-kit';

import * as styles from './styles';

interface IExpandableSpecialityListProps {
  title: string;
}

const ExpandableContainer: FC<PropsWithChildren<IExpandableSpecialityListProps>> = ({ title, children }) => {
  const { isOpen, onToggle } = useDisclosure();

  const arrowIconElement = isOpen ? <IconChevronSmallUp /> : <IconChevronSmallDown />;

  return (
    <Box className="expandable-speciality-list">
      <Collapse in={isOpen}>
        <Box sx={styles.container}>{children}</Box>
      </Collapse>

      <Box sx={styles.container}>
        <UnstyledButton sx={styles.button} onClick={onToggle}>
          <Typography variant="Paragraph MD/Medium">{title}</Typography> {arrowIconElement}
        </UnstyledButton>
      </Box>
    </Box>
  );
};

export default ExpandableContainer;
