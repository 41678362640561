import { apiPaths } from 'const';

import { AuthServiceApi } from './AuthServiceApi';
import { DnevnikApi } from './DnevnikApi';
import { ExcelServiceApi } from './ExcelServiceApi';
import { MainApi } from './MainApi';
import { MosVuzApi } from './MosVuzApi';
import { ProforientationApi } from './ProforientationApi';

export * from './types';

export const mainApi = new MainApi(`${apiPaths.PORTFOLIO}${apiPaths.BASE_PREFIX}`);
export const referenceApi = new MainApi(`${apiPaths.PORTFOLIO}${apiPaths.BASE_PREFIX}${apiPaths.REFERENCE}`);
export const authServiceApi = new AuthServiceApi(`${apiPaths.PORTFOLIO}${apiPaths.BASE_PREFIX}`);
export const excelServiceApi = new ExcelServiceApi(`${apiPaths.PORTFOLIO}${apiPaths.BASE_PREFIX}`);
export const dnevnikApi = new DnevnikApi();
export const mosVuzApi = new MosVuzApi(`${apiPaths.PORTFOLIO}`);
export const proforientationApi = new ProforientationApi();

export const setToken = (token: string) => {
  mainApi.setToken(token);
  referenceApi.setToken(token);
  authServiceApi.setToken(token);
  excelServiceApi.setToken(token);
};

export const removeToken = () => {
  mainApi.removeToken();
  referenceApi.removeToken();
  authServiceApi.removeToken();
  excelServiceApi.removeToken();
};
