import { ICommonResponse, IIndependentDiagnosticLearningGroup } from 'api/types';

import { action, createAction, createRequestTypes, FAILURE, INVALIDATE, REQUEST, SUCCESS } from './utils';

export const GET_INDEPENDENT_DIAGNOSTICS = createRequestTypes('GET_INDEPENDENT_DIAGNOSTICS');
export const getIndependentDiagnosticsActions = {
  request: (personId: string, count: number) =>
    action(GET_INDEPENDENT_DIAGNOSTICS[REQUEST], { payload: [personId, count] }),
  // ! некорректный тип response
  success: (response: ICommonResponse<IIndependentDiagnosticLearningGroup>) =>
    action(GET_INDEPENDENT_DIAGNOSTICS[SUCCESS], { payload: response }),
  failure: (error: unknown) => action(GET_INDEPENDENT_DIAGNOSTICS[FAILURE], { payload: error }),
  invalidate: () => action(GET_INDEPENDENT_DIAGNOSTICS[INVALIDATE]),
};

export const GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING = createRequestTypes(
  'GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING',
);
export const getIndependentDiagnosticsGeneralRatingActions = {
  request: (personId: string) => action(GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING[REQUEST], { payload: [personId] }),
  // ! кажется, тут некорректный тип
  success: (response: ICommonResponse<IIndependentDiagnosticLearningGroup>) =>
    action(GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING[SUCCESS], { payload: response }),
  failure: (error: unknown) => action(GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING[FAILURE], { payload: error }),
  invalidate: () => action(GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING[INVALIDATE]),
};

export const GET_INDEPENDENT_DIAGNOSTICS_RATING = createRequestTypes('GET_INDEPENDENT_DIAGNOSTICS_RATING');
export const getIndependentDiagnosticsRatingActions = {
  request: (personId: string) => action(GET_INDEPENDENT_DIAGNOSTICS_RATING[REQUEST], { payload: [personId] }),
  // ! кажется, тут некорректный тип
  success: (response: ICommonResponse<IIndependentDiagnosticLearningGroup>) =>
    action(GET_INDEPENDENT_DIAGNOSTICS_RATING[SUCCESS], { payload: response }),
  failure: (error: unknown) => action(GET_INDEPENDENT_DIAGNOSTICS_RATING[FAILURE], { payload: error }),
  invalidate: () => action(GET_INDEPENDENT_DIAGNOSTICS_RATING[INVALIDATE]),
};

export const GET_PERSONAL_DIAGNOSTICS = createRequestTypes('GET_PERSONAL_DIAGNOSTICS');
export const getPersonalDiagnosticsActions = {
  request: (personId: string, count: number, subject?: string) =>
    createAction(GET_PERSONAL_DIAGNOSTICS[REQUEST], { personId, count, subject }),
  success: (response: unknown) => createAction(GET_PERSONAL_DIAGNOSTICS[SUCCESS], response),
  failure: (error: unknown) => createAction(GET_PERSONAL_DIAGNOSTICS[FAILURE], error),
  invalidate: () => createAction(GET_PERSONAL_DIAGNOSTICS[INVALIDATE], null),
};
