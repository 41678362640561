import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponseObject, IProforientaitonRecommendationEvents } from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

import { GET_PROFORIENTATION_RECOMMENDATION_EVENTS } from '../actions';

export type ProforientationRecommendationEventsState =
  ICommonResponseObject<IProforientaitonRecommendationEvents | null>;

const initialState: ProforientationRecommendationEventsState = {
  content: null,
  loading: false,
};

const proforientationRecommendationEvents: IReducers<ProforientationRecommendationEventsState> = {
  [GET_PROFORIENTATION_RECOMMENDATION_EVENTS[REQUEST]]: genericReducers().get.request,
  [GET_PROFORIENTATION_RECOMMENDATION_EVENTS[SUCCESS]]: genericReducers().get.success,
  [GET_PROFORIENTATION_RECOMMENDATION_EVENTS[FAILURE]]: genericReducers().get.failure,
  [GET_PROFORIENTATION_RECOMMENDATION_EVENTS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, proforientationRecommendationEvents);
