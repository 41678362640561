import { IDictionaryItem } from 'api/types';
import { DIAGNOSTIC_SUBJECT_ALL_CODE, DIAGNOSTIC_SUBJECT_ALL_VALUE } from 'const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

const selfDiagnosticSubjectsSelector = createSelector(
  [(state: IRootState) => state.studentDiagnosis],
  (selfDiagnostics): IDictionaryItem[] => {
    const dictionarySubjects =
      selfDiagnostics?.content?.selfDiagnosticSubjects.map(
        (subject, index): IDictionaryItem => ({
          value: subject.name,
          code: index + 1,
        }),
      ) || [];

    dictionarySubjects.unshift({
      value: DIAGNOSTIC_SUBJECT_ALL_VALUE,
      code: DIAGNOSTIC_SUBJECT_ALL_CODE,
    });

    return dictionarySubjects;
  },
);

export default selfDiagnosticSubjectsSelector;
