import { FC } from 'react';

import { Box } from '@mui/material';
import { IDictionaryItem } from 'api/types';

import FiltersContainer from './FiltersContainer';
import { DistrictFilter, IndustryFilter } from './specificFilters';

interface IRecommendationExcursionsFiltersProps {
  industryOptions: IDictionaryItem[];
  districtOptions: IDictionaryItem[];
}

const RecommendationExcursionsFilters: FC<IRecommendationExcursionsFiltersProps> = ({
  industryOptions,
  districtOptions,
}) => {
  const industryControl = <IndustryFilter section="excursions" options={industryOptions} />;
  const districtControl = <DistrictFilter section="excursions" options={districtOptions} />;

  return (
    <FiltersContainer sectionKey="excursions" controlKeys={['industry', 'district']}>
      <Box className="row" sx={{ display: 'flex', gap: '12px' }}>
        {industryControl}
        {districtControl}
      </Box>
    </FiltersContainer>
  );
};

export default RecommendationExcursionsFilters;
