import { pdfCivilClubs, pdfCivilContests, pdfCivilRewards, pdfCivilUnits } from './civil';
import { pdfCreationCollectives, pdfCreationContests, pdfCreationRewards, pdfCreationUnits } from './creation';
import { pdfCultureOfflineEvents, pdfCultureOnlineEvents } from './culture';
import {
  pdfBriefcaseFilled,
  pdfCubeFilled,
  pdfFileFilled,
  pdfMapFilled,
  pdfMessageCircleFilled,
  pdfSchoolFilled,
} from './other';
import {
  pdfProfessionEducation,
  pdfProfessionEvents,
  pdfProfessionProforientationEvents,
  pdfProfessionProforientationRecommendations,
  pdfProfessionRewards,
} from './profession';
import { pdfProfileInterests } from './profile';
import { pdfScienceContests, pdfScienceEmployments, pdfScienceProjects, pdfScienceRewards } from './science';
import { pdfGia } from './shared';
import { pdfSportClubs, pdfSportContests, pdfSportHikes, pdfSportRewards, pdfSportUnits } from './sport';
import { pdfStudyDiagnostics, pdfStudyOlympiads, pdfStudyResults } from './study';

export const pdfIconProfileInterests = pdfProfileInterests;

export const pdfIconStudyEducation = pdfSchoolFilled;
export const pdfIconStudyPractice = pdfCubeFilled;
export const pdfIconStudyDocuments = pdfFileFilled;
export const pdfIconStudyResults = pdfStudyResults;
export const pdfIconStudyGia = pdfGia;
export const pdfIconStudyDiagnostics = pdfStudyDiagnostics;
export const pdfIconStudyOlympiads = pdfStudyOlympiads;

export const pdfIcoScienceRewards = pdfScienceRewards;
export const pdfIcoScienceProjects = pdfScienceProjects;
export const pdfIcoScienceEmployments = pdfScienceEmployments;
export const pdfIcoScienceContests = pdfScienceContests;

export const pdfIconSportRewards = pdfSportRewards;
export const pdfIconSportClubs = pdfSportClubs;
export const pdfIconSportUnits = pdfSportUnits;
export const pdfIconSportContests = pdfSportContests;
export const pdfIconSportHikes = pdfSportHikes;

export const pdfIconCreationRewards = pdfCreationRewards;
export const pdfIconCreationCollectives = pdfCreationCollectives;
export const pdfIconCreationContests = pdfCreationContests;
export const pdfIconCreationUnits = pdfCreationUnits;

export const pdfIconCultureOnlineEvents = pdfCultureOnlineEvents;
export const pdfIconCultureOfflineEvents = pdfCultureOfflineEvents;

export const pdfIconCivilRewards = pdfCivilRewards;
export const pdfIconCivilClubs = pdfCivilClubs;
export const pdfIconCivilContests = pdfCivilContests;
export const pdfIconCivilUnits = pdfCivilUnits;

export const pdfIconProfessionProforientationRecommendations = pdfProfessionProforientationRecommendations;
export const pdfIconProfessionProforientationEvents = pdfProfessionProforientationEvents;
export const pdfIconProfessionRewards = pdfProfessionRewards;
export const pdfIconProfessionJobs = pdfBriefcaseFilled;
export const pdfIconProfessionMetaskills = pdfMessageCircleFilled;
export const pdfIconProfessionEducation = pdfProfessionEducation;
export const pdfIconProfessionEvents = pdfProfessionEvents;
export const pdfIconProfessionGia = pdfGia;
export const pdfIconProfessionTalentMap = pdfMapFilled;
