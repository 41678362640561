/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';

import InterestProgressBar from '../ProgressBar';
import DesktopInterestKinds from '../Kinds/desktop';
import MobileInterestKinds from '../Kinds/mobile';
import IconFilter from '../../../../../icons/IconFilter';
import { IconSearch } from '../../../../../icons';
import { Button } from '../../../../../ui-kit';
import { CommonInput } from '../../../../common/Input';
import { IInterestKind, IInterestSection } from '../../types';
import { ScrollStatusContext } from '../../../../../context';

import './index.scss';

interface IInterestHeaderProps {
  currentSection: IInterestSection;
  progressBarRef: React.MutableRefObject<HTMLElement | null>;

  interestKinds: IInterestKind[];
  filterValue: string;
  currentInterestKinds: number[] | undefined;
  setFilterValue: React.Dispatch<React.SetStateAction<string>>;
  setCurrentInterestKinds: React.Dispatch<React.SetStateAction<number[] | undefined>>;
}

const InterestHeader: React.VFC<IInterestHeaderProps> = ({
  currentSection,
  progressBarRef,

  interestKinds,
  filterValue,
  currentInterestKinds,
  setFilterValue,
  setCurrentInterestKinds
}) => {
  const [isMobileKindsOpen, setIsMobileKindsOpen] = useState(false);
  const [mobileSelectedKinds, setMobileSelectedKinds] = useState<number[] | undefined>(currentInterestKinds);
  const { isContainerScrolled } = useContext(ScrollStatusContext);

  const isSmallScreen = useMediaQuery('(max-width: 1023px)', { noSsr: true });
  const isTabletScreen = useMediaQuery('(max-width: 766px)', { noSsr: true });

  useEffect(() => {
    setMobileSelectedKinds(currentInterestKinds);
  }, [currentInterestKinds]);

  const toggleKindId = (kindsState: number[] | undefined, id: number): number[] => {
    const kinds = kindsState?.filter((kindId) => kindId !== id) || [];
    const isSameLength = kinds && kindsState && kinds.length === kindsState.length;

    if (isSameLength || !kindsState) {
      kinds.push(id);
    }

    return kinds;
  };

  const handleKindSelect = (id: number) => {
    const kinds = toggleKindId(currentInterestKinds, id);
    return () => setCurrentInterestKinds(kinds.length ? kinds : undefined);
  };

  const filterInputController = {
    handleChange: (value: string) => {
      setFilterValue(value);
    }
  };

  const handleMobileKindsOpen = () => {
    setMobileSelectedKinds(currentInterestKinds);
    setIsMobileKindsOpen(false);
  };

  const handleMobileKindSelect = (id: number) => {
    const kinds = toggleKindId(mobileSelectedKinds, id);
    return () => setMobileSelectedKinds(kinds.length ? kinds : undefined);
  };

  const handleMobileKindsReset = () => {
    setMobileSelectedKinds(undefined);
    setCurrentInterestKinds(undefined);
  };

  const handleMobileKindsAccept = () => {
    setCurrentInterestKinds(mobileSelectedKinds);
    setIsMobileKindsOpen(false);
  };

  const headerBackgroundStyles: React.CSSProperties = {
    backgroundColor: currentSection.theme.header.backgroundColor,
    backgroundImage: !isSmallScreen
      ? `url(${currentSection.theme.header.leftImage}),
          url(${currentSection.theme.header.rightImage})`
      : '',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center left, center right'
  };

  return (
    <header className={`interests-header ${isContainerScrolled ? 'interests-header--scrolled' : ''}`}>
      <div
        className="interests-header__background"
        style={headerBackgroundStyles}
      >
        <div className="interests-header__progress">
          <InterestProgressBar ref={progressBarRef} />
        </div>
        <h4 className="interests-header__title" style={{color: currentSection.theme.header.titleColor}}>{currentSection.name}</h4>
        <p className="interests-header__description">Выбери кружки, содержащие твои увлечения</p>
      </div>
      <div className="interests-header__filters">
        <div className="interests-header__filter-container">
          <CommonInput
            inputController={filterInputController}
            startAdornment={<IconSearch className="interests-interests__filter-icon" />}
            value={filterValue}
            placeholder="Введи вид деятельности..."
          />
        </div>

        {interestKinds.length > 0 && (
          <div className="interests-header__kinds-container">
            {isTabletScreen && (
              <MobileInterestKinds
                isOpen={isMobileKindsOpen}
                onClose={handleMobileKindsOpen}
                interestKinds={interestKinds}
                theme={currentSection.theme}
                selectedKinds={mobileSelectedKinds}
                onSelectKind={handleMobileKindSelect}
                onReset={handleMobileKindsReset}
                onAccept={handleMobileKindsAccept}
              />
            )}
            {isTabletScreen ? (
              <Button
                className="interests-header__kinds-button"
                variant="outlined"
                size="small"
                onClick={() => setIsMobileKindsOpen(true)}
              >
                <IconFilter fill="none" />
                <span className="label">Фильтры</span>
              </Button>
            ) : (
              <DesktopInterestKinds
                interestKinds={interestKinds}
                theme={currentSection.theme}
                selectedKinds={currentInterestKinds}
                onSelectKind={handleKindSelect}
              />
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default InterestHeader;
