import { FC } from 'react';

import { Stack } from '@mui/material';

import { IRecommendedSpeciality } from '../../model/types';
import Speciality from './Speciality';

interface ISpecialityListProps {
  specialities: IRecommendedSpeciality[];
}

const SpecialityList: FC<ISpecialityListProps> = ({ specialities }) => {
  return (
    <Stack className="speciality-list" gap="12px">
      {specialities.map((speciality) => {
        const { code, name } = speciality;
        return <Speciality key={`${name}-${code}`} speciality={speciality} />;
      })}
    </Stack>
  );
};

export default SpecialityList;
