import { FC, ReactNode } from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { commonTheme } from 'portfolio3/styles/theme';
import { Attributes } from 'portfolio3/ui-kit';
import { mergeSx } from 'utils';

import ApplicationStatus from './ApplicationStatus';
import * as styles from './styles';

interface IGenericRecommendationCardProps {
  date: string;
  industry: string;
  name: string;
  place?: string;
  statusApplicationCode: number;
  bottomAddon?: ReactNode;
}

const GenericApplicationCard: FC<IGenericRecommendationCardProps> = ({
  date,
  industry,
  name,
  place,
  statusApplicationCode,
  bottomAddon,
}) => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const attributes = [date, industry].filter(Boolean).map((value) => {
    return (
      <Typography variant="Paragraph MD/Regular" key={value}>
        {value}
      </Typography>
    );
  });

  const leftElement = (
    <Stack gap="8px">
      <Attributes attributes={attributes} />
      <Typography variant="Paragraph LG/Semi Bold">{name}</Typography>
      {place && <Typography variant="Paragraph MD/Regular">{place}</Typography>}
    </Stack>
  );

  const rightElement = (
    <ApplicationStatus
      statusApplicationCode={statusApplicationCode}
      sx={mergeSx(styles.rootRight, !isSmallScreen && { alignSelf: 'flex-end' })}
    />
  );

  return (
    <Box className="generic-application-card" sx={styles.root}>
      <Box
        className="generic-application-card__main"
        sx={mergeSx(styles.rootMain, { flexDirection: isSmallScreen ? 'column' : 'row' })}
      >
        {leftElement}
        {rightElement}
      </Box>
      {bottomAddon}
    </Box>
  );
};

export default GenericApplicationCard;
