import { FAILURE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponseObject } from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

import { POST_PROFORIENTATION_EVENT_APPLICATION } from '../actions';

export type ProforientationEventApplicationState = ICommonResponseObject<null>;

const initialState: ProforientationEventApplicationState = {
  content: null,
  loading: false,
};

const proforientationEventApplication: IReducers<ProforientationEventApplicationState> = {
  [POST_PROFORIENTATION_EVENT_APPLICATION[REQUEST]]: genericReducers().createUpdate.request,
  [POST_PROFORIENTATION_EVENT_APPLICATION[SUCCESS]]: genericReducers().createUpdate.success,
  [POST_PROFORIENTATION_EVENT_APPLICATION[FAILURE]]: genericReducers().createUpdate.failure,
};

export default connectReducers(initialState, proforientationEventApplication);
