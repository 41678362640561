import { IReducers } from 'reducers';

import {
  CLEAR_PERSONAL_DIAGNOSTICS_SETTINGS,
  personalDiagnosticsSettingsActions,
  SET_PERSONAL_DIAGNOSTICS_SETTINGS,
} from '../../actions';
import connectReducers from '../../utils/connectReducers';

export type PersonalDiagnosticsSettingsState = {
  settings: {
    [key: string]: boolean;
  };
};

const initialState: PersonalDiagnosticsSettingsState = {
  settings: {},
};

const personalDiagnosticsSettings: IReducers<PersonalDiagnosticsSettingsState> = {
  [SET_PERSONAL_DIAGNOSTICS_SETTINGS]: (
    state,
    { payload }: ReturnType<typeof personalDiagnosticsSettingsActions.setSetting>,
  ) => {
    return {
      settings: {
        ...state.settings,
        [payload.recordId]: payload.isVisible,
      },
    };
  },
  [CLEAR_PERSONAL_DIAGNOSTICS_SETTINGS]: () => {
    return initialState;
  },
};

export default connectReducers(initialState, personalDiagnosticsSettings);
