/* eslint-disable prettier/prettier */
/* eslint-disable */

import { IQueryParams } from '../api';

import {
  createRequestTypes,
  action,
  FAILURE,
  REQUEST,
  SUCCESS,
  createAction
} from './utils';

import {
  IChildInfo,
  IShareLinkResponse,
  IDiagnosisResponse,
  ISaveVisibilitySettingsRequest,
  ILocalVisibilitySettingsList,
  ISaveStudentAvatarRequest,
  IIndependentDiagnosticResponse,
  ISaveDiagnosisCardSettingsRequest,
  IGetRecommendationsRequest,
  IGetClassRecommendationsRequest,
  IPostErrorMessageRequest,
  IResponseError,
  IEntityErrorMessage,
  IPutErrorMessageRequest,
  IPostGratitudeTeacherRequest,
  IEisDopRecommendationQueryParams,
  IResponsePayload,
  IShareLink,
  IGetStudentAvatarResponse,
  MetaObject,
} from '../api/types';
import { EntityType } from '../const';

// CURRENT_STUDENT
export const SET_CURRENT_STUDENT = 'SET_CURRENT_STUDENT';
export const setCurrentStudentAction = (response: IChildInfo) => action(SET_CURRENT_STUDENT, { payload: response });

export const SET_INDEPENDENT_DIAGNOSIS_SETTING = 'SET_INDEPENDENT_DIAGNOSIS_SETTING';
export const setIndependentDiagnosisSettingAction = (recordId: string, isVisible: boolean) => action(SET_INDEPENDENT_DIAGNOSIS_SETTING, { payload: { recordId, isVisible } });

export const CLEAR_INDEPENDENT_DIAGNOSIS_SETTING = 'CLEAR_INDEPENDENT_DIAGNOSIS_SETTING';
export const clearIndependentDiagnosisSettingAction = () => action(CLEAR_INDEPENDENT_DIAGNOSIS_SETTING);

export const SAVE_INDEPENDENT_DIAGNOSIS_SETTINGS = createRequestTypes('SAVE_INDEPENDENT_DIAGNOSIS_SETTINGS');
export const saveIndependentDiagnosisSettingsActions = {
  request: (request: ISaveDiagnosisCardSettingsRequest) => action(SAVE_INDEPENDENT_DIAGNOSIS_SETTINGS[REQUEST], { payload: { request } }),
  success: (response: any) => action(SAVE_INDEPENDENT_DIAGNOSIS_SETTINGS[SUCCESS], { payload: response }),
  failure: (error: any) => action(SAVE_INDEPENDENT_DIAGNOSIS_SETTINGS[FAILURE], { payload: error })
};

export const SET_PERSONAL_DIAGNOSTICS_SETTINGS = 'SET_PERSONAL_DIAGNOSTICS_SETTINGS';
export const CLEAR_PERSONAL_DIAGNOSTICS_SETTINGS = 'CLEAR_PERSONAL_DIAGNOSTICS_SETTINGS';
export const personalDiagnosticsSettingsActions = {
  setSetting: (recordId: string, isVisible: boolean) => createAction(SET_PERSONAL_DIAGNOSTICS_SETTINGS, { recordId, isVisible }),
  clearSettings: () => createAction(CLEAR_PERSONAL_DIAGNOSTICS_SETTINGS, null),
};

export const SAVE_PERSONAL_DIAGNOSTICS_SETTINGS = createRequestTypes('SAVE_PERSONAL_DIAGNOSTICS_SETTINGS');
export const savePersonalDiagnosticsSettingsActions = {
  request: (request: ISaveDiagnosisCardSettingsRequest) => createAction(SAVE_PERSONAL_DIAGNOSTICS_SETTINGS[REQUEST], request),
  success: (response: unknown) => createAction(SAVE_PERSONAL_DIAGNOSTICS_SETTINGS[SUCCESS], response),
  failure: (error: unknown) => createAction(SAVE_PERSONAL_DIAGNOSTICS_SETTINGS[FAILURE], error),
};

export const GET_STUDENT_PERSONAL_ACCESS = createRequestTypes('GET_STUDENT_PERSONAL_ACCESS')
export const getStudentPersonalAccessActions = {
  request: (personId: string) => action(GET_STUDENT_PERSONAL_ACCESS[REQUEST], { payload: { personId } }),
  success: (response: any) => action(GET_STUDENT_PERSONAL_ACCESS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_PERSONAL_ACCESS[FAILURE], { payload: error })
}

export const UPDATE_STUDENT_PERSONAL_ACCESS = createRequestTypes('UPDATE_STUDENT_PERSONAL_ACCESS')
export const updateStudentPersonalAccessActions = {
  request: (personId: string, isActive: boolean) => action(UPDATE_STUDENT_PERSONAL_ACCESS[REQUEST], { payload: { personId, isActive } }),
  success: (response: any) => action(UPDATE_STUDENT_PERSONAL_ACCESS[SUCCESS], { payload: response }),
  failure: (error: any) => action(UPDATE_STUDENT_PERSONAL_ACCESS[FAILURE], { payload: error })
}

export const GET_STUDENT_PROGRESS = createRequestTypes('GET_STUDENT_PROGRESS');
export const getStudentProgressActions = {
  request: (id: string, queryParams?: IQueryParams) => action(GET_STUDENT_PROGRESS[REQUEST], { payload: { id, queryParams } }),
  success: (response: any) => action(GET_STUDENT_PROGRESS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_PROGRESS[FAILURE], { payload: error })
};

export const GET_STUDENT_INFO = createRequestTypes('GET_STUDENT_INFO');
export const getStudentInfoActions = {
  request: (personId: string) => action(GET_STUDENT_INFO[REQUEST], { payload: { personId } }),
  success: (response: any) => action(GET_STUDENT_INFO[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_INFO[FAILURE], { payload: error })
};

// GIA
export const GET_STUDENT_GIA_LIST = createRequestTypes('GET_STUDENT_GIA_LIST');
export const getStudentGiaListActions = {
  request: (id: string, queryParams?: IQueryParams) => action(GET_STUDENT_GIA_LIST[REQUEST], { payload: { id, queryParams } }),
  success: (response: any) => action(GET_STUDENT_GIA_LIST[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_GIA_LIST[FAILURE], { payload: error })
};

// OLYMPIADS

export const GET_OLYMPIAD_LEVELS = createRequestTypes('GET_OLYMPIAD_LEVELS');
export const getOlympiadLevelsActions = {
  request: (olympiadLevelId?: number, queryParams?: IQueryParams) => action(GET_OLYMPIAD_LEVELS[REQUEST], { payload: { olympiadLevelId, queryParams } }),
  success: (response: any) => action(GET_OLYMPIAD_LEVELS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_OLYMPIAD_LEVELS[FAILURE], { payload: error })
};

export const GET_OLYMPIAD_RESULTS = createRequestTypes('GET_OLYMPIAD_RESULTS');
export const getOlympiadResultsActions = {
  request: (olympiadResultId?: number) => action(GET_OLYMPIAD_RESULTS[REQUEST], { payload: { olympiadResultId } }),
  success: (response: any) => action(GET_OLYMPIAD_RESULTS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_OLYMPIAD_RESULTS[FAILURE], { payload: error })
};

export const GET_OLYMPIAD_PARALLELS = createRequestTypes('GET_OLYMPIAD_PARALLELS');
export const getOlympiadParallelsActions = {
  request: (parallelId?: number, queryParams?: IQueryParams) => action(GET_OLYMPIAD_PARALLELS[REQUEST], { payload: { parallelId, queryParams } }),
  success: (response: any) => action(GET_OLYMPIAD_PARALLELS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_OLYMPIAD_PARALLELS[FAILURE], { payload: error })
};

export const GET_OLYMPIAD_TYPES = createRequestTypes('GET_OLYMPIAD_TYPES');
export const getOlympiadTypesActions = {
  request: (olympiadTypeId?: number, queryParams?: IQueryParams) => action(GET_OLYMPIAD_TYPES[REQUEST], { payload: { olympiadTypeId, queryParams } }),
  success: (response: any) => action(GET_OLYMPIAD_TYPES[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_OLYMPIAD_TYPES[FAILURE], { payload: error })
};

export const GET_OLYMPIAD_FORMATS = createRequestTypes('GET_OLYMPIAD_FORMATS');
export const getOlympiadFormatsActions = {
  request: (olympiadFormatId?: number) => action(GET_OLYMPIAD_FORMATS[REQUEST], { payload: { olympiadFormatId } }),
  success: (response: any) => action(GET_OLYMPIAD_FORMATS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_OLYMPIAD_FORMATS[FAILURE], { payload: error })
};

// DIAGNOSTICS
export const GET_STUDENT_DIAGNOSIS = createRequestTypes('GET_STUDENT_DIAGNOSIS');
export const getStudentDiagnosisActions = {
  request: (personId: string, period: number, subject?: string) => action(GET_STUDENT_DIAGNOSIS[REQUEST], { payload: { personId, period, subject } }),
  success: (response: IDiagnosisResponse) => action(GET_STUDENT_DIAGNOSIS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_DIAGNOSIS[FAILURE], { payload: error })
};

export const GET_STUDENT_INDEPENDENT_DIAGNOSIS = createRequestTypes('GET_STUDENT_INDEPENDENT_DIAGNOSIS');
export const getStudentIndependentDiagnosisActions = {
  request: (personId: string, period: number, count: number, subject?: string) => action(GET_STUDENT_INDEPENDENT_DIAGNOSIS[REQUEST], { payload: { personId, period, count, subject } }),
  success: (response: IIndependentDiagnosticResponse) => action(GET_STUDENT_INDEPENDENT_DIAGNOSIS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_INDEPENDENT_DIAGNOSIS[FAILURE], { payload: error })
};

export const GET_PDF_STUDENT_DIAGNOSIS_FULL = createRequestTypes('GET_PDF_STUDENT_DIAGNOSIS_FULL');
export const getPdfStudentDiagnosisFullActions = {
  request: (personId: string, period: number) => action(GET_PDF_STUDENT_DIAGNOSIS_FULL[REQUEST], { payload: { personId, period } }),
  success: (response: IDiagnosisResponse) => action(GET_PDF_STUDENT_DIAGNOSIS_FULL[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_PDF_STUDENT_DIAGNOSIS_FULL[FAILURE], { payload: error })
};

export const GET_PDF_STUDENT_DIAGNOSIS_YEAR = createRequestTypes('GET_PDF_STUDENT_DIAGNOSIS_YEAR');
export const getPdfStudentDiagnosisYearActions = {
  request: (personId: string, period: number) => action(GET_PDF_STUDENT_DIAGNOSIS_YEAR[REQUEST], { payload: { personId, period } }),
  success: (response: IDiagnosisResponse) => action(GET_PDF_STUDENT_DIAGNOSIS_YEAR[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_PDF_STUDENT_DIAGNOSIS_YEAR[FAILURE], { payload: error })
};

// SHARE LINKS
export const CREATE_STUDENT_SHARE_LINK = createRequestTypes('CREATE_STUDENT_SHARE_LINK');
export const createStudentShareLinkActions = {
  request: (personId: string, request: any, meta?: MetaObject) => createAction(CREATE_STUDENT_SHARE_LINK[REQUEST], { personId, request, meta }),
  success: (response: IShareLinkResponse) => action(CREATE_STUDENT_SHARE_LINK[SUCCESS], { payload: response }),
  failure: (error: any) => action(CREATE_STUDENT_SHARE_LINK[FAILURE], { payload: error })
};

export const UPDATE_STUDENT_SHARE_LINK_ACTIVITY = createRequestTypes('UPDATE_STUDENT_SHARE_LINK_ACTIVITY');
export const updateStudentShareLinkActivityActions = {
  request: (personId: string, request: any) => action(UPDATE_STUDENT_SHARE_LINK_ACTIVITY[REQUEST], { payload: { personId, request } }),
  success: (response: IResponsePayload<null>) => action(UPDATE_STUDENT_SHARE_LINK_ACTIVITY[SUCCESS], { payload: response }),
  failure: (error: any) => action(UPDATE_STUDENT_SHARE_LINK_ACTIVITY[FAILURE], { payload: error })
};

export const GET_STUDENT_SHARE_LINK_LIST = createRequestTypes('GET_STUDENT_SHARE_LINK_LIST');
export const getStudentShareLinkListActions = {
  request: (personId: string, queryParams?: IQueryParams) => action(GET_STUDENT_SHARE_LINK_LIST[REQUEST], { payload: { personId, queryParams } }),
  success: (response: IResponsePayload<IShareLink[]>) => action(GET_STUDENT_SHARE_LINK_LIST[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_SHARE_LINK_LIST[FAILURE], { payload: error })
};

export const DELETE_STUDENT_SHARE_LINK = createRequestTypes('DELETE_STUDENT_SHARE_LINK');
export const deleteStudentShareLinkActions = {
  request: (id: number, personId: string) => action(DELETE_STUDENT_SHARE_LINK[REQUEST], { payload: { id, personId } }),
  success: (response: any) => action(DELETE_STUDENT_SHARE_LINK[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_STUDENT_SHARE_LINK[FAILURE], { payload: error })
};

export const GET_USER_SETTINGS = createRequestTypes('GET_USER_SETTINGS');
export const getUserSettingsActions = {
  request: (personId: string) => action(GET_USER_SETTINGS[REQUEST], { payload: { personId } }),
  success: (response: any) => action(GET_USER_SETTINGS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_USER_SETTINGS[FAILURE], { payload: error })
};

export const SAVE_VISIBILITY_SETTINGS = createRequestTypes('SAVE_VISIBILITY_SETTINGS');
export const saveVisibilitySettingsActions = {
  request: (request: ISaveVisibilitySettingsRequest, personId: string) => action(SAVE_VISIBILITY_SETTINGS[REQUEST], { payload: { request, personId } }),
  success: (response: any) => action(SAVE_VISIBILITY_SETTINGS[SUCCESS], { payload: response }),
  failure: (error: any) => action(SAVE_VISIBILITY_SETTINGS[FAILURE], { payload: error })
};

export const SET_LOCAL_VISIBILITY_SETTINGS = 'SET_LOCAL_VISIBILITY_SETTINGS';
export const setLocalVisibilitySettingsAction = (localVisibilitySettings: ILocalVisibilitySettingsList) => action(SET_LOCAL_VISIBILITY_SETTINGS, { payload: localVisibilitySettings });

export const SAVE_AVATAR_SETTINGS = createRequestTypes('SAVE_AVATAR_SETTINGS');
export const saveAvatarSettingsActions = {
  request: (personId: string, avatarTypeCode: number) => createAction(SAVE_AVATAR_SETTINGS[REQUEST], { personId, avatarTypeCode }),
  success: (response: unknown) => createAction(SAVE_AVATAR_SETTINGS[SUCCESS], response),
  failure: (error: unknown) => createAction(SAVE_AVATAR_SETTINGS[FAILURE], error),
};

export const GET_STUDENT_AVATAR = createRequestTypes('GET_STUDENT_AVATAR');
export const getStudentAvatarActions = {
  request: (personId: string) => createAction(GET_STUDENT_AVATAR[REQUEST], personId),
  success: (response: IGetStudentAvatarResponse) => createAction(GET_STUDENT_AVATAR[SUCCESS], response),
  failure: (error?: any) => createAction(GET_STUDENT_AVATAR[FAILURE], error),
};

export const SET_AVATAR_TYPE_SETTING = 'SET_AVATAR_TYPE_SETTING';
export const setAvatarTypeSettingAction = (personId: string, type: number) => createAction(SET_AVATAR_TYPE_SETTING, { personId, type });

export const SAVE_STUDENT_AVATAR = createRequestTypes('SAVE_STUDENT_AVATAR');
export const saveStudentAvatarActions = {
  request: (request: ISaveStudentAvatarRequest) => action(SAVE_STUDENT_AVATAR[REQUEST], { payload: { request } }),
  success: (response: any) => action(SAVE_STUDENT_AVATAR[SUCCESS], { payload: response }),
  failure: (error: any) => action(SAVE_STUDENT_AVATAR[FAILURE], { payload: error })
};

export const GET_STUDENT_PROJECTS = createRequestTypes('GET_STUDENT_PROJECTS');
export const getStudentProjectsActions = {
  request: (personId: string, queryParams?: IQueryParams) => action(GET_STUDENT_PROJECTS[REQUEST], { payload: { personId, queryParams } }),
  success: (response: any) => action(GET_STUDENT_PROJECTS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_PROJECTS[FAILURE], { payload: error })
};

export const GET_STUDENT_REWARDS = createRequestTypes('GET_STUDENT_REWARDS');
export const getStudentRewardsActions = {
  request: (personId: string, queryParams?: IQueryParams) => action(GET_STUDENT_REWARDS[REQUEST], { payload: { personId, queryParams } }),
  success: (response: any) => action(GET_STUDENT_REWARDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_REWARDS[FAILURE], { payload: error })
};

export const GET_STUDENT_SPORT_REWARDS = createRequestTypes('GET_STUDENT_SPORT_REWARDS');
export const getStudentSportRewardsActions = {
  request: (personId: string, queryParams?: IQueryParams) => action(GET_STUDENT_SPORT_REWARDS[REQUEST], { payload: { personId, queryParams } }),
  success: (response: any) => action(GET_STUDENT_SPORT_REWARDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_SPORT_REWARDS[FAILURE], { payload: error })
};

export const GET_STUDENT_EVENTS = createRequestTypes('GET_STUDENT_EVENTS');
export const getStudentEventsActions = {
  request: (personId: string, queryParams?: IQueryParams) => action(GET_STUDENT_EVENTS[REQUEST], { payload: { personId, queryParams } }),
  success: (response: any) => action(GET_STUDENT_EVENTS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_EVENTS[FAILURE], { payload: error })
};

export const GET_STUDENT_EMPLOYMENTS = createRequestTypes('GET_STUDENT_EMPLOYMENTS');
export const getStudentEmploymentsActions = {
  request: (personId: string, queryParams?: IQueryParams) => action(GET_STUDENT_EMPLOYMENTS[REQUEST], { payload: { personId, queryParams } }),
  success: (response: any) => action(GET_STUDENT_EMPLOYMENTS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_EMPLOYMENTS[FAILURE], { payload: error })
};

export const GET_STUDENT_AFFILATIONS = createRequestTypes('GET_STUDENT_AFFILATIONS');
export const getStudentAffilationsActions = {
  request: (personId: string, queryParams?: IQueryParams) => action(GET_STUDENT_AFFILATIONS[REQUEST], { payload: { personId, queryParams } }),
  success: (response: any) => action(GET_STUDENT_AFFILATIONS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_AFFILATIONS[FAILURE], { payload: error })
};

export const GET_STUDENT_GIA_WORLDSKILLS = createRequestTypes('GET_STUDENT_GIA_WORLDSKILLS');
export const getStudentGiaWorldSkillsActions = {
  request: (personId: string, queryParams?: IQueryParams) => action(GET_STUDENT_GIA_WORLDSKILLS[REQUEST], { payload: { personId, queryParams } }),
  success: (response: any) => action(GET_STUDENT_GIA_WORLDSKILLS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_GIA_WORLDSKILLS[FAILURE], { payload: error })
};

export const GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION = createRequestTypes('GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION');
export const getStudentProfessionalAdditionalEducationActions = {
  request: (personId: string) => action(GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION[REQUEST], { payload: { personId } }),
  success: (response: any) => action(GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION[FAILURE], { payload: error })
};

export const GET_STUDENT_META_SKILLS = createRequestTypes('GET_STUDENT_META_SKILLS');
export const getStudentMetaSkillsActions = {
  request: (personId: string) => action(GET_STUDENT_META_SKILLS[REQUEST], { payload: [personId] }),
  success: (response: any) => action(GET_STUDENT_META_SKILLS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_META_SKILLS[FAILURE], { payload: error })
}

export const POST_STUDENT_META_SKILLS = createRequestTypes('POST_STUDENT_META_SKILLS');
export const postStudentMetaSkillsActions = {
  request: (personId: string, metaskillCodes: number[]) => action(POST_STUDENT_META_SKILLS[REQUEST], { payload: [personId, metaskillCodes] }),
  success: (response: any) => action(POST_STUDENT_META_SKILLS[SUCCESS], { payload: response }),
  failure: (error: any) => action(POST_STUDENT_META_SKILLS[FAILURE], { payload: error })
}

export const GET_STUDENT_SPO_STATUS = createRequestTypes('GET_STUDENT_SPO_STATUS');
export const getStudentSpoStatusActions = {
  request: (personId: string) => action(GET_STUDENT_SPO_STATUS[REQUEST], { payload: [personId] }),
  success: (response: any) => action(GET_STUDENT_SPO_STATUS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_SPO_STATUS[FAILURE], { payload: error })
}

export const POST_STUDENT_SPO_STATUS = createRequestTypes('POST_STUDENT_SPO_STATUS');
export const postStudentSpoStatusActions = {
  request: (personId: string, code: number) => action(POST_STUDENT_SPO_STATUS[REQUEST], { payload: [personId, code] }),
  success: (response: any) => action(POST_STUDENT_SPO_STATUS[SUCCESS], { payload: response }),
  failure: (error: any) => action(POST_STUDENT_SPO_STATUS[FAILURE], { payload: error })
};

export const SET_STUDENT_SPO_STATUS = 'SET_STUDENT_SPO_STATUS';
export const setStudentSpoStatusAction = (code: number | null) => action(SET_STUDENT_SPO_STATUS, { payload: [code] });

export const GET_STUDENT_CHECKIN_HISTORY = createRequestTypes('GET_STUDENT_CHECKIN_HISTORY');
export const getStudentCheckInHistoryActions = {
  request: (personId: string) => action(GET_STUDENT_CHECKIN_HISTORY[REQUEST], { payload: { personId } }),
  success: (response: any) => action(GET_STUDENT_CHECKIN_HISTORY[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_CHECKIN_HISTORY[FAILURE], { payload: error })
};

export const DELETE_STUDENT_CHECKIN = createRequestTypes('DELETE_STUDENT_CHECKIN');
export const deleteStudentCheckInActions = {
  request: (personId: string, id: number) => action(DELETE_STUDENT_CHECKIN[REQUEST], { payload: { personId, id } }),
  success: (response: any) => action(DELETE_STUDENT_CHECKIN[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_STUDENT_CHECKIN[FAILURE], { payload: error })
};

export const GET_STUDENT_CULTURE_VISITINGS = createRequestTypes('GET_STUDENT_CULTURE_VISITINGS');
export const getStudentCultureVisitingsActions = {
  request: (personId: string, queryParams?: IQueryParams) => action(GET_STUDENT_CULTURE_VISITINGS[REQUEST], { payload: { personId, queryParams } }),
  success: (response: any) => action(GET_STUDENT_CULTURE_VISITINGS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_CULTURE_VISITINGS[FAILURE], { payload: error })
};

export const GET_SOURCES = createRequestTypes('GET_SOURCES');
export const getSourcesActions = {
  request: () => action(GET_SOURCES[REQUEST]),
  success: (response: any) => action(GET_SOURCES[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_SOURCES[FAILURE], { payload: error })
};

export const GET_STUDENT_RECOMMENDATIONS = createRequestTypes('GET_STUDENT_RECOMMENDATIONS');
export const getStudentRecommendationsActions = {
  request: (personId: string, request: IGetRecommendationsRequest) => action(GET_STUDENT_RECOMMENDATIONS[REQUEST], { payload: { personId, request } }),
  success: (response: any) => action(GET_STUDENT_RECOMMENDATIONS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_RECOMMENDATIONS[FAILURE], { payload: error })
};

export const GET_STUDENT_CLASS_RECOMMENDATIONS = createRequestTypes('GET_STUDENT_CLASS_RECOMMENDATIONS');
export const getStudentClassRecommendationsActions = {
  request: (personId: string, request: IGetClassRecommendationsRequest) => action(GET_STUDENT_CLASS_RECOMMENDATIONS[REQUEST], { payload: { personId, request } }),
  success: (response: any) => action(GET_STUDENT_CLASS_RECOMMENDATIONS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_CLASS_RECOMMENDATIONS[FAILURE], { payload: error })
};

export const GET_STUDENT_EIS_DOP_RECOMMENDATIONS = createRequestTypes('GET_STUDENT_EIS_DOP_RECOMMENDATIONS');
export const getStudentEisDopRecommendationsActions = {
  request: (personId: string, queryParams: IEisDopRecommendationQueryParams) => action(GET_STUDENT_EIS_DOP_RECOMMENDATIONS[REQUEST], { payload: { personId, queryParams } }),
  success: (response: any) => action(GET_STUDENT_EIS_DOP_RECOMMENDATIONS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_EIS_DOP_RECOMMENDATIONS[FAILURE], { payload: error })
};

export const REPORT_ERROR = createRequestTypes('REPORT_ERROR');
export const reportErrorActions = {
  request: (personId: string, request: IPostErrorMessageRequest | IPutErrorMessageRequest, method: 'post' | 'put', meta?: MetaObject) => action(REPORT_ERROR[REQUEST], { payload: [personId, request, method, meta] }),
  success: (response: IResponsePayload<null>) => action(REPORT_ERROR[SUCCESS], { payload: response }),
  failure: (error?: IResponseError) => action(REPORT_ERROR[FAILURE], { payload: { error } }),
};

export const GET_ENTITY_ERROR_MESSAGES = createRequestTypes('GET_ENTITY_ERROR_MESSAGES');
export const getEntityErrorMessagesActions = {
  request: (personId: string, entityType: EntityType, entityId?: number, recordId?: string) => action(GET_ENTITY_ERROR_MESSAGES[REQUEST], { payload: [personId, entityType, entityId, recordId] }),
  success: (entityErrors: IEntityErrorMessage[]) => action(GET_ENTITY_ERROR_MESSAGES[SUCCESS], { payload: entityErrors }),
  failure: () => action(GET_ENTITY_ERROR_MESSAGES[FAILURE]),
};

export const CLEAR_ENTITY_ERROR_MESSAGES = 'CLEAR_ENTITY_ERROR_MESSAGES';
export const clearEntityErrorMessagesAction = () => action(CLEAR_ENTITY_ERROR_MESSAGES);

export const GRATITUDE_TEACHER = createRequestTypes('GRATITUDE_TEACHER');
export const gratitudeTeacherActions = {
  request: (personId: string, request: IPostGratitudeTeacherRequest, meta?: MetaObject) => action(GRATITUDE_TEACHER[REQUEST], { payload: [personId, request, meta] }),
  success: (response: IResponsePayload<null>) => action(GRATITUDE_TEACHER[SUCCESS], { payload: response }),
  failure: (error?: IResponseError) => action(GRATITUDE_TEACHER[FAILURE], { payload: [error] }),
}

export const GET_STUDENT_JOBS = createRequestTypes('GET_STUDENT_JOBS');
export const getStudentJobsActions = {
  request: (personId: string) => action(GET_STUDENT_JOBS[REQUEST], { payload: [personId] }),
  success: (response: any) => action(GET_STUDENT_JOBS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_JOBS[FAILURE], { payload: error }),
};

export const GET_STUDENT_SPO_DOCUMENTS = createRequestTypes('GET_STUDENT_SPO_DOCUMENTS');
export const getStudentSpoDocumentsActions = {
  request: (personId: string) => action(GET_STUDENT_SPO_DOCUMENTS[REQUEST], { payload: [personId] }),
  success: (response: any) => action(GET_STUDENT_SPO_DOCUMENTS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_SPO_DOCUMENTS[FAILURE], { payload: error }),
};

export const GET_STUDENT_SPO_EDUCATION = createRequestTypes('GET_STUDENT_SPO_EDUCATION');
export const getStudentSpoEducationActions = {
  request: (personId: string) => action(GET_STUDENT_SPO_EDUCATION[REQUEST], { payload: [personId] }),
  success: (response: any) => action(GET_STUDENT_SPO_EDUCATION[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_SPO_EDUCATION[FAILURE], { payload: error }),
};
