import { FC, useState } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { professionNavigation, StatusApplicationCode, StatusApplicationCodeText } from 'const';
import { useActiveWidget, useAppSelector, useBrightTheme } from 'hooks';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { WidgetContainerTitle } from 'portfolio3/components/widgetContainerRecipes';
import { commonTheme } from 'portfolio3/styles/theme';
import { Switcher, SwitcherButton } from 'portfolio3/ui-kit';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';

import { proforientationVisibilitySelectors } from '../model/selectors';
import {
  ApplicationExcursionsWidget,
  ApplicationOpenDayWidget,
  ApplicationProbesWidget,
} from './ApplicationSubWidgets';
import { WidgetNames, WidgetType } from './widgets';

const applicationStatusOptions = Object.values(StatusApplicationCode).map((code): IDictionaryItem => {
  return {
    code,
    value: StatusApplicationCodeText[code],
  };
});

const navigationData = professionNavigation.applications;

const ProforientationApplicationsWidget: FC = () => {
  const isVisible = useAppSelector(proforientationVisibilitySelectors.applications);

  const widgets = [
    {
      id: WidgetType.PROBES,
      isVisible: isVisible.probes,
      component: ApplicationProbesWidget,
    },
    {
      id: WidgetType.EXCURSIONS,
      isVisible: isVisible.excursions,
      component: ApplicationExcursionsWidget,
    },
    {
      id: WidgetType.OPEN_DAY,
      isVisible: isVisible.dods,
      component: ApplicationOpenDayWidget,
    },
  ];

  const { activeWidgetId, setActiveWidgetId, activeWidgets } = useActiveWidget(widgets);
  const [applicationStatus, setApplicationStatus] = useState<number | undefined>(undefined);

  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonMd'));
  const isBrightTheme = useBrightTheme();

  const applicationStatusController: IController<string | undefined> = {
    handleChange(value) {
      setApplicationStatus(Number(value));
    },
  };

  const handleChangeActiveWidget = (widgetType: WidgetType) => {
    setActiveWidgetId(widgetType);
    setApplicationStatus(undefined);
  };

  const widgetTitleElement = <WidgetContainerTitle title={navigationData.label} />;

  const optionsElement = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '12px',
        flexDirection: isSmallScreen ? 'column' : 'row',
      }}
    >
      <Switcher value={activeWidgetId ?? undefined} onChange={handleChangeActiveWidget} useHiddenList>
        {activeWidgets.map(({ id }) => {
          return <SwitcherButton key={id} value={id} content={WidgetNames[id]} />;
        })}
      </Switcher>

      <Box width="272px">
        <Select
          inputRenderMode="fixed"
          inputSize="medium"
          placeholder="Статус заявки"
          value={applicationStatus}
          options={applicationStatusOptions}
          controller={applicationStatusController}
          strictMaxWidth
        />
      </Box>
    </Box>
  );

  const applicationStatusCode = applicationStatus ?? null;
  const AcitveWidgetComponent = activeWidgets.find((widget) => widget.id === activeWidgetId)?.component;

  if (activeWidgets.length === 0) return null;

  return (
    <Widget containerData={navigationData} withBackground={isBrightTheme}>
      <Widget.Header title={widgetTitleElement} options={optionsElement} />
      <Widget.Padding>
        {AcitveWidgetComponent && <AcitveWidgetComponent applicationStatusCode={applicationStatusCode} />}
      </Widget.Padding>
    </Widget>
  );
};

export default ProforientationApplicationsWidget;
