/* eslint-disable prettier/prettier */
/* eslint-disable */

import { apiPaths, EntityType } from '../const';
import {
  IAttachmentParams,
  IGetAttachmentResponse,
  IShareLinkResponse,
  IUpdateLinkActivityRequest,
  ICreateLinkRequest,
  ILinkSettingsRequest,
  ISaveVisibilitySettingsRequest,
  ISaveStudentAvatarRequest,
  IPostPortfolioEvent,
  IPostPortfolioEmployment,
  IPostPortfolioAffilation,
  IPostPortfolioReward,
  IPostPortfolioSportReward,
  IPostPortfolioProject,
  IPersonsListRequest,
  IPutAttachmentRequest,
  IPutTemplateAttachmentResponse,
  IHistoryRequest,
  IImportHistoryRequest,
  IGetImportReportResponse,
  IImportListResponse,
  ISaveAdminSettingsRequest,
  IAdminHistoryRequest,
  ISaveAdminFunctionSettingsRequest,
  IAdminDeleteHistoryRequest,
  ISaveDiagnosisCardSettingsRequest,
  ISaveInterestRequest,
  IGetRecommendationsRequest,
  IRecord,
  PostStudentGiaWorldSkills,
  StudentGiaWorldSkills,
  IGetClassRecommendationsRequest,
  IPostErrorMessageRequest,
  IGetAdminIncorrectDataRequest,
  IUserSettingsByAdminResponse,
  IResponsePayload,
  IEntityErrorMessage,
  IPutErrorMessageRequest,
  IOrganizationTeacher,
  IPostGratitudeTeacherRequest,
  IThemeSettings,
  ISaveThemeSettingsResponse,
  SpoStatusRefItem,
  PostJobRequest,
  EditJobRequest,
  PostSpoDocumentRequest,
  EditSpoDocumentRequest,
  IUserSettingsResponse,
  IEisDopRecommendationQueryParams,
  IEisDopRecommendation,
  IInterestResponse,
  IIndependentSelfDiagnostic,
  IProfessionalAdditionalEducationResponse,
  SpoDocument,
  SpoEducation,
  IJobsResponse,
  MetaSkill,
  IStudentEvent,
  IStudentProject,
  IStudentEmployment,
  IStudentAffilation,
  IStudentReward,
  IStudentSportReward,
  ISaveAvatarSettingsParams,
  MetaObject,
} from './types';
import { serializeQueryParams } from '../utils';
import { mainApi as api, authServiceApi, excelServiceApi } from './impl';
import { serializeMetaObjectToHeader } from './utils';

export interface IQueryParams {
  size?: number;
  page?: number;
  id?: number;
  [key: string]: string | string[] | number | number[] | undefined;
}

export const DEFAULT_PAGE_SIZE = 50;
export const MAX_PAGE_SIZE = 9999;
export const DEFAULT_PAGE_NUMBER = 0;
export const DEFAULT_QUERY_PARAMS: IQueryParams = {
  size: DEFAULT_PAGE_SIZE,
  page: DEFAULT_PAGE_NUMBER
};

/**
 * Функция примает объект с параметрами запроса и преобразует его в строку, для последующей подставноки в url при get запросе
 * @param queryParams - объект содержащий параметры для формирования запроса
 */
export const getQueryString = (queryParams?: IQueryParams): string => {
  if (!queryParams) return '';

  let pageQueryArray: string[] = [];

  Object.keys(queryParams).forEach((key: string) => {
    const value = queryParams[key];
    if (value || typeof value === 'boolean') {
      pageQueryArray = [...pageQueryArray, `${key}=${encodeURIComponent(Array.isArray(value) ? value.join(',') : value)}`];
    }
  });

  return `?${pageQueryArray.join('&')}`;
};

// COMMON
const getSources = () => api.get<any>(`${apiPaths.REFERENCE}/data-source/`);

// AUTH
const getUserContext = () => authServiceApi.get<any>(`${apiPaths.PORTFOLIO}${apiPaths.USERS}/context/`);
const getLinkSettings = (request: ILinkSettingsRequest) => api.post<ILinkSettingsRequest, any>(`${apiPaths.STUDENT}/share/linkSettings`, request);

// ATTACHMENT
const postAttachment = (request: FormData, attachmentParams: IAttachmentParams) => api.post<FormData, any>(`${apiPaths.ATTACHMENT}`, request, attachmentParams);
const putAttachment = (request: IPutAttachmentRequest, entityId: string, entityType: string) => api.put<IPutAttachmentRequest, any>(`/employee${apiPaths.ATTACHMENT}?entityId=${entityId}&entityType=${entityType}`, request);
const getAttachment = (queryParams: IQueryParams) => api.get<IGetAttachmentResponse>(`${apiPaths.ATTACHMENT}${getQueryString(queryParams)}`);

// IMPORT
const getImportTemplate = (categoryCode: number, dataKind: number, typeCode?: number) => api.get<IGetAttachmentResponse>(`${apiPaths.EMPLOYEE}/templates?categoryCode=${categoryCode}&dataKind=${dataKind}${typeCode ? `&typeCode=${typeCode}` : ''}`);
const postAttachmentTemplate = (formData: FormData, requestHeaders?: any) => excelServiceApi.post<FormData, IPutTemplateAttachmentResponse>(`${apiPaths.EMPLOYEE}/templates`, formData, requestHeaders);
const getAttachmentTemplateInfo = (uuid: string) => api.get<IPutTemplateAttachmentResponse>(`${apiPaths.EMPLOYEE}/templates/info?cacheUUID=${uuid}`);
const getAttachmentTemplatePartitionInfo = (partition: number, uuid: string) => api.get<IRecord[]>(`${apiPaths.EMPLOYEE}/templates/info/${partition}?cacheUUID=${uuid}`);
const deleteAttachmentTemplateInfo = (uuid?: string) => api.delete<any>(`${apiPaths.EMPLOYEE}/templates${uuid ? `?uuid=${uuid}` : ''}`);
const getImportList = () => api.get<IImportListResponse>(`${apiPaths.EMPLOYEE}/templates/list`);

// STUDENT
const getStudentInfo = (id: string) => api.get<any>(`${apiPaths.STUDENT}/info/${id}`);
const getAchievementTypes = (achievementTypeID?: string) => api.get<any>(`${apiPaths.REFERENCE}/achievement/types/${achievementTypeID || ''}`);
const getAchievementCategories = (achievementCategoryID?: string) => api.get<any>(`${apiPaths.REFERENCE}/achievement/categories/${achievementCategoryID || ''}`);
const getAchievementFormats = (achievementActivityFormatID?: string) => api.get<any>(`${apiPaths.REFERENCE}/achievement/activity/formats/${achievementActivityFormatID || ''}`);
const getStudentGiaList = (id: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.STUDENT}/${id}/govexams/list/${getQueryString(queryParams)}`);
const getStudentLearningHistory = (id: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.STUDENT}/${id}/study/history/${getQueryString(queryParams)}`);
const getStudentOlympiadsList = (id: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.STUDENT}/${id}/olympiads/list/${getQueryString(queryParams)}`);
const getOlympiadLevels = (olympiadLevelId?: number, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/olympiad/level/${olympiadLevelId || ''}${getQueryString(queryParams)}`);
const getOlympiadResults = (olympiadResultId?: number) => api.get<any>(`${apiPaths.REFERENCE}/olympiad/result/${olympiadResultId || ''}`);
const getOlympiadParallels = (parallelId?: number, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/olympiad/parallel/${parallelId || ''}${getQueryString(queryParams)}`);
const getOlympiadTypes = (olympiadTypeId?: number, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/olympiad/type/${olympiadTypeId || ''}${getQueryString(queryParams)}`);
const getOlympiadFormats = (olympiadFormatId?: number) => api.get<any>(`${apiPaths.REFERENCE}/olympiad/format/${olympiadFormatId || ''}`);
const getStudentProgress = (id: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.STUDENT}/${id}/academic/performance${getQueryString(queryParams)}`);

const getStudentDiagnosis = (personId: string, period: number, subject?: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/diagnostic?period=${period}${subject ? `&subject=${subject}` : ''}`);
/** @deprecated метод не используется */
const getStudentLastDiagnosis = (personId: string, period: number, subject?: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/diagnostic/last?period=${period}${subject ? `&subject=${subject}` : ''}`);
/** @deprecated метод не используется */
const getStudentDiagnosisSubjects = (personId: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/diagnostic/subjects`);
/** @deprecated перенести функционал в pdf на новый метод */
const getStudentIndependentDiagnosis = (personId: string, period: number, count: number, subject?: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/independent-diagnostic?period=${period}&count=${count}${subject ? `&subject=${subject}` : ''}`);
/** @deprecated метод не используется */
const getStudentIndependentDiagnosisSubjects = (personId: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/independent-diagnostic/subjects`);

const getIndependentDiagnostics = (personId: string, count: number, subject?: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/independent-diagnostic-grouped${serializeQueryParams({ count, subject })}`);
const getIndependentDiagnosticsGeneralRating = (personId: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/diagnostic/general-rating`);
const getIndependentDiagnosticsRating = (personId: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/diagnostic/independent-rating`);
const saveIndependentDiagnosticsSettings = (request: ISaveDiagnosisCardSettingsRequest) => api.patch<ISaveDiagnosisCardSettingsRequest, any>(`${apiPaths.SETTINGS}/user/independent-diagnostic`, request);

const getPersonalDiagnostics = (personId: string, count: number, subject?: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/personal-diagnostic-grouped${serializeQueryParams({ count, subject })}`);
const savePersonalDiagnosticsSettings = (request: ISaveDiagnosisCardSettingsRequest) => api.patch<ISaveDiagnosisCardSettingsRequest, any>(`${apiPaths.SETTINGS}/user/personal-diagnostic`, request);

// PERFORMANCE
const getStudentFinalGrades = (personId: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/academic-performance/final-mark`);
const getStudentAverageGrades = (personId: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/academic-performance/average-mark`);
const getStudentSubjectThemes = (personId: string, subjectId: number, meta?: MetaObject) => api.get<any>(`${apiPaths.STUDENT}/${personId}/academic-performance/average-by-theme${serializeQueryParams({ subjectId })}`, serializeMetaObjectToHeader(meta));
const getStudentPerformanceOld = (personId: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/academic/performance/average`);
const getStudentPerformanceLessonsOld = (personId: string, subjectName: string, meta?: MetaObject) => api.get<any>(`${apiPaths.STUDENT}/${personId}/result-lessons?subjectName=${subjectName}`, serializeMetaObjectToHeader(meta));

const createStudentShareLink = (personId: string, request: ICreateLinkRequest, meta?: MetaObject) => api.post<ICreateLinkRequest, IShareLinkResponse>(`${apiPaths.STUDENT}/${personId}/share/`, request, serializeMetaObjectToHeader(meta));
const updateStudentShareLinkActivity = (personId: string, request: IUpdateLinkActivityRequest) => api.put<IUpdateLinkActivityRequest, IResponsePayload<null>>(`${apiPaths.STUDENT}/${personId}/share/activity`, request);
const getStudentShareLinkList = (personId: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.STUDENT}/${personId}/share/list/${getQueryString(queryParams)}`);
const deleteStudentShareLink = (id: number, personId: string) => api.delete<any>(`${apiPaths.STUDENT}/${personId}/share/${id}`);

const checkUserRoleSubsystemLink = (meshRoleId: number, applicationId: number) => api.get<any>(`${apiPaths.PORTFOLIO}${apiPaths.USERS}/createUserRoleSubsystemLink?meshRoleId=${meshRoleId}&applicationId=${applicationId}`);
const getFeedbackLink = () => api.get<any>(`${apiPaths.FOS}/feedbackLink`);

// VIEWED FUNCTIONS
const getViewedFunctions = (personId: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/function/list`);
const addStudentViewedFunction = (personId: string, functionCode: number) => api.get<any>(`${apiPaths.STUDENT}/${personId}/function?functionCode=${functionCode}`);

// VISIBILITY SETTINGS
const getUserSettings = (personId: string) => authServiceApi.get<IResponsePayload<IUserSettingsResponse>>(`/settings${apiPaths.USERS}/section/${personId}`);
const saveVisibilitySettings = (request: ISaveVisibilitySettingsRequest, personId: string) => api.post<ISaveVisibilitySettingsRequest, any>(`/settings${apiPaths.USERS}/section/${personId}`, request);

// THEME SETTINGS
const saveThemeSettings = (personId: string, request: IThemeSettings) => api.post<IThemeSettings, ISaveThemeSettingsResponse>(`/settings${apiPaths.USERS}/theme/${personId}`, request);

// AVATAR
const saveAvatarSettings = (personId: string, request: ISaveAvatarSettingsParams) => api.post<null, any>(`${apiPaths.SETTINGS}/users/${personId}/avatar-setting${serializeQueryParams(request)}`, null);
const getStudentAvatar = (personId: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/avatar`);
/** @deprecated */
const saveStudentAvatar = (request: ISaveStudentAvatarRequest) => api.put<ISaveStudentAvatarRequest, any>(`${apiPaths.STUDENT}/${request.personId}/info/photo?avatarId=${request.avatarId}`, request);

// EMPLOYEE
const getEventKinds = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/event-kinds/${getQueryString(queryParams)}`);
const getPortfolioSubcategories = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/subcategories${getQueryString(queryParams)}`);
const getStatusRewards = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/reward-statuses${getQueryString(queryParams)}`);
const getProjectFormats = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/project-formats${getQueryString(queryParams)}`);
const getSportKinds = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/sport-kinds/${getQueryString(queryParams)}`);
const getTourismKinds = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/tourism-kinds/${getQueryString(queryParams)}`);
const getCreationKinds = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/creation-kinds/${getQueryString(queryParams)}`);
const getCulturalKinds = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/cultural-kinds/${getQueryString(queryParams)}`);
const getAffilationKinds = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/affilation-kinds/${getQueryString(queryParams)}`);
const getRewardKinds = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/reward-kinds/${getQueryString(queryParams)}`);
const getDisciplines = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/disciplines/${getQueryString(queryParams)}`);

const getMuseums = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/museums/${getQueryString(queryParams)}`);
const getCinemas = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/cinemas/${getQueryString(queryParams)}`);
const getTheatres = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/theatres/${getQueryString(queryParams)}`);
const getSportClubs = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/sport-clubs/${getQueryString(queryParams)}`);
const getSportRewardKinds = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/sport-rewards/${getQueryString(queryParams)}`);
const getSportTrainingStage = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/training-stage/${getQueryString(queryParams)}`);

const getStudentRewards = (personId: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.STUDENT}/${personId}/rewards/list${getQueryString(queryParams)}`);
const postPortfolioReward = (request: IPostPortfolioReward, meta?: MetaObject) => api.post<IPostPortfolioReward, any>('/rewards', request, serializeMetaObjectToHeader(meta));
const editPortfolioReward = (request: IPostPortfolioReward, id: number) => api.put<IPostPortfolioReward, any>(`/rewards/${id}`, request);
const deletePortfolioReward = (id: number) => api.delete<any>(`${apiPaths.STUDENT}/delete/reward/${id}`);

const getStudentSportRewards = (personId: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.STUDENT}/${personId}/sport-rewards/list${getQueryString(queryParams)}`);
const postPortfolioSportReward = (request: IPostPortfolioSportReward, meta?: MetaObject) => api.post<IPostPortfolioSportReward, any>('/sport-rewards', request, serializeMetaObjectToHeader(meta));
const editPortfolioSportReward = (request: IPostPortfolioSportReward, id: number) => api.put<IPostPortfolioSportReward, any>(`/sport-rewards/${id}`, request);
const deletePortfolioSportReward = (id: number) => api.delete<any>(`${apiPaths.STUDENT}/delete/sport-reward/${id}`);

const getStudentProjects = (personId: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.STUDENT}/${personId}/projects/list${getQueryString(queryParams)}`);
const postPortfolioProject = (request: IPostPortfolioProject, meta?: MetaObject) => api.post<IPostPortfolioProject, any>('/projects', request, serializeMetaObjectToHeader(meta));
const editPortfolioProject = (request: IPostPortfolioProject, id: number) => api.put<IPostPortfolioProject, any>(`/projects/${id}`, request);
const deletePortfolioProject = (id: number) => api.delete<any>(`${apiPaths.STUDENT}/delete/project/${id}`);

const getStudentEvents = (personId: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.STUDENT}/${personId}/events/list${getQueryString(queryParams)}`);
const postPortfolioEvent = (request: IPostPortfolioEvent, meta?: MetaObject) => api.post<IPostPortfolioEvent, any>('/events', request, serializeMetaObjectToHeader(meta));
const editPortfolioEvent = (request: IPostPortfolioEvent, id: number) => api.put<IPostPortfolioEvent, any>(`/events/${id}`, request);
const deletePortfolioEvent = (id: number) => api.delete<any>(`${apiPaths.STUDENT}/delete/event/${id}`);

const getStudentEmployments = (personId: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.STUDENT}/${personId}/employments/list${getQueryString(queryParams)}`);
const postPortfolioEmployment = (request: IPostPortfolioEmployment, meta?: MetaObject) => api.post<IPostPortfolioEmployment, any>('/employments', request, serializeMetaObjectToHeader(meta));
const editPortfolioEmployment = (request: IPostPortfolioEmployment, id: number) => api.put<IPostPortfolioEmployment, any>(`/employments/${id}`, request);
const deletePortfolioEmployment = (id: number) => api.delete<any>(`${apiPaths.STUDENT}/delete/employment/${id}`);

const getStudentAffilations = (personId: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.STUDENT}/${personId}/affilations/list${getQueryString(queryParams)}`);
const postPortfolioAffilation = (request: IPostPortfolioAffilation, meta?: MetaObject) => api.post<IPostPortfolioAffilation, any>('/affilations', request, serializeMetaObjectToHeader(meta));
const editPortfolioAffilation = (request: IPostPortfolioAffilation, id: number) => api.put<IPostPortfolioAffilation, any>(`/affilations/${id}`, request);
const deletePortfolioAffilation = (id: number) => api.delete<any>(`${apiPaths.STUDENT}/delete/affilation/${id}`);

const getStudentGiaWorldSkills = (personId: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.STUDENT}/${personId}/gia-worldskills/list${getQueryString(queryParams)}`);
const postPortfolioGiaWorldSkills = (request: PostStudentGiaWorldSkills, meta?: MetaObject) => api.post<PostStudentGiaWorldSkills, any>('/gia-worldskills', request, serializeMetaObjectToHeader(meta));
const editPortfolioGiaWorldSkills = (request: PostStudentGiaWorldSkills, id: number) => api.put<PostStudentGiaWorldSkills, any>(`/gia-worldskills/${id}`, request);
const deletePortfolioGiaWorldSkills = (id: number) => api.delete<any>(`${apiPaths.STUDENT}/delete/gia-worldskills/${id}`);

const getStudentSpoEducation = (personId: string) => api.get(`${apiPaths.STUDENT}/${personId}/professional-education/list`);

const getStudentSpoDocuments = (personId: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/professional-education/documents`);
const postPortfolioSpoDocument = (request: PostSpoDocumentRequest, meta?: MetaObject) => api.post<PostSpoDocumentRequest, any>('/document', request, serializeMetaObjectToHeader(meta));
const editPortfolioSpoDocument = (request: EditSpoDocumentRequest, id: number) => api.put<EditSpoDocumentRequest, any>(`/document/${id}`, request);
const deletePortfolioSpoDocument = (id: number) => api.delete(`/document/${id}`);

const getStudentJobs = (personId: string) => api.get(`${apiPaths.STUDENT}/${personId}/professional-education/job`);
const postPortfolioJob = (request: PostJobRequest, meta?: MetaObject) => api.post<PostJobRequest, any>(`${apiPaths.STUDENT}/professional-education/job`, request, serializeMetaObjectToHeader(meta));
const editPortfolioJob = (request: EditJobRequest, id: number) => api.put<EditJobRequest, any>(`${apiPaths.STUDENT}/professional-education/job/${id}`, request);
const deletePortfolioJob = (id: number) => api.delete<any>(`${apiPaths.STUDENT}/professional-education/job/${id}`);

const getStudentProfessionalAdditionalEducation = (personId: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/additional-education`);
const getStudentMetaSkills = (personId: string) => api.get(`${apiPaths.STUDENT}/${personId}/professional-education/metaskills/list`);
const postStudentMetaSkills = (personId: string, metaskillCodes: number[]) => api.post<number[], any>(`${apiPaths.STUDENT}/${personId}/professional-education/metaskill`, metaskillCodes);

const getStudentSpoStatus = (personId: string) => api.get<SpoStatusRefItem>(`${apiPaths.STUDENT}/${personId}/professional-education/spo-status`)
const postStudentSpoStatus = (personId: string, spoStatusCode: number) => api.post(`${apiPaths.STUDENT}/${personId}/professional-education/spo-status`, spoStatusCode);

const getStudentPersonalAccess = (personId: string) => api.get<any>(`/persons/${personId}/settings/personal-access`);
const editStudentPersonalAccess = (personId: string, isActive: boolean) => api.put<undefined, any>(`/persons/${personId}/settings/personal-access/?isActive=${isActive}`, undefined);

const getStudentRecommendations = (personId: string, request: IGetRecommendationsRequest) => api.post<IGetRecommendationsRequest, any>(`${apiPaths.STUDENT}/${personId}/recommendations/interest`, request);
const getStudentClassRecommendations = (personId: string, request: IGetClassRecommendationsRequest) => api.post<IGetClassRecommendationsRequest, any>(`${apiPaths.STUDENT}/${personId}/recommendations/classes`, request);
const getEisDopRecommendations = (personId: string, queryParams: IEisDopRecommendationQueryParams) => api.get<IResponsePayload<IEisDopRecommendation[]>>(`${apiPaths.STUDENT}/${personId}/recommendations/mosreg${serializeQueryParams(queryParams)}`);

const getStudentCultureVisitings = (personId: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.STUDENT}/${personId}/cultural/list/${getQueryString(queryParams)}`);

const getPersonsObjects = (personId: string, queryParams?: IQueryParams, sectionCodes?: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/sort${getQueryString(queryParams)}${sectionCodes ?? ''}`);
const getPersonsList = (request: IPersonsListRequest) => api.post<IPersonsListRequest, any>(`${apiPaths.STUDENT}/list`, request);

const getOrganizations = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.EMPLOYEE}/schools${getQueryString(queryParams)}`);
const getParallels = (schoolId?: number, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.EMPLOYEE}/parallels?schoolId=${schoolId}${getQueryString(queryParams)}`);
const getHistory = (request: IHistoryRequest) => api.post<IHistoryRequest, any>(`${apiPaths.EMPLOYEE}/changeHistory`, request);
const getImportHistory = (request: IImportHistoryRequest) => api.post<IImportHistoryRequest, any>(`${apiPaths.EMPLOYEE}/importHistory`, request);
const getImportReport = (id: number) => api.get<IGetImportReportResponse>(`${apiPaths.EMPLOYEE}/templates/report/${id}`);

const getRewardById = (id: number) => api.get<IStudentReward>(`/rewards/${id}`);
const getSportRewardById = (id: number) => api.get<IStudentSportReward>(`/sport-rewards/${id}`);
const getEventById = (id: number) => api.get<IStudentEvent>(`/events/${id}`);
const getProjectById = (id: number) => api.get<IStudentProject>(`/projects/${id}`);
const getEmploymentById = (id: number) => api.get<IStudentEmployment>(`/employments/${id}`);
const getAffilationsById = (id: number) => api.get<IStudentAffilation>(`/affilations/${id}`);
const getGiaWorldSkillsById = (id: number) => api.get<StudentGiaWorldSkills>(`/gia-worldskills/${id}`);

// CheckIn
const getStudentCheckInHistory = (personId: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/checkIn/list`);
const deleteStudentCheckIn = (personId: string, id: number) => api.patch<number, any>(`${apiPaths.STUDENT}/${personId}/checkIn`, id);

// Admin
const getAdminSettings = (classLevel: string) => authServiceApi.get<any>(`${apiPaths.SETTINGS}/administrators?sort=code&classLevel=${classLevel}`);
const getUserSettingsByAdmin = (personId: string) => api.get<IUserSettingsByAdminResponse>(`${apiPaths.SETTINGS}/users/section-by-admin/${personId}`);
const getAdminHistory = (request: IAdminHistoryRequest) => api.post<IAdminHistoryRequest, any>(`${apiPaths.SETTINGS}/administrators/history`, request);
const getAdminDeleteHistory = (request: IAdminDeleteHistoryRequest) => api.post<IAdminDeleteHistoryRequest, any>(`${apiPaths.SETTINGS}/entity/administrators/history`, request);
const saveAdminSettings = (request: ISaveAdminSettingsRequest) => api.post<any, any>(`${apiPaths.SETTINGS}/administrators`, { sections: request });
const getLearnerCategories = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/learner-category${getQueryString(queryParams)}`);
const getSettingsSections = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/section/settings${getQueryString(queryParams)}`);
const getAdminLog = (startDate: string, endDate: string, actionKindCodes: number[]) => api.post<any, any>('/action-history', { startDate, endDate, actionKindCodes });
const getActionKinds = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.PORTFOLIO}/action-kind${getQueryString(queryParams)}`);
const getAdminFunctionSettings = (functionType: string) => api.get<any>(`${apiPaths.SETTINGS}/function/administrators?functionType=${functionType}`);
const saveAdminFunctionSettings = (request: ISaveAdminFunctionSettingsRequest) => api.post<any, any>(`${apiPaths.SETTINGS}/function/administrators`, request);
const restorePortfolioEntity = (entityId: number, entityType: string) => api.patch<any, any>(`${apiPaths.STUDENT}/recover/${entityType}/${entityId}`, {});
const getAdminIncorrectDataFile = (request: IGetAdminIncorrectDataRequest) => api.post<IGetAdminIncorrectDataRequest, any>(`${apiPaths.ADMIN}/data-incorrect`, request);

// Search
const searchStudentRewards = (personId: string, searchString: string) => api.get<any>(`${apiPaths.SEARCH}/${personId}/rewards?searchString=${searchString}`);
const searchStudentSportRewards = (personId: string, searchString: string) => api.get<any>(`${apiPaths.SEARCH}/${personId}/sport-rewards?searchString=${searchString}`);
const searchStudentGiaList = (personId: string, searchString: string) => api.get<any>(`${apiPaths.SEARCH}/${personId}/govexams?searchString=${searchString}`);
const searchStudentDiagnotics = (personId: string, searchString: string) => api.get<any>(`${apiPaths.SEARCH}/${personId}/diagnostic?searchString=${searchString}`);
const searchStudentEvents = (personId: string, searchString: string) => api.get<any>(`${apiPaths.SEARCH}/${personId}/events?searchString=${searchString}`);
const searchStudentProjects = (personId: string, searchString: string) => api.get<any>(`${apiPaths.SEARCH}/${personId}/projects?searchString=${searchString}`);
const searchStudentAffilations = (personId: string, searchString: string) => api.get<any>(`${apiPaths.SEARCH}/${personId}/affilations?searchString=${searchString}`);
const searchStudentEmployments = (personId: string, searchString: string) => api.get<any>(`${apiPaths.SEARCH}/${personId}/employments?searchString=${searchString}`);
const searchStudentCultureVisitings = (personId: string, searchString: string) => api.get<any>(`${apiPaths.SEARCH}/${personId}/culture?searchString=${searchString}`);
const searchStudentCheckInHistory = (personId: string, searchString: string) => api.get<any>(`${apiPaths.SEARCH}/${personId}/check-in-history?searchString=${searchString}`);
const searchStudentInterests = (personId: string, searchString: string) => api.get<IResponsePayload<IInterestResponse[]>>(`${apiPaths.SEARCH}/${personId}/interest?searchString=${searchString}`);
const searchStudentIndependentDiagnostics = (personId: string, searchString: string) => api.get<IResponsePayload<IIndependentSelfDiagnostic[]>>(`${apiPaths.SEARCH}/${personId}/independent-diagnostic?searchString=${searchString}`);
const searchStudentProfessionalAdditionalEducation = (personId: string, searchString: string) => api.get<IResponsePayload<IProfessionalAdditionalEducationResponse>>(`${apiPaths.SEARCH}/${personId}/additional-education?searchString=${searchString}`);
const searchStudentDocuments = (personId: string, searchString: string) => api.get<IResponsePayload<SpoDocument[]>>(`${apiPaths.SEARCH}/${personId}/document?searchString=${searchString}`);
const searchStudentSpoEducation = (personId: string, searchString: string) => api.get<IResponsePayload<SpoEducation>>(`${apiPaths.SEARCH}/${personId}/education?searchString=${searchString}`);
const searchStudentJobs = (personId: string, searchString: string) => api.get<IResponsePayload<IJobsResponse>>(`${apiPaths.SEARCH}/${personId}/job?searchString=${searchString}`);
const searchStudentMetaskills = (personId: string, searchString: string) => api.get<IResponsePayload<MetaSkill>>(`${apiPaths.SEARCH}/${personId}/metaskills?searchString=${searchString}`);
const searchStudentGiaWorldskills = (personId: string, searchString: string) => api.get<IResponsePayload<StudentGiaWorldSkills>>(`${apiPaths.SEARCH}/${personId}/gia-worldskills?searchString=${searchString}`);

// Interests
const getStudentInterests = (personId: string) => api.get<any>(`${apiPaths.STUDENT}/${personId}/interests/list`);
const postStudentInterestList = (request: ISaveInterestRequest[], personId: string) => api.post<ISaveInterestRequest[], any>(`${apiPaths.STUDENT}/${personId}/interests/list`, request);
const postStudentInterest = (request: ISaveInterestRequest) => api.post<ISaveInterestRequest, any>('/interests', request);
const editStudentInterest = (request: ISaveInterestRequest, id: number) => api.put<ISaveInterestRequest, any>(`/interests/${id}`, request);
const deleteStudentInterest = (id: number) => api.delete<any>(`/interests/${id}`);

const getInterestHeadKinds = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/interest/head${getQueryString(queryParams)}`);
const getInterestActionKinds = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/interest/action${getQueryString(queryParams)}`);
const getInterestGroupKinds = (queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/interest/group${getQueryString(queryParams)}`);

const getInterestObjectKinds = (kindPath: string, queryParams?: IQueryParams) => api.get<any>(`${apiPaths.REFERENCE}/interest/${kindPath}${getQueryString(queryParams)}`);

// DOWNLOAD
const getCultureMuseumsPdf = () => api.get<any>('/cultural/pdf')

// Report error
const getErrorMessage = (personId: string, entityType: EntityType, entityId?: number, recordId?: string) => api.get<IResponsePayload<IEntityErrorMessage[]>>(`${apiPaths.STUDENT}/${personId}/error-message${serializeQueryParams({ entityType, entityId, recordId })}`);
const postErrorMessage = (personId: string, request: IPostErrorMessageRequest, meta?: MetaObject) => api.post<IPostErrorMessageRequest, null>(`${apiPaths.STUDENT}/${personId}/error-message`, request, serializeMetaObjectToHeader(meta));
const putErrorMessage = (personId: string, request: IPutErrorMessageRequest, meta?: MetaObject) => api.put<IPutErrorMessageRequest, null>(`${apiPaths.STUDENT}/${personId}/error-message`, request, serializeMetaObjectToHeader(meta));

// Thanks teacher
const gratitudeTeacher = (personId: string, request: IPostGratitudeTeacherRequest, meta?: MetaObject) => api.post<IPostGratitudeTeacherRequest, null>(`${apiPaths.STUDENT}/${personId}/gratitude-teacher`, request, serializeMetaObjectToHeader(meta));
const getOrganizationTeachers = (organizationId: number, subjectName?: string) => api.get<IResponsePayload<IOrganizationTeacher[]>>(`${apiPaths.REFERENCE}/teacher/${organizationId}${serializeQueryParams({ subjectName })}`);

export * from './dnevnik';
export * from './reference';
export * from './vuz';
export * from './proforientation';

export * as apiImpl from './impl';

export {
  checkUserRoleSubsystemLink,
  createStudentShareLink,
  deletePortfolioAffilation,
  deletePortfolioEmployment,
  deletePortfolioEvent,
  deletePortfolioProject,
  deletePortfolioReward,
  deletePortfolioSportReward,
  deleteStudentShareLink,
  deleteStudentCheckIn,
  deleteAttachmentTemplateInfo,
  editPortfolioAffilation,
  editPortfolioEmployment,
  editPortfolioEvent,
  editPortfolioProject,
  editPortfolioReward,
  editPortfolioSportReward,
  getAchievementCategories,
  getAchievementFormats,
  getAchievementTypes,
  getAffilationKinds,
  getAttachment,
  getAdminLog,
  getAdminSettings,
  getUserSettingsByAdmin,
  getSources,
  getCinemas,
  getCreationKinds,
  getCulturalKinds,
  getDisciplines,
  getEventKinds,
  getFeedbackLink,
  getViewedFunctions,
  addStudentViewedFunction,
  getLinkSettings,
  getMuseums,
  getOlympiadFormats,
  getOlympiadLevels,
  getOlympiadParallels,
  getOlympiadResults,
  getOlympiadTypes,
  getOrganizations,
  getParallels,
  getAdminHistory,
  getAdminDeleteHistory,
  getHistory,
  getImportHistory,
  getImportReport,
  getPersonsList,
  getPersonsObjects,
  getPortfolioSubcategories,
  getProjectFormats,
  getRewardKinds,
  getSportClubs,
  getSportKinds,
  getSportRewardKinds,
  getSportTrainingStage,
  getStatusRewards,
  getStudentAffilations,
  getStudentDiagnosis,
  getStudentIndependentDiagnosis,
  getStudentFinalGrades,
  getStudentAverageGrades,
  getStudentSubjectThemes,
  getStudentPerformanceOld,
  getStudentPerformanceLessonsOld,
  getStudentSpoEducation,
  getStudentCultureVisitings,
  getStudentDiagnosisSubjects,
  getStudentIndependentDiagnosisSubjects,
  getStudentEmployments,
  getStudentEvents,
  getStudentGiaList,
  getStudentInfo,
  getStudentLastDiagnosis,
  getStudentLearningHistory,
  getStudentOlympiadsList,
  getStudentProgress,
  getStudentProjects,
  getStudentRewards,
  getStudentShareLinkList,
  getStudentSportRewards,
  getSettingsSections,
  getTheatres,
  getTourismKinds,
  getUserContext,
  getUserSettings,
  getImportTemplate,
  getImportList,
  getStudentCheckInHistory,
  getAttachmentTemplateInfo,
  getAttachmentTemplatePartitionInfo,
  getRewardById,
  getSportRewardById,
  getEventById,
  getProjectById,
  getEmploymentById,
  getAffilationsById,
  getGiaWorldSkillsById,
  getLearnerCategories,
  getAdminFunctionSettings,
  postAttachment,
  postPortfolioAffilation,
  postPortfolioEmployment,
  postPortfolioEvent,
  postPortfolioProject,
  postPortfolioReward,
  postPortfolioSportReward,
  putAttachment,
  postAttachmentTemplate,
  saveAvatarSettings,
  getStudentAvatar,
  saveStudentAvatar,
  saveVisibilitySettings,
  saveThemeSettings,
  saveAdminSettings,
  saveAdminFunctionSettings,
  restorePortfolioEntity,
  getAdminIncorrectDataFile,
  searchStudentRewards,
  searchStudentSportRewards,
  searchStudentGiaList,
  searchStudentEvents,
  searchStudentAffilations,
  searchStudentEmployments,
  searchStudentProjects,
  searchStudentDiagnotics,
  searchStudentCultureVisitings,
  searchStudentCheckInHistory,
  searchStudentInterests,
  searchStudentIndependentDiagnostics,
  searchStudentProfessionalAdditionalEducation,
  searchStudentDocuments,
  searchStudentSpoEducation,
  searchStudentJobs,
  searchStudentMetaskills,
  searchStudentGiaWorldskills,
  getActionKinds,
  updateStudentShareLinkActivity,
  getStudentInterests,
  postStudentInterestList,
  postStudentInterest,
  editStudentInterest,
  deleteStudentInterest,
  getInterestHeadKinds,
  getInterestActionKinds,
  getInterestGroupKinds,
  getInterestObjectKinds,
  getCultureMuseumsPdf,
  getStudentRecommendations,
  getStudentClassRecommendations,
  getEisDopRecommendations,
  getStudentGiaWorldSkills,
  getStudentProfessionalAdditionalEducation,
  getStudentMetaSkills,
  getStudentSpoStatus,
  postStudentSpoStatus,
  postStudentMetaSkills,
  postPortfolioGiaWorldSkills,
  editPortfolioGiaWorldSkills,
  deletePortfolioGiaWorldSkills,
  getErrorMessage,
  postErrorMessage,
  putErrorMessage,
  gratitudeTeacher,
  getOrganizationTeachers,

  getIndependentDiagnostics,
  getIndependentDiagnosticsGeneralRating,
  getIndependentDiagnosticsRating,
  saveIndependentDiagnosticsSettings,
  getPersonalDiagnostics,
  savePersonalDiagnosticsSettings,

  getStudentSpoDocuments,
  postPortfolioSpoDocument,
  editPortfolioSpoDocument,
  deletePortfolioSpoDocument,

  getStudentJobs,
  postPortfolioJob,
  editPortfolioJob,
  deletePortfolioJob,

  getStudentPersonalAccess,
  editStudentPersonalAccess
};
