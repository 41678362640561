import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { professionNavigation } from 'const';
import { useAppSelector, useUserFunctionality } from 'hooks';
import { Button } from 'portfolio3/ui-kit/button';

import ExpandableContainer from '../../components/ExpandableContainer';
import { RecommendationProbesFilters } from '../../components/filters';
import GenericEntityList from '../../components/GenericEntityList';
import GenericRecommendationCard from '../../components/GenericRecommendationCard';
import NoDataPlug from '../../components/NoDataPlug';
import { SpecialityList } from '../../components/specialities';
import { proforientationParticipationDialogActions } from '../../model/actions';
import { proforientationRecommendationSelectors } from '../../model/selectors';
import { getFilterOptions, getFilterStoreKey, mapSpecialityOptionValue } from '../../utils/filters';

const RecommendationProbesWidget: FC = () => {
  const dispatch = useDispatch();
  const probes = useAppSelector(proforientationRecommendationSelectors.probes);
  const filters = useAppSelector((state) => state.proforientationRecommendationFilters);

  const { isStudent } = useUserFunctionality();

  const industries = probes.map((event) => event.industry);
  const industryOptions = getFilterOptions(industries);

  const specialities = probes.flatMap((event) => event.specialities.map(mapSpecialityOptionValue));
  const specialityOptions = getFilterOptions(specialities);

  const colleges = probes.map((event) => event.place);
  const collegeOptions = getFilterOptions(colleges);

  const districts = probes.map((event) => event.district);
  const districtOptions = getFilterOptions(districts);

  const filteredProbes = probes.filter((event) => {
    const { industry, specialities, place, district } = event;
    const mappedSpecialities = specialities.map(mapSpecialityOptionValue);

    const filterIndustries =
      (filters[getFilterStoreKey('probes', 'industry', 'applied')] as string | undefined) ?? null;
    const filterSpecialities =
      (filters[getFilterStoreKey('probes', 'speciality', 'applied')] as string | undefined) ?? null;
    const filterColleges = (filters[getFilterStoreKey('probes', 'college', 'applied')] as string | undefined) ?? null;
    const filterDistricts = (filters[getFilterStoreKey('probes', 'district', 'applied')] as string | undefined) ?? null;

    const filterIndustryCodes = filterIndustries?.split(',').map(Number) ?? [];
    const filterSpecialityCodes = filterSpecialities?.split(',').map(Number) ?? [];
    const filterCollegeCodes = filterColleges?.split(',').map(Number) ?? [];
    const filterDistrictCodes = filterDistricts?.split(',').map(Number) ?? [];

    const isContainIndustry = filterIndustryCodes.some((code) => {
      const option = industryOptions.find((option) => option.code === code);
      return option?.value === industry;
    });
    const isContainSpeciality = filterSpecialityCodes.some((code) => {
      const option = specialityOptions.find((option) => option.code === code);
      return mappedSpecialities.some((speciality) => option?.value === speciality);
    });
    const isContainCollege = filterCollegeCodes.some((code) => {
      const option = collegeOptions.find((option) => option.code === code);
      return option?.value === place;
    });
    const isContainDistrict = filterDistrictCodes.some((code) => {
      const option = districtOptions.find((option) => option.code === code);
      return option?.value === district;
    });

    return (
      (isContainIndustry || filterIndustryCodes.length === 0) &&
      (isContainSpeciality || filterSpecialityCodes.length === 0) &&
      (isContainCollege || filterCollegeCodes.length === 0) &&
      (isContainDistrict || filterDistrictCodes.length === 0)
    );
  });

  const filtersElement = (
    <RecommendationProbesFilters
      industryOptions={industryOptions}
      specialityOptions={specialityOptions}
      collegeOptions={collegeOptions}
      districtOptions={districtOptions}
    />
  );

  const listElement = (
    <GenericEntityList
      containerId={`${professionNavigation.recommendations.id}/probes`}
      entities={filteredProbes}
      initialCardCount={3}
      renderEntityCard={(entity) => {
        const handleSendApplication = () => {
          dispatch(proforientationParticipationDialogActions.setParticipationEventId(entity.eventId));
        };

        return (
          <GenericRecommendationCard
            key={entity.eventId}
            date={entity.formattedDate}
            industry={entity.industry}
            name={entity.name}
            description={entity.description}
            place={entity.place}
            district={entity.district}
            illustration={entity.illustration}
            buttonElement={isStudent && <Button onClick={handleSendApplication}>Хочу пойти</Button>}
            bottomAddon={
              <ExpandableContainer title="Специальности">
                <SpecialityList specialities={entity.specialities} />
              </ExpandableContainer>
            }
          />
        );
      }}
    />
  );

  const emptyFilterResultsElement = (
    <Box sx={{ display: 'grid', placeItems: 'center', paddingBlock: '26px' }}>
      <NoDataPlug title="Ничего не найдено" description="Попробуйте изменить поисковый запрос" />
    </Box>
  );

  if (probes.length === 0) {
    return (
      <Box sx={{ display: 'grid', placeItems: 'center' }}>
        <NoDataPlug
          title="Пройдены все доступные пробы"
          description="Пройденные пробы можно посмотреть ниже в блоке «Профориентационные мероприятия»"
        />
      </Box>
    );
  }

  return (
    <>
      <Typography variant="Paragraph LG/Regular">
        Можно пройти только 3&nbsp;пробы (по одной на отрасль), поэтому будь внимателен при&nbsp;выборе и&nbsp;отправке
        заявки. Рекомендации обновляются ежедневно.
      </Typography>

      <Box marginTop="20px">{filtersElement}</Box>

      {filteredProbes.length > 0 ? <Box marginTop="20px">{listElement}</Box> : emptyFilterResultsElement}
    </>
  );
};

export default RecommendationProbesWidget;
