import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';
import { setCurrentNavigationSectionAction } from 'actions';
import { FeaturePreviewContainer, FeaturePreviewEffects } from 'components/common/FeaturePreview';
import { ScrollStatusContext } from 'context';
import { useAppSelector, useHeaderHeight } from 'hooks';
import { YandexMetrika } from 'portfolio3/features/yandexMetrika';
import { getCurrentPortfolioSectionId } from 'utils';

import { rootStyles } from './styles';

const MainContainer: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const { currentNavigationSection } = useAppSelector((state) => state.navigationSection);
  const [isContainerScrolled, setContainerScrolled] = useState(false);

  const headerHeight = useHeaderHeight();

  useEffect(() => {
    const handleScroll = () => {
      const currentSection = getCurrentPortfolioSectionId(headerHeight);
      const { scrollTop } = document.documentElement;

      if (currentSection !== currentNavigationSection) {
        dispatch(setCurrentNavigationSectionAction(currentSection));
      }

      if (scrollTop > 0 && !isContainerScrolled) {
        setContainerScrolled(true);
      } else if (scrollTop <= 0 && isContainerScrolled) {
        setContainerScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <ScrollStatusContext.Provider
      value={{
        isContainerScrolled,
      }}
    >
      <FeaturePreviewContainer />
      <FeaturePreviewEffects />
      <YandexMetrika />
      <Box component="main" sx={rootStyles} className="main-container">
        {children}
      </Box>
    </ScrollStatusContext.Provider>
  );
};

export default MainContainer;
