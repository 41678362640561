/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconFileTextFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.714 8C14.32 8 14 7.619 14 7.15V3.978L17.742 8h-3.028ZM15 18H9a1 1 0 0 1 0-2h6a1 1 0 1 1 0 2Zm-6-6h3a1 1 0 0 1 0 2H9a1 1 0 0 1 0-2Zm10.74-4.672-4.444-5a.998.998 0 0 0-.74-.328h-8C5.146 2 4 3.122 4 4.5v15C4 20.878 5.147 22 6.556 22h10.888C18.854 22 20 20.878 20 19.5V8a1 1 0 0 0-.26-.672Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconFileTextFilled;
