import { widgetIcons } from 'icons';

import { TALENT_MAP_LINK } from './links';
import { SectionCodes } from './sections';
import { LocalSectionSettingTypeCodes, StudentSectionSettingTypeCodes } from './visibility';

export interface IPortfolioNavigation {
  label: string;
  isVisible: boolean;
  icon: keyof typeof widgetIcons;
  id: string;
  sectionTypeCode: string;
  url?: string;
  // Статичные кнопки навигации отсутствуют в настройках видимости
  isStatic?: boolean;
}

enum PROFILE_NAVIGATIONS {
  INTERESTS = 'interest',
  RECOMMENDATIONS = 'recommendations',
  // GOALS = 'goals'
}

enum STUDY_NAVIGATIONS {
  PERFORMANCE = 'performance',
  PRACTICE = 'practice',
  TRAINING = 'training',
  DOCUMENTS = 'documents',
  GIA = 'gia',
  DIAGNOSTICS = 'diagnostics',
  OLYMPIADS = 'olympiads',
  TRIAL_EXAM = 'trialExam',
}

enum SCIENCE_NAVIGATIONS {
  REWARDS = 'rewards',
  PROJECTS = 'projects',
  EMPLOYMENTS = 'employments',
  CONTESTS = 'contests',
}

enum SPORT_NAVIGATIONS {
  REWARDS = 'rewards',
  CLUBS = 'clubs',
  SECTIONS = 'sections',
  CONTESTS = 'contests',
  HIKES = 'hikes',
}

enum CREATION_NAVIGATIONS {
  REWARDS = 'rewards',
  COLLECTIVES = 'collectives',
  CONTESTS = 'contests',
  SECTIONS = 'sections',
}

enum CIVIL_NAVIGATIONS {
  REWARDS = 'rewards',
  CLUBS = 'clubs',
  CONTESTS = 'contests',
  SECTIONS = 'sections',
}

enum CULTURE_NAVIGATIONS {
  OFFLINE_VISIT = 'offlineVisit',
  ONLINE_VISIT = 'onlineVisit',
}

enum PROFESSION_NAVIGATIONS {
  PROFORIENTATION = 'proforientation',
  APPLICATIONS = 'applications',
  RECOMMENATIONS = 'recommendations',
  PROFORIENTATION_EVENTS = 'proforientationEvents',
  REWARDS = 'rewards',
  JOB = 'job',
  METASKILLS = 'metaskills',
  PROFESSIONAL_EDUCATION = 'professionalEducation',
  EVENTS = 'events',
  GIA_WORLDSKILLS = 'giaWorldskills',
  TALENT_MAP = 'talentMap',
}

enum RECOMMENDATION_NAVIGATIONS {
  VUZ_RECOMMENDATIONS = 'vuzRecommendations',
}

export const profileNavigation: Record<PROFILE_NAVIGATIONS, IPortfolioNavigation> = {
  [PROFILE_NAVIGATIONS.INTERESTS]: {
    label: 'Анкета интересов',
    isVisible: true,
    id: 'interests',
    icon: 'IconWidgetProfileInterests',
    sectionTypeCode: 'interest',
  },
  [PROFILE_NAVIGATIONS.RECOMMENDATIONS]: {
    label: 'Рекомендации',
    isVisible: true,
    id: 'recommendations',
    icon: 'IconWidgetProfileRecommendations',
    sectionTypeCode: 'recommendation',
  },
  // [PROFILE_NAVIGATIONS.GOALS]: {
  //   label: 'Цели',
  //   isVisible: true,
  //   id: 'goals',
  //   icon: IconProfileGoal,
  //   sectionTypeCode: 'goals'
  // }
};

export const studyNavigation: Record<STUDY_NAVIGATIONS, IPortfolioNavigation> = {
  [STUDY_NAVIGATIONS.PERFORMANCE]: {
    label: 'Предметные результаты',
    isVisible: true,
    id: 'performance',
    icon: 'IconWidgetStudyResults',
    sectionTypeCode: 'performance',
  },
  [STUDY_NAVIGATIONS.TRAINING]: {
    label: 'Сведения об обучении',
    isVisible: true,
    id: 'spoTraining',
    icon: 'IconWidgetStudyResults',
    sectionTypeCode: StudentSectionSettingTypeCodes.studyTraining,
  },
  [STUDY_NAVIGATIONS.PRACTICE]: {
    label: 'Практика',
    isVisible: true,
    id: 'practice',
    icon: 'IconWidgetStudyPractice',
    sectionTypeCode: StudentSectionSettingTypeCodes.studyPractice,
  },
  [STUDY_NAVIGATIONS.DOCUMENTS]: {
    label: 'Документы',
    isVisible: true,
    id: 'documents',
    icon: 'IconWidgetStudyDocuments',
    sectionTypeCode: StudentSectionSettingTypeCodes.studyDocuments,
  },
  [STUDY_NAVIGATIONS.GIA]: {
    label: 'ГИА',
    isVisible: true,
    id: 'gia',
    icon: 'IconWidgetStudyGia',
    sectionTypeCode: 'gia',
  },
  [STUDY_NAVIGATIONS.DIAGNOSTICS]: {
    label: 'Диагностики',
    isVisible: true,
    id: 'diagnostic',
    icon: 'IconWidgetStudyDiagnostics',
    sectionTypeCode: 'diagnostics',
  },
  [STUDY_NAVIGATIONS.OLYMPIADS]: {
    label: 'Олимпиады',
    isVisible: true,
    id: 'olympiads',
    icon: 'IconWidgetStudyOlympiads',
    sectionTypeCode: 'olympiads',
  },
  [STUDY_NAVIGATIONS.TRIAL_EXAM]: {
    label: 'Пробный ЕГЭ',
    isVisible: true,
    id: 'trialExam',
    icon: 'IconWidgetStudyGia',
    sectionTypeCode: StudentSectionSettingTypeCodes.studyTrialExam,
  },
};

export const scienceNavigation: Record<SCIENCE_NAVIGATIONS, IPortfolioNavigation> = {
  [SCIENCE_NAVIGATIONS.REWARDS]: {
    label: 'Награды',
    isVisible: true,
    id: 'scienceReward',
    icon: 'IconWidgetScienceRewards',
    sectionTypeCode: 'rewards',
  },
  [SCIENCE_NAVIGATIONS.PROJECTS]: {
    label: 'Проекты',
    isVisible: true,
    id: 'projects',
    icon: 'IconWidgetScienceProjects',
    sectionTypeCode: 'projects',
  },
  [SCIENCE_NAVIGATIONS.EMPLOYMENTS]: {
    label: 'Занятия',
    isVisible: true,
    id: 'scienceEmployments',
    icon: 'IconWidgetScienceEmployments',
    sectionTypeCode: 'employments',
  },
  [SCIENCE_NAVIGATIONS.CONTESTS]: {
    label: 'Конкурсы, конференции',
    isVisible: true,
    id: 'scienceContest',
    icon: 'IconWidgetScienceContests',
    sectionTypeCode: 'contests',
  },
};

export const sportNavigation: Record<SPORT_NAVIGATIONS, IPortfolioNavigation> = {
  [SPORT_NAVIGATIONS.REWARDS]: {
    label: 'Награды и разряды',
    isVisible: true,
    id: 'sportReward',
    icon: 'IconWidgetSportRewards',
    sectionTypeCode: 'rewards',
  },
  [SPORT_NAVIGATIONS.CLUBS]: {
    label: 'Клубы и команды',
    isVisible: true,
    id: 'sportClub',
    icon: 'IconWidgetSportClubs',
    sectionTypeCode: 'clubs',
  },
  [SPORT_NAVIGATIONS.SECTIONS]: {
    label: 'Кружки и секции',
    isVisible: true,
    id: 'sportUnit',
    icon: 'IconWidgetSportUnits',
    sectionTypeCode: 'units',
  },
  [SPORT_NAVIGATIONS.CONTESTS]: {
    label: 'Соревнования',
    isVisible: true,
    id: 'sportGames',
    icon: 'IconWidgetSportContests',
    sectionTypeCode: 'sport_games',
  },
  [SPORT_NAVIGATIONS.HIKES]: {
    label: 'Походы и экспедиции',
    isVisible: true,
    id: 'hike',
    icon: 'IconWidgetSportHikes',
    sectionTypeCode: 'hike',
  },
};

export const creationNavigation: Record<CREATION_NAVIGATIONS, IPortfolioNavigation> = {
  [CREATION_NAVIGATIONS.REWARDS]: {
    label: 'Награды и достижения',
    isVisible: true,
    id: 'creationReward',
    icon: 'IconWidgetCreationRewards',
    sectionTypeCode: 'rewards',
  },
  [CREATION_NAVIGATIONS.COLLECTIVES]: {
    label: 'Творческие коллективы',
    isVisible: true,
    id: 'creationClub',
    icon: 'IconWidgetCreationCollectives',
    sectionTypeCode: 'creation_club',
  },
  [CREATION_NAVIGATIONS.CONTESTS]: {
    label: 'Конкурсы',
    isVisible: true,
    id: 'creationContest',
    icon: 'IconWidgetCreationContests',
    sectionTypeCode: 'creation_contest',
  },
  [CREATION_NAVIGATIONS.SECTIONS]: {
    label: 'Кружки',
    isVisible: true,
    id: 'creationUnit',
    icon: 'IconWidgetCreationUnits',
    sectionTypeCode: 'creation_unit',
  },
};

export const civilNavigation: Record<CIVIL_NAVIGATIONS, IPortfolioNavigation> = {
  [CIVIL_NAVIGATIONS.REWARDS]: {
    label: 'Награды и статусы',
    isVisible: true,
    id: 'civilReward',
    icon: 'IconWidgetCivilRewards',
    sectionTypeCode: 'rewards',
  },
  [CIVIL_NAVIGATIONS.CLUBS]: {
    label: 'Клубы',
    isVisible: true,
    id: 'civilClub',
    icon: 'IconWidgetCivilClubs',
    sectionTypeCode: 'civil_club',
  },
  [CIVIL_NAVIGATIONS.CONTESTS]: {
    label: 'Конкурсы, соревнования',
    isVisible: true,
    id: 'civilContest',
    icon: 'IconWidgetCivilContests',
    sectionTypeCode: 'civil_contest',
  },
  [CIVIL_NAVIGATIONS.SECTIONS]: {
    label: 'Кружки',
    isVisible: true,
    id: 'civilUnit',
    icon: 'IconWidgetCivilUnits',
    sectionTypeCode: 'civil_unit',
  },
};

export const cultureNavigation: Record<CULTURE_NAVIGATIONS, IPortfolioNavigation> = {
  [CULTURE_NAVIGATIONS.OFFLINE_VISIT]: {
    label: 'Посещение учреждений',
    isVisible: true,
    id: 'offlineVisit',
    icon: 'IconWidgetCultureOfflineEvents',
    sectionTypeCode: 'offline_visit',
  },
  [CULTURE_NAVIGATIONS.ONLINE_VISIT]: {
    label: 'Онлайн-мероприятия',
    isVisible: true,
    id: 'onlineVisit',
    icon: 'IconWidgetCultureOnlineEvents',
    sectionTypeCode: 'online_visit',
  },
};

export const professionNavigation: Record<PROFESSION_NAVIGATIONS, IPortfolioNavigation> = {
  [PROFESSION_NAVIGATIONS.PROFORIENTATION]: {
    label: 'Профориентация',
    isVisible: true,
    id: 'professionProforientation',
    icon: 'IconWidgetProfessionProforientation',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionProforientation,
  },
  [PROFESSION_NAVIGATIONS.APPLICATIONS]: {
    label: 'Заявки',
    isVisible: true,
    id: 'professionProforientationApplications',
    icon: 'IconWidgetProfessionProforientationApplications',
    sectionTypeCode: LocalSectionSettingTypeCodes.professionApplications,
    isStatic: true,
  },
  [PROFESSION_NAVIGATIONS.RECOMMENATIONS]: {
    label: 'Рекомендации',
    isVisible: true,
    id: 'professionProforientationRecommendations',
    icon: 'IconWidgetProfessionProforientationRecommendations',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionProforientationRecommendations,
  },
  [PROFESSION_NAVIGATIONS.PROFORIENTATION_EVENTS]: {
    label: 'Профориентационные мероприятия',
    isVisible: true,
    id: 'professionProforientationEvents',
    icon: 'IconWidgetProfessionProforientationEvents',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionProforientationEvents,
  },
  [PROFESSION_NAVIGATIONS.REWARDS]: {
    label: 'Награды',
    isVisible: true,
    id: 'professionRewards',
    icon: 'IconWidgetProfessionRewards',
    sectionTypeCode: 'profession_rewards',
  },
  [PROFESSION_NAVIGATIONS.JOB]: {
    label: 'Трудоустройство',
    isVisible: true,
    id: 'professionJob',
    icon: 'IconWidgetProfessionJobs',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionJob,
  },
  [PROFESSION_NAVIGATIONS.METASKILLS]: {
    label: 'Метанавыки',
    isVisible: true,
    id: 'professionMetaskills',
    icon: 'IconWidgetProfessionMetaskills',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionMetaskills,
  },
  [PROFESSION_NAVIGATIONS.PROFESSIONAL_EDUCATION]: {
    label: 'Профессиональное обучение',
    isVisible: true,
    id: 'professionEducation',
    icon: 'IconWidgetProfessionEducation',
    sectionTypeCode: 'profession_education',
  },
  [PROFESSION_NAVIGATIONS.EVENTS]: {
    label: 'Мероприятия',
    isVisible: true,
    id: 'professionEvents',
    icon: 'IconWidgetProfessionEvents',
    sectionTypeCode: 'profession_events',
  },
  [PROFESSION_NAVIGATIONS.GIA_WORLDSKILLS]: {
    label: 'Демонстрационные экзамены',
    isVisible: true,
    id: 'professionWorldskills',
    icon: 'IconWidgetProfessionGia',
    sectionTypeCode: 'profession_worldskills',
  },
  [PROFESSION_NAVIGATIONS.TALENT_MAP]: {
    label: 'Карта талантов',
    isVisible: true,
    id: 'professionTalentMap',
    icon: 'IconWidgetProfessionTalentMap',
    sectionTypeCode: StudentSectionSettingTypeCodes.professionTalentMap,
    url: TALENT_MAP_LINK,
    isStatic: true,
  },
};

export const recommendationNavigation: Record<RECOMMENDATION_NAVIGATIONS, IPortfolioNavigation> = {
  vuzRecommendations: {
    label: 'Рекомендации вузов',
    isVisible: true,
    id: 'vuzRecommendations',
    icon: 'IconWidgetProfileRecommendations',
    sectionTypeCode: StudentSectionSettingTypeCodes.recommendationVuz,
  },
};

export const SectionCodeNavigation: Record<number, IPortfolioNavigation[]> = {
  [SectionCodes.profile]: Object.values(profileNavigation),
  [SectionCodes.study]: Object.values(studyNavigation),
  [SectionCodes.sport]: Object.values(sportNavigation),
  [SectionCodes.science]: Object.values(scienceNavigation),
  [SectionCodes.creation]: Object.values(creationNavigation),
  [SectionCodes.culture]: Object.values(cultureNavigation),
  [SectionCodes.civil]: Object.values(civilNavigation),
  [SectionCodes.profession]: Object.values(professionNavigation),
  [SectionCodes.recommendation]: Object.values(recommendationNavigation),
};
