export enum WidgetType {
  PROFORIENTATION = 1,
  PROBES,
  EXCURSIONS,
  OPEN_DAY,
}

export const WidgetNames: Record<WidgetType, string> = {
  [WidgetType.PROFORIENTATION]: 'Профориентация',
  [WidgetType.PROBES]: 'Пробы',
  [WidgetType.EXCURSIONS]: 'Экскурсии к работодателю',
  [WidgetType.OPEN_DAY]: 'Дни открытых дверей',
};
