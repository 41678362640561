import {
  getIndependentDiagnosticsActions,
  getIndependentDiagnosticsGeneralRatingActions,
  getIndependentDiagnosticsRatingActions,
  getPersonalDiagnosticsActions,
  savePersonalDiagnosticsSettingsActions,
} from 'actions';
import {
  getIndependentDiagnostics,
  getIndependentDiagnosticsGeneralRating,
  getIndependentDiagnosticsRating,
  getPersonalDiagnostics,
  savePersonalDiagnosticsSettings,
} from 'api';
import { call, put } from 'redux-saga/effects';
import { GenericGetAction } from 'sagas';

export function* getIndependentDiagnosticsSaga({
  payload,
}: GenericGetAction<Parameters<typeof getIndependentDiagnosticsActions.request>>) {
  if (!payload) {
    yield put(getIndependentDiagnosticsActions.failure(null));
    return;
  }

  const [personId, count] = payload;
  const { response, error } = yield call(getIndependentDiagnostics, personId, count);

  if (response) {
    yield put(getIndependentDiagnosticsActions.success(response));
  } else {
    yield put(getIndependentDiagnosticsActions.failure(error));
  }
}

export function* getIndependentDiagnosticsGeneralRatingSaga({
  payload,
}: GenericGetAction<Parameters<typeof getIndependentDiagnosticsGeneralRatingActions.request>>) {
  if (!payload) {
    yield put(getIndependentDiagnosticsGeneralRatingActions.failure(null));
    return;
  }

  const [personId] = payload;
  const { response, error } = yield call(getIndependentDiagnosticsGeneralRating, personId);

  if (response) {
    yield put(getIndependentDiagnosticsGeneralRatingActions.success(response));
  } else {
    yield put(getIndependentDiagnosticsGeneralRatingActions.failure(error));
  }
}

export function* getIndependentDiagnosticsRatingSaga({
  payload,
}: GenericGetAction<Parameters<typeof getIndependentDiagnosticsRatingActions.request>>) {
  if (!payload) {
    yield put(getIndependentDiagnosticsRatingActions.failure(null));
    return;
  }

  const [personId] = payload;
  const { response, error } = yield call(getIndependentDiagnosticsRating, personId);

  if (response) {
    yield put(getIndependentDiagnosticsRatingActions.success(response));
  } else {
    yield put(getIndependentDiagnosticsRatingActions.failure(error));
  }
}

export function* getPersonalDiagnosticsSaga({ payload }: ReturnType<typeof getPersonalDiagnosticsActions.request>) {
  const { personId, count, subject } = payload;
  const { response, error } = yield call(getPersonalDiagnostics, personId, count, subject);

  if (response) {
    yield put(getPersonalDiagnosticsActions.success(response));
  } else {
    yield put(getPersonalDiagnosticsActions.failure(error));
  }
}

export function* savePersonalDiagnosticsSettingsSaga({
  payload,
}: ReturnType<typeof savePersonalDiagnosticsSettingsActions.request>) {
  const { response, error } = yield call(savePersonalDiagnosticsSettings, payload);

  if (response) {
    yield put(savePersonalDiagnosticsSettingsActions.success(response));
  } else {
    yield put(savePersonalDiagnosticsSettingsActions.failure(error));
  }
}
