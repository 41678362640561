import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  width: '280px',
  textAlign: 'center',
};

export const image: SxStyles = {
  width: '200px',
};
