import { FC } from 'react';

import { Box } from '@mui/material';
import { professionNavigation } from 'const';
import { useAppSelector } from 'hooks';
import { isDefined } from 'utils';

import ExpandableContainer from '../../components/ExpandableContainer';
import GenericApplicationCard from '../../components/GenericApplicationCard';
import GenericEntityList from '../../components/GenericEntityList';
import NoDataPlug from '../../components/NoDataPlug';
import { IndustrySpecialityList } from '../../components/specialities';
import { proforientationApplicationSelectors } from '../../model/selectors';

interface IApplicationOpenDayWidgetProps {
  applicationStatusCode: number | null;
}

const ApplicationOpenDayWidget: FC<IApplicationOpenDayWidgetProps> = ({ applicationStatusCode }) => {
  const dods = useAppSelector(proforientationApplicationSelectors.dods);

  const filterdDods = dods.filter((event) => {
    return !isDefined(applicationStatusCode) || event.statusApplication === applicationStatusCode;
  });

  if (filterdDods.length === 0) {
    return (
      <Box sx={{ display: 'grid', placeItems: 'center', paddingTop: '16px' }}>
        <NoDataPlug title="Ничего не найдено" />
      </Box>
    );
  }

  return (
    <GenericEntityList
      containerId={`${professionNavigation.applications.id}/dods`}
      entities={filterdDods}
      initialCardCount={3}
      renderEntityCard={(entity) => {
        const specialityDisclosureTitle = entity.industryList.length > 1 ? 'Отрасли и специальности' : 'Специальности';

        return (
          <GenericApplicationCard
            key={entity.eventId}
            date={entity.formattedDate}
            industry={entity.industry}
            name={entity.name}
            place={entity.place}
            statusApplicationCode={entity.statusApplication}
            bottomAddon={
              <ExpandableContainer title={specialityDisclosureTitle}>
                <IndustrySpecialityList industries={entity.industryList} />
              </ExpandableContainer>
            }
          />
        );
      }}
    />
  );
};

export default ApplicationOpenDayWidget;
