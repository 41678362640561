import { IReducers } from 'reducers';
import { connectReducers } from 'utils';

import { proforientationParticipationDialogActions, SET_PROFORIENTATION_PARTICIPATION_EVENT_ID } from '../actions';

export type ProforientationParticipationDialogState = {
  eventId: string | null;
};

const initialState: ProforientationParticipationDialogState = {
  eventId: null,
};

const proforientationParticipationDialog: IReducers<ProforientationParticipationDialogState> = {
  [SET_PROFORIENTATION_PARTICIPATION_EVENT_ID]: (
    state,
    action: ReturnType<typeof proforientationParticipationDialogActions.setParticipationEventId>,
  ) => {
    const { eventId } = action.payload;

    return {
      ...state,
      eventId,
    };
  },
};

export default connectReducers(initialState, proforientationParticipationDialog);
