import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { IDictionaryItem } from 'api/types';
import { useAppSelector } from 'hooks';
import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';
import { TokenFieldSelect } from 'portfolio3/ui-kit/selects';
import { SelectController } from 'portfolio3/ui-kit/types';

import { proforientationRecommendationFiltersActions } from '../../model/actions';

interface ICommonTokenFilterProps {
  storeKey: string;
  options: IDictionaryItem[];
  label: string;
  placeholder: string;
}

const CommonTokenFilter: FC<ICommonTokenFilterProps> = ({ storeKey, options, label, placeholder }) => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.proforientationRecommendationFilters);

  const controller: SelectController = {
    handleChange(value) {
      dispatch(proforientationRecommendationFiltersActions.setFilter(storeKey, value));
    },
  };

  return (
    <FormControl
      renderMode="fixed"
      label={<BaseInputLabel>{label}</BaseInputLabel>}
      control={
        <TokenFieldSelect
          inputRenderMode="fixed"
          inputSize="medium"
          placeholder={placeholder}
          value={filters[storeKey] as string}
          options={options}
          controller={controller}
        />
      }
    />
  );
};

export default CommonTokenFilter;
