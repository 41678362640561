import { REQUEST } from 'actions/utils';
import {
  getProforientation,
  getProforientationRecommendationApplications,
  getProforientationRecommendationEvents,
  getProforientationToken,
  postProforientationEventApplication,
} from 'api';
import { ApiResult } from 'api/impl';
import {
  IProfessionProforientationData,
  IProfessionProforientationResponse,
  IProforientaitonRecommendationEvents,
  IProforientationRecommendatonApplications,
  IResponsePayload,
} from 'api/types';
import { LocalStorageGlobals } from 'const';
import { toast } from 'portfolio3/ui-kit';
import { IRootState } from 'reducers';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import store from 'store';
import { isDefined } from 'utils';

import {
  GET_PROFORIENTATION,
  GET_PROFORIENTATION_RECOMMENDATION_APPLICATIONS,
  GET_PROFORIENTATION_RECOMMENDATION_EVENTS,
  getProforientationActions,
  getProforientationRecommendationApplicationsActions,
  getProforientationRecommendationEventsActions,
  POST_PROFORIENTATION_EVENT_APPLICATION,
  postProforientationEventApplicationActions,
} from './actions';

type GetProforientationResult = ApiResult<IProfessionProforientationResponse<IProfessionProforientationData>, unknown>;
type GetProforientationRecommendationEventsResult = ApiResult<
  IResponsePayload<IProforientaitonRecommendationEvents>,
  unknown
>;
type GetProforientationRecommendationApplicationsResult = ApiResult<
  IResponsePayload<IProforientationRecommendatonApplications>,
  unknown
>;

export const collection = [
  watchGetProforientation,
  watchGetProforientationRecommendationEvents,
  watchGetProforientationRecommendationApplications,
  watchPostProforientationEventApplication,
].map(fork);

function* watchGetProforientation() {
  yield takeLatest(GET_PROFORIENTATION[REQUEST], getProforientationSaga);
}

function* watchGetProforientationRecommendationEvents() {
  yield takeLatest(GET_PROFORIENTATION_RECOMMENDATION_EVENTS[REQUEST], getProforientationRecommendationEventsSaga);
}

function* watchGetProforientationRecommendationApplications() {
  yield takeLatest(
    GET_PROFORIENTATION_RECOMMENDATION_APPLICATIONS[REQUEST],
    getProforientationRecommendationApplicationsSaga,
  );
}

function* watchPostProforientationEventApplication() {
  yield takeLatest(POST_PROFORIENTATION_EVENT_APPLICATION[REQUEST], postProforientationEventApplicationSaga);
}

function* getProforientationSaga({ payload }: ReturnType<typeof getProforientationActions.request>) {
  const { guid } = payload;

  // Подмена для теста
  // Если Татарченко Алексей Сергеевич
  // if (guid === '75b6ea3b-87ac-4e16-ac3b-936ac100d17f') {
  //   guid = 'fe7dedde-d722-476c-8905-43b46b4f5129';
  // }

  let response: GetProforientationResult['response'] = undefined;
  let error: GetProforientationResult['error'] = undefined;

  // 1) получить первый ответ
  const initialResult: GetProforientationResult = yield call(getProforientation, guid);
  response = initialResult.response;
  error = initialResult.error;

  // 2) проверить есть ли ошибка авторизации
  if (!response?.success) {
    // 3) если есть ошибка авторизации то получить новый токен
    const tokenResult: ApiResult<IResponsePayload<string>, unknown> = yield call(getProforientationToken);

    const newToken = tokenResult.response?.data ?? '';

    // 4) сохранить новый токен в LS
    localStorage.setItem(LocalStorageGlobals.PROFORIENTATION_TOKEN, newToken);

    // 5) отправить запрос повторно с новым токеном
    const secondResult: GetProforientationResult = yield call(getProforientation, guid);
    response = secondResult.response;
    error = secondResult.error;
  }

  if (isDefined(response)) {
    yield put(getProforientationActions.success(response));
  } else {
    yield put(getProforientationActions.failure(error));
  }
}

function* getProforientationRecommendationEventsSaga({
  payload,
}: ReturnType<typeof getProforientationRecommendationEventsActions.request>) {
  const { personId, classLevel } = payload;

  const { response, error }: GetProforientationRecommendationEventsResult = yield call(
    getProforientationRecommendationEvents,
    personId,
    classLevel,
  );

  if (isDefined(response)) {
    yield put(getProforientationRecommendationEventsActions.success(response));
  } else {
    yield put(getProforientationRecommendationEventsActions.failure(error));
  }
}

function* getProforientationRecommendationApplicationsSaga({
  payload,
}: ReturnType<typeof getProforientationRecommendationApplicationsActions.request>) {
  const { personId } = payload;

  const { response, error }: GetProforientationRecommendationApplicationsResult = yield call(
    getProforientationRecommendationApplications,
    personId,
  );

  if (isDefined(response)) {
    yield put(getProforientationRecommendationApplicationsActions.success(response));
  } else {
    yield put(getProforientationRecommendationApplicationsActions.failure(error));
  }
}

function* postProforientationEventApplicationSaga({
  payload,
}: ReturnType<typeof postProforientationEventApplicationActions.request>) {
  const { personId, eventId } = payload;

  const { error } = yield call(postProforientationEventApplication, personId, eventId);

  if (isDefined(error)) {
    yield put(postProforientationEventApplicationActions.failure(error));
    toast.error('Не удалось отправить заявку');
  } else {
    yield put(postProforientationEventApplicationActions.success(null));
    toast.success('Заявка на участие отправлена');

    const state: IRootState = store.getState();
    const classLevel = state.currentStudent.classLevel;

    // получаем актуальные данные
    yield put(getProforientationRecommendationApplicationsActions.request(personId));
    yield put(getProforientationRecommendationEventsActions.request(personId, Number(classLevel)));
  }
}
