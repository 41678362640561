import { IndependentDiagnosticLevelTypeKeys, IndependentDiagnosticLevelTypeNames } from 'const';
import { AccentColorModifier, AccentColorType, getAccentColor } from 'portfolio3/styles';

const levelTypeMap: Record<number, string> = {
  2: IndependentDiagnosticLevelTypeNames.belowBase,
  3: IndependentDiagnosticLevelTypeNames.base,
  4: IndependentDiagnosticLevelTypeNames.overHigh,
  5: IndependentDiagnosticLevelTypeNames.high,
};

export const getDiagnosticMarkValue5Color = (
  markValue5: number,
  colorSet: Record<IndependentDiagnosticLevelTypeKeys, AccentColorType>,
  modifier?: AccentColorModifier,
) => {
  const levelType = levelTypeMap[markValue5] as string | undefined;
  return getDiagnosticLevelTypeColor(levelType ?? '', colorSet, modifier);
};

export const getDiagnosticLevelTypeColor = (
  levelType: string,
  colorSet: Record<IndependentDiagnosticLevelTypeKeys, AccentColorType>,
  modifier?: AccentColorModifier,
): string => {
  const levelTypeKey = Object.entries(IndependentDiagnosticLevelTypeNames).find(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, value]) => value.toLowerCase() === levelType?.toLocaleLowerCase(),
  )?.[0] as IndependentDiagnosticLevelTypeKeys | undefined;

  const levelTypeAccentColor = levelTypeKey ? colorSet[levelTypeKey] : 'green';
  const levelTypeColor = getAccentColor(levelTypeAccentColor, modifier ?? '100');

  return levelTypeColor;
};

export const getDiagnosticPercentColor = (percent: number, colorSet: [string, string, string, string]) => {
  if (percent >= 80) return colorSet[3];
  else if (percent >= 60) return colorSet[2];
  else if (percent >= 30) return colorSet[1];
  return colorSet[0];
};
