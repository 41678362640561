import { FC } from 'react';

import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';

import { widgetPdfIcons } from '../../../../icons';
import PdfCommonDataEntityContainer from '../common';
import RecommendationExcursionsSection from './proforientation/RecommendationExcursionsSection';
import RecommendationOpenDaySection from './proforientation/RecommendationOpenDaySection';
import RecommendationProbesSection from './proforientation/RecommendationProbesSection';
import RecommendationProforientationSection from './proforientation/RecommendationProforientationSection';

import '../index.scss';

const PdfProforientationRecommendations: FC = () => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().professionProforientation });

  return (
    <PdfCommonDataEntityContainer
      title="Рекомендации"
      icon={widgetPdfIcons.pdfIconProfessionProforientationRecommendations}
    >
      <RecommendationProforientationSection />
      <RecommendationProbesSection />
      <RecommendationExcursionsSection />
      <RecommendationOpenDaySection />
    </PdfCommonDataEntityContainer>
  );
};

export default PdfProforientationRecommendations;
