import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { saveUserThemeSettingsActions, setUserThemeSettingsAction } from 'actions';
import { IThemeSettings } from 'api/types';
import { ThemeBackgroundKeys, ThemeCategoryKeys, ThemeCoverKeys } from 'const';
import { useAppSelector } from 'hooks';
import { featureSnowTheme, featureSnowThemeMobile } from 'images';
import { commonTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';

import { IFeaturePreviewComponentProps } from '../../types';
import * as styles from './styles';

const SnowThemePreviewComponent: FC<IFeaturePreviewComponentProps> = ({ onView }) => {
  const dispatch = useDispatch();
  const currentStudent = useAppSelector((state) => state.currentStudent);
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const handleSetTheme = () => {
    const settings: IThemeSettings = {
      themeName: ThemeCategoryKeys.SNOW,
      background: ThemeBackgroundKeys.SNOW_BACKGROUND,
      controls: '',
      cover: ThemeCoverKeys.SNOW_RED_HAIRED_GIRL,
    };
    dispatch(setUserThemeSettingsAction(settings));
    dispatch(saveUserThemeSettingsActions.request(currentStudent.meshId, settings));
    onView();
  };

  const backgroundImage = isMobile ? featureSnowThemeMobile : featureSnowTheme;
  const titleVariant = isMobile ? 'Headings/H6' : 'Headings/H5';
  const descriptionVariant = isMobile ? 'Paragraph MD/Regular' : 'Paragraph LG/Regular';
  const buttonSize = isMobile ? 'large' : 'medium';

  return (
    <Box className="snow-theme-preview" sx={styles.root}>
      <Box component="img" sx={styles.image} src={backgroundImage} alt="Новогодняя тема" />
      <Box sx={styles.body}>
        <Typography variant={titleVariant}>Вот и наступило время зимних забав и приключений!</Typography>
        <Typography variant={descriptionVariant} sx={styles.description}>
          Добавим немного волшебства в твоё портфолио? Заходи в настройки оформления и выбирай новую зимнюю тему или
          кликай по кнопке ниже! А МЭШик пока пойдёт пробовать оливье
        </Typography>
        <Box sx={styles.buttons}>
          <Button size={buttonSize} onClick={onView} variant="secondary">
            Позже посмотрю
          </Button>
          <Button size={buttonSize} onClick={handleSetTheme}>
            Применить тему
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SnowThemePreviewComponent;
