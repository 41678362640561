import { FC, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Collapse } from '@mui/material';
import { useAppSelector, useDisclosure } from 'hooks';
import { IconChevronSmallDown, IconChevronSmallUp } from 'icons';
import { Button } from 'portfolio3/ui-kit/button';
import { isDefined } from 'utils';

import { filterControls, filterSections } from '../../const/filters';
import { proforientationRecommendationFiltersActions } from '../../model/actions';
import { getFilterStoreKey } from '../../utils/filters';

interface IFiltersContainerProps {
  sectionKey: keyof typeof filterSections;
  controlKeys: (keyof typeof filterControls)[];
}

const FiltersContainer: FC<PropsWithChildren<IFiltersContainerProps>> = ({ sectionKey, controlKeys, children }) => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.proforientationRecommendationFilters);

  const { isOpen, onToggle } = useDisclosure();

  const handleReset = () => {
    controlKeys.forEach((key) => {
      const storeKey = getFilterStoreKey(sectionKey, filterControls[key]);
      dispatch(proforientationRecommendationFiltersActions.setFilter(storeKey, null));
    });
  };

  const handleApply = () => {
    controlKeys.forEach((key) => {
      const storeKey = getFilterStoreKey(sectionKey, filterControls[key]);
      const appliedStoreKey = getFilterStoreKey(sectionKey, filterControls[key], 'applied');
      const currentValue = filters[storeKey];
      dispatch(proforientationRecommendationFiltersActions.setFilter(appliedStoreKey, currentValue));
    });
  };

  const canReset = controlKeys.some((key) => isDefined(filters[getFilterStoreKey(sectionKey, filterControls[key])]));
  const canApply = controlKeys.some((key) => {
    const currentValue = filters[getFilterStoreKey(sectionKey, filterControls[key])] ?? null;
    const appliedValue = filters[getFilterStoreKey(sectionKey, filterControls[key], 'applied')] ?? null;

    return currentValue !== appliedValue;
  });

  return (
    <Box className="filters-container">
      <Collapse in={isOpen}>
        <Box marginBottom="16px">{children}</Box>
      </Collapse>
      <Box display="flex" justifyContent="space-between">
        <Button
          iconRight={isOpen ? <IconChevronSmallUp /> : <IconChevronSmallDown />}
          variant="tetriary"
          onClick={onToggle}
        >
          Фильтры
        </Button>
        <Box display="flex" gap="16px">
          <Button variant="secondary" disabled={!canReset} onClick={handleReset}>
            Сбросить все
          </Button>
          <Button disabled={!canApply} onClick={handleApply}>
            Начать поиск
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FiltersContainer;
