import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useAppSelector } from 'hooks';
import { ProfessionProforientationGenericEventCard } from 'portfolio3/features/pdf/components/cards';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { proforientationModel } from 'portfolio3/features/proforientation';

const EventExcursionsSection: FC = () => {
  const excursions = useAppSelector(proforientationModel.selectors.proforientationEventSelectors.excursions);
  const hasData = excursions.length > 0;

  return (
    <Box className="event-excursions">
      <Typography variant="Headings/H5">Экскурсии к работодателю</Typography>
      <Box marginTop="16px">
        {hasData && (
          <Stack gap="16px">
            {excursions.map((event, index) => {
              const key = `${event.date}-${event.name}-${index}`;
              return (
                <ProfessionProforientationGenericEventCard
                  key={key}
                  date={event.date}
                  title={event.name}
                  subtitle={event.organizer}
                  industry={event.industry}
                />
              );
            })}
          </Stack>
        )}
        {!hasData && <PdfNoDataText />}
      </Box>
    </Box>
  );
};

export default EventExcursionsSection;
