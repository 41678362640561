import { FC } from 'react';

import { Box, Stack } from '@mui/material';
import { IDictionaryItem } from 'api/types';

import FiltersContainer from './FiltersContainer';
import { CollegeFilter, IndustryFilter, SpecialityFilter } from './specificFilters';

interface IRecommendationOpenDayFiltersProps {
  industryOptions: IDictionaryItem[];
  specialityOptions: IDictionaryItem[];
  collegeOptions: IDictionaryItem[];
}

const RecommendationOpenDayFilters: FC<IRecommendationOpenDayFiltersProps> = ({
  industryOptions,
  specialityOptions,
  collegeOptions,
}) => {
  const industryControl = <IndustryFilter section="dods" options={industryOptions} />;
  const specialityControl = <SpecialityFilter section="dods" options={specialityOptions} />;
  const collegeControl = <CollegeFilter section="dods" options={collegeOptions} />;

  return (
    <FiltersContainer sectionKey="dods" controlKeys={['industry', 'speciality', 'college']}>
      <Stack gap="12px">
        <Box className="row" sx={{ display: 'flex', gap: '12px' }}>
          {industryControl}
          {specialityControl}
        </Box>
        {collegeControl}
      </Stack>
    </FiltersContainer>
  );
};

export default RecommendationOpenDayFilters;
