import { IRecommendationIndustryWithSpecialities, IRecommendationSpeciality } from 'api/types';
import { formatDate, formatTime, getNumeral } from 'utils';

import { IndustryCodeIllustration } from '../const/industryIllustrationMapping';
import { IRecommendedIndustryWithSpecialities, IRecommendedSpeciality } from '../model/types';

export const getIndustryIllustration = (industryCode: number): string | undefined => {
  const illustrationCode = Number(industryCode) ?? -1;
  return IndustryCodeIllustration[illustrationCode];
};

export const getFormattedEventDate = (date: string, startTimeDate: string, endTimeDate: string) => {
  const dateText = formatDate(date);
  const startTimeText = formatTime(startTimeDate);
  const endTimeText = formatTime(endTimeDate);

  return `${dateText} ${startTimeText}–${endTimeText}`;
};

export const getMappedSpecialities = (specialities: IRecommendationSpeciality[]) => {
  return specialities.map((spec): IRecommendedSpeciality => {
    return {
      code: spec.specCode,
      name: spec.specName,
    };
  });
};

export const getMappedIndustriesWithSpeciality = (industries: IRecommendationIndustryWithSpecialities[]) => {
  return industries.map((industry): IRecommendedIndustryWithSpecialities => {
    return {
      industry: industry.industryName,
      specialities: industry.specialities.map((spec) => {
        return {
          code: spec.specCode,
          name: spec.specName,
        };
      }),
    };
  });
};

export const getComputedIndustryTitleOfIndustryList = (industries: IRecommendedIndustryWithSpecialities[]) => {
  let industryTitle = '';

  if (industries.length === 1) {
    industryTitle = industries[0]?.industry ?? '';
  } else {
    const pluralizedIndustry = getNumeral(industries.length, 'отрасль', 'отрасли', 'отраслей');
    industryTitle = `${industries.length} ${pluralizedIndustry}`;
  }

  return industryTitle;
};
